export default {
  DivisionsDefault: {
    CROSS_TALENT_1: 'Talent Übergreifend 1',
    CROSS_TALENT_2: 'Talent Übergreifend 2',
    TALENT_UNIT_1: 'Talent Unit  1',
    TALENT_UNIT_2: 'Talent Unit  2',
    TALENT_UNIT_3: 'Talent Unit  3',
    TALENT_UNIT_4: 'Talent Unit  4',
    TALENT_UNIT_5: 'Talent Unit  5',
    TALENT_UNIT_6: 'Talent Unit  6',
    TALENT_UNIT_7: 'Talent Unit  7',
    TALENT_UNIT_8: 'Talent Unit  8',
    TALENT_UNIT_9: 'Talent Unit  9',
    TALENT_UNIT__10: 'Talent Unit 10',
    TALENT_UNIT__11: 'Talent Unit 11',
    TALENT_UNIT__12: 'Talent Unit 12',
    HSC_SAP_TRANSFORMATION: 'HSC - SAP Transformation',
    HSC_SAP_TECHNOLOGY: 'HSC - SAP Technology',
    HSC_SAP_CUSTOM_DEVELOPMENT: 'HSC - SAP Custom Development',
    HSC_SALES_AND_MARKETING: 'HSC - Sales & Marketing',
    HSC_RECRUITING_AND_TRAINING_AND_OPERATIONS: 'HSC - Recruiting&Training&Operations',
    HSC_CONTROLLING_AND_FAKTURA: 'HSC - Controlling & Faktura',
    HSC_SAP_CROSS_INDUSTRY: 'HSC - SAP Cross Industry',
    HSC_SAP_BUSINESS_DEVELOPMENT: 'HSC - Business Development',
    CLIENT_MANAGEMENT: 'Client Management',
    SERVICE_PORTFOLIO_MANAGEMENT: 'Service Portfolio Management',
    FINANCE_OPERATIONS_SERVICES: 'Finance Operations Services',
    HDS_TALENT_MANAGEMENT_GORISSEN: 'HDS.Talent Management Gorissen',
    HDS_TALENT_MANAGEMENT_LOOKE: 'HDS.Talent Management Looke',
    HDS_TALENT_MANAGEMENT_UHE: 'HDS.Talent Management Uhe',
    HDS_TALENT_MANAGEMENT_FISCH: 'HDS.Talent Management Fisch',
    HDS_TALENT_MANAGEMENT_REISCHER: 'HDS.Talent Management Reischer',
    HDS_TALENT_MANAGEMENT_FRIES: 'HDS.Talent Management Fries',
    HDS_TALENT_MANAGEMENT_HELTEN: 'HDS.Talent Management Helten',
    HDS_CLOUD_TRANSFORMATION_PRODUKTENTW: 'HDS.Cloud Transformation - Produktentw.',
    HDS_CLOUD_TRANSFORMATION_PROJEKTE: 'HDS.Cloud Transformation - Projekte',
    HDS_CLOUD_SAAS_HDS_INFRASTRUKTUR: 'HDS.Cloud / SaaS HDS Infrastruktur',
    HDS_CLOUD_SAAS_OPERATIONS_PROJEKTE: 'HDS.Cloud / SaaS Operations Projekte',
    HDS_CUSTOMER_CENTRICITY_PRODUKTENTW: 'HDS.Customer Centricity - Produktentw.',
    HDS_CUSTOMER_CENTRICITY_PROJEKTE: 'HDS.Customer Centricity - Projekte',
    HDS_DIGITAL_BUSINESS_MODELS_PRODUKTENTW: 'HDS.Digital Business Models - Produktentw.',
    HDS_DIGITAL_BUSINESS_MODELS_PROJEKTE: 'HDS.Digital Business Models - Projekte',
    HDS_DIGITAL_BUSINESS_SERVICES_PRODUKTENTW: 'HDS.Digital Business Services - Produktentw.',
    HDS_DIGITAL_BUSINESS_SERVICES_PROJEKTE: 'HDS.Digital Business Services - Projekte',
    HDS_EXPERIENCE_MANAGEMENT_PROJEKTE: 'HDS.Experience Management - Projekte',
  },
  DivisionsGBI: {
    GBI_CUSTOMER: 'Kundenmanagement',
    GBI_PRIMINSUR: 'Erstversicherung',
    GBI_FIN_AND_RISK: 'Finance & Risk',
    GBI_REINSUR: 'Rückversicherung',
    GBI_LIFE_INSURANCE: 'Aktuarielle Beratung',
    GBI_XM_STRATEGY: 'XM Strategy & Transformation',
    GBI_BUSINESS: 'Business Services',
  },
  DivisionsZT: {
    IAS: 'IAS',
    DIT: 'DIT'
  },
  DivisionsMSG_SERVICES: {
    MSA_MANAGEMENT: "Steuerung",
    MSA_FINANCE: "Sales",
    MSA_CS1C: "AM Group",
    MSA_CS1U: "PFM",
    MSA_IT: "IMD",
    MSA_IOS: "DMS",
    MSA_DIGITAL_WORKPLACE: "DWI",
    MSA_SAP_AWS: "SAP HSC"
  },
  DivisionsM3: {
    M3_PRACTICE_1: "Practice 1",
    M3_PRACTICE_2: "Practice 2",
    M3_PRACTICE_3: "Practice 3"
  },
  DivisionsMSG_INDUSTRY_ADVISORS: {
    MIA_BPM: "BPM",
    MIA_COE: "COE",
    MIA_GRC: "GRC",
    MIA_LS_AND_C: "LS&C",
    MIA_MARKETS: "Markets",
    MIA_OPS: "OPS",
    MIA_PPM: "PPM",
    MIA_QMS: "QMS",
    MIA_SND: "SND",
    MIA_D_AND_A: "DUA",
    MIA_OTC: "S4C",
    MIA_CEX: "CEX",
    MIA_BTR: "BTR",
    MIA_MLR: "MLR"
  },
  DivisionsZM: {
    ZM_EDITORIAL_OFFICE: "00 - ZM Leitung/Kopfkostenstelle",
    ZM_WEB_AND_DIG: "10 - Web & Digital",
    ZM_COMMUNICATION: "20 - Kommunikation",
    ZM_EXECUTIVE: "21 - Executive Communications",
    ZM_BRAND_DESIGN: "30 - Brand & Design",
    ZM_EVENT: "42 - Event/Live",
    ZM_BUSINESS_PARTNER: "50 - Business Partner"
  },
  DivisionsTreorbis: {
    TREORBIS_ASM: "AMS",
    TREORBIS_ANALYTICS: "Business Analytics",
    TREORBIS_MANAGEMENT: "EPI Management",
    TREORBIS_PROJECTMANAGEMENT: "EPI Projektmanagement",
    TREORBIS_CONSULTING: "ERP Consulting",
    TREORBIS_TECHNOLOGIE: "ERP Technologie",
    TREORBIS_CONTROLLING: "Finance&Controlling",
    TREORBIS_HR_CONSULTING: "HR Consulting",
    TREORBIS_PRODUCTS: "HR Technologie and Products",
    TREORBIS_LANDSCAPE: "Landscape Transformation",
    TREORBIS_MTE: "MTE",
    TREORBIS_SALLES: "Sales"
  },
  DivisionsNexinsure: {
    NEXINSURE_AE1: "AE1",
    NEXINSURE_AE2: "AE2",
    NEXINSURE_ARCH_TEC: "Arch. & Tec. Leitung",
    NEXINSURE_DEV1: "Entwicklung 1",
    NEXINSURE_DEV2: "Entwicklung 2",
    NEXINSURE_INSURANCE_SUITE: "Insurance Suite Leben Leitung",
    NEXINSURE_LF_PROCESSES: "LF Prozesse",
    NEXINSURE_FACTORY: "Life Factory Leitung",
    NEXINSURE_LIFE_BA: "msg life BA",
    NEXINSURE_PROJECTS_AT: "Projects AT (vorm. Consulting AT)",
    NEXINSURE_PROJECTS_BA: "Projects BA",
    NEXINSURE_PROJECTS: "Projects Leitung",
    NEXINSURE_PROJECT_DIVISION: "Projektbereich",
    NEXINSURE_PS_BENELUX: "PS Benelux",
    NEXINSURE_SYSTEMTEC1: "Systemtechnik 1",
  },
  DivisionsGBF: {
    GBF_FOOD: "GBF - Food",
    GBF_SAP_STANDARD: "GBF - SAP Standard Projekte",
    GBF_SAP_DEV: "GBF - SAP Entwicklung",
    GBF_SAP_DAIRY: "GBF - SAP Dairy Produkt"
  },
  DivisionsGBG: {
    GBG_PUBLIC_SECTOR: "Public Sector",
    GBG_COUNTRIES_AND_JUSTICE: "Länder & Justiz",
    GBG_FEDERAL_ADMNISTRATION: "Bundesverwaltung",
    GBG_LABOR_AND_SOCIAL: "Arbeit & Soziales",
    GBG_EXTERNAL_SECURITY: "Äußere Sicherheit",
    GBG_SAP_CONSULTING: "SAP Consulting",
    GBG_BOS: "BOS"
  },
  DivisionsMSG_GILLARDON: {
    GILLARDON_IT_CONSULTING: "IT Consulting Genossenschaftliche FinanzGruppe"
  },

  DivisionsMSG_CH: {
    MSG_CH_Business_Solutions: "Business Solutions Kollektiv-Leben",
    MSG_CH_SAP_Finance: "SAP Finance",
    MSG_CH_Public_Tax: "Public - Tax",
    MSG_CH_SAP_Reinsurance: "SAP Reinsurance",
    MSG_CH_Managment_and_Admin: "Managment & Admin",
    MSG_CH_sales: "Sales"
  },

  DivisionsGBY: {
    GBY_GB_MANAGEMENT: "GB-Leitung",
    GBY_RHEIN_MAIN: "Rhein-Main",
    GBY_KOELN_BONN: "Köln/Bonn",
    GBY_HAMBURG: "Hamburg",
    GBY_RUHRGEBIET1: "Ruhrgebiet 1",
    GBY_RUHRGEBIET2: "Ruhrgebiet 2",
    GBY_BERLIN: "Berlin",
    GBY_DUESSELDORF: "Düsseldorf",
    GBY_VERTRIEB_AND_MARKETING: "Vertrieb & Marketing",
    GBY_PROC_AND_DATAINTELL_MGMT: "Proc&DataIntell-Mgmt",
    GBY_DIGITAL_TRANSFORMATI: "Digital Transformati",
    GBY_MDM_SOLUTIONS: "MDM Solutions",
    GBY_IND_40_TRANSFORM: "Ind. 4.0 Transform",
    GBY_T_IOT_SOLUTIONS: "T- IoT Solutions",
    GBY_T_IOT_AND_INTEGRATION: "T- IoT&Integration",
    GBY_T_IOT_AND_ANALYTICS: "T- IoT&Analytics",
    GBY_DATA_MGMT_CONSULTIN: "Data Mgmt. Consultin",
    GBY_PROCESS_DATA_ANALYTI: "Process-Data-Analyti",
    GBY_PDA_DAN: "PDA DAN",
    GBY_PDA_DCS: "PDA DCS",
    GBY_PDA_DCM: "PDA DCM",
    GBY_CLOUD_SERVICES: "Cloud Services",
    GBY_DIGITAL_INTELLIGENCE: "Digital Intelligence",
    GBY_KI_AND_DIG_ASSISTENTEN: "KI&Dig. Assistenten",
    GBY_BUSINESS_INTELLIGENC: "Business Intelligenc",
  }
};
