import {NgModule} from '@angular/core';
import {Router, RouterModule, Routes} from '@angular/router';
import {AppAuthGuard} from './core/guards/app.authguard';
import {BackNavigationGuard} from "./core/guards/back-navigation.guard";
import {HomeScreenComponent} from "./feature/homescreen/homescreen.component";

const EMPLOYEE_EDIT = 'employee-edit';
const EMPLOYEE_ID = '/:employeeId';
const PROJECT_DETAIL = 'project-details';
const DOCUMENTTITLE_EMPLOYEE_EDIT = 'mep.document-titles.employee-edit';
const DOCUMENTTITLE_PROJECT_EDIT = 'mep.document-titles.project-edit';

const routes: Routes = [{
  path: '',
  redirectTo: '/homescreen',
  pathMatch: 'full',
  data: {title: 'mep.document-titles.homescreen'}
},
  {
    path: 'login',
    redirectTo: '/homescreen'
  },
  {
    path: 'employee-list',
    loadChildren: () => import('./feature/employee-list/employee-list.module').then(m => m.EmployeeListModule),
    canActivate: [AppAuthGuard, BackNavigationGuard],
    canDeactivate: [BackNavigationGuard],
    data: {
      breadcrumbs: true,
      breadCrumbIcon: 'group',
      title: 'mep.document-titles.employee-list',
      name: 'EmployeeListComponent'
    },
  },
  {
    path: EMPLOYEE_EDIT,
    canActivate: [AppAuthGuard],
    data: {
      breadcrumbs: '/employee-list',
      title: DOCUMENTTITLE_EMPLOYEE_EDIT
    },
    children: [
      {
        path: ':employeeId',
        loadChildren: () => import('./feature/employee-operations/employee-operations.module').then(m => m.EmployeeOperationsModule),
        canActivate: [AppAuthGuard],
        data: {
          breadcrumbs: EMPLOYEE_EDIT,
          breadCrumbIcon: 'group',
          name: "EmployeeOperationsComponent"
        }
      },
      {
        path: ':employeeId/actionPlan',
        loadChildren: () => import('./feature/action-plan/action-plan.module').then(m => m.ActionPlanModule),
        canActivate: [AppAuthGuard],
        data: {
          breadcrumbs: EMPLOYEE_EDIT,
          breadCrumbIcon: 'group',
          title: 'mep.document-titles.action-plan',
          name: 'ActionPlanComponent'
        },
      },
      {
        path: 'absence-list/:employeeId',
        loadChildren: () => import('./feature/absence-list/absence-list.module').then(m => m.AbsenceListModule),
        canActivate: [AppAuthGuard],
        data: {
          breadcrumbs: '/absence-list',
          breadCrumbIcon: 'folder',
          title: 'mep.document-titles.absence-list'
        }
      },
    ]
  },
  {
    path: 'skill-search',
    children: [{
      path: '',
      loadChildren: () => import('./feature/skill-search/skill-search.module').then(m => m.SkillSearchModule),
      canActivate: [AppAuthGuard],
      data: {
        breadcrumbs: '/skill-search',
        breadCrumbIcon: 'group',
        title: 'mep.document-titles.skill-search'
      },
    }]
  },
  {
    path: 'admin-homescreen',
    loadChildren: () => import('./feature/admin-homescreen/admin-homescreen.module').then(m => m.AdminHomescreenModule),
    canActivate: [AppAuthGuard],
    data: {
      breadcrumbs: '/admin-homescreen',
      title: 'mep.document-titles.admin-homescreen'
    }
  },
  {
    path: 'resource-request-list',
    loadChildren: () => import('./feature/resource-request-list/resource-request-list.module').then(m => m.ResourceRequestListModule),
    canActivate: [AppAuthGuard],
    data: {
      breadcrumbs: true,
      breadCrumbIcon: 'list',
      title: 'mep.document-titles.resource-request-list'
    },
  },
  {
    path: 'reports-screen',
    children: [{
      path: '',
      loadChildren: () => import('./feature/reports-screen/reports-screen.module').then(m => m.ReportsScreenModule),
      canActivate: [AppAuthGuard]
    }],
    data: {
      title: 'mep.document-titles.reports',
      breadcrumbs: '/reports-screen',
    }
  },
  {
    path: 'absence-list',
    data: {
      breadcrumbs: true,
      breadCrumbIcon: 'folder',
      title: 'mep.document-titles.absence-list'
    },
    children: [
      {
        path: '',
        loadChildren: () => import('./feature/absence-list/absence-list.module').then(m => m.AbsenceListModule),
        canActivate: [AppAuthGuard]
      },
      {
        path: ':employeeId',
        loadChildren: () => import('./feature/absence-list/absence-list.module').then(m => m.AbsenceListModule),
        canActivate: [AppAuthGuard],
      },
      {
        path: EMPLOYEE_EDIT + EMPLOYEE_ID,
        loadChildren: () => import('./feature/employee-operations/employee-operations.module').then(m => m.EmployeeOperationsModule),
        canActivate: [AppAuthGuard],
        data: {
          breadcrumbs: EMPLOYEE_EDIT,
          breadCrumbIcon: 'group',
          title: DOCUMENTTITLE_EMPLOYEE_EDIT
        }
      }
    ]
  },
  {
    path: 'my-projects',
    data: {
      breadcrumbs: true,
      breadCrumbIcon: 'folder',
      title: 'mep.document-titles.my-projects'
    },
    children: [
      {
        path: '',
        loadChildren: () => import('./feature/my-projects/my-projects.module').then(m => m.MyProjectsModule),
        canActivate: [AppAuthGuard],
      },
      {
        path: 'project-edit/:id',
        loadChildren: () => import('./feature/project-edit/project-edit.module').then(m => m.ProjectEditModule),
        canActivate: [AppAuthGuard],
        data: {
          breadcrumbs: PROJECT_DETAIL,
          breadCrumbIcon: 'group',
          title: DOCUMENTTITLE_PROJECT_EDIT,
          name: 'ProjectEditComponent'
        }
      },
      {
        path: EMPLOYEE_EDIT + EMPLOYEE_ID,
        loadChildren: () => import('./feature/employee-operations/employee-operations.module').then(m => m.EmployeeOperationsModule),
        canActivate: [AppAuthGuard],
        data: {
          breadcrumbs: EMPLOYEE_EDIT,
          breadCrumbIcon: 'group',
          title: DOCUMENTTITLE_EMPLOYEE_EDIT,
          name: 'EmployeeOperationsComponent'
        }
      }
    ]
  },
  {
    path: 'project-list',
    loadChildren: () => import('./feature/project-list/project-list.module').then(m => m.ProjectListModule),
    canActivate: [AppAuthGuard, BackNavigationGuard],
    canDeactivate: [BackNavigationGuard],
    data: {
      breadcrumbs: true,
      breadCrumbIcon: 'folder',
      title: 'mep.document-titles.project-list',
      name: 'ProjectListComponent'
    }
  },
  {
    path: 'action-plan-list',
    canActivate: [AppAuthGuard],
    data: {
      breadcrumbs: true,
      breadCrumbIcon: 'alarm_on',
      title: 'mep.document-titles.action-plan-list'
    },
    loadChildren: () => import('./feature/action-plan/action-plan.module').then(m => m.ActionPlanModule)
  },
  {
    path: 'project-edit',
    data: {
      breadcrumbs: '/project-list',
      title: DOCUMENTTITLE_PROJECT_EDIT
    },
    children: [
      {
        path: ':id',
        loadChildren: () => import('./feature/project-edit/project-edit.module').then(m => m.ProjectEditModule),
        canActivate: [AppAuthGuard],
        data: {
          breadcrumbs: PROJECT_DETAIL,
          breadCrumbIcon: 'group'
        }
      }
    ]
  },
  {
    path: 'homescreen',
    component: HomeScreenComponent,
    canActivate: [AppAuthGuard],
    data: {
      title: 'mep.document-titles.homescreen'
    }
  },
  {
    path: 'employee-capacities',
    data: {
      breadcrumbs: true,
      breadCrumbIcon: 'exposure',
      title: 'mep.document-titles.employee-capacities'
    },
    children: [
      {
        path: '',
        loadChildren: () => import('./feature/employee-capacity-container/employee-capacity-container.module').then(m => m.EmployeeCapacityContainerModule),
        canActivate: [AppAuthGuard, BackNavigationGuard],
        canDeactivate: [BackNavigationGuard],
        data: {
          name: 'EmployeeCapacityContainerComponent'
        }
      },
      {
        path: EMPLOYEE_EDIT + EMPLOYEE_ID,
        loadChildren: () => import('./feature/employee-operations/employee-operations.module').then(m => m.EmployeeOperationsModule),
        canActivate: [AppAuthGuard],
        data: {
          breadcrumbs: EMPLOYEE_EDIT,
          breadCrumbIcon: 'group',
          title: DOCUMENTTITLE_EMPLOYEE_EDIT,
          name: "EmployeeEditComponent"
        }
      }
    ]
  },
  {
    path: 'project-total-capacities',
    data: {
      breadcrumbs: true,
      breadCrumbIcon: 'bar_chart',
      title: 'mep.document-titles.project-total-capacities'
    },
    children: [
      {
        path: '',
        loadChildren: () => import('./feature/project-total-capacity-list/project-total-capacities.module').then(m => m.ProjectTotalCapacitiesModule),
        canActivate: [AppAuthGuard]
      },
      {
        path: 'project-edit/:id',
        loadChildren: () => import('./feature/project-edit/project-edit.module').then(m => m.ProjectEditModule),
        canActivate: [AppAuthGuard],
        data: {
          breadcrumbs: PROJECT_DETAIL,
          breadCrumbIcon: 'exposure',
          title: DOCUMENTTITLE_PROJECT_EDIT
        }
      }
    ]
  },
  {
    path: 'instance-wide-search',
    loadChildren: () => import('./feature/instance-wide-search/instance-wide-search.module').then(m => m.InstanceWideSearchModule),
    canActivate: [AppAuthGuard],
    data: {
      breadcrumbs: true,
      breadCrumbIcon: "exposure",
      title: 'mep.document-titles.instance-wide-search'
    }
  },
  {
    path: 'global-search',
    data: {
      breadcrumbs: true,
      breadCrumbIcon: 'folder'
    },
    children: [
      {
        path: '',
        loadChildren: () => import('./feature/global-search-list/global-search-list.module').then(m => m.GlobalSearchListModule),
        canActivate: [AppAuthGuard],
      },
      {
        path: 'project-edit/:id',
        loadChildren: () => import('./feature/project-edit/project-edit.module').then(m => m.ProjectEditModule),
        canActivate: [AppAuthGuard],
        data: {
          breadcrumbs: PROJECT_DETAIL,
          breadCrumbIcon: 'group',
          title: DOCUMENTTITLE_PROJECT_EDIT
        }
      },
      {
        path: EMPLOYEE_EDIT + EMPLOYEE_ID,
        loadChildren: () => import('./feature/employee-operations/employee-operations.module').then(m => m.EmployeeOperationsModule),
        canActivate: [AppAuthGuard],
        data: {
          breadcrumbs: EMPLOYEE_EDIT,
          breadCrumbIcon: 'group',
          title: DOCUMENTTITLE_EMPLOYEE_EDIT
        }
      }
    ]
  },
];

export function BackNavigationGuardFactory(router: Router) {
  // This configuration only works if the component has the BackNavigationGuard assigned to its 'canActivate' and 'canDeactivate'
  return new BackNavigationGuard(router, [
    {
      componentName: "EmployeeListComponent",
      validTargets: ["EmployeeOperationsComponent", "ActionPlanComponent"]
    },
    {
      componentName: "ProjectListComponent",
      validTargets: ["ProjectEditComponent"]
    },
    {
      componentName: "ActionPlanListComponent",
      validTargets: ["ActionPlanComponent"]
    },
    {
      componentName: "EmployeeCapacityContainerComponent",
      validTargets: ["EmployeeOperationsComponent", "ActionPlanComponent"]
    }
  ]);
}

@NgModule({
  imports: [RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'})],
  exports: [RouterModule],
  providers: [
    {provide: BackNavigationGuard, useFactory: BackNavigationGuardFactory, deps: [Router]},
  ]
})
export class AppRoutingModule {
}
