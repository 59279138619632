import {Component} from '@angular/core';
import {TranslatableComponent} from "../../../core/models/TranslatableComponent";
import {TranslateService} from "@ngx-translate/core";
import {MatDialog, MatDialogConfig, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-disclaimer-dialog',
  templateUrl: './disclaimer-dialog.component.html',
  styleUrls: ['./disclaimer-dialog.component.css']
})
export class DisclaimerDialogComponent extends TranslatableComponent {

  constructor(protected translate: TranslateService,
              private dialogRef: MatDialogRef<DisclaimerDialogComponent>) {
    super(translate, 'DisclaimerDialogComponent');
  }

  public static openDialog(dialogContainer: MatDialog, afterClose: (result: boolean) => void) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = 600;

    dialogContainer.open(DisclaimerDialogComponent, dialogConfig).afterClosed().subscribe((result: boolean) => {
      if (afterClose) {
        afterClose(result);
      }
    });
  }

  confirm() {
    this.close(true);
  }

  cancel() {
    this.close(false);
  }

  private close(result: boolean) {
    this.dialogRef.close(result);
  }
}
