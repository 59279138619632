import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  Injector,
  LOCALE_ID,
  NgModule,
  Optional,
  SkipSelf
} from '@angular/core';
import {CommonModule, LocationStrategy} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';

import {CachingInterceptor} from './interceptors/caching.interceptor';
import {ServerErrorInterceptor} from './interceptors/server-error.interceptor';
import {KeycloakAngularModule, KeycloakBearerInterceptor, KeycloakService} from 'keycloak-angular';

import {AppAuthGuard} from './guards/app.authguard';

import {RouterModule} from '@angular/router';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {NavigationComponent} from './components/navigation/navigation.component';
import {MessageComponent} from './components/message/message.component';
import {BreadcrumbComponent} from './components/breadcrumb/breadcrumb.component';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {BreadcrumbsModule} from "@exalif/ngx-breadcrumbs";


import {MEP_DATE_FORMATS} from './models/dateFormat';
import {OverlayModule} from '@angular/cdk/overlay';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MomentDateAdapter, MomentDateModule} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from "@angular/material/core";
import {InfoDialogModule} from '../shared/info-dialog/info-dialog.module';
import {AutoCompleteModule} from '../shared/autocomplete/autocomplete.module';
import {
  BreadcrumbEmpSearchComponent
} from './components/breadcrumb/breadcrumb-emp-search/breadcrumb-emp-search.component';
import {
  BreadcrumbProjectSearchComponent
} from './components/breadcrumb/breadcrumb-project-search/breadcrumb-project-search.component';
import {PermissionModule} from "../shared/permission/permission.module";
import {AutofocusModule} from "../shared/autofocus/autofocus.module";
import {PipesModule} from "../shared/pipes/pipes.module";
import {GlobalSearchBoxComponent} from "./components/navigation/global-search-box/global-search-box.component";
import {appInitFactory} from "./initializer/app-init-factory";
import {Title} from "@angular/platform-browser";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatIconModule} from "@angular/material/icon";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatListModule} from "@angular/material/list";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatButtonModule} from "@angular/material/button";
import {MatInputModule} from "@angular/material/input";
import {InputDirectivesModule} from "../shared/inputdirectives/input-directives.module";
import {initializer} from "./initializer/app-init";
import {CustomizingService} from "./services/services/customizing.service";
import {PendingChangesGuard} from "./guards/pending-changes.guard";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {HomescreenModule} from "../feature/homescreen/homescreen.module";
import {
  BreadcrumbResourceRequestSearchComponent
} from './components/breadcrumb/breadcumb-resource-request-search/breadcrumb-resource-request-search.component';
import {NewsNotificationModule} from "../shared/news-notification/news-notification.module";

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  imports: [
    HttpClientModule,
    CommonModule,
    BreadcrumbsModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    MomentDateModule,
    FormsModule,
    ReactiveFormsModule,
    OverlayModule,
    KeycloakAngularModule,
    InfoDialogModule,
    AutoCompleteModule,
    PermissionModule,
    AutofocusModule,
    PipesModule,
    MatFormFieldModule,
    MatIconModule,
    MatToolbarModule,
    RouterModule,
    MatSidenavModule,
    MatListModule,
    MatTooltipModule,
    MatButtonModule,
    MatInputModule,
    InputDirectivesModule,
    MatProgressSpinnerModule,
    HomescreenModule,
    NewsNotificationModule
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: CachingInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ServerErrorInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: KeycloakBearerInterceptor, multi: true},
    AppAuthGuard,
    TranslateService,
    {provide: LOCALE_ID, useValue: 'de'},
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MEP_DATE_FORMATS},
    {
      provide: APP_INITIALIZER,
      useFactory: appInitFactory,
      deps: [TranslateService, Injector, CustomizingService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializer,
      multi: true,
      deps: [KeycloakService, CustomizingService, LocationStrategy]
    },
    Title,
    PendingChangesGuard
  ],
  exports: [
    TranslateModule,
    MomentDateModule,
    FormsModule,
    ReactiveFormsModule,
    OverlayModule,
    KeycloakAngularModule,
    NavigationComponent,
    BreadcrumbComponent,
    BreadcrumbEmpSearchComponent,
    BreadcrumbProjectSearchComponent,
    MessageComponent,
    PipesModule,
    GlobalSearchBoxComponent
  ],
  declarations: [
    GlobalSearchBoxComponent,
    NavigationComponent,
    MessageComponent,
    BreadcrumbComponent,
    BreadcrumbEmpSearchComponent,
    BreadcrumbProjectSearchComponent,
    BreadcrumbResourceRequestSearchComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CoreModule {

  constructor(@Optional() @SkipSelf() otherCoreModule: CoreModule) {
    if (otherCoreModule) {
      throw new Error("Core module is only allowed to be loaded once!.");
    }
  }

}
