import {HttpBaseService} from './http-base.service';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {MessageService} from './message.service';
import {Injectable} from '@angular/core';
import {
  ProjectCapacityListElement
} from '../../../feature/project-total-capacity-list/project-total-capacity.interface';

@Injectable({
  providedIn: 'root'
})
export class ProjectTotalCapacityService extends HttpBaseService {

  url: string = environment.baseUrl + 'api/projects/projectCapacities';

  constructor(public http: HttpClient, public messageService: MessageService) {
    super(http, messageService);
  }

  public async getProjectCapacities(startMonth: string, isMyProjectCapacityView: boolean): Promise<ProjectCapacityListElement[]> {
    return (await this.getAll<any[]>(this.url + '/' + startMonth + '/' + isMyProjectCapacityView).toPromise()).body.map(result => ({
        projectName: result.projectName,
        sharePointId: result.sharePointId,
        client: result.client,
        divisionManager: result.divisionManager,
        projectLeader: result.projectLeader,
        projectCapacity: result.valueMap.valueOf(),
        internalEffort: result.internalEffort,
        myProject: result.myProject,
        probabilities: result.probabilities,
        totalCapacitiesPercent: result.totalCapacitiesPercent,
        effortSum: Math.round(Object.values<number>(result.internalEffort).reduce((a, b) => a + b, 0) * 10) / 10,
        projectCapacitySum: Math.round(Object.values<number>(result.valueMap).reduce((a, b) => a + b, 0) * 10) / 10,
        projectResourceLink: result._links.self.href,
        expanded: false
      })
    );
  }

}
