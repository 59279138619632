/** Angular Modules Import */
import {ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CoreModule} from './core/core.module';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import moment from "moment";
// Manually import necessary locales
import "moment/locale/de";
import "moment/locale/en-gb";

import {CommonModule, registerLocaleData} from '@angular/common';
import localeDe from '@angular/common/locales/de';
import {MatPaginatorIntl} from "@angular/material/paginator";
import {MatPaginatorTranslator} from "../locale/MatPaginatorTranslator";
import {ClientErrorHandlerService} from "./core/services/services/client-error-handler.service";
import {MessageService} from "./core/services/services/message.service";
import {OverlayModule} from "@angular/cdk/overlay";
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from "@angular/material/form-field";
import {MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions} from "@angular/material/tooltip";

registerLocaleData(localeDe, 'de');

export const myCustomTooltipDefaults: MatTooltipDefaultOptions = {
  showDelay: 0,
  hideDelay: 0,
  touchendHideDelay: 1500,
  disableTooltipInteractivity:true
};

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    OverlayModule,
  ],
  bootstrap: [AppComponent],
  providers: [
    {provide: MatPaginatorIntl, useClass: MatPaginatorTranslator},
    {provide: ErrorHandler, useClass: ClientErrorHandlerService, deps: [MessageService]},
    {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {appearance: 'outline'}},
    {provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: myCustomTooltipDefaults},
  ]
})
export class AppModule {
}

moment.fn.toJSON = function () {
  return this.format('YYYY-MM-DD');
};
