import {Directive, HostListener} from '@angular/core';
import {SanitizeInputPipe} from "../pipes/pipes/sanitize-input.pipe";

@Directive({
  selector: '[appSanitizeInput]'
})
export class SanitizeInputDirective {

  constructor() {}

  @HostListener('input', ['$event'])
  onKeyPress(event: KeyboardEvent) {
    if (event.target['value'] && event.constructor.name === 'InputEvent') {

      event.target['value'] = SanitizeInputPipe.sanitize(event.target['value']);
      event.target.dispatchEvent(new Event('input'));
    }
  }
}
