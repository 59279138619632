import {Component, OnInit, ViewChild} from '@angular/core';
import {TranslatableComponent} from "../../../../../core/models/TranslatableComponent";
import {TranslateService} from "@ngx-translate/core";
import {AlertEnum} from "../../../../../core/models/Enums/AlertEnum";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ResourceRequestFile} from "../../../models/resource-request-file";
import moment from "moment";
import {MessageService} from "../../../../../core/services/services/message.service";
import {AuthenticationService} from "../../../../../core/services/services/authentication.service";

@Component({
  selector: 'app-assets-upload',
  templateUrl: './assets-upload.component.html'
})
export class AssetsUploadComponent extends TranslatableComponent implements OnInit {
  today = moment(new Date()).format('yyyy-MM-DDThh:MM:ss.ms');
  username: string;

  resourceRequestFile: ResourceRequestFile;

  loading = false;

  constructor(protected translate: TranslateService, private dialogRef: MatDialogRef<AssetsUploadComponent>,
              private messageService: MessageService,
              private authService: AuthenticationService,
              private _dialog: MatDialog) {
    super(translate, 'AssetsUploadComponent');
  }

  ngOnInit(): void {
    this.resourceRequestFile = new ResourceRequestFile();
    this.authService.getApplicationUser().then(user => {
      this.username = user.username;
    });
  }

  save() {
    if (this.resourceRequestFile.file) {
      this.resourceRequestFile.createdAt = this.today.toString();
      this.resourceRequestFile.createdBy = this.username;
      this.resourceRequestFile.name = this.resourceRequestFile.file.name;
      this.dialogRef.close(this.resourceRequestFile);
    } else {
      const message = this._lang('chooseFile');
      this.messageService.add(message, AlertEnum.danger);
    }
  }

  private fileSizeOk(file: File) {
    const sizeRestriction = 20;
    return (file.size / 1024 / 1024) < sizeRestriction;
  }

  onFileChosen(file: File) {
    if (this.fileSizeOk(file)) {
      this.resourceRequestFile.file = file;
    } else {
      this.resourceRequestFile.file = null;
      const message = this.translate.instant('mep.components.files-dialog.failed-file-requirements-size');
      this.messageService.add(message, AlertEnum.warning);
    }
  }

  cancel() {
    this.dialogRef.close();
  }
}
