import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import Config from '../../../core/config/Config';
import {Employee} from '../../models/Employee';
import {HttpBaseService} from './http-base.service';
import {MessageService} from './message.service';
import {AlertEnum} from '../../models/Enums/AlertEnum';
import {EnumMapper} from './enumMapper.service';
import {Observable} from 'rxjs';
import {AssignmentProjectEmployee} from '../../models/AssignmentProjectEmployee';
import {TranslateService} from '@ngx-translate/core';
import {map, tap} from 'rxjs/operators';
import ConfigurableClientAttributes from '../../../core/config/ConfigurableClientAttributes';
import {Absence} from '../../../feature/absence-list/components/absence-list/absence-list.interface';
import {EmployeeHierarchy} from '../../models/EmployeeHierarchy';
import {Page} from '../../models/Page';
import {Category, Cluster, EmployeeSkill, Skill, SkillWithAverageLevel} from "../../models/EmployeeSkill";
import {Project} from "../../models/Project";
import {Blacklist} from '../../models/Blacklist';
import {EmployeeFullName} from "../../models/EmployeeFullName";
import {CustomizingService} from "./customizing.service";
import moment from "moment";
import {AssignmentEmployeeProjectTimeSlice} from "../../models/viewModels/AssignmentEmployeeProjectTimeSlice";
import {ScreenRefreshService} from "./refresh-screens.service";
import {EmployeeProfileData} from "../../models/EmployeeProfileData";
import {SkillEvaluation, SkillEvaluationExportData} from "../../models/SkillEvaluation";
import {DropDownChoice} from "../../../feature/employee-operations/abstract-employee-details.component";
import {
  EmployeeProjectCapacityParameters
} from "../../../shared/capacity-list/employee-capacity-list/employee-capacity-list.component";
import {Client} from "../../config/Client";
import {NotifyHrmOrigin} from "../../models/mail/NotifyHrmOrigin";
import {EmployeePermissionAttributeDTO} from "../../models/EmployeePermissionAttributeDTO";

@Injectable({
  providedIn: 'root'
})

export class EmployeeService extends HttpBaseService {

  errorMessageKey = 'mep.services.employee.fetch-employees-failed';
  private readonly API_URL = Config.services.employees.baseUrl; // >>> This url should be checked ???
  private allEmployeeAttributsWithOptions = ['jobVariant', 'status', 'location', 'country', 'travelWillingness', 'travelWillingnessCombined', 'businessUnit', 'division', 'jobFamily', 'jobLevel', 'travelWillingnessInternational'];

  constructor(public http: HttpClient, public messageService: MessageService, private translate: TranslateService,
              private customizationService: CustomizingService, private _screenRefreshService: ScreenRefreshService) {
    super(http, messageService);

  }

  static monthToString(month: number) {
    switch (month) {
      case 1:
        return "JAN";
      case 2:
        return "FEB";
      case 3:
        return "MAR";
      case 4:
        return "APR";
      case 5:
        return "MAY";
      case 6:
        return "JUN";
      case 7:
        return "JUL";
      case 8:
        return "AUG";
      case 9:
        return "SEP";
      case 10:
        return "OCT";
      case 11:
        return "NOV";
      case 12:
        return "DEC";
      default:
        throw new Error(`Unknown month with index '${month}'`);
    }
  }

  async getEmployee(employeeResourceURL: string, currentClient: Client, projection: string = null): Promise<Employee> {
    if (projection) {
      projection = '?projection=' + projection;
    } else {
      projection = '';
    }

    try {
      let e: Employee = (await this.http.get<any>(employeeResourceURL.replace('{?projection}', projection), {
        observe: 'response'
      }).toPromise()).body;
      return EnumMapper.mapEmployeeData(e, currentClient, false, this.translate);
    } catch (e) {
      this.messageService.add(this.translate.instant('mep.services.employee.fetch-employee-failed'), AlertEnum.danger);
    }
  }

  getEmployeeAssignments<T>(url: string): Observable<any> {
    return this.http.get<T>(url, {observe: 'response'});
  }

  getEmployeeSkillsById(employeeId: string | number): Observable<any> {
    return this.http.get(Config.services.employeeSkills.baseUrl + employeeId + '/skills');
  }

  getEmployeeById(employeeId: string | number): Observable<{ 'string': Employee }> {
    return this.http.get<{ 'string': Employee }>(this.API_URL + employeeId);
  }

  getAllEmployeeSkills(): Observable<{ 'string': EmployeeSkill[] }> {
    return this.http.get<{ 'string': EmployeeSkill[] }>(Config.services.skills.baseUrl + '/all');
  }

  getAllEmployeeSkillsWithoutExpiredCertificates(): Observable<{ 'string': EmployeeSkill[] }> {
    return this.http.get<{ 'string': EmployeeSkill[] }>(Config.services.skills.baseUrl + '/allWithoutExpired');
  }

  getSkillPage(page: number, pageSize: number, filter: string, maintenance: boolean, showSkillsWithEmployeeSkills: boolean, showFullTable: boolean,
               employeeId?, parentClusterId?: number, parentCategoryId?: number, newDate?, additionalSkillsToShow?: number[], unselectedSkills?: number[]): Observable<Page<SkillWithAverageLevel>> {
    const params = {page: `${page}`, pageSize: `${pageSize}`, filter};

    params['maintenance'] = maintenance;

    if (employeeId !== undefined) {
      params['employeeId'] = employeeId;
      if (additionalSkillsToShow && additionalSkillsToShow.length > 0) {
        params['additionalSkillsToShow'] = additionalSkillsToShow;
      }
      if (unselectedSkills && unselectedSkills.length > 0) {
        params['unselectedSkills'] = unselectedSkills;
      }
    }

    if (newDate !== undefined) {
      params['newDate'] = newDate;
    }

    if (typeof parentClusterId === 'number') {
      params['parentClusterId'] = parentClusterId;
    }
    if (typeof parentCategoryId === 'number') {
      params['parentCategoryId'] = parentCategoryId;
    }

    params['showSkillsWithEmployeeSkills'] = showSkillsWithEmployeeSkills;
    params['showFullTable'] = showFullTable;

    const route = "/getSkillPage";
    return this.http.get<Page<SkillWithAverageLevel>>(Config.services.skills.baseUrl + route, {params});
  }

  setSkillDbRows(employee: Employee, employeeSkills: EmployeeSkill[], sort: boolean) {
    employee.skillDbRows = [];

    let skillNames = [];
    for (const employeeSkill of employeeSkills) {
      let skillName;
      let level;

      if (employeeSkill.experienceLevel && employeeSkill.experienceLevel.length > 1) {
        skillName = employeeSkill.skill.skillName + ': ' + employeeSkill.experienceLevel;
        level = 0;
      } else {
        skillName = employeeSkill.skill.skillName;
        level = employeeSkill.experienceLevel;
      }

      employee.skillDbRows.push({skillName, level});
      skillNames.push(skillName);
    }

    if (sort) {
      employee.skillDbRows.sort((a, b) => b.level - a.level);
    }

    employee.skillDbSkillNames = skillNames.join(' ');
  }

  getAllSkillCategories(maintenance: boolean): Observable<Category[]> {
    return this.http.get<Category[]>(Config.services.skills.baseUrl + '/getAllCategories/' + maintenance);
  }

  getAllSkillCluster(maintenance: boolean, employeeId: number): Observable<Cluster[]> {
    let urlParams = {};
    urlParams["maintenance"] = maintenance;
    if (employeeId) {
      urlParams["employeeId"] = employeeId;
    }
    return this.http.get<Cluster[]>(Config.services.skills.baseUrl + '/getAllCluster', {params: urlParams});
  }

  saveSkill(skill: Skill): Observable<Skill> {
    return this.add<any>(Config.services.skills.baseUrl, skill);
  }

  deleteSkill(skill: Skill) {
    return this.delete(Config.services.skills.baseUrl
      + '/' + skill.id);
  }

  deletePermissions(employeeID: number) {
    return this.delete(Config.services.employeePermission.baseUrl
      + '/deletePermissions/' + employeeID);
  }

  addPermission(key: string, permission: string) {
    let perm = new EmployeePermissionAttributeDTO(key, permission);
    return this.http.post(Config.services.employeePermission.baseUrl
      + '/addPermission', perm);
  }

  async getEmployeeMisplannedCapa<T>(url: string): Promise<any> {
    try {
      return (await this.http.get<T>(url, {observe: 'response'}).toPromise()).body;
    } catch (e) {
      this.messageService.add(this.translate.instant('mep.services.employee.fetch-employee-misplanned-capa-failed'), AlertEnum.danger);
    }
  }


  deleteAssignmentsFromEmployeeToProjectSharePointId(employeeId: number, projectSharePointId: number): Observable<any> {
    return this.http.delete(this.API_URL + employeeId + "/assignmentsToSharePointId/" + projectSharePointId, {observe: 'response'})
      .pipe(tap(() => {
        this._screenRefreshService.employeeWasRemoved();
        this.messageService.add(this.translate.instant('mep.services.project-service.processed'), AlertEnum.success);
      }, () => {
        this.messageService.add(this.translate.instant('mep.services.project-service.delete-assignment-failed'), AlertEnum.danger);
      }));
  }

  /**
   * Gets employee data.
   *
   * @param url url to get the data
   * @param projection
   */
  getEmployeeData<T>(url: string, projection: string = null): Observable<any> {
    projection = projection ? '?projection=' + projection : '';
    return this.http.get<T>(url.replace('{?projection}', projection), {observe: 'response'});
  }

  /**
   * Returns the AssignmentEmployeeProjectTimeSlices for an employee which consists of the assignments with recent effectiveUntil.
   *
   * @param e_id employee id
   * @param employeeProjectCapacityParameters
   */
  async getRecentAssignmentEmployeeProjectCapacityTimeSlices(e_id: number, employeeProjectCapacityParameters: EmployeeProjectCapacityParameters): Promise<AssignmentEmployeeProjectTimeSlice[]> {
    const requestUrl = `${Config.services.assignmentProjectEmployees.baseUrl}${Config.services.assignmentProjectEmployees.assignmentEmployeeProjectTimeSlices}${e_id}
    /${employeeProjectCapacityParameters.months}/${moment(employeeProjectCapacityParameters.startDate).format('MM_YYYY')}`;
    const resultObject = (await this.getAll<AssignmentEmployeeProjectTimeSlice[]>(requestUrl).toPromise()).body;
    return resultObject.map(result => this.extractEmployeeProjectCapacityResult(result));
  }

  getRecentAssignmentEmployeeProjectTimeSlices(employeeId: number): Observable<AssignmentEmployeeProjectTimeSlice[]> {
    return this.http.get<AssignmentEmployeeProjectTimeSlice[]>(Config.services.assignmentProjectEmployees.baseUrl +
      Config.services.assignmentProjectEmployees.assignmentEmployeeProjectTimeSlices + employeeId + '/1/' + moment(new Date()).format("MM_YYYY"));
  }

  extractEmployeeProjectCapacityResult(result: any): AssignmentEmployeeProjectTimeSlice {
    return {
      assignmentEffectiveFrom: result.assignmentEffectiveFrom,
      assignmentEffectiveUntil: result.assignmentEffectiveUntil,
      projectName: result.projectName,
      client: result.client,
      assignmentCapacity: result.assignmentCapacity,
      projectTimeSliceProbability: result.projectTimeSliceProbability,
      capacityInPersonDays: result.capacityInPersonDays,
      vacation: result.vacation,
      priority: result.priority
    };
  }

  updateEmployee(e: Employee): Observable<Employee> {
    return this.patch<Employee>(e._links.self.href, e);
  }

  updateEmployeeDispoFlag(employeeId: number): Observable<any> {
    const url = this.API_URL + 'dispoFlag/' + employeeId;
    return this.http.put(url, {});
  }

  createEmployee(e: Employee): Observable<Employee> {
    delete e._links;
    if (e.personnelNumber === '') {
      delete e.personnelNumber;
    }
    if (e.country === '' || e.country === null) {
      e.country = EnumMapper.getCountry(e.location);
    }
    return this.add<Employee>(this.API_URL, e);
  }

  employeeHasNoAssignments(e: Employee): Observable<any> { // returns a boolean
    return this.http.get(this.API_URL + e.id + "/hasNoAssignments");
  }

  deleteEmployee(e: Employee, deleteById: boolean): Observable<any> {
    return this.delete(this.deleteEmployeeLink(e, deleteById) + '/manually');
  }

  deleteEmployeeAndAssignments(e: Employee, deleteById: boolean): Observable<any> {
    return this.delete(this.deleteEmployeeLink(e, deleteById) + '/assignments');
  }

  public async addAbsence(employeeId: number, fromDate: Date, untilDate: Date, absenceType: string): Promise<Absence> {
    const url = Config.services.absence.baseUrl + employeeId + '/addAbsence';
    let from = fromDate.toISOString().split('T')[0];
    let until = untilDate.toISOString().split('T')[0];
    let params = {from, until, absenceType};
    return (this.http.post<Absence>(url, {observe: 'response'}, {params})).toPromise();
  }

  public deleteAbsence(absence: Absence): Observable<any> {
    return this.http.delete(Config.services.absence.baseUrl + absence.id);
  }

  public patchAbsence(absence: Absence): Observable<Absence> {
    return this.http.patch<Absence>(Config.services.absence.baseUrl + absence.id, absence);
  }

  public fetchVacationDaysPerMonth(selectedEmployee: Employee, startDateAsString: string): Observable<any> {
    return this.http.get<any>(Config.services.absence.baseUrl + selectedEmployee.id + "/getVacationPerMonth/" + startDateAsString);
  }

  /**
   * Get Person Days for a specific employee
   */
  async getPersonDays(employee: Employee, startDateAsUrlString: string): Promise<any> {
    const url = employee._links.self.href + '/' + Config.services.employees.personDays + '/' + startDateAsUrlString;
    try {
      return (await this.http.get<any>(url, {
        observe: 'response'
      }).toPromise()).body;
    } catch (e) {
      this.messageService.add(this.translate.instant('mep.services.employee.fetch-persondays-failed'), AlertEnum.danger);
    }
  }

  async getExternalRateAndCurrency(project: Project, employee: Employee): Promise<any> {
    const url = project._links.self.href + '/' + employee.id + "/rate";
    try {
      const rate = (await this.http.get<any>(url, {
        observe: 'response'
      }).toPromise()).body;
      return rate[0];
    } catch (e) {
      this.messageService.add(this.translate.instant('mep.services.employee.fetch-rate-failed'), AlertEnum.danger);
    }
  }

  getAssignments(employeeLink: string): Observable<AssignmentProjectEmployee[]> {
    return this.getEmployeeData(employeeLink + Config.services.assignmentProjectEmployees.assignmentEmployeeProjectInfo)
      .pipe(
        map(e => e.body._embedded.assignmentProjectEmployees)
      );
  }

  mapAttributeClientConfigurableOption(attribute: string, currentClient?: string) {
    if (currentClient && ConfigurableClientAttributes[currentClient] && ConfigurableClientAttributes[currentClient].includes(attribute)) {
      return attribute + currentClient;
    } else {
      return attribute;
    }
  }

  /**
   * Retrieves all Options for Attributes of the employee that have dropdown values.
   *
   * @param currentClient The current client
   * @return An object mapping the attribute names to their possible values asa DropDownChoice array.
   */
  fetchAttributeOptions(currentClient?: Client): { [index: string]: DropDownChoice<string>[] } {
    return this.fetchSomeAttributeOptions(this.allEmployeeAttributsWithOptions, currentClient);
  }

  /**
   * Retrieves all Options for Attributes of the employee that have dropdown values.
   *
   * @param currentClient The current client
   * @param attributes The attribute names for which the dropdown values should be fetched
   * @return An object mapping the attribute names to their possible values asa DropDownChoice array.
   */
  fetchSomeAttributeOptions(attributes: string[], currentClient?: string): { [index: string]: DropDownChoice<string>[] } {
    let allOptions: { [index: string]: DropDownChoice<string>[] } = {};
    for (let attribute of attributes) {
      if (attribute === 'travelWillingnessInternational') {
        allOptions[attribute + 'Clean'] = this.getOptions(attribute);
      }
      allOptions[attribute] = this.fetchAttributeOption(attribute, true, currentClient);
    }
    return allOptions;
  }

  /**
   * Fills the FilterViewData of the given FilterViewModel with all attributes of the employee that have dropdown values.
   * The attributes in the FilterViewData object have to match the attributes of the employee.
   *
   * @param filterViewModel The FilterViewModel to fill.
   * @param currentClient The current client.
   */
  fillFilterViewModelWithAllAttributes(filterViewModel, currentClient?: Client) {
    this.fillFilterViewModelWithAttributeOptions(filterViewModel, currentClient, this.allEmployeeAttributsWithOptions);
  }

  /**
   * Fills the FilterViewData of the given FilterViewModel with the given attributes of the employee.
   * The attributes in the FilterViewData object have to match the attributes of the employee.
   *
   * @param attributes The attributes of the employee for which the FilterViewModel should be filled.
   * @param attributesInViewData The attributes in the view data of the FilterViewModel to set.
   * @param filterViewModel The FilterViewModel to fill.
   * @param currentClient The current client.
   */
  fillFilterViewModelWithAttributeOptions(filterViewModel, currentClient: Client, attributes: string[], attributesInViewData: string[] = attributes) {
    if (attributes.length !== attributesInViewData.length) {
      throw new Error("Number of attributes and attributes in view data do not match.");
    }
    for (let i = 0; i < attributes.length; i++) {
      if (filterViewModel.viewData[attributesInViewData[i]]) {
        filterViewModel.viewData[attributesInViewData[i]] = this.fetchAttributeOption(attributes[i], true, currentClient);
      }
    }
  }

  /**
   * Retrieves the Options for the given attribute of the employee.
   *
   * @param attribute The attribute of the employee to fetch options for.
   * @param addNAOptions If the Config.services.missingValueBackend option should be added to the returned list.
   * @param currentClient The current client
   * @return An object mapping the attribute names to their possible values asa DropDownChoice array.
   */
  fetchAttributeOption(attribute: string, addNAOptions: boolean, currentClient?): DropDownChoice<string>[] {
    let configAttributeName = this.mapAttributeClientConfigurableOption(attribute, currentClient);
    let options = this.getOptions(configAttributeName);
    if (addNAOptions) {
      options.unshift({
        value: Config.services.missingValueBackend,
        viewValue: Config.services.missingValue
      });
    }
    return options.sort((a, b) => a.viewValue.localeCompare(b.viewValue));
  }

  async fetchManualOverwrittenProportionsOfWorkingTime(selectedEmployee: Employee, startDateAsString: string): Promise<any> {
    try {
      const personDays = await this.getPersonDays(selectedEmployee, startDateAsString);
      const months = Object.keys(personDays);
      const headerCol = ['header'];
      const manualOverwrittenProportionsOfWorkingTimeColumns = headerCol.concat(months);
      const misplannedEmployeePT = await this.getEmployeeMisplannedCapa(selectedEmployee._links.self.href + '/freeCapacityById/' + startDateAsString + '/' + false);
      const manualOverwrittenProportionsOfWorkingTime = await this.http.get(selectedEmployee._links.self.href + "/manualOverwrittenProportionsOfWorkingTime").toPromise();
      const manualOverwrittenProportionsOfWorkingTimeDataSource = [{
        headerCol: this.translate.instant('mep.services.employee.manual-overwritten-availability-col-header'),
        headerColTooltip: this.translate.instant('mep.components.employee-additional-info.tooltip-overload'),
        value: manualOverwrittenProportionsOfWorkingTime,
        hasColor: false,
        color: false,
        contenteditable: true
      },
        {
          headerCol: this.translate.instant('mep.services.employee.contractual-availability-col-header'),
          headerColTooltip: this.translate.instant('mep.services.employee.contractual-availability-col-header-tooltip'),
          value: personDays,
          hasColor: true,
          color: true,
          contenteditable: false
        },
        {
          headerCol: this.translate.instant('mep.components.general.misplannedCapacityPT'),
          headerColTooltip: this.translate.instant('mep.services.employee.misplanned-capacity-PT-col-header-tooltip'),
          value: misplannedEmployeePT && misplannedEmployeePT[0] ? misplannedEmployeePT[0].valueMap : '',
          hasColor: true,
          color: false,
          contenteditable: false
        }];

      let isEmployeeDetailsPlannedCapacityEnabled = await this.customizationService.isEmployeeDetailsPlannedCapacityEnabled();

      if (isEmployeeDetailsPlannedCapacityEnabled) {
        const plannedEmployeePT = await this.getEmployeeMisplannedCapa(selectedEmployee._links.self.href + '/freeCapacityById/' + startDateAsString + '/' + true);
        manualOverwrittenProportionsOfWorkingTimeDataSource.push({
          headerCol: this.translate.instant('mep.services.employee.plannedCapacityPT'),
          headerColTooltip: this.translate.instant('mep.services.employee.planned-capacity-PT-col-header-tooltip'),
          value: plannedEmployeePT && plannedEmployeePT[0] ? plannedEmployeePT[0].valueMap : '',
          hasColor: true,
          color: false,
          contenteditable: false
        });
      }

      let isVacationDaysPerMonth = await this.customizationService.isVacationDaysPerMonthEnabled();

      if (isVacationDaysPerMonth) {
        const vacationDaysPerMonth = await this.fetchVacationDaysPerMonth(selectedEmployee, startDateAsString).toPromise();
        manualOverwrittenProportionsOfWorkingTimeDataSource.push({
          headerCol: this.translate.instant('mep.components.general.vacationDays'),
          headerColTooltip: this.translate.instant('mep.services.employee.vacation-day-col-header-tooltip'),
          value: vacationDaysPerMonth,
          hasColor: false,
          color: false,
          contenteditable: false
        });
      }

      if (!personDays) {
        this._messageService.add(this.translate.instant('mep.services.employee.no-contractual-availability'), AlertEnum.danger);
      }
      return {
        manualOverwrittenProportionsOfWorkingTimeDataSource,
        manualOverwrittenProportionsOfWorkingTimeColumns,
        months
      };
    } catch (e) {
      this._messageService.add(this.translate.instant('mep.services.employee.no-contractual-availability'), AlertEnum.danger);
    }
  }

  async fetchEmployees(currentClient: Client): Promise<Employee[]> {

    try {
      let employees = (await this.getAll(this.API_URL).toPromise()).body;
      return employees._embedded ? EnumMapper.initialEnumMapper(employees._embedded.employees, currentClient, this.translate) : [];
    } catch (e) {
      this._messageService.add(this.translate.instant(this.errorMessageKey), AlertEnum.danger);
    }
  }

  async fetchEmployeesByBusinessUnit(currentClient: Client, businessUnits: string[]): Promise<Employee[]> {
    try {
      let employees = (await this.getAllEmployeesByBusinessUnit(businessUnits).toPromise());
      return employees ? EnumMapper.initialEnumMapper(employees, currentClient, this.translate) : [];
    } catch (e) {
      this._messageService.add(this.translate.instant(this.errorMessageKey), AlertEnum.danger);
    }
  }

  private getAllEmployeesByBusinessUnit(businessUnits: string[]): Observable<Employee[]> {
    return this.http.get<Employee[]>(this.API_URL + 'employeesByBusinessUnit/' + businessUnits);
  }

  async fetchAllEmployeeFullName(onlyDivLeaderOrManager: boolean): Promise<EmployeeFullName[]> {
    try {
      let employees;
      if (onlyDivLeaderOrManager) {
        employees = (await this.getAll(this.API_URL + 'filter?jobVariant=DIVISION_MANAGER,DIVISIONAL_LEADER&projection=employeeFullName').toPromise()).body;
      } else {
        employees = (await this.getAll(this.API_URL + '?projection=employeeFullName').toPromise()).body;
      }
      return employees._embedded.employees;
    } catch (e) {
      this._messageService.add(this.translate.instant(this.errorMessageKey), AlertEnum.danger);
    }
  }

  async fetchEmployeeFullNameByNameSearch(searchTerm: string, onlyDivLeaderOrManager: boolean = false, onlyHumanResourcesManager: boolean = false): Promise<EmployeeFullName[]> {
    let url = this.API_URL + Config.services.employees.fullNameProjectionByNameSearch + '?searchTerm=' + encodeURIComponent(searchTerm);
    if (onlyDivLeaderOrManager) {
      url += "&onlyDivisionalLeaderOrDivisionManger=true";
    }
    if (onlyHumanResourcesManager) {
      url += "&onlyHumanResourcesManager=true";
    }

    try {
      return (await this.getAll(url).toPromise()).body;
    } catch (e) {
      this._messageService.add(this.translate.instant(this.errorMessageKey), AlertEnum.danger);
    }
  }

  async fetchBlacklistedAsEmployeeFullName(): Promise<EmployeeFullName[]> {
    try {
      return (await this.getAll(this.API_URL + Config.services.employees.blacklisted).toPromise()).body;
    } catch (e) {
      this._messageService.add(this.translate.instant(this.errorMessageKey), AlertEnum.danger);
    }
  }

  async fetchProposedEmployees(projectId: number): Promise<EmployeeFullName[]> {
    let id = projectId ? projectId : -1;
    try {
      return (await this.http.get<EmployeeFullName[]>(this.API_URL + Config.services.employees.proposalUrl + id, {
        observe: 'response'
      }).toPromise()).body;
    } catch (e) {
      this._messageService.add(this.translate.instant(this.errorMessageKey), AlertEnum.danger);
    }
  }

  async fetchEmployeesWithoutName(currentClient: Client): Promise<Employee[]> {
    try {
      let employees = (await this.getAll(this.API_URL + Config.services.employees.withoutNameUrl).toPromise()).body;
      return employees._embedded ? EnumMapper.initialEnumMapper(employees._embedded.employees, currentClient, this.translate) : [];
    } catch (e) {
      this._messageService.add(this.translate.instant(this.errorMessageKey), AlertEnum.danger);
    }
  }

  isNotPastEmployee(employee: Employee): boolean {
    let leavingDate = new Date(employee.leavingDate);
    let today = new Date();

    return (employee.status !== this.translate.instant(Config.services.employees.status.INACTIVE) && employee.leavingDate === null)
      || (employee.leavingDate !== null && (leavingDate.getTime() >= today.getTime()
        || (leavingDate.getFullYear() === today.getFullYear() &&
          leavingDate.getMonth() === today.getMonth() &&
          leavingDate.getDate() === today.getDate())));
  }

  isNotExternal(employee: Employee): boolean {
    return employee.status !== this.translate.instant(Config.services.employees.status.EXTERNAL);
  }

  isNotDispoFlagFalse(dispoFlag: boolean): boolean {
    return dispoFlag === true;
  }

  employeeToFirstAndLastName(employee: Employee): string {
    return `${employee.firstName} ${employee.lastName}`;
  }

  getEmployeeFullNameByEmployeeId(employeeId: number): Observable<EmployeeFullName> {
    const url = this.API_URL + 'getEmployeeFullNameByEmployeeId/' + employeeId;
    return this.http.get<EmployeeFullName>(url);
  }

  downloadMyEmployeesAsExcel(dates: any): Observable<any> {
    const url = '/mep/api/employees' + Config.services.employees.exportMyEmployeesAsExcel + '/' + dates[0] + '/' + dates[1] + '/' + dates[2] + '/' + dates[3] + '/' + false;
    return this.http.post(url, {}, {responseType: 'blob'});
  }

  downloadMyEmployeesAsExcelWithFullTimeEquivalent(dates: any): Observable<any> {
    const url = '/mep/api/employees' + Config.services.employees.exportMyEmployeesAsExcel + '/' + dates[0] + '/' + dates[1] + '/' + dates[2] + '/' + dates[3] + '/' + true;
    return this.http.post(url, {}, {responseType: 'blob'});
  }

  downloadEmployeesAsExcel(): Observable<any> {
    return this.http.post(
      this.API_URL + Config.services.employees.exportExcel, {}, {responseType: 'blob'});
  }

  downloadSelectedEmployeesAsExcel(selectedEmployeeIds: any): Observable<any> {
    return this.http.post(
      this.API_URL + Config.services.employees.exportSelectedProjectListExcel + '/' + selectedEmployeeIds, {}, {responseType: 'blob'});
  }

  downloadSelectedEmployeeProfilesAsExcel(): Observable<any> {
    return this.http.post(
      this.API_URL + Config.services.employees.exportSelectedEmployeeProfileDataExcel, {}, {responseType: 'blob'});
  }

  downloadSelectedEmployeesSkillsAsExcel(selectedEmployeeIds: any): Observable<any> {
    return this.http.post(
      Config.services.employeeSkills.baseUrl + Config.services.employees.exportSelectedEmployeesSkillsExcel + '/' + selectedEmployeeIds, {}, {responseType: 'blob'});
  }

  downloadEmployeesSkillsAsExcel(): Observable<any> {
    return this.http.post(
      Config.services.employeeSkills.baseUrl + Config.services.employees.exportEmployeesSkillsExcel, {}, {responseType: 'blob'});
  }

  getSkillNamesByNameSearch(searchTerm: string, filterSkillsWithoutLevel = true, showInvisibleSkills = false): Observable<string[]> {
    return this.http.get<string[]>(Config.services.employees.skillNameSearchURL + showInvisibleSkills, {params: {searchTerm}}).pipe(
      map(skills => {
        if (filterSkillsWithoutLevel) {
          skills = skills.filter(skillName => !skillName.startsWith('other '));
        }
        return skills.sort((a, b) => a.toLowerCase() < b.toLowerCase() ? -1 : 1);
      })
    );
  }

  async getAllSkillsBySearchTerm(name: string, showInvisibleSkills: boolean = false): Promise<Skill[]> {
    return (await this.http.get<Skill[]>(Config.services.skills.baseUrl + '/getAllSkillsBySearchTerm/' + showInvisibleSkills, {params: {searchTerm: name}}).toPromise());
  }

  saveEmployees(projectId: number, employeesWithAccess: Employee[]): Observable<Employee[]> {
    return this.http.post(this.API_URL + "addAccess/" + projectId,
      employeesWithAccess) as Observable<Employee[]>;
  }

  updateBlacklist(blacklistEntries: Blacklist[]): Observable<Blacklist> {
    return this.http.post(
      this.API_URL +
      Config.services.employees.updateBlacklist,
      blacklistEntries
    ) as Observable<Blacklist>;
  }

  getEmployeeHiearchyByCostcenter(costCenter): Observable<EmployeeHierarchy> {
    return this.http.get<EmployeeHierarchy>(this.API_URL + Config.services.employees.hierarchy + costCenter);
  }

  getHierarchiesByCostCenter(): Observable<{ [costCenter: string]: EmployeeHierarchy }> {
    return this.http.get<{ [costCenter: string]: EmployeeHierarchy }>(this.API_URL + Config.services.employees.hierarchiesByCostCenter);
  }

  /**
   * Sets the employee division based on the employee hierarchy
   *
   * @param elements The array containing employees
   * @param client The current client
   * @param costCenterGetter Returns the cost center of one element in the array
   * @param divisionDepartmentSetter Sets the division of one element in the array
   */
  async setEmployeesDepartmentByHierarchy<T>(elements: T[], client, costCenterGetter: (element: T) => string, departmentSetter: (element: T, department: string) => void) {
    const hierarchiesByCostCenter = await this.getHierarchiesByCostCenter().toPromise();

    for (const element of elements) {
      const costCenter = costCenterGetter(element);

      if (costCenter && costCenter !== "" && costCenter !== "N/A") {
        const hierarchy = hierarchiesByCostCenter[costCenter];

        if (hierarchy) {
          departmentSetter(element, hierarchy.department);
        }
      }
    }
  }

  getDepartmentOptions(): Observable<string[]> {
    return this.http.get<string[]>(this.API_URL + Config.services.employees.departments);
  }

  getPersonnelManagers(): Observable<Employee[]> {
    return this.http.get<Employee[]>(this.API_URL + Config.services.employees.personnelManagers);
  }

  getYearMonthKeysBetween(startDate: any, endDate: any): string[] {
    const start = moment(startDate);
    const end = moment(endDate);

    if (end.isBefore(start)) {
      throw new Error('Invalid time interval!');
    }

    const yearMonths = [];
    let month = start.get('month') + 1;
    let year = start.get('year');
    const yearTo = end.get('year');
    const monthTo = end.get('month') + 1;

    while (year <= yearTo) {
      yearMonths.push(EmployeeService.monthToString(month) + " " + year.toString());

      if (year < yearTo) {
        if (month !== 12) {
          month = month + 1;
        } else {
          month = 1;
          year = year + 1;
        }
      } else {
        if (month < monthTo) {
          month = month + 1;
        } else {
          year = year + 1;
        }
      }
    }

    return yearMonths;
  }

  validAssignmentInterval(start: string, end: string): boolean {
    if (!start || !end) {
      return false;
    } else {
      const startMoment = moment(start);
      const endMoment = moment(end);

      return startMoment.isSameOrBefore(endMoment);
    }
  }


  changeSkillVisibility(skill: Skill) {
    const old = skill.visibility;
    this.http.put(Config.services.employees.skillURL + Config.services.employees.changeSkillVisibility, skill.id).subscribe(() => {
      old === 1 ? skill.visibility = 0 : skill.visibility = 1;
    });
  }

  async getAvailableEmployeesByMonthAndSkillgroup(month: string, skillgroupId: number, onlyFreeEmployees: boolean) {
    const url = Config.services.employeeSkills.baseUrl + Config.services.employees.byMonthAndSkillgroup;
    let urlParams = new HttpParams();
    urlParams = urlParams.append("month", month);
    urlParams = urlParams.append("skillgroupId", skillgroupId.toString());
    urlParams = urlParams.append("onlyFreeEmployees", String(onlyFreeEmployees));

    return (await this.http.get<Employee[]>(url, {
      params: urlParams,
      observe: 'response'
    }).toPromise()).body;
  }

  getProfileData(): Observable<EmployeeProfileData[]> {
    return this.http.get<EmployeeProfileData[]>(this.API_URL + Config.services.employees.profileData);
  }

  getEmployeeLanguages(employeeId: number): Observable<string> {
    return this.http.get(this.API_URL + employeeId + "/languages", {responseType: 'text'});
  }

  saveEmployeeLanguages(employeeId: number, languages: string) {
    let urlParams = new HttpParams();
    urlParams = urlParams.append("languages", languages);
    this.http.post(this.API_URL + employeeId + "/saveLanguages", urlParams).toPromise().then();
  }

  getEmployeeTags(): Observable<string[]> {
    return this.http.get<string[]>(this.API_URL + Config.services.employees.tags);
  }

  exportSkillgroupEvaluationAsExcel(evaluationData: SkillEvaluation[], startDate: string, freeEmployeeFlag: boolean): Observable<any> {
    let exportData: SkillEvaluationExportData[] = [];
    evaluationData.forEach(edata => {
      let date = new SkillEvaluationExportData();
      let employeesList: Employee[][] = [];
      date.skillgroup = edata.skillgroup;
      let employees: Employee[] = [];
      edata.employees.forEach(month => {
        employees = month.filteredData;
        employeesList.push(employees);
      });
      date.employees = employeesList;
      exportData.push(date);
    });
    let urlParams = new HttpParams();
    urlParams = urlParams.append("startDate", startDate);
    urlParams = urlParams.append("freeEmployeeFlag", String(freeEmployeeFlag));

    return this.http.post(
      Config.services.employeeSkills.baseUrl + Config.services.employees.exportSkillgroupExcel, exportData, {
        params: urlParams,
        responseType: 'blob'
      });
  }

  sendMailToEmployeesHRM(employeeId: number, message: string, origin: NotifyHrmOrigin): Observable<void> {
    const requestUrl = this.API_URL + Config.services.employees.notifyHRM + employeeId;
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    return this.http.post<void>(requestUrl, JSON.stringify(message), {headers, params: {origin}});
  }

  setDeclarationOfConsent(employeeId: number, declarationOfConsent: boolean) {
    const requestUrl = this.API_URL + employeeId + Config.services.employees.setDeclarationOfConsentUrl;
    let urlParams = new HttpParams();
    urlParams = urlParams.append("declarationOfConsent", String(declarationOfConsent));
    this.http.post<any>(requestUrl, urlParams).subscribe(() => {
      this.messageService.add(this.translate.instant('mep.components.general.notification.changed-declaration-of-consent'), AlertEnum.success);
    });
  }

  public getLastImportDate(): Observable<string> {
    return this.http.get(this.API_URL + "lastImportDate", {responseType: 'text'});
  }

  getBusinessUnitChangedEmployees(): Observable<Employee[]> {
    return this.http.get<Employee[]>(this.API_URL + "businessUnitChanged");
  }

  getSignature(): Observable<string> {
    return this.http.get(this.API_URL + Config.services.employees.getSignature, {responseType: 'text'});
  }

  private getOptions(attribute: string): DropDownChoice<string>[] {
    return Object.keys(Config.services.employees[attribute]).sort().map(function (i) {
      return {value: i, viewValue: Config.services.employees[attribute][i]};
    });
  }

  private deleteEmployeeLink(e: Employee, deleteById: boolean): string {
    if (e._links == null || e._links.self == null || e._links.self.href == null) {
      return this.API_URL + e.id;
    } else {
      return deleteById ? this.API_URL + e.id : e._links.self.href;
    }
  }

}
