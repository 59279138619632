import {Injectable} from '@angular/core';
import {MessageService} from './message.service';
import {HttpClient} from '@angular/common/http';
import {HttpBaseService} from './http-base.service';
import Config from "../../config/Config";
import {CustomizingService} from "./customizing.service";

@Injectable({
  providedIn: 'root'
})
export class ThemesService extends HttpBaseService {

  constructor(public http: HttpClient, messageService: MessageService,
              private customizingService: CustomizingService) {
    super(http, messageService);
  }

  async getTheme(): Promise<string> {
    return (await this.customizingService.getCustomizingTable()).theme;
  }

  updateTheme(data: string) {
    return this.http.put(Config.services.customizing.baseUrl + Config.services.customizing.themes + "/" + data, null).subscribe(() => this.customizingService.refreshCustomizingTable());
  }

}
