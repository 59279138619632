import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {RequiresPermissionDirective} from "./directives/requires-permission.directive";

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    RequiresPermissionDirective
  ],
  exports: [
    RequiresPermissionDirective
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PermissionModule {}
