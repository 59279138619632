import {NgModule} from "@angular/core";
import {EditLanguagesDialogComponent} from "./edit-languages-dialog/edit-languages-dialog.component";
import {CommonModule} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {AutoCompleteModule} from "../autocomplete/autocomplete.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {PermissionModule} from "../permission/permission.module";
import {MomentDateModule} from "@angular/material-moment-adapter";
import {InfoDialogModule} from "../info-dialog/info-dialog.module";
import {FilesDialogComponent} from "./files-dialog/files-dialog.component";
import {AddProjectDialogComponent} from "./add-project-dialog/add-project-dialog.component";
import {AssignEmployeeDialogComponent} from "./assign-employee-dialog/assign-employee-dialog.component";
import {ConfirmationDialogComponent} from "./confirmation-dialog/confirmation-dialog.component";
import {CopyAssignmentsDialogComponent} from "./copy-assignments-dialog/copy-assignments-dialog.component";
import {EditSkillDialogComponent} from "./edit-skill-dialog/edit-skill-dialog.component";
import {
  ExtendOrMoveProjectDialogComponent
} from "./extend-or-move-project-dialog/extend-or-move-project-dialog.component";
import {FileSelectionDialogComponent} from "./file-selection-dialog/file-selection-dialog.component";
import {NotifyHrmDialogComponent} from "./notify-hrm-dialog/notify-hrm-dialog.component";
import {SkillDbDialogComponent} from "./skill-db-dialog/skill-db-dialog.component";
import {SkillRatingComponent} from "./skill-db-dialog/skill-rating/skill-rating.component";
import {AssignmentTableComponent} from "./extend-or-move-project-dialog/assignment-table/assignment-table.component";
import {MatDialogModule} from "@angular/material/dialog";
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatTableModule} from "@angular/material/table";
import {MatSortModule} from "@angular/material/sort";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatSelectModule} from "@angular/material/select";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {MatTreeModule} from "@angular/material/tree";
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatDividerModule} from "@angular/material/divider";
import {InputDirectivesModule} from "../inputdirectives/input-directives.module";
import {MAT_DATE_FORMATS} from "@angular/material/core";
import {MEP_DATE_FORMATS} from "../../core/models/dateFormat";
import {FileInputModule} from "../file-input/file-input.module";
import {AddSourceCodeDialogComponent} from './add-source-code-dialog/add-source-code-dialog.component';
import {SkillDbTooltipModule} from "./skill-db-tooltip/skill-db-tooltip.module";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {MatRadioModule} from "@angular/material/radio";
import {FileTableModule} from "../files-dialog/file-table.module";
import {SkillDbListModule} from "../skill-db-list/skill-db-list.module";
import {HotkeyModule} from "../hotkey/hotkey.module";
import {LanguageTooltipModule} from "../language-tooltip/language-tooltip.module";
import {PdfJsViewerModule} from "ng2-pdfjs-viewer";
import {NewsNotificationModule} from "../news-notification/news-notification.module";
import {PaginatorSmartModule} from "../paginator-smart/paginator-smart.module";

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    AutoCompleteModule,
    FormsModule,
    ReactiveFormsModule,
    MomentDateModule,
    InfoDialogModule,
    PermissionModule,
    MatDialogModule,
    MatInputModule,
    MatButtonModule,
    MatProgressBarModule,
    MatTableModule,
    MatSortModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatTooltipModule,
    MatSelectModule,
    MatExpansionModule,
    MatIconModule,
    MatButtonToggleModule,
    MatTreeModule,
    MatPaginatorModule,
    MatDividerModule,
    DragDropModule,
    InputDirectivesModule,
    FileInputModule,
    MatRadioModule,
    SkillDbTooltipModule,
    PdfJsViewerModule,
    FileTableModule,
    SkillDbListModule,
    HotkeyModule,
    LanguageTooltipModule,
    NewsNotificationModule,
    PaginatorSmartModule,
  ],
  declarations: [
    EditLanguagesDialogComponent,
    FilesDialogComponent,
    AddProjectDialogComponent,
    AssignEmployeeDialogComponent,
    ConfirmationDialogComponent,
    CopyAssignmentsDialogComponent,
    EditSkillDialogComponent,
    ExtendOrMoveProjectDialogComponent,
    FileSelectionDialogComponent,
    NotifyHrmDialogComponent,
    SkillDbDialogComponent,
    SkillRatingComponent,
    AssignmentTableComponent,
    AddSourceCodeDialogComponent
  ],
  exports: [
    EditLanguagesDialogComponent,
    FilesDialogComponent,
    AddProjectDialogComponent,
    AssignEmployeeDialogComponent,
    ConfirmationDialogComponent,
    CopyAssignmentsDialogComponent,
    EditSkillDialogComponent,
    ExtendOrMoveProjectDialogComponent,
    FileSelectionDialogComponent,
    NotifyHrmDialogComponent,
    SkillDbDialogComponent,
    AddSourceCodeDialogComponent
  ],
  providers: [
    {provide: MAT_DATE_FORMATS, useValue: MEP_DATE_FORMATS},

  ]
})
export class DialogsModule {
}
