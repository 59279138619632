export class LanguageSelection {
  private static languageLocalizationToResultIndex = {
    'englisch': 'english',
    'english': 'english',
    'deutsch': 'german',
    'german': 'german',
    'rumänisch': 'romanian',
    'romanian': 'romanian',
  };

  private static languageTranslation = {
    'english': 'englisch',
    'englisch': 'english',
    'deutsch': 'german',
    'german': 'deutsch',
    'romanian': 'rumänisch',
    'rumänisch': 'romanian'
  };

  native: string;
  english: string;
  german: string;
  romanian: string;
  other: string;

  static createSelectionFromString(languagesString: string): LanguageSelection {
    let result = new LanguageSelection();
    result.native = LanguageSelection.regExpResultToString(languagesString.match(/Native:([^:]*)(English:|German:|Romanian:|Others:|$)/i));
    result.english = LanguageSelection.regExpResultToString(languagesString.match(/English:([^:]*)(Native:|German:|Romanian:|Others:|$)/i));
    result.german = LanguageSelection.regExpResultToString(languagesString.match(/German:([^:]*)(Native:|English:|Romanian:|Others:|$)/i));
    result.romanian = LanguageSelection.regExpResultToString(languagesString.match(/Romanian:([^:]*)(Native:|English:|German:|Others:|$)/i));
    result.other = LanguageSelection.regExpResultToString(languagesString.match(/Others:([^:]*)(Native:|English:|German:|Romanian:|$)/i));
    return result;
  }

  private static regExpResultToString(regexpResult: RegExpMatchArray) {
    if (regexpResult !== null) {
      return LanguageSelection.removeTrailingComma(regexpResult[1]);
    } else {
      return null;
    }
  }

  private static removeTrailingComma(str: string): string {
    str = str.trim();
    if (str.endsWith(',')) {
      str = str.substring(0, str.length - 1);
    }
    return str;
  }

  public static testLanguageSelection(languagesString: string, filterString: string) {
    let langs = LanguageSelection.createSelectionFromString(languagesString);
    return langs.matchesFilterString(filterString);
  }

  public matchesFilterString(filterString: string) {
    filterString = filterString.trim().toLowerCase();

    if (/[ a-zä]+>[a-c][0-9]/gi.test(filterString)) {
      let parts = filterString.split('>');
      let lhs = parts[0].trim().toLowerCase();
      let rhs = parts[1].trim().toLowerCase();

      if (this.isOtherLanguage(lhs)) {
        return false;
      }

      return this.includesLanguageOfLevel(lhs, rhs);

    } else if (!this.isOtherLanguage(filterString)) {
      return this.isLanguageWithoutLevelRequirement(filterString);

    } else if (/(Sonstige|Others)=.+/gi.test(filterString)) {
      let searchedLanguage = filterString.split(/(Sonstige|Others)=/i)[2];
      return this.includesOtherLanguage(searchedLanguage);
    } else {
      return this.includesOtherLanguage(filterString) || this.isInNativeLanguage(filterString);
    }
  }

  private isLanguageWithoutLevelRequirement(language: string) {
    language = language.trim().toLowerCase();
    let index = LanguageSelection.languageLocalizationToResultIndex[language.trim().toLowerCase()];
    return this.isInNativeLanguage(language) || (this[index] !== null && this[index].length > 0);
  }

  private isBiggerLevel(minLevel: string, actualLevel: string) {
    return minLevel < actualLevel;
  }

  private isOtherLanguage(languageName: string) {
    return LanguageSelection.languageLocalizationToResultIndex[languageName.trim().toLowerCase()] === undefined;
  }

  private includesOtherLanguage(languageName: string) {
    let otherLanguages = this.other;
    if (otherLanguages === undefined || otherLanguages === null) {
      return false;
    } else {
      let langRegexp = new RegExp(languageName, 'i');
      return langRegexp.test(otherLanguages);
    }
  }

  private isInNativeLanguage(language: string) {
    let nativeLanguage = '';

    if (this.native !== null) {
      nativeLanguage = this.native.toLowerCase();
    }

    return nativeLanguage.includes(language) || nativeLanguage.includes(LanguageSelection.languageTranslation[language]);
  }

  private includesLanguageOfLevel(languageName: string, minLevel: string) {
    let languageCleaned = languageName.trim().toLowerCase();
    if (this.isInNativeLanguage(languageCleaned)) {
      return true;
    } else {
      let languageLevel = this[LanguageSelection.languageLocalizationToResultIndex[languageCleaned]];
      if (languageLevel === undefined || languageLevel === null) {
        return false;
      } else {
        return this.isBiggerLevel(minLevel, languageLevel);
      }
    }
  }
}
