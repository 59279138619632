import {HttpBaseService} from './http-base.service';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {MessageService} from './message.service';
import {Injectable} from '@angular/core';
import {MissplannedCapacityEnum} from "../../models/Enums/MissplannedCapacityEnum";
import {EnumMapper} from "./enumMapper.service";
import {FormControl} from "@angular/forms";
import {CustomizingService} from "./customizing.service";
import moment from "moment";
import {
  EmployeeCapacityList,
  EmployeeCapacityListElement,
  EmployeeRevenueListElement
} from "../../../shared/capacity-list/employee-capacity-list/employee-capacity-list.interface";
import {CapacityParameters} from "../../../shared/capacity-list/employee-capacity-legend/employee-capacity-legend.component";
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class EmployeeCapacityService extends HttpBaseService {

  url: string = environment.baseUrl + 'api/employees/capacityOverview';
  urlNN: string = environment.baseUrl + 'api/employees/NNemployees';

  constructor(public http: HttpClient, public messageService: MessageService, private customizingService: CustomizingService, private translateService: TranslateService) {
    super(http, messageService);
  }

  public async getEmployeeCapacities(capacityParameters: CapacityParameters, businessUnit: String[], showPastAndCurrentEmployee: boolean): Promise<EmployeeCapacityList> {
    const requestUrl = `${this.url}/${capacityParameters.viewType}/${capacityParameters.months}/${moment(capacityParameters.startDate).format('MM_YYYY')}/${capacityParameters.planned}/${showPastAndCurrentEmployee}`;
    const params = {businessUnit: `${businessUnit}`}
    const resultObject = (await this.http.get<any>(requestUrl, {
      params,
      observe: "response"
    }).toPromise()).body
    const client = await this.customizingService.getCurrentClient();

    return {
      employeeCapacityListElement: resultObject.map(result => this.extractResult(result, client)),
      errors: resultObject.errors
    };
  }

  public async getNNEmployeeCapacity(calculationVariant: MissplannedCapacityEnum, numberOfMonths: number, startMonth: string): Promise<{ [month: string]: number }> {
    const requestUrl = `${this.urlNN}/${calculationVariant}/${numberOfMonths}/${startMonth}`;
    return (await this.getAll<any>(requestUrl).toPromise()).body;
  }

  public async getEmployeeCapacitiesWithoutFixedprice(startDate: string, months: number, employeeIds: number[]): Promise<EmployeeRevenueListElement[]> {
    const requestUrl = `${this.url}/revenue/${months}/${startDate}`;
    const params = {employeeIds: `${employeeIds}`};
    return (await this.http.get<EmployeeRevenueListElement[]>(requestUrl, {
      params,
      observe: 'response'
    }).toPromise()).body;
  }

  extractResult(result: any, client): EmployeeCapacityListElement {
    delete result['assignmentsToProject'];
    delete result['capacity'];
    delete result['capacityExcel'];
    delete result['role'];

    return {
      employee: {
        businessUnit: result.employee.businessUnit,
        personnelNumber: result.employee.personnelNumber,
        firstName: result.employee.firstName,
        lastName: result.employee.lastName,
        id: result.employee.id,
        humanResourcesManager: result.employee.humanResourcesManager,
        proportionOfWorkingTime: result.employee.proportionOfWorkingTime,
        misplannedAnnotation: result.employee.misplannedAnnotation,
        travelRestrictions: result.employee.travelRestrictions,
        languages: result.employee.languages,
        jobVariant: result.employee.jobVariant,
        jobLevel: result.employee.jobLevel,
        _links: result.employee._links,
        status: EnumMapper.statusEmployeeMapper([result.employee.status], false, this.translateService).toString(),
        leavingDate: result.employee.leavingDate,
        costCenter: result.employee.costCenter,
        tag: result.employee.tag,
        notRelevantForCapa: result.employee.notRelevantForCapa,
      },
      employeeName: result.employee.lastName + ', ' + result.employee.firstName,
      skills: result.employee.skills || '',
      division: EnumMapper.divisionMapper([result.employee.division], client, false).toString(),
      travelWillingness: EnumMapper.travelWillingnessMapper([result.employee.travelWillingness], client, false).toString(),
      travelWillingnessInternational: result.employee.travelWillingnessInternational,
      travelWillingnessCombined: EnumMapper.travelWillingnessCombinedMapper([result.employee.travelWillingness], [result.employee.travelWillingnessInternational], client, false, this.translateService).toString(),
      location: EnumMapper.locationMapper([result.employee.location], false, this.translateService).toString(),
      dispoFlag: result.employee.dispoFlag,
      country: result.employee.country,
      employeeCapacityInPersonDays: result.valueMap,
      freeCapacityInPersonDays: result.valueMap,
      employeeCapacityInPercent: result.freePersonDaysInPercent,
      vacation: result.vacation,
      availableCapacity: result.availableCapacity,
      projectLeaderNames: result.projectLeaderNames,
      divisionManagerNames: result.divisionManagerNames,
      isVisibleByMask: true,
      isVisibleByColor: true,
      hasValidActionPlan: result.hasValidActionPlan,
      skillsControl: new FormControl(result.employee.skills || ''),
      misplannedAnnotationControl: new FormControl(result.employee.misplannedAnnotation),
      capacitySumInPT: parseFloat(this.sumValueMap(result.valueMap).toFixed(2)),
      capacitySumInPercent: (Math.round(this.sumValueMap(result.freePersonDaysInPercent) * 10) / 10),
      resourceRequestCount: result.resourceRequestCount
    };
  }

  private sumValueMap(valueMap: { [month: string]: number }): number {
    return Object.values(valueMap).reduce((a, b) => a + b, 0);
  }
}
