import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {BreadcrumbItem} from "../../../models/BreadcrumbItem";
import {
  ResourceRequest,
  ResourceRequestOverviewDTO
} from "../../../../feature/resource-request-list/models/resource-request";
import {
  ResourceRequestListService
} from "../../../../feature/resource-request-list/services/resource-request-list.service";
import {ActivatedRoute, Router} from "@angular/router";
import {DataService} from "../../../services/services/data.service";
import {
  AddResourceRequestComponent
} from "../../../../feature/resource-request-list/components/add-resource-request/add-resource-request.component";

@Component({
  selector: 'app-breadcrumb-resource-request-search',
  templateUrl: './breadcrumb-resource-request-search.component.html',
  styleUrls: ['./breadcrumb-resource-request-search.component.scss']
})
export class BreadcrumbResourceRequestSearchComponent implements OnInit {
  @Input() breadcrumb: BreadcrumbItem;
  currentResourceRequest: ResourceRequestOverviewDTO;
  readonly detailPath: string = '/resource-request-list/';

  @ViewChild('resourceRequestInput') resourceRequestInput: ElementRef;

  constructor(
    private _route: ActivatedRoute,
    private _requestService: ResourceRequestListService,
    private _router: Router,
    private _dataService: DataService,
    private _addResourceRequestComponent: AddResourceRequestComponent
  ) {
  }

  private static mapToResourceRequestOverviewDTO(resourceRequest: ResourceRequest): ResourceRequestOverviewDTO {
    const resourceRequestOverviewDTO = new ResourceRequestOverviewDTO();
    Object.assign(resourceRequestOverviewDTO, resourceRequest);
    return resourceRequestOverviewDTO;
  }

  ngOnInit(): void {
    this._dataService.currentResourceRequest.subscribe(currentRequest => {
      const subPath = this.breadcrumb.path.substring(this.breadcrumb.path.lastIndexOf(this.detailPath) + this.detailPath.length);
      const sharePointId = subPath.lastIndexOf('/') > 0 ? subPath.substring(0, subPath.lastIndexOf('/')) : subPath;
      if (currentRequest && currentRequest.id.toString() === sharePointId) {
        this.currentResourceRequest = currentRequest;
      } else {
        this._requestService.getById(Number.parseInt(sharePointId, 10))
          .subscribe(r => this.currentResourceRequest
            = BreadcrumbResourceRequestSearchComponent.mapToResourceRequestOverviewDTO(r));
      }
    });
  }

  onItemChange(resourceRequest: ResourceRequestOverviewDTO) {
    this._dataService.changeResourceRequest(resourceRequest);
    this._router.navigate([this.detailPath + resourceRequest.id]).then();
    this._router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  onClear() {
    this.resourceRequestInput.nativeElement.value = '';
  }

  onLeaveText() {
    this.resourceRequestInput.nativeElement.value = this.getCurrentResourceRequestText();
  }

  getCurrentResourceRequestText(): string {
    if (this.currentResourceRequest.client) {
      return this.currentResourceRequest.client + ' ' + this.currentResourceRequest.requestTitle;
    } else {
      return this.currentResourceRequest.requestTitle;
    }
  }

}
