import { Component, OnInit } from '@angular/core';
import { MessageService } from '../../services/services/message.service';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit {

  constructor(public messageService: MessageService) { }

  ngOnInit() {
  }

  deleteMessage(key: string) {
    delete this.messageService.messages[key];
  }

  containsPermanent() {
    return Object.values(this.messageService.messages).some(m => m.isPermanent);
  }
}
