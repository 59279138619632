// Can be used for instance: this.currentClient === Clients.MSG_SERVICES
export enum Client {
  MSG_SERVICES = "MSG_SERVICES",
  ZA = "ZA",
  ZT = "ZT",
  ZM = "ZM",
  GBA = "GBA",
  GBF = "GBF",
  GBG = "GBG",
  GBI = "GBI",
  GBM = "GBM",
  GBP = "GBP",
  GBS = "GBS",
  GBL = "GBL",
  GBY = "GBY",
  INNOVAS = "INNOVAS",
  MINNOSPHERE = "MINNOSPHERE",
  MSG_GILLARDON = "MSG_GILLARDON",
  MSG_GLOBAL = "MSG_GLOBAL",
  MSG_INDUSTRY_ADVISORS = "MSG_INDUSTRY_ADVISORS",
  MSG_TREORBIS = "MSG_TREORBIS",
  MSG_AT = "MSG_AT",
  MSG_CH = "MSG_CH",
  MSG_LIFE = "MSG_LIFE",
  PLAUT_AT = "PLAUT_AT",
  PLAUT_DE = "PLAUT_DE",
  XBI = "XBI",
  SDA_SE = "SDA_SE",
  XQT = "XQT",
  MSG_NEXINSURE = "MSG_NEXINSURE",
  M3 = "M3",
  DX = "DX",
  GBD = "GBD",
  GBO = "GBO",
  MSG_DAVID = "MSG_DAVID",
  BELTIOS = "BELTIOS",
  CONPLAN = "CONPLAN",
  GBV = "GBV",
  HSC = "HSC",
  HDS = "HDS",
  MISSING_VALUE = "MISSING_VALUE",
  XAI = "XAI",
  GBR = "GBR",
  EMPTY = ""
}
