import {Directive, ElementRef, Input, OnInit} from '@angular/core';

@Directive({
  selector: '[appAutofocusOnInit]'
})
/**
 * The native autofocus directive only focuses after the initial loading of the page.
 * This directive refocuses whenever the element reappears
 */
export class AutofocusOnInitDirective implements OnInit {
  private focus = true;

  constructor(private el: ElementRef) { }

  ngOnInit() {
    if (this.focus) {
      // Otherwise Angular throws error: Expression has changed after it was checked.
      window.setTimeout(() => {
        this.el.nativeElement.focus();
      });
    }
  }

  @Input() set autofocus(condition: boolean) {
    this.focus = condition !== false;
  }
}
