import {Injectable} from "@angular/core";
import {HttpErrorResponse, HttpEvent} from "@angular/common/http";
import {Observable, of, throwError} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {MessageService} from "./message.service";
import {AlertEnum} from "../../models/Enums/AlertEnum";
import {environment} from "../../../../environments/environment";
import Config from "../../config/Config";
import {map, mergeMap, tap} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class ServerErrorHandler {
  private static readonly TRANSLATION_KEY_PREFIX = 'mep.services.error-handler.';
  private static readonly ERROR_KEY_PREFIX = ServerErrorHandler.TRANSLATION_KEY_PREFIX + 'server.';
  private static readonly DEFAULT_ERROR_KEY = ServerErrorHandler.ERROR_KEY_PREFIX + 'default';

  constructor(private translate: TranslateService, private messageService: MessageService) {
  }

  private static mapErrorToMessageInfo(error: HttpErrorResponse): { errorMessageKey: string; interpolationArgs: Object; showErrorCode: boolean } {
    let errorMessageKey;
    let interpolationArgs = error.error;
    let showErrorCode = true;

    if (error.status === 409) {
      errorMessageKey = ServerErrorHandler.ERROR_KEY_PREFIX + '409';
      showErrorCode = false;
    } else if (error.error && error.error.key && error.error.arguments) {
      errorMessageKey = error.error.key;
      interpolationArgs = error.error.arguments;
      showErrorCode = false;
    } else if (error.error && typeof error.error === 'string') {
      errorMessageKey = error.error;
    } else if (error.error && error.error.message) {
      errorMessageKey = error.error.message;
    } else {
      errorMessageKey = ServerErrorHandler.ERROR_KEY_PREFIX + error.status;
    }

    return {errorMessageKey, interpolationArgs, showErrorCode};
  }

  public handleError(error: HttpErrorResponse, httpMethod: string): Observable<HttpEvent<any>> {
    if (error.url && error.url.includes("assets/i18n/") && error.status === 404) {
      if (!environment.production) {
        console.warn("Unable to find client specific language pack! Using default language pack instead. (This message will not be displayed in production)", error);
      }
      this.setLanguageToDefault();
      return of();
    }

    return this.getErrorMessage(error, httpMethod).pipe(
      tap(errorMessage => this.messageService.add(errorMessage, AlertEnum.danger, 0)),
      mergeMap(() => throwError(error))
    );
  }

  public setLanguageToDefault() {
    const browserLang = this.translate.getBrowserLang();
    const lang = browserLang.match(Config.supportedLanguagesRegex) ? browserLang : Config.defaultLanguage;
    this.translate.setDefaultLang(lang);
    this.translate.use(lang);
  }

  private getErrorMessage(error: HttpErrorResponse, httpMethod: string): Observable<string> {
    const {errorMessageKey, interpolationArgs, showErrorCode} = ServerErrorHandler.mapErrorToMessageInfo(error);

    const additionalInterpolationArgs = {
      httpMethod: this.translateHttpMethod(httpMethod)
    };

    Object.assign(interpolationArgs || {}, additionalInterpolationArgs);

    return this.translateOrDefault(errorMessageKey, ServerErrorHandler.DEFAULT_ERROR_KEY, interpolationArgs).pipe(
      map(translatedErrorKey => {
        let errorMessage = showErrorCode ? `(${error.status}) ${translatedErrorKey}` : translatedErrorKey;
        return `${errorMessage}<br><br><small>${httpMethod} ${error.url}</small>`;
      })
    );
  }

  private translateOrDefault(key: string, defaultKey: string, interpolationArg: Object): Observable<string> {
    return this.translate.get(key, interpolationArg).pipe(
      mergeMap(translation => {
        if (translation.startsWith(ServerErrorHandler.ERROR_KEY_PREFIX)) {
          return this.translate.get(defaultKey, interpolationArg);
        } else {
          return of(translation);
        }
      })
    );
  }

  private translateHttpMethod(httpMethod: string): string {
    return this.translate.instant(ServerErrorHandler.TRANSLATION_KEY_PREFIX + 'httpMethod.' + httpMethod);
  }
}
