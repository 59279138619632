import {Component, Inject, OnInit} from '@angular/core';
import {TranslatableComponent} from "../../../core/models/TranslatableComponent";
import {TranslateService} from "@ngx-translate/core";
import {FilterViewData} from "./filter-dialog.interface";
import {EmployeeCapacityFilterData} from "../../capacity-list/employee-capacity-list/employee-capacity-list.interface";
import {EmployeeFilterData} from "../../../core/models/viewModels/EmployeeFilterData";
import {ProjectFilterData} from "../../../core/models/viewModels/ProjectFilterData";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {
  InstanceWideSearchFilterData
} from "../../../feature/instance-wide-search/components/instance-wide-search/instance-wide-search.interface";
import {ActionPlanFilterData} from "../../../core/models/viewModels/ActionPlanFilterData";
import {FilterListComponent} from "./filter-list/filter-list.component";
import {FilterElement} from "../../../core/models/viewModels/FilterElement";
import {SkillgroupFilterData} from "../../../core/models/viewModels/SkillgroupFilterData";
import {EmployeeProfileFilterData} from "../../../core/models/viewModels/EmployeeProfileFilterViewModel";
import {MessageService} from "../../../core/services/services/message.service";
import {EnumMapper} from "../../../core/services/services/enumMapper.service";
import {FilterStoreService} from "../../../core/services/services/filter-store.service";
import {ResourceRequestFilterData} from "../../../feature/resource-request-list/models/resource-request";
import {AuthenticationService} from "../../../core/services/services/authentication.service";

@Component({
  selector: 'app-filter-dialog',
  templateUrl: './filter-dialog.component.html',
  styleUrls: ['./filter-dialog.component.scss']
})
export class FilterDialogComponent extends TranslatableComponent implements OnInit {

  filterData;
  filters: FilterElement[] = [];
  loading = true;
  isEmployeeInDB: boolean;
  alreadySetFilter: any;

  constructor(protected translate: TranslateService, public dialogRef: MatDialogRef<FilterDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: FilterDialogTemplate, public enumMapper: EnumMapper,
              private _authenticationService: AuthenticationService,
              private messageService: MessageService, private storeFilterService: FilterStoreService,
              public dialog: MatDialog) {
    super(translate, 'FilterDialogComponent');
  }

  private static arrayEmpty(array: any[]): boolean {
    return !array || array.length === 0;
  }

  onCloseClick(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    this.filterData = this.storeFilterService.parseFilterValue(this.storeFilterService.stringifyFilterValue(this.data.initialFilterData));
    if (this.filterData.capaRelevance && this.filterData.capaRelevance.length > 0) {
      this.filterData.capaRelevance = this.filterData.capaRelevance[0];
    }
    this.data.viewData.loadData().then(() => this.loading = false);
    this._authenticationService.getApplicationUser().then(value => {
      this.isEmployeeInDB = value.employee !== null;
      if (this.isEmployeeInDB) {
        this.fetchFilters();
      }
    });
  }

  private fetchFilters() {
    this.storeFilterService.getFilters(this.data.filterType).subscribe((filterElements: FilterElement[]) => {
      this.filters = filterElements;
      const includedFilters = this.filters?.filter(filter => {
        return filter.filterValue === JSON.stringify(this.filterData)
      });
      if (includedFilters?.length) {
        this.alreadySetFilter = includedFilters[0].id;
      }
    });
  }

  filterOnEnter(event: any) {
    if (event.keyCode === 13) {
      this.dialogRef.close(this.filterData);
    }
  }

  saveFilterData() {
    FilterListComponent.openDialog(this.dialog, this.filterData, this.data.filterType, () => {
      this.fetchFilters();
    });
  }

  loadFilterData(event) {
    let filterId = event.value as number;
    const filter = this.filters.find(f => f.id === filterId);
    this.filterData = this.storeFilterService.parseFilterValue(filter.filterValue);
  }

  get filterID() {
    return this.data.filterType + 'FilterData';
  }

  getFilterAttributeDisplayValue(filterAttribute: string): string {
    return this.translate.instant('mep.components.general.filter.values.' + filterAttribute);
  }

  getSortedViewData(filterAttribute: string): FilterViewData {
    if (filterAttribute === 'division') {
      this.data.viewData['division'].sort((a, b) => a.viewValue.localeCompare(b.viewValue));
    }
    return this.data.viewData[filterAttribute];
  }

  getFilterAttributeTooltip(filterAttribute: string): string {
    let key = 'mep.components.general.filter.tooltips.' + filterAttribute;
    let tooltip = this.translate.instant(key);
    return key === tooltip ? null : tooltip;
  }

  noFilterAttributes(): boolean {
    return FilterDialogComponent.arrayEmpty(this.data.employeeFilterAttributes)
      && FilterDialogComponent.arrayEmpty(this.data.normalFilterAttributes)
      && FilterDialogComponent.arrayEmpty(this.data.enumFilterAttributes)
      && FilterDialogComponent.arrayEmpty(this.data.singleEnumFilterAttributes);
  }
}

export interface FilterDialogTemplate {
  initialFilterData: EmployeeCapacityFilterData | EmployeeFilterData | ProjectFilterData
    | InstanceWideSearchFilterData | ActionPlanFilterData
    | SkillgroupFilterData | EmployeeProfileFilterData | ResourceRequestFilterData;

  filterType: FilterDialogType;

  /**
   * e.g. firstName
   */
  normalFilterAttributes?: string[];

  /**
   * e.g. status
   */
  enumFilterAttributes: string[];

  /**
   * e.g. relevance
   */
  singleEnumFilterAttributes?: string[];

  /**
   * e.g. personnelManager
   */
  employeeFilterAttributes: string[];

  /**
   * The skill filter attribute name
   */
  skillFilterAttribute?: string;

  /**
   * Show invisible skills
   */
  showInvisibleSkills?: boolean;

  viewData: FilterViewData;

  excludeCheckbox?: string[];
  excludeCheckboxName?: string[];

  filterName?: string;
}

export enum FilterDialogType {
  EMPLOYEE = 'employeeList',
  PROJECT = 'projectList',
  EMPLOYEE_CAPACITY = 'employeeCapacityList',
  INSTANCE_WIDE_SEARCH = 'instanceWideSearch',
  ACTION_PLAN = 'actionPlan',
  SKILLGROUP = "skillgroupEvaluation",
  EMPLOYEE_PROFILE = 'profileOverview',
  RESOURCE_REQUEST = 'resourceRequest'
}
