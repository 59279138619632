import {Injectable} from '@angular/core';
import {catchError, map} from "rxjs/operators";
import {AlertEnum} from "../../models/Enums/AlertEnum";
import {Observable, throwError} from "rxjs";
import {MessageService} from "./message.service";
import {TranslateService} from "@ngx-translate/core";
import {InfoDialogComponent} from "../../../shared/info-dialog/components/info-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import * as FileSaver from 'file-saver';
import moment from "moment";
import {Export} from "../../../shared/export-dialog/export";

@Injectable({
  providedIn: 'root'
})
export class ExcelExportService {

  constructor(private messageService: MessageService, private translate: TranslateService, private dialog: MatDialog) {

  }

  public export(exportObject: Export): Observable<boolean> {
    const filePrefix = exportObject.getFileNamePrefix();

    return exportObject.export().pipe(
      map(exportFile => {
        if (exportFile) {
          this.afterSuccessfulExport(exportFile, filePrefix);
          return true;
        } else {
          return false;
        }
      }),
      catchError(error => {
        this.afterUnsuccessfulExport(error);
        return throwError(error);
      })
    );
  }

  private afterSuccessfulExport(exportFile: BlobPart, fileNamePrefix: string): void {
    const suffix = moment().format('YYYY-MM-DD');
    const postfix = 'CON';
    const data: Blob = new Blob([exportFile], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    });
    FileSaver.saveAs(data, `${fileNamePrefix}_${suffix}_${postfix}.xlsx`);
    this.messageService.add(this.translate.instant('mep.components.export-dialog.notification.export-success'), AlertEnum.success);
  }

  private afterUnsuccessfulExport(error: any) {
    this.dialog.open(InfoDialogComponent, {
      maxHeight: '600px', maxWidth: '800px', data: error.message
    }).componentInstance.dialogTitle = 'Error Log';
  }
}
