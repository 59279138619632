import moment, {Moment} from "moment";
import {MatDatepicker} from "@angular/material/datepicker";
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from "@angular/material/core";
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from "@angular/material-moment-adapter";
import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {FormBuilder, FormControl} from "@angular/forms";
import {AuthenticationService} from "../../../core/services/services/authentication.service";
import {Preference, PreferencesService} from "../../../core/services/services/preferences.service";
import {CustomizingService} from "../../../core/services/services/customizing.service";

export const MONTH_YEAR_FORMAT = {
  parse: {
    dateInput: 'MM.YYYY',
  },
  display: {
    dateInput: 'MM.YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-month-picker',
  templateUrl: './month-picker.component.html',
  styleUrls: ['./month-picker.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MONTH_YEAR_FORMAT},
  ]
})
export class MonthPickerComponent implements OnInit {

  @Input()
  currentDate: Moment;

  @Input()
  parent: string;

  @Input()
  useMonthToPast;
  startDateInPast: Moment;

  date: FormControl;


  @Output()
  changedDate: EventEmitter<Moment> = new EventEmitter<Moment>();

  constructor(public authenticationService: AuthenticationService, private customizingService: CustomizingService,
              private formBuilder: FormBuilder, private preferencesService: PreferencesService) {
  }

  async ngOnInit() {
    if (!this.currentDate) {
      this.currentDate = moment();
    }
    this.date = new FormControl(this.currentDate);

    const preferenceResult = await this.preferencesService.get(Preference.START_DATE, this.parent);
    if (preferenceResult) {
      const dateItem = moment(preferenceResult);
      const ctrlValue = moment();
      ctrlValue.month(dateItem.month());
      ctrlValue.year(dateItem.year());
      this.setStartDate(ctrlValue);
    } else if (this.useMonthToPast) {
      const monthsToPast = await this.customizingService.getMonthPickerMonthsToPast();
      this.startDateInPast = moment().subtract(monthsToPast, 'months');
      this.setStartDate(this.startDateInPast);
    } else {
      this.setStartDate(this.currentDate);
    }
  }

  setStartDate(start: Moment) {
    this.date = new FormControl(start);
    this.changedDate.emit(start);
  }

  async chosenYearMonthHandler(normalizedDate: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = moment(this.date.value);
    ctrlValue.month(normalizedDate.month());
    ctrlValue.year(normalizedDate.year());
    this.setStartDate(ctrlValue);
    datepicker.close();
    await this.preferencesService.put(Preference.START_DATE, ctrlValue.toJSON(), this.parent);
  }
}

