import {Injectable} from '@angular/core';
import {MessageService} from './message.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import Config from '../../../core/config/Config';
import {HttpBaseService} from './http-base.service';

import {Observable} from 'rxjs';
import {EmployeeFile} from "../../models/EmployeeFile";
import {map, tap} from "rxjs/operators";
import {UploadImportFileResponse} from "../../models/import/UploadImportFileResponse";

@Injectable({
  providedIn: 'root'
})
export class EmployeeFileService extends HttpBaseService {

  constructor(public http: HttpClient, messageService: MessageService) {
    super(http, messageService);
  }

  fireEmployeeImportRequest(employeeFile: FormData): Observable<UploadImportFileResponse> {
    return this.http.post<UploadImportFileResponse>(Config.services.employees.import.baseUrl, employeeFile);
  }

  fireP1BookingImportRequest(employeeFile: FormData): Observable<UploadImportFileResponse> {
    return this.http.post<UploadImportFileResponse>(Config.services.employees.import.p1BookingImport, employeeFile);
  }

  getEmployeeFiles(employeeId): Observable<EmployeeFile[]> {
    return this.http.get<EmployeeFile[]>(Config.services.employees.baseUrl + employeeId + Config.services.employees.files)
      .pipe(
        map(result => result ? result : []),
        tap((result: EmployeeFile[]) => {
          for (const employeeFile of result) {
            employeeFile.downloadLink = Config.services.employees.baseUrl + Config.services.employees.downloadFile + employeeFile.id;
          }
        })
      );
  }

  /**
   * Don't use this method! It is only consider for dev mode and not production mode.
   * Downloads all employee files as zip. A filter can be set in EmployeeController.downloadEmployeeFiles.
   */
  getAllEmployeeFiles(): Observable<any> {
    return this.http.post(Config.services.employees.baseUrl + Config.services.employees.downloadFiles, null, {
      responseType: 'blob' as 'json',
      headers: new HttpHeaders({timeout: `${200000000}`}) // just a large number to not have any timeout issues
    });
  }

  addEmployeeFile(employeeId, employeeFile: EmployeeFile, file: File = null, link: string = null) {
    const formData = new FormData();
    formData.append('file', file);
    if (link) {
      formData.append('link', link);
    }
    let urlParams = {
      type: employeeFile.type,
      name: file ? file.name : link
    };

    if (employeeFile.comment) {
      urlParams['comment'] = employeeFile.comment;
    }

    return this.http.post(Config.services.employees.baseUrl + employeeId + Config.services.employees.uploadFile, formData, {
      reportProgress: true,
      observe: 'events',
      params: urlParams
    });
  }

  deleteEmployeeFile(employeeFileId: number) {
    return this.http.delete(Config.services.employeeFiles.baseUrl + employeeFileId);
  }

  getLatestUploadDate(employeeId): Observable<any> {
    return this.http.get(Config.services.employees.baseUrl + employeeId + Config.services.employees.files +
      Config.services.employees.latest).pipe(map(d => d));
  }
}
