import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {TranslatableComponent} from '../../core/models/TranslatableComponent';
import {TranslateService} from '@ngx-translate/core';
import {Project, SourceCodeStorage} from '../../core/models/Project';
import {ActivatedRoute, Router} from '@angular/router';
import {DataService} from '../../core/services/services/data.service';
import {AlertEnum} from '../../core/models/Enums/AlertEnum';
import {MessageService} from '../../core/services/services/message.service';
import {ProjectService} from '../../core/services/services/project.service';
import {ProjectTimeSlice, ProjectTimeSliceForm} from '../../core/models/ProjectTimeSlice';
import {ScreenRefreshService} from "../../core/services/services/refresh-screens.service";
import {ImportRefreshService} from "../../core/services/services/importDialogRefresh.service";
import {InfoDialogComponent} from "../info-dialog/components/info-dialog.component";
import Config from "../../core/config/Config";
import {CustomizingService} from "../../core/services/services/customizing.service";
import {MatSort} from "@angular/material/sort";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {MatTable, MatTableDataSource} from "@angular/material/table";
import {MatDrawer} from "@angular/material/sidenav";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {AuthenticationService} from "../../core/services/services/authentication.service";
import {
  ExtendOrMoveProjectDialogComponent
} from "../dialogs/extend-or-move-project-dialog/extend-or-move-project-dialog.component";
import {CopyAssignmentsDialogComponent} from "../dialogs/copy-assignments-dialog/copy-assignments-dialog.component";
import {Client} from "../../core/config/Client";
import {AddEmployeeToProjectComponent} from "./add-employee-to-project/add-employee-to-project.component";
import {AssignmentProjectEmployee} from "../../core/models/AssignmentProjectEmployee";
import {forkJoin, Observable} from "rxjs";
import {finalize, tap} from "rxjs/operators";
import moment from "moment";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {AddSourceCodeDialogComponent} from "../dialogs/add-source-code-dialog/add-source-code-dialog.component";
import {Employee} from "../../core/models/Employee";
import {ProjectDataInputComponent} from "./project-data-input/project-data-input.component";
import {DateValidator} from "../../core/validation/DateValidator";
import {AddEmployeeToProjectElement} from "./add-employee-to-project/add-employee-to.project.interface";

@UntilDestroy()
@Component({
  selector: 'app-project-data',
  templateUrl: './project-data.component.html',
  styleUrls: ['./project-data.component.scss']
})
export class ProjectDataComponent extends TranslatableComponent implements OnInit, OnChanges {

  @ViewChild('capaTableSort') set setCapaTableSort(sort: MatSort) {
    this.capaTableSort = sort;
    this.projectProbabilitiesDataSource.sort = this.capaTableSort;
  }

  @ViewChild('table') table: MatTable<any>;
  @ViewChild(ProjectDataInputComponent) projectDataInput: ProjectDataInputComponent

  @Output() projectSelected: EventEmitter<Project> = new EventEmitter<Project>();
  @Output() afterProjectAdded: EventEmitter<any> = new EventEmitter<any>();
  @Input() isAddMode: boolean;
  @Input() drawer: MatDrawer;
  @Input() isReadonlyMode: boolean;
  @Input() assignedEmployeesDataSource = new MatTableDataSource<AssignmentProjectEmployee>();
  @Input() projectProbabilitiesDataSource = new MatTableDataSource<ProjectTimeSliceForm>();
  @Input() convertFromStaffingRequest = false;

  isEditMode = false;
  project: Project = new Project();
  fixedProject: Project = new Project();
  displayedColumns: string[] = ['firstName', 'lastName'];
  employeesToProjectDataSource = new MatTableDataSource<Employee>();
  newProjAddEmployees: boolean;
  isProjectDataEdited: boolean;
  projectStatusChanged: boolean;
  isEmployeeAdded: boolean;
  loading = false;
  statusOOBcheck: boolean;
  addedTimeslices = false;
  requireProjectAdmin = false;
  showFinishedAggregatedProjects = true;
  addEmployeePanelExpanded = true;
  aggregatedProjectsDataSource = new MatTableDataSource<Project>();
  aggregatedProjectsDisplayedColumns: string[] = ['projectName', 'projectLeader', 'psp', 'startDate', 'endDate'];
  projectProbabilitiesDisplayedColumns: string[] = ['effectiveFrom', 'effectiveUntil', 'probability', 'delete'];
  capaTableSort: MatSort;
  aggregatedProjectsUnfiltered;
  sourceCodeStorage: SourceCodeStorage;
  today = moment();
  private currentClient: Client;
  addEmployeeToProjectElement: AddEmployeeToProjectElement;
  formGroup = new FormGroup({
    effectiveFrom: new FormControl('', {
      validators: Validators.required
    }),
    effectiveUntil: new FormControl('', {
      validators: Validators.required
    }),
    capacity: new FormControl('', [
      Validators.max(100), Validators.min(0), Validators.required
    ]),
  }, {validators: DateValidator.fromToDate("effectiveFrom", "effectiveUntil", "forbiddenEndDate")});

  constructor(private matDialog: MatDialog, private _importRefreshService: ImportRefreshService,
              private t: TranslateService, private _fb: FormBuilder, private _router: Router,
              private route: ActivatedRoute, public loginService: AuthenticationService,
              private _dataService: DataService, private _messageService: MessageService,
              private _projectService: ProjectService,
              private _screenRefreshService: ScreenRefreshService, private dialog: MatDialog,
              private customizing: CustomizingService) {
    super(t, 'ProjectDataComponent');
    customizing.getCurrentClient().then(client => {
      this.currentClient = client;
    });
    this.aggregatedProjectsDataSource = new MatTableDataSource<Project>();
  }

  ngOnInit() {
    if (!this.isReadonlyMode) {
      this.customizing.getExternalLinks().then(externalLinks => {
        this.statusOOBcheck = externalLinks === "OOB" || externalLinks === "CHARISMA_OOB";
      });
      if (this.isAddMode) {
        this.addEmployeeToProjectElement = {
          employees: this.employeesToProjectDataSource.data,
          capacity: 100,
          effectiveFrom: this.today,
          effectiveUntil: null,
          addEmployeeForm: false,
        };
        this.clearAll();
        this.drawer._closedStream.pipe(untilDestroyed(this)).subscribe(() => this.clearAll());
      } else {
        this.route.paramMap.subscribe(value => {
          const sharePointId = value.get('id');
          if (!sharePointId) {
            this._routeToHomeScreen(false);
          } else {
            this._setSelectedProject(sharePointId);
          }
        });
      }
    }

    this.projectProbabilitiesDataSource.sortingDataAccessor = (data: ProjectTimeSliceForm, column: string) => data[column].value;

    this.customizing.requireProjectAdmin().then(require => {
      this.requireProjectAdmin = require;
    });
    if (this.convertFromStaffingRequest) {
      this.newProjAddEmployees = true;
      this.formGroup.get('capacity').setValue('100');
    } else {
      this.customizing.isNewProjAddEmployee().then(enabled => {
        this.newProjAddEmployees = true;
        this.formGroup.get('capacity').setValue('100');
        this.formGroup.get('effectiveFrom').setValue(this.today);
      });
    }
  }

  clearAll(): void {
    this.project = new Project();
    this.customizing.getCurrentClient().then(client => {
      this.project.marketUnit = client;
      client === Client.GBP ? this.project.projectCategory = Config.services.employees.projectCategory.OTHER : "";
    });
    this.employeesToProjectDataSource = new MatTableDataSource<Employee>();
    this.sourceCodeStorage = null;
    this.projectProbabilitiesDataSource.data = [];
    this.addSlice();
  }

  openAddEmployeeToProjectDialog() {
    const infoForDialog: any = {
      addToProjectEmployees: this.addEmployeeToProjectElement?.employees
    };
    const dialogRef = this.dialog.open(AddEmployeeToProjectComponent, {
      data: infoForDialog,
    });

    dialogRef.afterClosed().subscribe(result => {
      this.addEmployees(result)
    });
  }

  addEmployees(result) {
    if (result !== undefined) {
      this.employeesToProjectDataSource.data = result?.employees;
      this.addEmployeeToProjectElement.employees = result?.employees;
      this.isEmployeeAdded = result?.addEmployeeForm;
      if (result.employees?.length > 0) {
        this.addEmployeePanelExpanded = true;
      }
    }
  }

  invalidProjectProbabilities(): boolean {
    return this.overlappingProjectProbabilities() || this.invalidProjectProbabilityForms();
  }


  invalidProjectProbabilityForms(): boolean {
    return this.projectProbabilitiesDataSource.data.some(control => control.invalid);
  }

  overlappingProjectProbabilities(): boolean {
    const probabilities: ProjectTimeSliceForm[] = this.projectProbabilitiesDataSource.data;
    return probabilities.some(p1 => probabilities.some(p2 => p1 !== p2 && this.overlapping(p1, p2)));
  }

  overlapping(timeSlice1: ProjectTimeSliceForm, timeSlice2: ProjectTimeSliceForm): boolean {
    return this.contains(timeSlice1, timeSlice2.effectiveFrom.value) || this.contains(timeSlice1, timeSlice2.effectiveUntil.value)
      || this.contains(timeSlice2, timeSlice1.effectiveFrom.value) || this.contains(timeSlice2, timeSlice1.effectiveUntil.value);
  }

  contains(timeSlice: ProjectTimeSliceForm, date: string): boolean {
    if (date) {
      const dateMoment = moment(date);
      return moment(timeSlice.effectiveFrom.value) <= dateMoment && (!timeSlice.effectiveUntil.value || moment(timeSlice.effectiveUntil.value) >= dateMoment);
    } else {
      return false;
    }
  }

  blockNonAdminEdit(): boolean {
    return this.requireProjectAdmin && !this.loginService.isProjectAdmin();
  }

  private _routeToHomeScreen(showErrorMessage: boolean = true, unknownProjectID: string = null) {
    if (showErrorMessage) {
      this._messageService.add(this._lang('no_project_found', {projectID: unknownProjectID}), AlertEnum.danger);
    }
    this._router.navigate(['homescreen']);
  }

  private _setSelectedProject(sharePointId: string) {
    this.fetchProject(sharePointId);
  }

  private fetchProject(sharePointId: string) {
    this.loading = true;
    this._projectService.getProjectData(sharePointId).subscribe(
      project => {
        this.project = Object.assign({}, project);
        this.fixedProject = Object.assign({}, project);
        this._afterProjectSelected();
      },
      () => this._routeToHomeScreen(true, sharePointId)
    ).add(() => this.loading = false);
  }

  private _afterProjectSelected() {
    this._getProjectTimeSlices();
    this.projectSelected.emit(this.project);
    this.fetchAggregatedProjects(this.project);
  }

  private _getProjectTimeSlices() {
    if (!this.isAddMode || this.addedTimeslices || this.isReadonlyMode) {
      this.loading = true;
      this._projectService.getProjectProbabilities(this.project, this.isAddMode).then((timeSlices: ProjectTimeSlice[]) => {
          this.projectProbabilitiesDataSource.data = timeSlices.map(timeSlice => new ProjectTimeSliceForm(timeSlice));
          this.loading = false;
        },
        () => {
          this.projectProbabilitiesDataSource.data = null;
          this.loading = false;
        }
      );
      this.addedTimeslices = false;
    }

  }

  checkToGetProjectTimeSlices() {
    if (!this.isAddMode) {
      this._getProjectTimeSlices();
    }
  }

  activateEditMode() {
    this.isEditMode = true;
  }

  // Deactivate the edit mode and reset the format content

  deactivateEditMode() {
    if (this.isAddMode || this.isReadonlyMode) {
      this.drawer.close().then();
    } else {
      this.project = Object.assign({}, this.fixedProject);
    }
    this.isEditMode = false;
  }

  submitProject() {
    this.loading = true;
    if (this.isAddMode) {
      if (this.currentClient === Client.GBP ? !this.sourceCodeStorageEmpty() : true) {
        this.addProject();
      } else {
        this._messageService.add(this._lang('fill-out-sourcecode-storage'), AlertEnum.danger);
        this.loading = false;
      }
    } else {
      this.updateProject();
    }
  }

  updateProject() {
    if (this.checkForSelectedEmployee()) {
      this._projectService.updateProject(this.project).subscribe(
        (project: any) => {
          if (project) {
            this._afterProjectSubmitted(this._lang('save_changes', {projectName: project.projectName}))
          } else {
            this._afterProjectSubmitted(project.message, AlertEnum.danger);
          }
          if (this.projectStatusChanged && this.statusOOBcheck) {
            this._messageService.add(this._lang('project_status_changed'), AlertEnum.success);
            this.projectStatusChanged = false;
          }
          this.project.eVersion = project.eVersion;
          this.setTimeslicesInProject().subscribe();
        },
        (error) => {
          this.onError(error);
        });
    } else {
      this.loading = false;
    }
  }

  onError(error: any) {
    if (error.status === 412) {
      this._importRefreshService.refreshScreens(true);
    }
    this.loading = false;
  }

  private addProject() {
    if (this.checkForSelectedEmployee()) {

      this._projectService.createProject(this.project).subscribe(
        (project: any) => {
          this.project.id = project.id;
          this.setTimeslicesInProject().subscribe(() => {
            project ? this._afterProjectSubmitted(this._lang('create-new', {projectName: project.projectName})) :
              this._afterProjectSubmitted(project.message, AlertEnum.danger);
            if (this.aggregatedProjectsDataSource.data.length > 0) {
              this.aggregatedProjectsDataSource.data.forEach(addedProject => {
                this._projectService.setAggregate(project.id, addedProject.id);
              });
            }
          });
        },
        (error) => {
          this.onError(error);
        });
    } else {
      this.loading = false;
    }
  }

  private checkForSelectedEmployee(): boolean {
    // because ZT has not divisionManager as required field
    if (this.isCurrentClientZT()) {
      return true;
    }
    // Allow undefined project leader because after imports there may are some projects without project leader
    // and it should be possible to edit there time slices.
    if (this.project.projectLeader === null) {
      return true;
    }
    if (this.project.projectLeader._links === undefined) {
      this._messageService.add(this._lang('project_leader_error'), AlertEnum.danger);
      return false;
    } else if (this.project.divisionManager._links === undefined) {
      this._messageService.add(this._lang('division_manager_error'), AlertEnum.danger);
      return false;
    } else {
      return true;
    }
  }


  addEmployeeToProject() {
    if (this.addEmployeeToProjectElement?.employees?.length > 0) {
      const observes: Observable<AssignmentProjectEmployee>[] = Object.values(this.addEmployeeToProjectElement.employees).map(employee => this._projectService.addAssignmentProjectEmployee({
        project: Config.services.projects.baseUrl + this.project.id,
        employee: Config.services.employees.baseUrl + employee.id,
        effectiveFrom: this.addEmployeeToProjectElement.effectiveFrom,
        effectiveUntil: this.addEmployeeToProjectElement.effectiveUntil,
        capacity: this.addEmployeeToProjectElement.capacity,
      } as any));
      forkJoin(observes).subscribe();
    }
  }

  openDrawer(projects: Project[]) {
    let title = document.getElementById('detail-title');
    title.innerText = this.t.instant('mep.components.project-data.headline-add-mode');
    this.activateEditMode();

  }

  isAssignmentBetweenProject(): boolean {
    if (!this.addEmployeeToProjectElement || !this.addEmployeeToProjectElement.employees?.length) {
      return true;
    } else {
      const employeeEffectiveFrom = moment(this.addEmployeeToProjectElement.effectiveFrom).startOf('day');
      const employeeEffectiveUntil = moment(this.addEmployeeToProjectElement.effectiveUntil).startOf('day');

      return this.projectProbabilitiesDataSource.data.some(timeSlice => {
        const projectEffectiveFrom = moment(timeSlice.effectiveFrom.value).startOf('day');

        return projectEffectiveFrom.isSameOrBefore(employeeEffectiveFrom)
          && (!timeSlice.effectiveUntil.value || moment(timeSlice.effectiveUntil.value).startOf('day').isSameOrAfter(employeeEffectiveUntil));
      });
    }
  }

  private setTimeslicesInProject(): Observable<any> {
    return this._projectService.putProjectTimeSlices(this.project.id, this.projectProbabilitiesDataSource.data.map(form => form.getRawValue())).pipe(tap
      (result => {
        this.projectProbabilitiesDataSource.data = result.map(timeSlice => new ProjectTimeSliceForm(timeSlice));

        if (this.project.id && !this.isAddMode) {
          // Fetch project again because the editing of project time slices could have caused an update of project start, end and probability
          // See ProjectTimeSliceService::syncProjectBoundsAndProbability in the BE
          this._setSelectedProject(this.project.sharePointId);
        }
      }),
      finalize(() => this.afterProjectAdded.emit())
    );
  }

  private _afterProjectSubmitted(message: string, messageType: AlertEnum = AlertEnum.success) {
    if (this.isAddMode) {
      this.addEmployeeToProject();
      this.drawer.close();
    }
    this.isEditMode = false;
    this._messageService.add(message, messageType);
    this.loading = false;
    this.projectProbabilitiesDataSource?.data.forEach(control => control.markAsPristine());
    this.isProjectDataEdited = false;
    if (this.sourceCodeStorage) {
      this.sourceCodeStorage.sourceCodeForm = false;
    }
  }

  allRequiredFieldIsFilled(): boolean {
    if (this.isCurrentClientGBP()) {
      return this.project.division
        && this.project.projectCategory
        && this.project.division
        && this.project.projectName
        && this.project.divisionManager
        && this.project.projectLeader
        && (this.isAddMode ? this.project.status : true)
        && (this.addEmployeeToProjectElement?.employees.length > 0 ? !this.formGroup.controls.effectiveUntil.invalid : true)
        && this.projectProbabilitiesDataSource.data.length > 0;
    }
    if (this.isCurrentClientZT()) {
      return this.project.projectName
        && this.project.projectLeader
        && this.project.status;
    }
    return this.project.projectName
      && this.project.divisionManager
      && this.project.projectLeader
      && (this.isAddMode ? this.project.status : true)
      && (this.addEmployeeToProjectElement?.employees.length > 0 ? !this.formGroup.controls.effectiveUntil.invalid : true)
      && this.projectProbabilitiesDataSource.data.length > 0;

  }

  sourceCodeStorageEmpty(): boolean {
    if ((!this.sourceCodeStorage || !this.project.sourceCodeStorageLocation)) {
      return true;
    }

  }

  addSlice() {
    let data = this.projectProbabilitiesDataSource.data;
    let effectiveFrom = moment();
    if (data.length > 0) {
      data.sort((a, b) => {
        if (!a.effectiveUntil.value && !b.effectiveUntil.value) {
          return 0;
        }
        if (!a.effectiveUntil.value) {
          return -1;
        }
        if (!b.effectiveUntil.value) {
          return 1;
        }
        if (moment(a.effectiveUntil.value) < moment(b.effectiveUntil.value)) {
          return -1;
        } else if (moment(a.effectiveUntil.value) > moment(b.effectiveUntil.value)) {
          return 1;
        }
        return 0;
      });
      if (data[data.length - 1].effectiveUntil.value) {
        effectiveFrom = moment(data[data.length - 1].effectiveUntil.value).add(1, 'days');
      }
    }
    data.push(new ProjectTimeSliceForm({
      effectiveFrom,
      effectiveUntil: '',
      probability: '100'
    } as any));
    this.projectProbabilitiesDataSource.data = data;
    if (this.table) {
      this.table.renderRows();
    }
  }

  deleteSlice(timeSlice: ProjectTimeSliceForm) {
    let employeesWithAssignmentInDeletedTimeSlice: string[] = ProjectService.checkAssignmentsAndProjectTimeSliceFormsForOverlapping(timeSlice, this.assignedEmployeesDataSource);
    if (employeesWithAssignmentInDeletedTimeSlice.length > 0) {
      let componentInstance = this.dialog.open(InfoDialogComponent, {
        maxHeight: '600px', maxWidth: '800px', data: employeesWithAssignmentInDeletedTimeSlice
      }).componentInstance;
      componentInstance.dialogTitle = 'Information';
      componentInstance.dialogDescription = this.translate.instant('mep.services.project-service.description');
    }
    let data = this.projectProbabilitiesDataSource.data;
    data.splice(data.indexOf(timeSlice), 1);
    this.projectProbabilitiesDataSource.data = data;
    this.table.renderRows();
  }

  openChangeProjectEndDialog(): void {
    const dialogRef = this.matDialog.open(ExtendOrMoveProjectDialogComponent, {
      disableClose: true,
      width: '1000px',
      data: this.project,
    });

    this.initOnClose(dialogRef);
  }

  openCopyAssignmentsDialog(): void {
    const dialogRef = this.matDialog.open(CopyAssignmentsDialogComponent, {
      disableClose: true,
      width: '1000px',
      data: this.project,
    });

    this.initOnClose(dialogRef);

  }

  initOnClose(dialogRef: MatDialogRef<any>) {
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.ngOnInit();
      }
    });
  }

  checkNull(value: number) {
    if (value) {
      return value + '%';
    } else {
      return '';
    }

  }

  aggregationInit(selected: Project[]) {
    let aggregatedProject: Project = null;
    this._projectService.getProjectData(selected[0].sharePointId).subscribe(project => {
      aggregatedProject = Object.assign({}, project);
      aggregatedProject.id = null;
      aggregatedProject.sharePointId = null;

      this.activateEditMode();
      let title = document.getElementById('detail-title');
      title.innerText = this.t.instant('mep.components.project-data.agg-title');

      this._projectService.getProjectProbabilities(aggregatedProject, false).then(probabilities => {
        this.projectProbabilitiesDataSource.data = probabilities.map(slice => new ProjectTimeSliceForm(slice));
      });
      aggregatedProject.projectName = project.projectName + this.t.instant('mep.components.project-data.agg-name-ending');

      let allPsps = '';
      selected.forEach((selectedProject, index) => {
        if (index + 1 === selected.length) {
          allPsps = allPsps.concat(selectedProject.psp);
        } else {
          allPsps = allPsps.concat(selectedProject.psp + ", ");
        }
      });
      aggregatedProject.psp = allPsps;

      this.project = aggregatedProject;
      this.aggregatedProjectsDataSource.data = selected;
      this.aggregatedProjectsUnfiltered = selected;
    });

  }

  fetchAggregatedProjects(selectedProject: Project) {
    this._projectService.getAggregatedProjects(selectedProject.id).subscribe(projects => {
      this.aggregatedProjectsDataSource.data = projects;
      this.aggregatedProjectsUnfiltered = projects;
    }, () => {
      this._messageService.add(this._lang('notification.fetch-project-failed'), AlertEnum.danger);
    });
  }

  finishedCheckboxChange() {
    this.showFinishedAggregatedProjects = !this.showFinishedAggregatedProjects;
    if (this.showFinishedAggregatedProjects) {
      this.aggregatedProjectsDataSource.data = this.aggregatedProjectsUnfiltered;
    } else {
      let filtered: Project[] = [];
      this.aggregatedProjectsDataSource.data.forEach(project => {
        if (Date.parse(project.endDate) >= Date.now()) {
          filtered.push(project);
        }
      });
      this.aggregatedProjectsDataSource.data = filtered;
    }
  }

  setSourceCodeStorage() {
    this.project.sourceCodeStorageLocation = this.sourceCodeStorage?.sourceCodeStorageLocation;
    this.project.stackIntegratedStorage = this.sourceCodeStorage?.stackIntegratedStorage;
    this.project.msgHub = this.sourceCodeStorage?.msgHub;
    this.project.cloudSourceStorage = this.sourceCodeStorage?.cloudSourceStorage;
    this.project.reasonForOtherCloudSource = this.sourceCodeStorage?.reasonForOtherCloudSource;
  }

  openSourceCodeDialog() {

    const infoForDialog: any = {
      sourceCodeStorage: this.sourceCodeStorage,
      project: this.project
    };

    const dialogRef = this.dialog.open(AddSourceCodeDialogComponent, {
      data: infoForDialog,

    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.sourceCodeStorage = result;
        this.setSourceCodeStorage();
      }
    });
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (this.isReadonlyMode) {
      this.isAddMode = false;
      this.isEditMode = false;
      this._dataService.currentProject.pipe(untilDestroyed(this)).subscribe(project => {
        if (project) {
          this._setSelectedProject(project.sharePointId);
        }
      });
    }
  }

  isCurrentClientGBP(): boolean {
    return this.currentClient === Client.GBP;
  }

  isCurrentClientZT(): boolean {
    return this.currentClient === Client.ZT;
  }

  showSourceCodeStorageLocation() {
    if (this.project.sourceCodeStorageLocation == 'CLIENT_RESPONSIBILITY') {
      return this._lang("attributes.client_responsibility");
    } else if (this.project.sourceCodeStorageLocation == 'GBP_RESPONSIBILITY') {
      return this._lang("attributes.gbp_responsibility");
    } else if (this.project.sourceCodeStorageLocation == 'NO_SOURCE_CODE') {
      return this._lang("attributes.no_source_code");
    }
  }

  inputModelChanged(newItem: string) {
    if (newItem) {
      this.isProjectDataEdited = true;
    }
  }

  onProjectStatusChanged(projectStatus: string) {
    if (projectStatus) {
      this.projectStatusChanged = true;
    }
  }

  setEffectiveFromDate(event: any) {
    this.addEmployeeToProjectElement.effectiveFrom = event.value;
  }

  setEffectiveUntilDate(event: any) {
    this.addEmployeeToProjectElement.effectiveUntil = event.value;
  }

  setCapacity(newValue) {
    this.addEmployeeToProjectElement.capacity = newValue;
  }

}
