export interface OptionAutocomplete {
  id: string;
  identificator: string;
  icon: string;
  searchType: GlobalSearchType;
}

export enum GlobalSearchType {
  EMPLOYEE,
  PROJECT
}
