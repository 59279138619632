import MarketUnit from './MarketUnit';
import Divisions from './Divisions';
import JobVariants from "./JobVariants";
import JobFamilies from "./JobFamilies";
import EmployeeFields from "./EmployeeFields";
import ProjectFields from "./ProjectFields";

export const AWS_BASE_URL = 'http://mepServiceLbBackend-1476103919.eu-central-1.elb.amazonaws.com';

export default {
  titleSuffix: ' | MEP',
  sharePointLink: 'https://team.msg.de/site/GBP/GBP_PlanningLists/Projekte_bergreifend',
  releaseNotesLink: 'https://mep.gbp.msg.team/mep/static/release-notes.html',
  userManualLink: 'https://mep.gbp.msg.team/mep/static/user-manual/index.html',
  supportedLanguagesRegex: /en|de/,
  defaultLanguage: 'en',
  widthBreak: 1600,
  keycloakRoles: {
    divisionManager: 'Bereichsleiter',
    departmentManager: 'Abteilungsleiter',
    businessUnitManager: 'Geschäftsbereichsleiter',
    readonly: 'readonly'
  },
  filter: {
    baseUrl: 'filter?',
    inlineLeader: '&projection=inlineLeader',
    projectName: 'projectName',
    status: 'status',
    marketUnit: 'marketUnit',
    client: 'client',
    firstName: 'firstName',
    lastName: 'lastName',
    humanResourcesManager: 'humanResourcesManager',
    businessUnit: 'businessUnit',
    division: 'division',
    jobLevel: 'jobLevel',
    skillsRating: 'skillRating',
    location: 'location',
    travelWillingness: 'travelWillingness',
    skills: 'skills'
  },
  authentication: {
    refresh: 'refresh_token',
    access: 'access_token',
    status: 'authenticated'
  },
  services: {
    user: '/api/user',
    login: AWS_BASE_URL + '/mep/login',
    logout: AWS_BASE_URL + '/mep/token/logout',
    token: AWS_BASE_URL + '/mep/token',
    absence: {
      baseUrl: AWS_BASE_URL + '/mep/api/absences/',
      isManual: AWS_BASE_URL + '/mep/absence/isManual/'
    },
    default_values: {
      MISSING_VALUE: 'N/A'
    },
    missingValue: 'N/A',
    missingValueBackend: 'MISSING_VALUE',
    employees: {
      baseUrl: AWS_BASE_URL + '/mep/api/employees/',
      proposalUrl: 'projectEmployeeProposal/',
      exportCapacitiesAsExcel: '/capacities/excel',
      availableEmployees: '/capacities/availableEmployees',
      skillURL: AWS_BASE_URL + '/mep/api/skills/',
      skillNameSearchURL: AWS_BASE_URL + '/mep/api/skills/skillName/',
      employeeFullNameByEmployeeId: AWS_BASE_URL + '/mep/api/employees/getEmployeeFullNameByEmployeeId',
      files: '/files',
      uploadFile: '/uploadFile',
      downloadFile: 'downloadFile/',
      loadPreviewFile: 'loadPreviewFile/',
      downloadFiles: 'downloadFiles',
      hierarchy: 'hierarchy/',
      hierarchiesByCostCenter: 'hierarchiesByCostCenter',
      departments: 'departments',
      personnelManagers: 'personnelManagers',
      profileData: 'profileData',
      fullNameProjectionByNameSearch: 'fullNameProjectionByNameSearch',
      blacklisted: 'globalSearch/blacklisted',
      updateBlacklist: 'globalSearch/updateBlacklist',
      removeBlacklisted: 'globalSearch/removeBlacklist',
      withoutNameUrl: 'withoutName',
      notifyHRM: 'notifyHRM/',
      setDeclarationOfConsentUrl: '/setDeclarationOfConsent',
      getSignature: 'getSignature',
      changeSkillVisibility: 'changeSkillVisibility',
      latest: "/latest",
      byMonthAndSkillgroup: "byMonthAndSkillgroup",
      exportSkillgroupExcel: "exportSkillgroupEvaluation",
      tags: "tags",
      employeeEdit: 'employee-edit/',
      import: {
        baseUrl: AWS_BASE_URL + '/mep/import/employee',
        p1BookingImport: AWS_BASE_URL + '/mep/import/p1booking',
        jobFamily: AWS_BASE_URL + '/mep/api/employees/jobfamilies',
        jobLevel: AWS_BASE_URL + '/mep/api/employees/joblevels',
        jobVariant: AWS_BASE_URL + '/mep/api/employees/jobvariants',
        status: AWS_BASE_URL + '/mep/api/employees/statuses',
        location: AWS_BASE_URL + '/mep/api/employees/locations',
        travelWillingness: AWS_BASE_URL + '/mep/api/employees/travelwillingness',
        absence: AWS_BASE_URL + '/mep/import/absence',
        training: AWS_BASE_URL + '/mep/import/training',
        division: AWS_BASE_URL + '/mep/api/projects/divisions'
      },
      exportMyEmployeesAsExcel: '/myEmployees',
      exportExcel: 'employeeDetails/excel',
      exportSelectedProjectListExcel: 'employeeDetails/selectedEmployeeList/excel',
      exportSelectedEmployeeProfileDataExcel: 'selectedEmployeeProfileData/excel',
      exportSelectedEmployeesSkillsExcel: 'selectedEmployeesSkills/excel',
      exportEmployeesSkillsExcel: 'employeesSkills/excel',
      exportEmployeesAndCertificatesExcel: 'employeesAndCertificates/excel',
      exportEmployeesAndExpiredCertificatesExcel: 'employeesAndExpiredCertificates/excel',
      businessUnit: MarketUnit.MarketUnit,
      division: Divisions.DivisionsDefault,
      divisionGBI: Divisions.DivisionsGBI,
      divisionMSG_SERVICES: Divisions.DivisionsMSG_SERVICES,
      divisionM3: Divisions.DivisionsM3,
      divisionMSG_INDUSTRY_ADVISORS: Divisions.DivisionsMSG_INDUSTRY_ADVISORS,
      divisionZM: Divisions.DivisionsZM,
      divisionZT: Divisions.DivisionsZT,
      divisionMSG_TREORBIS: Divisions.DivisionsTreorbis,
      divisionMSG_NEXINSURE: Divisions.DivisionsNexinsure,
      divisionGBF: Divisions.DivisionsGBF,
      divisionMSG_GILLARDON: Divisions.DivisionsMSG_GILLARDON,
      divisionGBG: Divisions.DivisionsGBG,
      divisionMSG_CH: Divisions.DivisionsMSG_CH,
      divisionGBY: Divisions.DivisionsGBY,
      jobFamily: JobFamilies.JobFamilies,
      jobFamilyMSG_SERVICES: Object.assign({}, JobFamilies.JobFamilies, JobFamilies.JobFamiliesMSG_SERVICES),
      jobFamilyM3: Object.assign({}, JobFamilies.JobFamilies, JobFamilies.JobFamiliesM3),
      jobLevel: {
        L0: '00',
        L1: '01',
        L2: '02',
        L3: '03',
        L4: '04',
        L5: '05',
        L6: '06'
      },
      capaRelevance: {
        NOT_RELEVANT: 'mep.enum.capaRelevance.NOT_RELEVANT',
        ONLY_RELEVANT: 'mep.enum.capaRelevance.ONLY_RELEVANT',
        ALL: 'mep.enum.capaRelevance.ALL'
      },
      department: 'department',
      jobVariant: JobVariants.JobVariants,
      jobVariantMSG_SERVICES: Object.assign({}, JobVariants.JobVariants, JobVariants.JobVariantsMSG_SERVICES),
      jobVariantM3: Object.assign({}, JobVariants.JobVariants, JobVariants.JobVariantsM3),
      status: {
        ACTIVE: 'mep.enum.employee-status.ACTIVE',
        INACTIVE: 'mep.enum.employee-status.INACTIVE',
        EXTERNAL: 'mep.enum.employee-status.EXTERNAL',
      },
      travelWillingnessGBI: {
        NO: 'mep.components.employee-capacity-list.travelWillingnessRegion.NO',
        GERMANY: 'mep.components.employee-capacity-list.travelWillingnessRegion.GERMANY',
        DACH: 'mep.components.employee-capacity-list.travelWillingnessRegion.DACH',
        EUROPE: 'mep.components.employee-capacity-list.travelWillingnessRegion.EUROPE',
        INTERNATIONAL: 'mep.components.employee-capacity-list.travelWillingnessRegion.INTERNATIONAL'
      },
      travelWillingnessMSG_SERVICES: {
        NO: 'mep.components.employee-capacity-list.travelWillingnessRegion.NO',
        GERMANY: 'mep.components.employee-capacity-list.travelWillingnessRegion.GERMANY',
        DACH: 'mep.components.employee-capacity-list.travelWillingnessRegion.DACH',
        EUROPE: 'mep.components.employee-capacity-list.travelWillingnessRegion.EUROPE',
        INTERNATIONAL: 'mep.components.employee-capacity-list.travelWillingnessRegion.INTERNATIONAL'
      },
      travelWillingness: {
        PARTLY: 'mep.components.employee-capacity-list.travelWillingness.PARTLY',
        REGULARLY: 'mep.components.employee-capacity-list.travelWillingness.REGULARLY',
        PERMANENTLY: 'mep.components.employee-capacity-list.travelWillingness.PERMANENTLY'
      },
      travelWillingnessCombined: {
        PARTLYFALSE: 'mep.components.employee-capacity-list.travelWillingnessCombined.PARTLYFALSE',
        PARTLYTRUE: 'mep.components.employee-capacity-list.travelWillingnessCombined.PARTLYTRUE',
        REGULARLYFALSE: 'mep.components.employee-capacity-list.travelWillingnessCombined.REGULARLYFALSE',
        REGULARLYTRUE: 'mep.components.employee-capacity-list.travelWillingnessCombined.REGULARLYTRUE',
        PERMANENTLYFALSE: 'mep.components.employee-capacity-list.travelWillingnessCombined.PERMANENTLYFALSE',
        PERMANENTLYTRUE: 'mep.components.employee-capacity-list.travelWillingnessCombined.PERMANENTLYTRUE',
        MISSING_VALUE: 'N/A'
      },
      travelWillingnessCombinedGBI: {
        NO: 'mep.components.employee-capacity-list.travelWillingnessRegion.NO',
        GERMANY: 'mep.components.employee-capacity-list.travelWillingnessRegion.GERMANY',
        DACH: 'mep.components.employee-capacity-list.travelWillingnessRegion.DACH',
        EUROPE: 'mep.components.employee-capacity-list.travelWillingnessRegion.EUROPE',
        INTERNATIONAL: 'mep.components.employee-capacity-list.travelWillingnessRegion.INTERNATIONAL',
        MISSING_VALUE: 'N/A'
      },
      travelWillingnessCombinedMSG_SERVICES: {
        NO: 'mep.components.employee-capacity-list.travelWillingnessRegion.NO',
        GERMANY: 'mep.components.employee-capacity-list.travelWillingnessRegion.GERMANY',
        DACH: 'mep.components.employee-capacity-list.travelWillingnessRegion.DACH',
        EUROPE: 'mep.components.employee-capacity-list.travelWillingnessRegion.EUROPE',
        INTERNATIONAL: 'mep.components.employee-capacity-list.travelWillingnessRegion.INTERNATIONAL',
        MISSING_VALUE: 'N/A'
      },
      travelWillingnessInternational: {
        TRUE: 'mep.components.employee-edit.edit-international-travel.yes',
        FALSE: 'mep.components.employee-edit.edit-international-travel.no'
      },
      travelWillingnessInternationalText: {
        TRUE: 'mep.components.employee-capacity-list.travelWillingnessInternationalText.TRUE',
        FALSE: 'mep.components.employee-capacity-list.travelWillingnessInternationalText.FALSE'
      },
      location: {
        BERLIN: 'mep.components.employee-edit.locationPlaces.BERLIN',
        BRAUNSCHWEIG: 'mep.components.employee-edit.locationPlaces.BRAUNSCHWEIG',
        HAMBURG: 'mep.components.employee-edit.locationPlaces.HAMBURG',
        CHEMNITZ: 'mep.components.employee-edit.locationPlaces.CHEMNITZ',
        CLUJ: 'mep.components.employee-edit.locationPlaces.CLUJ',
        ESSEN: 'mep.components.employee-edit.locationPlaces.ESSEN',
        FRANKFURT: 'mep.components.employee-edit.locationPlaces.FRANKFURT',
        HOMEOFFICE: 'mep.components.employee-edit.locationPlaces.HOMEOFFICE',
        INGOLSTADT: 'mep.components.employee-edit.locationPlaces.INGOLSTADT',
        MUNICH: 'mep.components.employee-edit.locationPlaces.MUNICH',
        HUERTH: 'mep.components.employee-edit.locationPlaces.HÜRTH',
        LONDON: 'mep.components.employee-edit.locationPlaces.LONDON',
        PASSAU: 'mep.components.employee-edit.locationPlaces.PASSAU',
        KOELN: 'mep.components.employee-edit.locationPlaces.KOELN',
        STGEORGEN: 'mep.components.employee-edit.locationPlaces.STGEORGEN',
        STUTTGART: 'mep.components.employee-edit.locationPlaces.STUTTGART',
        TIMISOARA: 'mep.components.employee-edit.locationPlaces.TIMISOARA',
        WIEN: 'mep.components.employee-edit.locationPlaces.WIEN',
        GORLITZ: 'mep.components.employee-edit.locationPlaces.GORLITZ',
        BANGALORE: 'mep.components.employee-edit.locationPlaces.BANGALORE',
        NUREMBERG: 'mep.components.employee-edit.locationPlaces.NUREMBERG',
        HANNOVER: 'mep.components.employee-edit.locationPlaces.HANNOVER',
        BRETTEN: 'mep.components.employee-edit.locationPlaces.BRETTEN',
        DUSSELDORF: 'mep.components.employee-edit.locationPlaces.DUSSELDORF',
        MUENSTER: 'mep.components.employee-edit.locationPlaces.MUENSTER',
        SCHORTENS: 'mep.components.employee-edit.locationPlaces.SCHORTENS',
        LINGEN: 'mep.components.employee-edit.locationPlaces.LINGEN',
        GERMANY: 'mep.components.employee-edit.locationPlaces.DEUTSCHLAND',
        ROMANIA: 'mep.components.employee-edit.locationPlaces.ROMANIEN',
        BASEL: 'mep.components.employee-edit.locationPlaces.BASEL',
        REGENSDORF: 'mep.components.employee-edit.locationPlaces.REGENSDORF',
        KARLSRUHE: 'mep.components.employee-edit.locationPlaces.KARLSRUHE'
      },
      country: {
        DE: 'DE',
        RO: 'RO',
        GB: 'GB',
        OE: 'OE',
        IND: 'IND',
        CH: 'CH'
      },
      absenceTypes: {
        APPROVED: 'UG',
        REQUESTED: 'UB',
        TRAINING: 'S',
        ABSENT: 'A',
        REGIONAL: 'RF'
      },
      businessTopics: {
        MISSING_VALUE: 'mep.enums.business-topics.missing_value',
        OTHER: 'mep.enums.business-topics.other',
        REINSURANCE: 'mep.enums.business-topics.reinsurance',
        SMARTINPUT: 'mep.enums.business-topics.smartinput',
        DIGITALLEANMANUFACTURING: 'mep.enums.business-topics.digital_lean_manufacturing',
        DAIRY: 'mep.enums.business-topics.dairy'
      },
      branches: {
        MISSING_VALUE: 'N/A',
        AUTOMOTIVE: 'Automotive',
        BANKING: 'Banking',
        FOOD: 'Food',
        INSURANCE: 'Insurance',
        PUBLIC_SECTOR: 'Public Sector',
        LIFE_SCIENCE_HEALTHCARE: 'Life Science & Healthcare',
        TELECOMMUNICATIONS: 'Telecommunications',
        MANUFACTORING: 'Manufactoring',
        TRAVEL_LOGISTICS: 'Travel & Logistics',
        UTILITIES: 'Utilities'
      },
      strategicObjective: {
        MISSING_VALUE: 'mep.enums.project-edit.missing_value',
        OTHER: 'mep.enums.project-edit.other',
        PLATFORM: 'mep.enums.project-edit.platform',
        S4HANA: 'mep.enums.project-edit.s4hana',
        CLOUD: 'mep.enums.project-edit.cloud',
        JAVA_ENTERPRISE: 'mep.enums.project-edit.java_enterprise'
      },
      strategicObjectiveCONPLAN: {
        MISSING_VALUE: 'N/A',
        OTHER: 'Sonstiges',
        PROJECT_MANAGEMENT: 'Projektmanagement',
        SOFTWARE_ENGINEERING: 'Software Engineering',
        RELEASE_AUTOMATION: 'Release Automation',
        SOPA_360: 'SOPA 360'
      },
      currency: {
        EUR: 'EUR',
        USD: 'USD',
        CHF: 'CHF',
        SGD: 'SGD',
        MISSING_VALUE: 'N/A'
      },
      actionPlanStatus: {
        ACTION_STATUS_OPEN: 'mep.enums.actionplan-status.ACTION_STATUS_OPEN',
        ACTION_STATUS_IN_PROGRESS: 'mep.enums.actionplan-status.ACTION_STATUS_IN_PROGRESS',
        ACTION_STATUS_CLOSED: 'mep.enums.actionplan-status.ACTION_STATUS_CLOSED'
      },
      billingType: {
        MISSING_VALUE: 'mep.enums.billingType.missing_value',
        BILLING_FIXPRICE: 'mep.enums.billingType.billing_fixprice',
        BILLING_TM: 'mep.enums.billingType.billing_tm'
      },
      billingTypeMSG_TREORBIS: {
        MISSING_VALUE: 'N/A',
        BILLING_INVOICED: 'Fakturiert',
        BILLING_NOTINVOICED: 'Nicht fakturiert'
      },
      projectCategory: {
        INVEST: "mep.enums.projectCategory.invest",
        OPERATIV: "mep.enums.projectCategory.operativ",
        OTHER: "mep.enums.projectCategory.other"
      },
      list: 'list',
      edit: '/edit',
      delete: '/delete/',
      personDays: 'personDays'
    },
    employeeSkills: {
      transaction: 'transaction',
      baseUrl: AWS_BASE_URL + '/mep/api/employeeSkills/',
      skillTeamMatrixDownload: 'skillTeamMatrixDownload',
      certificateData: 'certificateData',
      expiredCertificateData: 'expiredCertificateData'
    },
    employeeFiles: {
      baseUrl: AWS_BASE_URL + '/mep/api/employeeFiles/',
      fileType: {
        PROFILE_SHORT: 'Kurzprofil',
        PROFILE_LONG: 'Langprofil',
        CERTIFICATE: 'Zertifikat',
        OTHER: 'Anderes'
      }
    },
    filterStore: {
      baseUrl: AWS_BASE_URL + '/mep/api/filterStore',
      getFilters: '/getFilters',
      getStandardFilter: '/getStandardFilter',
      searchFilters: '/searchFilters'
    },
    employeePermission: {
      baseUrl: AWS_BASE_URL + '/mep/api/employeePermission',
      getAll: '/getAll'
    },
    actionPlans: {
      baseUrl: AWS_BASE_URL + '/mep/api/actionPlans/',
      existsUrl: AWS_BASE_URL + '/mep/api/actionPlans/search/actionPlanExists?id=',
      projectionInlineSteps: '?projection=actionPlanInline',
      hasAccessUrl: AWS_BASE_URL + '/mep/api/actionPlans/hasLoggedInUserAccessToActionPlan/',
      actionPlansWithEmployee: AWS_BASE_URL + '/mep/api/actionPlans/actionPlanListWithEmployees',
      exportExcel: '/excel/'
    },
    actionSteps: {
      baseUrl: AWS_BASE_URL + '/mep/api/actionSteps/',
      notifyResponsible: '/notifyResponsible'
    },
    notifications: {
      baseUrl: AWS_BASE_URL + '/mep/api/notificationSubscriptions/',
      sendUrl: AWS_BASE_URL + '/mep/public/notifications/sendNotification/'
    },
    projects: {
      baseUrl: AWS_BASE_URL + '/mep/api/projects/',
      nameProjection: 'nameProjection',
      filterRejected: 'search/findAllNotRejectedOrCompleted',
      notification: AWS_BASE_URL + '/mep/public/notifications/projectlist',
      import: AWS_BASE_URL + '/mep/import/project',
      p1rateimport: AWS_BASE_URL + '/mep/import/p1rate',
      p1bookingAssignmentsImport: AWS_BASE_URL + '/mep/import/p1BookingAssignments',
      exportExcel: '/availability/excel',
      exportSelectedProjectListExcel: 'availability/selectedProjectCapacityList',
      exportEuplanExcel: '/euplan/excel',
      exportMultiEuplanExcel: '/euplan/multi/excel',
      exportShouldIs: 'shouldIs/excel',
      exportProjects: 'projectData/excel',
      exportSelectedProjects: 'selectedProjectData/excel',
      projectEdit: 'project-edit/',
      status: {
        ACTIVE: 'mep.enum.project-status.ACTIVE',
        OFFER: 'mep.enum.project-status.OFFER',
        WARRANTY: 'mep.enum.project-status.WARRANTY',
        REJECTED: 'mep.enum.project-status.REJECTED',
        COMPLETED: 'mep.enum.project-status.COMPLETED',
        NOT_RELEVANT: 'mep.enum.project-status.NOT_RELEVANT'
      },
      critically: {
        VERY_HIGH: 'sehr hoch',
        HIGH: 'hoch',
        MEDIUM: 'mittel',
        LOW: 'niedrig',
        MISSING_VALUE: 'N/A'
      },
      deliveryType: {
        MISSING_VALUE: 'N/A',
        EMPLOYEE_RELOCATION: 'Mitarbeiterabstellung',
        CDP_DEVELOPMENT: 'CDP-Entwicklung',
        PRODUCT_DEVELOPMENT: 'Produktentwicklung',
        MAINTENANCE: 'Wartung',
        ADVISORY: 'Beratung'
      },
      marketUnit: MarketUnit.MarketUnit,
      inlineLeader: '?projection=inlineLeader',
      projectList: '?projection=projectList',
      projectListWithEmployeeHierarchy: '?projection=projectListWithEmployeeHierarchy',

      activeProjectList: 'activeProjectList/?projection=activeProjectList',
      activeProjectListWithEmployeeHierarchy: 'activeProjectListWithEmployeeHierarchy/?projection=activeProjectListWithEmployeeHierarchy',
      statuses: 'statuses',
      divisions: 'divisions',
      projectTimeSlices: '/projectTimeSlices'
    },
    assignment: {
      baseUrl: '/assignments',
      inlineEmployeeProjectInfo: '?projection=assignmentEmployeeProjectInfo'
    },
    logs: {
      baseUrl: AWS_BASE_URL + '/mep/api/log/',
      logs: 'all'
    },
    assignmentProjectEmployees: {
      baseUrl: AWS_BASE_URL + '/mep/api/assignmentProjectEmployees/',
      assignmentEmployeeProjectInfo: '/assignmentProjectEmployeeSet?projection=assignmentEmployeeProjectInfo',
      assignmentEmployeeProjectTimeSlices: 'assignmentProjectTimeSlicesForEmployeeOfAllCurrentProjects/'
    },
    projectTimeSlice: {
      baseUrl: AWS_BASE_URL + '/mep/api/projectTimeSlices/'
    },
    customizing: {
      baseUrl: AWS_BASE_URL + '/mep/api/customizings/',
      illnessFactor: "illnessFactor",
      underloadBorderPercent: "underloadBorderPercent",
      optimalWorkloadPercent: "optimalWorkloadPercent",
      underloadBorderPT: "underloadBorderPT",
      optimalWorkloadPT: "optimalWorkloadPT",
      monthsToFuture: "monthsToFuture",
      monthsToPast: "monthsToPast",
      themes: 'themes',
      capacityOverviewDefaults: 'capacityOverviewDefaults',
      customizingTable: 'customizingTable',
      isPreferencesLocalStorage: 'isPreferencesLocalStorage',
      travelWillingness: 'travelWillingness',
      isCheckboxCapaNotRelevant: 'isCheckboxCapaNotRelevant',
      isUtilizationChartEnabled: 'isUtilizationChartEnabled',
      areSkillgroupsEnabled: 'areSkillgroupsEnabled',
      isEmployeeLeavingDateVisible: 'isEmployeeLeavingDateVisible',
      isHideExternalCheckboxSet: 'isHideExternalCheckboxSet',
      evictCache: "evictCache",
      instanceWideSearchMinCapacity: "instanceWideSearchMinCapacity",
      skilleditSkillsPerPage: "skilleditSkillsPerPage",
      newSkillsDate: "newSkillsDate"
    },
    webDataRocks: {
      baseUrl: AWS_BASE_URL + '/mep/api/webDataRocks/',
      reportDirectory: 'reportDirectory',
      report: 'reports'
    },
    skills: {
      baseGlobalUrl: AWS_BASE_URL + '/mep/global',
      baseUrl: AWS_BASE_URL + '/mep/api/skills',
      allSkillCluster: '/allSkillCluster',
      categoryUrl: '/skillCategories/',
      getAllEmployeeSkillClustersUrl: '/getAllEmployeeSkillClusters',
      getAllEmployeeSkillsetCluster: '/employee/skillset?instanceName=',
      skillLevels: ["1", "2", "3", "4", "5"],
      skillLevelsMSG_SERVICES: ["1", "2", "3", "4", "5", "X"]
    },
    admin_homescreen: {
      baseUrl: AWS_BASE_URL + '/mep/api/admin-homescreen/',
      baseUrlGuideline: AWS_BASE_URL + '/mep/api/admin-homescreen/maintenance-guideline/',
      getGuidelineForPrincipal: 'forPrincipal',
      skillgroups: 'skillgroups',
      alreadySetSkills: 'alreadySetSkills/',
      newSkillgroup: 'newSkillgroup/',
      downloadGuideline: 'downloadGuideline',
      deleteSkillgroup: 'deleteSkillgroup',
      updateSkillgroupname: 'updateSkillgroupname',
      importAPEFormGroup: ''
    },
    global_search: {
      baseUrl: AWS_BASE_URL + '/mep/api/global-search/',
      getEmployees: 'getEmployees',
      getProjects: 'getProjects',
      getProjectProposalsByKeyword: 'getProjectProposalsByKeyword',
      getEmployeeProposalsByKeyword: 'getEmployeeProposalsByKeyword'
    },
    resource_request: {
      baseUrl: '/resource-request-list/',
      travelMode: {
        REMOTE: 'Remote',
        ON_SITE: 'On Site',
        MIXED: 'Mixed'
      },
      requiredLanguage: {
        GERMAN: 'mep.components.staffing-request-table.german',
        ENGLISH: 'mep.components.staffing-request-table.english',
        NO_MATTER: 'mep.components.staffing-request-table.no-matter'
      },
      staffingLocation: {
        GERMANY: 'mep.components.staffing-request-table.germany',
        ROMANIA: 'mep.components.staffing-request-table.romania',
        NO_MATTER: 'mep.components.staffing-request-table.no-matter'
      },
      status: {
        AT_WORK: 'mep.components.staffing-request-table.at_work',
        STAFFED: 'mep.components.staffing-request-table.staffed',
        FOLLOW_UP: 'mep.components.staffing-request-table.follow_up',
        CLIENT_OFFERED: 'mep.components.staffing-request-table.client_offered',
        CLIENT_DISCARDED: 'mep.components.staffing-request-table.client_discarded',
        DECLINED: 'mep.components.staffing-request-table.declined',
        CLIENT_OFFER_DECLINED: 'mep.components.staffing-request-table.client_offer_declined',
        CLIENT_OFFER_ACEPTED: 'mep.components.staffing-request-table.client_offer_acepted'
      },
      probability: {
        LEAD: 'mep.components.add-resource-request.lead',
        OPPORTUNITY: 'mep.components.add-resource-request.opportunity',
        HIGH_COMPETITIVE: 'mep.components.add-resource-request.high_competitive',
        LOW_COMPETITIVE: 'mep.components.add-resource-request.low_competitive',
        CONTRACT: 'mep.components.add-resource-request.contract',
        MISSING_VALUE: 'mep.components.add-resource-request.missing_value'
      },
      notificationStatus: {
        NO_NOTIFICATION: 'mep.components.resource-request-list.no-notification',
        CREATE_NOTIFICATION: 'mep.components.resource-request-list.create-notification',
        MODIFY_NOTIFICATION: 'mep.components.resource-request-list.modify-notification',
        MODIFY_MY_NOTIFICATION: 'mep.components.resource-request-list.modify-my-notification'
      }
    },
    dataLake: {
      baseUrl: AWS_BASE_URL + '/mep/api/dataLake',
      trendAnalysisData: '/trendAnalysisData',
      trendAnalysisDataEmployeeNames: '/trendAnalysisDataEmployeeNames'
    },
    projectWithAssignments: {
      import: AWS_BASE_URL + '/mep/import/projectWithAssignment'
    }
  },
  configFields: {
    // employee fields for employee list
    employeeFields: EmployeeFields.EmployeeFieldsDefault,
    employeeFieldsMSG_INDUSTRY_ADVISORS: EmployeeFields.EmployeeFieldsMSG_INDUSTRY_ADVISORS,
    // project fields for project list
    projectFields: ProjectFields.ProjectFieldsDefault,
    projectFieldsGBP: ProjectFields.ProjectFieldsGBP,
    projectFieldsMSG_SERVICES: ProjectFields.ProjectFieldsMSG_SERVICES,
    projectFieldsMSG_INDUSTRY_ADVISORS: ProjectFields.ProjectFieldsMSG_INDUSTRY_ADVISORS,
  },
  customEmployeeDivisions: ['MSG_INDUSTRY_ADVISORS'],
  customProjectDivisions: ['GBP', 'MSG_SERVICES', 'MSG_INDUSTRY_ADVISORS']
};
