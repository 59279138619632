import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Component, EventEmitter, Inject, Output} from '@angular/core';
import {LanguageSelection} from '../../../core/models/LanguageSelection';


@Component({
    selector: 'app-edit-languages-dialog',
  templateUrl: './edit-languages-dialog.component.html',
  styleUrls: ['./edit-languages-dialog.component.scss']
  })
export class EditLanguagesDialogComponent {

  languageSelection: {[clazz: string]: string} = {};
  languageClasses = ['Native:', 'English:', 'German:', 'Romanian:', 'Others:'];
  languageLevels = ['0', 'A1', 'A2', 'B1', 'B2', 'C1', 'C2'];
  showLanguageTooltip = false;

  @Output() submitLanguages = new EventEmitter<any>();

  constructor(
    public dialogRef: MatDialogRef<EditLanguagesDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.languageClasses.forEach((languageType: string) => this.languageSelection[languageType] = '');
    if (data.language !== null && data.language.trim() !== '') {
      this.initializeLanguageSelection(data.language);
    }
  }

  private prettifyLanguageSelection(): string {
    let langs = '';

    this.languageClasses.forEach((langType: string) => {
      if (this.languageSelection[langType].trim() !== '') {
        langs += langType + ' ' + this.languageSelection[langType].trim() + ', ';
      }
    });

    return langs.substr(0, langs.length - 2);
  }

  private testAddLanguage(result: string, languageName: string) {
    if (result !== null) {
      this.languageSelection[languageName] = result;
    }
  }

  private initializeLanguageSelection(languages: string) {
    let result = LanguageSelection.createSelectionFromString(languages);
    this.testAddLanguage(result.native, 'Native:');
    this.testAddLanguage(result.english, 'English:');
    this.testAddLanguage(result.german, 'German:');
    this.testAddLanguage(result.romanian, 'Romanian:');
    this.testAddLanguage(result.other, 'Others:');
  }

  close(): void {
    this.dialogRef.close();
  }

  languageNamePath(name: string): string {
    return 'mep.components.edit-languages-dialog.languages.' + name.substring(0, name.length - 1).toLowerCase();
  }

  onOkClick(): void {
    this.submitLanguages.emit(this.prettifyLanguageSelection());
    this.dialogRef.close();
  }

}
