import {MessageService} from "../../../core/services/services/message.service";
import {TranslateService} from "@ngx-translate/core";
import {from, Observable, of} from "rxjs";
import {AlertEnum} from "../../../core/models/Enums/AlertEnum";
import {ExportSkill, ExportSkillWithCluster} from "./exportSkill";
import {NoParameterExport} from "../../export-dialog/export";
import {LazyXlsxService} from "../../xlsx/lazy-xlsx.service";
import {map} from "rxjs/operators";
import {WorkBook, WorkSheet} from "xlsx";

export class SkillDbDialogExports extends NoParameterExport {
  nameKey = undefined;
  private _employeeName: string;

  constructor(private messageService: MessageService,
              private translate: TranslateService,
              private lazyXlsxService: LazyXlsxService,
              private skillExportData: ExportSkill[] | ExportSkillWithCluster[],
              _employeeName: string) {
    super();
    this._employeeName = _employeeName;
  }

  getFileNamePrefix(): string {
    return "Employee_Skill_Export_" + this._employeeName;
  }

  export(): Observable<BlobPart> {
    if (this.skillExportData !== undefined) {
      return this.exportAsExcelFile(this.skillExportData);
    } else {
      this.messageService.add(this._lang('notification.skill-error'), AlertEnum.danger);
      return of(undefined);
    }
  }

  private exportAsExcelFile(json: ExportSkill[]) {
    return from(this.lazyXlsxService.getXlsx()).pipe(
      map(xlsx => {
        const worksheet: WorkSheet = xlsx.utils.json_to_sheet(json, {skipHeader: true});
        worksheet['!cols'] = [
          {wch: 10},
          {wch: 10},
          {wch: 10},
          {wch: 5}
        ];
        const workbook: WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data']};
        return xlsx.write(workbook, { bookType: 'xlsx', type: 'array'});
      })
    );
  }

  private _lang(key: string): string {
    return this.translate.instant('mep.components.export-dialog.' + key);
  }
}
