import {Component, Input} from '@angular/core';
import {AbstractAutocomplete} from "./abstract-autocomplete";
import {EmployeeService} from "../../../core/services/services/employee.service";
import {EmployeeFullName} from "../../../core/models/EmployeeFullName";

@Component({
  selector: 'app-employee-frontend-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss']
})
export class EmployeeFrontendAutocompleteComponent extends AbstractAutocomplete<EmployeeFullName> {

  @Input()
  onlyDivLeaderOrManager: boolean;

  @Input()
  proposeEmployees: boolean;

  @Input()
  projectId: number;

  private proposedEmployeesCache: EmployeeFullName[] = undefined;

  private allEmployeesCache: EmployeeFullName[] = undefined;

  constructor(private _employeeService: EmployeeService) {
    super();
  }

  async fetchElements(): Promise<EmployeeFullName[]> {
    if (this.proposeEmployees) {

      if (this.proposedEmployeesCache === undefined) {
        this.proposedEmployeesCache = await this.getProposedEmployees();
      }

      if (this.proposedEmployeesCache.length === 0) {
        return (await this.fetchAllEmployeesCached());
      }

      return this.proposedEmployeesCache;
    } else {

      return (await this.fetchAllEmployeesCached());
    }
  }

  private async fetchAllEmployeesCached() {
    if (this.allEmployeesCache === undefined) {
      this.allEmployeesCache = await this.getEmployees();
    }

    return this.allEmployeesCache;
  }

  private async getEmployees() {
    const employees = await this._employeeService.fetchAllEmployeeFullName(this.onlyDivLeaderOrManager);

    const noEmployees = !employees || employees.length === 0;
    if (noEmployees && this.onlyDivLeaderOrManager) {
      // If there are no division leaders or managers then return all employees
      return this._employeeService.fetchAllEmployeeFullName(false);
    } else {
      return employees;
    }
  }

  private async getProposedEmployees() {
    return await this._employeeService.fetchProposedEmployees(this.projectId);
  }

  displayAsSelectedOption(employee: EmployeeFullName): string {
    return employee ? employee.firstName + " " + employee.lastName : undefined;
  }

  filter(element: EmployeeFullName, value: string): boolean {
    return (element.firstName + " " + element.lastName).toLowerCase().includes(value.toLowerCase());
  }

  sort(element: EmployeeFullName, otherElement: EmployeeFullName): number {
    return element.firstName.localeCompare(otherElement.firstName);
  }

  public toggleProposeEmployees(propose: boolean, idChanged = false) {
    this.proposeEmployees = propose;
    if (idChanged) {
      this.proposedEmployeesCache = undefined;
    }
    super.initialize();
  }

}
