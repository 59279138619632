import {Component, Input} from '@angular/core';
import {EmployeeService} from "../../../core/services/services/employee.service";
import {AbstractBackendAutocomplete} from "./abstract-backend-autocomplete";
import {from, Observable} from "rxjs";
import {EmployeeFullName} from "../../../core/models/EmployeeFullName";
import {map} from "rxjs/operators";

@Component({
  selector: 'app-employee-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss']
})
export class EmployeeAutocompleteComponent extends AbstractBackendAutocomplete<EmployeeFullName|string> {

  @Input()
  onlyDivLeaderOrManager = false;

  @Input()
  onlyPersonnelManagers = false;

  @Input()
  asString = false;

  constructor(private _employeeService: EmployeeService) {
    super();
  }

  displayAsSelectedOption(employee: EmployeeFullName|string): string {
    if (typeof employee === 'string') {
      return employee;
    } else {
      return employee ? employee.firstName + " " + employee.lastName : undefined;
    }
  }

  sort(element: EmployeeFullName|string, otherElement: EmployeeFullName|string): number {
    return this.displayAsSelectedOption(element).localeCompare(this.displayAsSelectedOption(otherElement));
  }

  getElementsFromBackendForSearchTerm(searchTerm: string): Observable<(EmployeeFullName|string)[]> {
    return from(this._employeeService.fetchEmployeeFullNameByNameSearch(searchTerm, this.onlyDivLeaderOrManager, this.onlyPersonnelManagers)).pipe(
      map(employees => {
        if (this.asString) {
          return employees.map(employee => this.displayAsSelectedOption(employee));
        } else {
          return employees;
        }
      })
    );
  }

}
