import {Injectable} from '@angular/core';
import {AlertEnum} from '../../models/Enums/AlertEnum';
import {Message} from '../../models/Message';
import {Md5} from "ts-md5";

@Injectable({
  providedIn: 'root',
})
export class MessageService {

  messages: { [index: string]: Message } = {};

  /**
   * Adds message to messageArray
   *
   * @param {string} message Message as string
   * @param alert
   * @param timeout Default: 3000 ms, can be customized, pass null if you want to further modify the object without timeout
   * @memberof MessageService
   */
  add(message: string, alert: AlertEnum, timeout = 2000): MessageService {
    this.messages['' + Md5.hashStr(message)] = new Message(message, alert, !timeout);
    if (timeout) {
      setTimeout(() => {
        delete this.messages['' + Md5.hashStr(message)];
      }, timeout);
    }
    return this;
  }

  /**
   * Clears message array
   *
   * @memberof MessageService
   */
  clear() {
    this.messages = {};
  }
}

