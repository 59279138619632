import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {SkillDbTooltipComponent} from "./skill-db-tooltip.component";
import {OverlayModule} from "@angular/cdk/overlay";
import {MatTableModule} from "@angular/material/table";
import {MatIconModule} from "@angular/material/icon";
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
  declarations: [
    SkillDbTooltipComponent
  ],
  imports: [
    CommonModule,
    OverlayModule,
    TranslateModule,
    MatTableModule,
    MatIconModule
  ],
  exports: [
    SkillDbTooltipComponent
  ],
})
export class SkillDbTooltipModule {
}
