import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'appSanitizeInput', pure: true})
export class SanitizeInputPipe implements PipeTransform {

  static sanitize(value: string): string {
    if ( value === undefined || value === null ) {
      return value;
    }

    return value.replace( 'Ö', 'Oe' )
      .replace( 'ö', 'oe' )
      .replace( 'Ü', 'Ue' )
      .replace( 'ü', 'ue' )
      .replace( 'Ä', 'Ae' )
      .replace( 'ä', 'ae' )
      .replace( 'ß', 'ss' );
  }

  transform(value: string): string {
    return SanitizeInputPipe.sanitize(value);
  }
}
