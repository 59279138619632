import {HttpBaseService} from "./http-base.service";
import {HttpClient} from "@angular/common/http";
import {MessageService} from "./message.service";
import {Observable} from "rxjs";
import {Employee} from "../../models/Employee";
import Config from "../../config/Config";
import {Injectable} from "@angular/core";
import {EnumMapper} from "./enumMapper.service";
import {AlertEnum} from "../../models/Enums/AlertEnum";
import {TranslateService} from "@ngx-translate/core";
import {Project} from "../../models/Project";
import {EmployeeFullName} from "../../models/EmployeeFullName";
import {Client} from "../../config/Client";

@Injectable({
  providedIn: 'root'
})
export class GlobalSearchService extends HttpBaseService {

  errorMessageKey = 'mep.services.employee.fetch-employees-failed';

  constructor(public http: HttpClient, messageService: MessageService, private translate: TranslateService) {
    super(http, messageService);
  }

  async getEmployeesWithKeyword(keyword: string, client: Client): Promise<Employee[]> {
    try {
      let employees = (await this.http.get<Employee[]>(Config.services.global_search.baseUrl +
        Config.services.global_search.getEmployees + "/" + keyword).toPromise());
      return employees ? EnumMapper.initialEnumMapper(employees, client, this.translate) : [];
    } catch (e) {
      this._messageService.add(this.translate.instant(this.errorMessageKey), AlertEnum.danger);
    }
  }

  getProjectsWithKeyword(keyword: string): Observable<Project[]> {
    return this.http.get<Project[]>(Config.services.global_search.baseUrl +
      Config.services.global_search.getProjects + "/" + keyword);
  }

  getProjectProposalsByKeyword(keyword: string): Observable<Project[]> {
    return this.http.get<Project[]>(Config.services.global_search.baseUrl +
      Config.services.global_search.getProjectProposalsByKeyword + "/" + keyword);
  }

  getEmployeeProposalsByKeyword(keyword: string): Observable<EmployeeFullName[]> {
    return this.http.get<EmployeeFullName[]>(Config.services.global_search.baseUrl +
      Config.services.global_search.getEmployeeProposalsByKeyword + "/" + keyword);
  }


}
