import {Injectable} from "@angular/core";
import {CapacityBorders} from "../../../shared/capacity-list/employee-capacity-legend/capacity-borders";
import {
  CapacityColorFilter,
  CapacityColors
} from "../../../shared/capacity-list/employee-capacity-color-select/employee-capacity-color-select.component";

@Injectable({
  providedIn: 'root'
})
export class ColorService {
  green = 99;
  yellow = 67;
  orange = 34;
  red = 1;

  /**
   * Groups the given free capacity into one of 4 possible color buckets and returns the string representing the color.
   * The grouping is based on the given underloadBorder and optiomalWorkload.
   *
   * @param capacity The free capacity to group. Based on the parameter isInPercent a percentage or person-days value has to be supplied.
   * @param capacityBorders The capacity borders for the coloring.
   */
  applyColorRules(capacity: number, capacityBorders: CapacityBorders) {
    let underload = capacityBorders.getUnderload();
    let optimal = capacityBorders.getOptimal();

    if (underload < capacity) {
      return 'color-strongly-underplanned';
    }
    if (optimal < capacity && capacity <= underload) {
      return 'color-underplanned';
    }
    if (-optimal <= capacity && capacity <= optimal) {
      return 'color-optimal-planned';
    }
    return 'color-overplanned';
  }

  applyColorRulesProjectProbability(probability: number) {
    if (probability >= this.green) {
      return 'color-very-likely';
    }
    if (this.green > probability && probability >= this.yellow) {
      return 'color-likely';
    }
    if (this.yellow > probability && probability >= this.orange) {
      return 'color-unlikely';
    }
    if (this.orange > probability && probability >= this.red) {
      return 'color-very-unlikely';
    }
    if (probability === 0) {
      return 'color-bluebird';
    }
  }

  /**
   * Checks whether the given free capacity  is visible in any of the given colors and PT ranges.
   *
   * @param capacityPercent The capacity in percent to check.
   * @param capacityPersonDays The capacity in PT to check.
   * @param capacityBorders The capacity borders for the coloring.
   * @param colorFilter The color filter determines what capacities should stay visible
   * @returns true if the given free percentage lies in any of the given color values or is greater than any of the given numbers.
   */
  public visibleByColor(capacityPercent: number, capacityPersonDays: number, capacityBorders: CapacityBorders, colorFilter: CapacityColorFilter): boolean {
    const rowCapacityColor = this.applyColorRules(capacityBorders.inPercentage ? capacityPercent : capacityPersonDays, capacityBorders);
    return colorFilter.colors.some(color => {
      if (color === CapacityColors.CUSTOM_PD) {
        return capacityPersonDays > colorFilter.customPD;
      } else {
        return color === rowCapacityColor;
      }
    });
  }

  public allMonthsVisibleByColor(months: string[], colorFilters: { [month: string]: CapacityColorFilter },
                                 capacityPercentsPerMonth: { [month: string]: number },
                                 capacityPersonDaysPerMonth: { [month: string]: number },
                                 capacityBorders: CapacityBorders): boolean {
    return months.every(month => {
      const colorFilterForMonth = colorFilters[month];

      return !colorFilterForMonth || colorFilterForMonth.colors.length === 0
        || this.visibleByColor(capacityPercentsPerMonth[month], capacityPersonDaysPerMonth[month], capacityBorders, colorFilterForMonth);
    });
  }
}
