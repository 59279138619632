import {Component} from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";
import {ProjectName} from "../../../core/models/Project";
import {FormControl} from "@angular/forms";
import {TranslatableComponent} from "../../../core/models/TranslatableComponent";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-add-project-dialog',
  templateUrl: './add-project-dialog.component.html',
  styleUrls: ['./add-project-dialog.component.scss']
})
export class AddProjectDialogComponent extends TranslatableComponent {

  addForm = new FormControl();
  selectedProject: ProjectName;

  constructor(private t: TranslateService, private dialogRef: MatDialogRef<AddProjectDialogComponent>) {
    super(t, 'AddProjectDialogComponent');
  }

  /**
   * Closes the dialog
   */
  closeDialog() {
    this.dialogRef.close();
  }

  onProjectSelectionChanged(project: ProjectName) {
    this.selectedProject = project;
  }

  addProject() {
    this.dialogRef.close(this.selectedProject);
  }

  isInputValid(inputValue: any): boolean {
    if ( this.selectedProject === undefined) {
      // No Project selected
      return false;
    } else if ( this.selectedProject.projectName === undefined ) {
      // Project selected, but, without projectName.
      return false;
    } else if ( inputValue === undefined ) {
      // Project selected without manual changes.
      return true;
    } else {
      // Project selected without manual input changes.
      // AutoCompletion adds the project client as prefix to the input.
      // Check if the extended projectName matches.
      return String(inputValue) === String(this.selectedProject.client + ' - ' + this.selectedProject.projectName);
    }
  }
}
