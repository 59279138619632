export default {
  'GBI': ['travelWillingness', 'travelWillingnessCombined', 'division'],
  'MSG_SERVICES': ['travelWillingness', 'travelWillingnessCombined', 'division', 'skillLevels', 'jobFamily', 'jobVariant'],
  'M3': ['division', 'jobFamily', 'jobVariant'],
  'MSG_INDUSTRY_ADVISORS': ['division'],
  'ZT': ['division'],
  'ZM': ['division'],
  'MSG_TREORBIS': ['division', 'billingType'],
  'MSG_NEXINSURE': ['division'],
  'GBF': ['division'],
  'GBG': ['division'],
  'MSG_GILLARDON': ['division'],
  'MSG_CH': ['division'],
  'CONPLAN': ['strategicObjective'],
  'GBY': ['division'],
  disabledMapping: {
    'travelWillingnessInternational': ['GBI', 'MSG_SERVICES']
  }
};
