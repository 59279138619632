import {Moment} from "moment";
import {Observable} from "rxjs";
import {Project} from "../../core/models/Project";
import {Cluster} from "../../core/models/EmployeeSkill";

/**
 * Export parameters can be configured by the user inside the export dialog.
 */
export interface ExportParameter {
  nameKey: string;
  value: string | number | boolean | Moment | Project | Cluster[];
  type: 'string' | 'number' | 'boolean' | 'monthYear' | 'project' | 'skillLevel' | 'clusterList';
  tooltipKey?: string;
  clusterList?: Cluster[];
}

/**
 * Represents one export option.
 */
export interface Export {
  nameKey: string;
  /**
   * Parameters configurable by the user (used inside the export dialog)
   */
  exportParameters: ExportParameter[];
  /**
   * A message displayed at the bottom of the export dialog.
   */
  infoMessageKey?: string;
  nameTooltipKey?: string;

  /**
   * Checks whether the {@link exportParameters} provided are valid (e.g date checks)
   */
  parametersValid(): boolean;

  /**
   * Starts the export and returns an observable.
   */
  export(): Observable<BlobPart>;
  getFileNamePrefix(): string;
}

export abstract class NoParameterExport implements Export {
  exportParameters = [];
  abstract nameKey;

  parametersValid(): boolean {
    return true;
  }

  abstract export(): Observable<BlobPart>;

  abstract getFileNamePrefix(): string;
}
