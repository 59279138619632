import {Pipe, PipeTransform} from '@angular/core';
import {formatNumber} from "@angular/common";

@Pipe({name: 'inputWithDecimals', pure: true})
export class InputWithDecimalsPipe implements PipeTransform {

  transform(input: number | string): any {
    return input || input === 0 ? formatNumber(Number(input.toString().replace(',', '.')), 'de', '1.0-2') : '';
  }

  formatWithDecimals(input: number) {
    return input ? Number(formatNumber(Number(input.toString().replace(',', '.')), 'de', '1.0-2').replace(',', '.')) : '';
  }
}
