import {Injectable} from "@angular/core";
import {Employee} from "../../models/Employee";
import {Project} from "../../models/Project";
import MarketUnit from "../../config/MarketUnit";
import Config from "../../config/Config";
import ConfigurableClientAttributes from "../../config/ConfigurableClientAttributes";
import {FormGroup} from "@angular/forms";
import {EmployeeFilterData} from "../../models/viewModels/EmployeeFilterData";
import {Params} from "@angular/router";
import {
  DefaultSkillDbFilterData,
  SkillDbFilterData
} from "../../../shared/dialogs/filter-dialog/filter-dialog.interface";
import {
  InstanceWideSearchFilterData
} from "../../../feature/instance-wide-search/components/instance-wide-search/instance-wide-search.interface";
import {ProjectFilterData} from "../../models/viewModels/ProjectFilterData";
import {ActionPlanFilterData} from "../../models/viewModels/ActionPlanFilterData";
import {ResourceRequestFilterData} from "../../../feature/resource-request-list/models/resource-request";
import {
  EmployeeCapacityFilterData
} from "../../../shared/capacity-list/employee-capacity-list/employee-capacity-list.interface";
import {Client} from "../../config/Client";
import {TranslateService} from "@ngx-translate/core";
import {EmployeePermission} from "../../models/viewModels/EmployeePermission";


@Injectable({
  providedIn: 'root'
})
export class EnumMapper {
  static mapEmployeeData(employee: Employee, currentClient: Client, searchInverse = true, translateService: TranslateService) {
    employee.businessUnit = this.marketUnitMapper([employee.businessUnit], currentClient, searchInverse)[0];
    employee.jobFamily = this.jobFamilyMapper([employee.jobFamily], currentClient, searchInverse)[0];
    employee.jobLevel = this.jobLevelMapper([employee.jobLevel], currentClient, searchInverse)[0];
    employee.travelWillingness = this.travelWillingnessMapper([employee.travelWillingness], currentClient, searchInverse)[0];
    employee.travelWillingnessCombined = this.travelWillingnessCombinedMapper([employee.travelWillingness], [employee.travelWillingnessInternational], currentClient, searchInverse, translateService)[0];
    employee.status = this.statusEmployeeMapperWithoutTranslate([employee.status], searchInverse)[0];
    employee.location = this.locationMapperWithoutTranslate([employee.location], searchInverse)[0];
    employee.country = this.countryMapper([employee.country], currentClient, searchInverse)[0];
    employee.jobVariant = this.jobVariantMapper([employee.jobVariant], currentClient, searchInverse)[0];
    employee.division = this.divisionMapper([employee.division], currentClient, searchInverse)[0];

    return employee;
  }

  static mapProjectData(project: Project) {
    project.marketUnit = this.marketUnitMapper([project.marketUnit], Client.EMPTY, false)[0];
    project.status = this.statusProjectMapper([project.status], false)[0];
    project.businessTopic = this.businessTopicsMapper([project.businessTopic], false)[0];
    project.strategicObjective = this.strategicObjectiveMapper([project.strategicObjective], false)[0];
    project.division = this.divisionMapper([project.division], Client.EMPTY, false)[0];
    return project;
  }

  static marketUnitMapper(enumArray, currentClient: Client, searchInverse = true) {
    let marketUnitForClient = MarketUnit[this.getConfigObjectForClient('MarketUnit', currentClient)];
    return EnumMapper.genericMapping(enumArray, marketUnitForClient, searchInverse);
  }

  static divisionMapper(enumArray, currentClient: Client, searchInverse = true) {
    let divisionForClient = Config.services.employees[this.getConfigObjectForClient('division', currentClient)];
    return EnumMapper.genericMapping(enumArray, divisionForClient, searchInverse);
  }

  static jobFamilyMapper(enumArray, currentClient: Client, searchInverse = true) {
    let jobFamilyForClient = Config.services.employees[this.getConfigObjectForClient('jobFamily', currentClient)];
    return EnumMapper.genericMapping(enumArray, jobFamilyForClient, searchInverse);
  }

  static jobLevelMapper(enumArray, currentClient: Client, searchInverse = true) {
    let jobLevelForClient = Config.services.employees[this.getConfigObjectForClient('jobLevel', currentClient)];
    return EnumMapper.genericMapping(enumArray, jobLevelForClient, searchInverse);
  }

  static travelWillingnessCombinedMapper(enumArrayTW, enumArrayTWI = [], currentClient: Client, searchInverse = true, translateService: TranslateService) {
    let result: any[];

    if (searchInverse) {
      let travelWillingnessCombinedForClient = Config.services
        .employees[this.getConfigObjectForClient('travelWillingnessCombined', currentClient)];

      result = EnumMapper.genericMapping([enumArrayTW],
        travelWillingnessCombinedForClient, searchInverse);
    } else {
      let travelWillingnessForClient = Config.services
        .employees[this.getConfigObjectForClient('travelWillingness', currentClient)];
      result = EnumMapper.genericMapping([enumArrayTW], travelWillingnessForClient, searchInverse);
      result.forEach((r, i) => {
        result[i] = translateService.instant(r.toString());
      });
      if (!currentClient || !ConfigurableClientAttributes.disabledMapping.travelWillingnessInternational
        || !ConfigurableClientAttributes.disabledMapping.travelWillingnessInternational.includes(currentClient)) {
        result.forEach((r, i) => {
          if (enumArrayTWI[i] != null) {
            result[i] = r + ' - '
              + translateService.instant(EnumMapper.genericMapping([enumArrayTWI.toString().toUpperCase()],
                Config.services.employees.travelWillingnessInternationalText, searchInverse)[0]);
          }
        });
      }
    }
    return result;
  }

  public static getConfigObjectForClient(attributeName: string, currentClient: Client) {
    if (currentClient && ConfigurableClientAttributes[currentClient] && ConfigurableClientAttributes[currentClient].includes(attributeName)) {
      return attributeName + currentClient;
    } else {
      return attributeName;
    }
  }

  static travelWillingnessMapper(enumArray, currentClient: Client, searchInverse = true) {
    let travelWillingnessForClient = Config.services.employees[this.getConfigObjectForClient('travelWillingness', currentClient)];
    return EnumMapper.genericMapping(enumArray, travelWillingnessForClient, searchInverse);
  }

  static statusEmployeeMapperWithoutTranslate(enumArray, searchInverse = true) {
    let result: any;
    let employeeStatus = Config.services.employees.status;

    result = EnumMapper.genericMapping(enumArray, employeeStatus, searchInverse);

    return result;
  }


  static statusEmployeeMapper(enumArray, searchInverse = true, translateService: TranslateService) {
    let result: any[];
    let employeeStatus = Config.services.employees.status;
    if (searchInverse) {
      result = EnumMapper.genericMapping(enumArray, employeeStatus, searchInverse);
    } else {
      result = EnumMapper.genericMapping(enumArray, employeeStatus, searchInverse);
      result.forEach((r, i) => {
        if (r) {
          result[i] = translateService.instant(r.toString());
        }
      });
    }
    return result;
  }


  static statusProjectMapper(enumArray, searchInverse = true) {

    return EnumMapper.genericMapping(enumArray, Config.services.projects.status, searchInverse);
  }

  static profileTypeMapper(enumArray, searchInverse = true) {
    return EnumMapper.genericMapping(enumArray, Config.services.employeeFiles.fileType, searchInverse);
  }

  static locationMapper(enumArray, searchInverse = true, translateService: TranslateService) {
    let result: any[];
    if (searchInverse) {
      return EnumMapper.genericMapping(enumArray, Config.services.projects.status, searchInverse);
    } else {
      let location = Config.services.employees.location;
      result = EnumMapper.genericMapping([enumArray], location, searchInverse);
      result.forEach((r, i) => {
        result[i] = translateService.instant(r.toString());
      });
    }
    return result;
  }

  static locationMapperWithoutTranslate(enumArray, searchInverse = true) {
    let location = Config.services.employees.location;
    return EnumMapper.genericMapping(enumArray, location, searchInverse);

  }


  static countryMapper(enumArray, currentClient: Client, searchInverse = true) {
    let countryForClient = Config.services.employees[this.getConfigObjectForClient('country', currentClient)];
    return EnumMapper.genericMapping(enumArray, countryForClient, searchInverse);
  }

  static staffingRequestsMapper(enumArray, searchInverse = true) {
    return EnumMapper.genericMapping(enumArray, Config.services.resource_request.status, searchInverse);
  }

  static jobVariantMapper(enumArray, currentClient: Client, searchInverse = true) {
    let jobVariantForClient = Config.services.employees[this.getConfigObjectForClient('jobVariant', currentClient)];
    return EnumMapper.genericMapping(enumArray, jobVariantForClient, searchInverse);
  }

  static mapBoolToInternationalTravelWill(travelWill) {
    return travelWill === null ? 'MISSING_VALUE' : String(travelWill).toUpperCase();
  }

  static mapBoolToRelevance(notRelevantForCapa) {
    return Config.services.employees.capaRelevance.ALL + " " + (notRelevantForCapa ? Config.services.employees.capaRelevance.NOT_RELEVANT : Config.services.employees.capaRelevance.ONLY_RELEVANT);
  }

  static mapInternationalTravelWillToBool(travelWill) {
    return travelWill === 'MISSING_VALUE' ? null : travelWill.toLowerCase() === 'true';
  }

  static absenceStatusMapper(enumArray, searchInverse = true) {
    return EnumMapper.genericMapping(enumArray, Config.services.employees.absenceTypes, searchInverse);
  }

  public static genericMapping(enumArray, objectToSearchIn: {}, searchInverse = false) {
    let inverseDefaults = searchInverse ? EnumMapper.swapKeyAndValue(Config.services.default_values) : {};
    let inverseObject = searchInverse ? EnumMapper.swapKeyAndValue(objectToSearchIn) : {};

    let merged;
    if (searchInverse) {
      merged = {...inverseObject, ...inverseDefaults};
    } else {
      merged = {...objectToSearchIn, ...Config.services.default_values};
    }

    const mappedArray = [];
    for (let i = 0; i < enumArray.length; i++) {
      if (enumArray[i] === undefined || enumArray[i] === '') {
        mappedArray[i] = Config.services.missingValue;
      } else if (merged[enumArray[i]] === undefined) {
        mappedArray[i] = enumArray[i];
      } else {
        mappedArray[i] = merged[enumArray[i]];
      }
    }

    return mappedArray;
  }

  public static mapEmployeePermissionData(dataArray: EmployeePermission[], currentClient: Client, translateService: TranslateService) {
    for (const employeePermission of dataArray) {
      employeePermission.status = EnumMapper.statusEmployeeMapper([employeePermission.status], false, translateService).toString();
      employeePermission.businessUnit = EnumMapper.marketUnitMapper([employeePermission.businessUnit], currentClient, false).toString();
    }
    return dataArray;
  }

  public static mapEmployeeDataForm(employeeForm: FormGroup, selectedEmployee, currentClient: Client, translateService: TranslateService) {
    const properties = ['personnelNumber', 'lastName', 'firstName', 'title', 'entryDate', 'leavingDate', 'costCenter',
      'annotation', 'humanResourcesManager', 'skills', 'proportionOfWorkingTime', 'languages', 'travelRestrictions', 'tag'];

    for (const property of properties) {
      employeeForm.get(property).setValue(selectedEmployee[property]);
    }

    employeeForm.get('location').setValue(EnumMapper.locationMapperWithoutTranslate([selectedEmployee.location], true)[0]);
    employeeForm.get('country').setValue(EnumMapper.countryMapper([selectedEmployee.country], currentClient)[0]);
    employeeForm.get('jobFamily').setValue(EnumMapper.jobFamilyMapper([selectedEmployee.jobFamily], currentClient)[0]);
    employeeForm.get('jobVariant').setValue(EnumMapper.jobVariantMapper([selectedEmployee.jobVariant], currentClient)[0]);
    employeeForm.get('jobLevel').setValue(EnumMapper.jobLevelMapper([selectedEmployee.jobLevel], currentClient)[0]);
    employeeForm.get('status').setValue(EnumMapper.statusEmployeeMapperWithoutTranslate([selectedEmployee.status], true)[0]);
    employeeForm.get('travelWillingness').setValue(EnumMapper.travelWillingnessMapper([selectedEmployee.travelWillingness], currentClient)[0]);
    employeeForm.get('travelWillingnessCombined').setValue(
      EnumMapper.travelWillingnessCombinedMapper([selectedEmployee.travelWillingness], [selectedEmployee.travelWillingnessInternational], currentClient, true, translateService)[0]
    );
    employeeForm.get('businessUnit').setValue(EnumMapper.marketUnitMapper([selectedEmployee.businessUnit], currentClient)[0]);
    employeeForm.get('division').setValue(EnumMapper.divisionMapper([selectedEmployee.division], currentClient)[0]);
    employeeForm.get('travelWillingnessInternational').setValue(EnumMapper.mapBoolToInternationalTravelWill(selectedEmployee.travelWillingnessInternational));
  }

  public static initialEnumMapper(dataArray, currentClient: Client, translateService: TranslateService) {
    // Map the enums to strings
    for (const employee of dataArray) {
      employee.location = EnumMapper.locationMapper([employee.location], false, translateService).toString();
      employee.country = EnumMapper.countryMapper([employee.country], currentClient, false).toString();
      employee.jobVariant = EnumMapper.jobVariantMapper([employee.jobVariant], currentClient, false).toString();
      employee.jobFamily = EnumMapper.jobFamilyMapper([employee.jobFamily], currentClient, false).toString();
      employee.status = EnumMapper.statusEmployeeMapper([employee.status], false, translateService).toString();
      employee.businessUnit = EnumMapper.marketUnitMapper([employee.businessUnit], currentClient, false).toString();
      employee.division = EnumMapper.divisionMapper([employee.division], currentClient, false).toString();
      employee.travelWillingness = EnumMapper.travelWillingnessMapper([employee.travelWillingness], currentClient, false).toString();
      employee.travelWillingnessCombined =
        EnumMapper.travelWillingnessCombinedMapper([employee.travelWillingness], [employee.travelWillingnessInternational], currentClient, false, translateService).toString();
      employee.jobLevel = EnumMapper.jobLevelMapper([employee.jobLevel], currentClient, false).toString();
      if (employee.location.toString() === 'N/A') {
        employee.country = Config.services.missingValue;
      }
      if (employee.division.toString() === '') {
        employee.division = Config.services.missingValue;
      }
      employee.fileType = EnumMapper.profileTypeMapper([employee.fileType], false).toString();
    }
    return dataArray;
  }

  public static parseSkillDbFilterDataRequestParam(skillDbFilterDataRequestParam: string): SkillDbFilterData {
    if (skillDbFilterDataRequestParam) {
      return JSON.parse(skillDbFilterDataRequestParam);
    } else {
      return new DefaultSkillDbFilterData();
    }
  }

  public static requestParametersToEmployeeFilterModel(filterData: EmployeeFilterData, urlParameters: Params, client: Client) {
    Object.assign(filterData, (({
                                  firstName,
                                  lastName,
                                  humanResourcesManager,
                                  skills,
                                  showPastEmployee,
                                  skillDbFilterData,
                                  department
                                }) =>
      ({
        firstName,
        lastName,
        humanResourcesManager,
        skills,
        showPastEmployee,
        skillDbFilterData: this.parseSkillDbFilterDataRequestParam(skillDbFilterData),
        department
      }))(urlParameters));

    const attributes = ['businessUnit', 'jobVariant', 'jobLevel', 'location', 'country', 'travelWillingnessCombined', 'status', 'division', 'department', 'tag'];
    EnumMapper.mapFilterAttributeStringToArray(attributes, filterData, urlParameters, Config.services.employees, client);
  }

  public static requestParametersToCapacityFilterModel(filterData: EmployeeCapacityFilterData, urlParameters: Params, client: Client) {
    Object.assign(filterData, (({
                                  skills,
                                  humanResourcesManager,
                                  projectLeaderNames,
                                  divisionManagerNames,
                                  skillDbFilterData
                                }) =>
      ({
        humanResourcesManager,
        skills,
        projectLeaderNames,
        divisionManagerNames,
        skillDbFilterData: this.parseSkillDbFilterDataRequestParam(skillDbFilterData)
      }))(urlParameters));
    filterData.hideExternEmployees = urlParameters.hideExternEmployees === "true";
    filterData.showPastAndCurrentEmployee = urlParameters.showPastAndCurrentEmployee === "true";
    const attributes = ['businessUnit', 'jobVariant', 'jobLevel', 'location', 'country', 'division', 'travelWillingnessCombined', 'tag', 'capaRelevance'];
    EnumMapper.mapFilterAttributeStringToArray(attributes, filterData, urlParameters, Config.services.employees, client);

    if (urlParameters.hideExternEmployees === 'false') {
      filterData.businessUnit = null;
    }
  }

  public static requestParametersToInstanceWideSearchFilterModel(filterData: InstanceWideSearchFilterData, urlParameters: Params, client: string) {
    Object.assign(filterData, (({skillDbFilterData}) => ({skillDbFilterData: this.parseSkillDbFilterDataRequestParam(skillDbFilterData)}))(urlParameters));
  }

  public static requestParametersToProjectFilterModel(filterData: ProjectFilterData, urlParameters: Params) {
    Object.assign(filterData, (({projectName, client, rejectedDisplay}) => ({
      projectName,
      client,
      rejectedDisplay
    }))(urlParameters));

    const attributes = ['businessUnit', 'marketUnit', 'status'];
    EnumMapper.mapFilterAttributeStringToArray(attributes, filterData, urlParameters, Config.services.projects);
  }

  public static requestParametersToResourceRequestFilterModel(filterData: ResourceRequestFilterData, urlParameters: Params) {
    Object.assign(filterData, (({projectName, client, rejectedDisplay}) => ({
      projectName,
      client,
      rejectedDisplay
    }))(urlParameters));

    const attributes = ['businessUnits', 'travelMode', 'divisions'];
    EnumMapper.mapFilterAttributeStringToArray(attributes, filterData, urlParameters, Config.services.projects);
  }

  public static requestParametersToActionPlanFilterModel(filterData: ActionPlanFilterData, urlParameters: Params) {
    Object.assign(filterData, (({humanResourcesManager, showClosedPlans}) => ({
      humanResourcesManager,
      showClosedPlans
    }))(urlParameters));

    const attributes = ['division', 'actionPlanStatus', 'country'];
    EnumMapper.mapFilterAttributeStringToArray(attributes, filterData, urlParameters, Config.services.employees);
  }

  public static mapFilterAttributeStringToArray(attributes: string[], filterData, urlParameters: Params, mappingObj, client?: Client) {

    for (const attr of attributes) {
      let customizedAttribute = this.getConfigObjectForClient(attr, client);
      this.mapSingleFilterAttributeStringToArray(attr, filterData, urlParameters, mappingObj[customizedAttribute]);
    }
  }

  public static mapSingleFilterAttributeStringToArray(attr: string, filterData, urlParameters: Params, mappingAttribute) {
    const urlParamAsArray = EnumMapper.stringAsStringArray(urlParameters[attr]);

    filterData[attr] = urlParamAsArray.map(param => ({
      value: param,
      viewValue: param ? EnumMapper.genericMapping([param], mappingAttribute)[0] : ''
    }));
  }

  public static mapNonEnumAttributeStringToArray(attr: string, filterData, urlParameters: Params) {
    const urlParamAsArray = EnumMapper.stringAsStringArray(urlParameters[attr]);

    filterData[attr] = urlParamAsArray.map(param => ({
      value: param,
      viewValue: param
    }));
  }

  public static stringAsStringArray(string: string): string[] {
    return string ? string.split(',') : [];
  }

  public static stringArrayToString(stringArray: string[]): string {
    return stringArray && stringArray.length > 0 ? stringArray.reduce((previousValue, currentValue) => previousValue + ',' + currentValue) : '';
  }

  public static filterModelToRequestParameters(filterViewModel: EmployeeFilterData | ProjectFilterData | EmployeeCapacityFilterData
    | InstanceWideSearchFilterData | ActionPlanFilterData | ResourceRequestFilterData): { [key: string]: any } {
    let value = Object.assign({}, filterViewModel);
    Object.keys(value).forEach(function (key, index) {
      if (key === 'skillDbFilterData') {
        value[key] = JSON.stringify(value[key]);
      } else if (value[key] !== undefined && (value[key] === null || value[key].viewValue === '')) {
        delete value[key];
      } else if (Array.isArray(value[key])) {
        value[key] = EnumMapper.stringArrayToString(value[key].map(option => option.value));
        if (value[key].length === 0) {
          delete value[key];
        }
      } else {
        value[key] = value[key] ? (value[key].value || value[key]) : value[key] === false ? 'false' : '';
      }
    });
    return value;
  }

  public static getCountry(city: string): string {
    switch (city) {
      case "PASSAU":
      case "BRAUNSCHWEIG":
      case "BERLIN":
      case "HAMBURG":
      case "CHEMNITZ":
      case "ESSEN":
      case "FRANKFURT":
      case "INGOLSTADT":
      case "MUENCHEN":
      case "HUERTH":
      case "KOELN":
      case "STGEORGEN":
      case "STUTTGART":
      case "GOERLITZ":
      case "NUERNBERG":
      case "HANNOVER":
      case "BRETTEN":
      case "DUESSELDORF":
      case "SCHORTENS":
      case "MUENSTER":
      case "LINGEN":
        return 'DE';
      case "WIEN" :
        return 'OE';
      case "LONDON":
        return 'GB';
      case "CLUJ":
      case "TIMISOARA":
        return 'RO';
      case "BANGALORE":
        return 'IND';
      default:
        return 'MISSING_VALUE';
    }
  }

  private static swapKeyAndValue(jsonObject): {} {
    let ret = {};
    for (let key in jsonObject) {
      if (jsonObject.hasOwnProperty(key)) {
        ret[jsonObject[key]] = key;
      }
    }
    return ret;
  }

  private static businessTopicsMapper(enumArray, searchInverse = true) {
    return EnumMapper.genericMapping(enumArray, Config.services.employees.businessTopics, searchInverse);
  }

  private static strategicObjectiveMapper(enumArray, searchInverse = true) {
    return EnumMapper.genericMapping(enumArray, Config.services.employees.strategicObjective, searchInverse);
  }

  basicEnumEqual(enum1: { value: string }, enum2: { value: string }): boolean {
    return (!enum1 && !enum2) || (enum1 && enum2 && enum1.value === enum2.value);
  }

  private static resourceRequestMapper(enumArray, searchInverse = true) {
    return EnumMapper.genericMapping(enumArray, Config.services.resource_request.status, searchInverse);
  }

}
