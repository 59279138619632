import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {TranslatableComponent} from "../../../core/models/TranslatableComponent";
import {TranslateService} from "@ngx-translate/core";
import {Filter} from "./filter-parser";

@Component({
  selector: 'app-filter-parser',
  templateUrl: './filter-parser.component.html',
  styleUrls: ['./filter-parser.component.scss']
})
export class FilterParserComponent extends TranslatableComponent implements OnInit, OnChanges {

  /**
   * The internal representation for filter attributes
   * Is equal to the representation in filter data and used for accessing the display value (for translation)
   */
  @Input()
  filterAttributes: string[] = [];

  @Input()
  hiddenAttributes: string[] = [];

  @Input()
  filterDataAccessor: (element, filterAttribute) => string;

  /**
   * If a filter value is entered without specifying a filter attribute these columns will be searched
   */
  @Input()
  freeTextSearchAttributes: string[] = [];

  /**
   * Emits the filter
   */
  @Output()
  filterParsed: EventEmitter<(element) => boolean> = new EventEmitter();

  @Output()
  searchTextArray: EventEmitter<string[]> = new EventEmitter<string[]>();

  @Output()
  plainSearchText: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('filterInput', {static: true})
  filterInput: ElementRef;

  currentFilterDialogSearch = '';

  currentFilterSearch = '';

  validFilter = true;
  tooltip;

  private lowerCaseFilterAttributes: FilterAttribute[] = [];

  private delayedFilterCreation;

  private filterAttributeRegexs: RegExp[] = [];

  constructor(protected translate: TranslateService) {
    super(translate, 'FilterParserComponent');
  }

  ngOnInit() {
    this.translate.onLangChange.subscribe(() => this.updateSetAttributes());
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateSetAttributes();
  }

  private updateSetAttributes() {
    if (this.filterAttributes) {
      this.createFilterAttributeArrays();
      this.displayPossibleTooltip();
    }
  }

  createFilterAttributeArrays() {
    this.lowerCaseFilterAttributes = this.filterAttributes.map(value => ({
      internalRepresentation: value,
      displayValue: this.mapFilterAttributeInternalRepToDisplayValue(value).toLocaleLowerCase()
    }));
    this.filterAttributeRegexs = this.filterAttributes.map(value => new RegExp('(' + this.mapFilterAttributeInternalRepToDisplayValue(value) + ':)', 'gi'));
  }

  input(event) {
    if (this.delayedFilterCreation) {
      clearTimeout(this.delayedFilterCreation);
    }

    this.delayedFilterCreation = setTimeout(() => {
      const text = (event.target instanceof HTMLElement ? event.target.textContent : '' || '').trim();
      this.updateFilter(text);
    }, 300);
  }

  updateFilter(filterString) {
    this.plainSearchText.emit(filterString);
    this.highlightKeyWordsAndFilterAttributes(filterString);
    this.createFilter(filterString);
  }

  clearFilter() {
    setTimeout(() => {
      this.filterInput.nativeElement.innerText = '';
      this.updateFilter('');
    });
  }

  private highlightKeyWordsAndFilterAttributes(text) {
    let newContent = text.replace(/\s(or)\s/gi, ' <span class="highlightOR">$1</span> ')
      .replace(/\s(or)$/gi, ' <span class="highlightOR">$1</span> ');

    this.filterAttributeRegexs.forEach((regex) => {
      newContent = newContent.replace(regex, '<span class="highlightFilterAttribute">$1</span>');
    });

    document.querySelectorAll(".overlay .overlayTextContent").forEach((element: HTMLElement) => element.innerHTML = newContent);
  }

  /**
   * Uses a scanner to parse the filter string
   *
   * @param filterString
   */
  private createFilter(filterString: string = this.currentFilterSearch) {
    this.currentFilterSearch = filterString;
    if (this.currentFilterDialogSearch) {
      filterString = filterString ? "(" + filterString + ")" : "";
      filterString = filterString + " (" + this.currentFilterDialogSearch + ")";
    }

    filterString = filterString.toLocaleLowerCase();
    const filter: Filter = this.parseFilterString(filterString);

    if (this.validFilter) {
      if (filterString) {
        try {
          const filterPredicate = filter.createFilter();
          this.filterParsed.emit(filterPredicate);
        } catch (e) {
          this.validFilter = false;
        }
      } else {
        this.filterParsed.emit(() => true);
      }
    }
  }

  private parseFilterString(filterString: string): Filter {
    this.validFilter = true;
    const filter = new Filter(this.filterDataAccessor, this.lowerCaseFilterAttributes, this.freeTextSearchAttributes, this.translate);
    this.validFilter = filter.validFilter;
    this.searchTextArray.emit(filter.doFiltering(filterString));
    return filter;
  }

  isFilterAttribute(filterAttributeDisplayValueLowerCase: string): boolean {
    return this.lowerCaseFilterAttributes.some((filterAttribute: FilterAttribute) => filterAttribute.displayValue === filterAttributeDisplayValueLowerCase);
  }

  async parseFilterData(filterData, loadPromise?: Promise<any[]>) {
    if (!loadPromise !== undefined && loadPromise !== null) {
      await loadPromise;
    }
    await this.createFilterString(filterData).then(filterString => {
      this.currentFilterDialogSearch = filterString;
      this.createFilter();
    });
  }

  private async createFilterString(filterData): Promise<string> {
    // wait until translate service is ready

    await this.translate.get('mep.components.filter-parser.filter-attribute.projectName').toPromise();
    let filterString = '';
    Object.keys(filterData).forEach(filterAttribute => {

      const filterAttributeDisplayValue = this.mapFilterAttributeInternalRepToDisplayValue(filterAttribute);
      if (this.isFilterAttribute(filterAttributeDisplayValue.toLocaleLowerCase())) {
        let filterValue: string | [] = filterData[filterAttribute];
        if (filterValue && typeof filterValue !== 'boolean') {
          if (typeof filterValue === 'string') {
            // Escape space character with single quotes
            if (filterValue.trim().includes(' ')) {
              filterValue = "'" + filterValue + "'";
            }
            filterString = filterString + ' ' + filterAttributeDisplayValue + ':' + filterValue;
          } else {
            const filterValueArray: { value: string; viewValue: string }[] = filterValue;
            let orExpression = '';
            let numConditions = 0;
            filterValueArray.forEach((filterV, index) => {
              if (filterV.value.includes("&")) {
                orExpression = (index > 0 ? orExpression + ' OR ' : '') + filterAttributeDisplayValue + ':' + "'" + filterV.value.replace("& ", "") + "'";
                numConditions++;
              } else if (filterV.value) {
                orExpression = (index > 0 ? orExpression + ' OR ' : '') + filterAttributeDisplayValue + ':' + "'" + filterV.value + "'";
                numConditions++;
              }
              const translatedViewValue = this.translate.instant(filterV.viewValue);
              if (translatedViewValue.includes("&")) {
                orExpression = orExpression + ' OR ' + filterAttributeDisplayValue + ':' + "'" + translatedViewValue.replace("& ", "") + "'";
                numConditions++;
              } else if (translatedViewValue) {
                orExpression = orExpression + ' OR ' + filterAttributeDisplayValue + ':' + "'" + translatedViewValue + "'";
                numConditions++;
              }
            });
            if (numConditions === 1) {
              filterString = filterString + (orExpression ? ' ' + orExpression : '');
            } else if (numConditions > 1) {
              filterString = filterString + (orExpression ? ' (' + orExpression + ')' : '');
            }
          }
        }
      }
    });
    return filterString ? '(' + filterString.trim() + ')' : '';
  }

  displayPossibleTooltip() {
    if (this.filterAttributes.length > 0) {
      this.tooltip = this._lang('filter-parser-title') + "\n\n" + this.filterAttributes.map(value =>
        this.hiddenAttributes.includes(value) ? "" :
          this.mapFilterAttributeInternalRepToDisplayValue(value) + " : -- " +
          this.mapFilterAttributeExplanation(value) + "\n").reduce((prev, cur) => prev + cur, '');
    }
  }


  private mapFilterAttributeInternalRepToDisplayValue(filterAttributeInternalRep: string): string {
    return this._lang('filter-attribute.' + filterAttributeInternalRep);
  }

  private mapFilterAttributeExplanation(filterAttributeInternalRep: string): string {
    return this._lang('filter-attribute-explanation.' + filterAttributeInternalRep);
  }


}

export interface FilterAttribute {
  internalRepresentation: string;
  displayValue: string;
}

