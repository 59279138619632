import {Injectable} from '@angular/core';
import {MessageService} from './message.service';
import {HttpClient} from '@angular/common/http';
import {HttpBaseService} from './http-base.service';
import Config from '../../config/Config';

import {BehaviorSubject, Observable} from 'rxjs';
import {MaintenanceGuideline} from "../../models/MaintenanceGuideline";
import {map, tap} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class MaintenanceGuidelinesService extends HttpBaseService {

  private content = new BehaviorSubject<MaintenanceGuideline>(null);
  public share = this.content.asObservable();

  updateData(guideline: MaintenanceGuideline) {
    this.content.next(guideline);
  }

  constructor(public http: HttpClient, messageService: MessageService) {
    super(http, messageService);
  }

  getAllMaintenanceGuidelines(): Observable<MaintenanceGuideline[]> {
    return this.http.get<MaintenanceGuideline[]>(Config.services.admin_homescreen.baseUrlGuideline)
      .pipe(
        map(result => result ? result : []),
        tap((result: MaintenanceGuideline[]) => {
          for (const guideline of result) {
            this.putDownloadLinkForGuideline(guideline);
          }
        })
      );
  }

  getMaintenanceGuideline(): Observable<MaintenanceGuideline> {
    const url = Config.services.admin_homescreen.baseUrlGuideline + Config.services.admin_homescreen.getGuidelineForPrincipal;
    return this.http.post<MaintenanceGuideline>(url, null)
      .pipe(
        tap((result: MaintenanceGuideline) => {
          if (result) {
            this.putDownloadLinkForGuideline(result);
          }
        })
      );
  }

  putDownloadLinkForGuideline(guideline: MaintenanceGuideline): void {
    guideline.downloadLink = Config.services.admin_homescreen.baseUrlGuideline + Config.services.admin_homescreen.downloadGuideline + '/' + guideline.id;
  }

  addMaintenanceGuideline(maintenanceGuideline: MaintenanceGuideline, file: File) {
    const formData = new FormData();
    formData.append('file', file);

    let urlParams = {
      name: file.name
    };

    if (maintenanceGuideline.comment) {
      urlParams['comment'] = maintenanceGuideline.comment;
    }
    urlParams['type'] = maintenanceGuideline.type;

    return this.http.post(Config.services.admin_homescreen.baseUrlGuideline, formData, {
      reportProgress: true,
      observe: 'events',
      params: urlParams
    });
  }

  deleteMaintenanceGuideline(maintenanceGuidelineId: number) {
    return this.http.delete(Config.services.admin_homescreen.baseUrlGuideline + maintenanceGuidelineId);
  }


}
