import {
  ActivatedRouteSnapshot,
  ActivationEnd,
  CanActivate,
  CanDeactivate,
  Params,
  Router,
  RouterStateSnapshot,
  UrlSegmentGroup,
  UrlTree
} from "@angular/router";
import {Type} from "@angular/core";

declare type ComponentType = Type<any>;

export interface BackNavigationComponentInfo {
  componentName: string;
  validTargets: string[];
}

/**
 * Caches query parameters according to the component information defined.
 * Keeps the query parameters between navigations from components to their valid targets.
 * When navigating from a component to an invalid target the cached query parameters are removed.
 *
 * Important: Needs to be used with the components canActivate and canDeactivate inside the routing module.
 *
 * The name of the component needs to be set in the app-routing-module under data->name.
 * Otherwise, the BackNavigationGuard will not work in production mode, because the component name will be minified!
 */
export class BackNavigationGuard implements CanActivate, CanDeactivate<ComponentType> {
  private cache: { [componentName: string]: Params } = {};

  constructor(private router: Router, private componentInformation: BackNavigationComponentInfo[]) {
    router.events.subscribe(event => {
      if (event instanceof ActivationEnd) {
        const navigatedToComponent = this.getComponentFromRoute(event.snapshot);
        if (navigatedToComponent instanceof Type) {
          this.clearOutdatedCache(navigatedToComponent);
        }
      }
    });
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): true | UrlTree {
    const to = this.getComponentFromRoute(route);
    const componentInfo = this.getInfoForComponent(to);
    if (componentInfo) {
      const params = this.cache[to.data["name"]];

      if (params && JSON.stringify(params) !== JSON.stringify(route.queryParams)) {
        const redirect = new UrlTree();
        redirect.queryParams = params;
        redirect.fragment = route.fragment;
        redirect.root = this.activatedRouteToUrlSegmentGroup(route.root);

        return redirect;
      }
    }

    return true;
  }

  canDeactivate(component: ComponentType, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): true {
    const leavingComponent = this.getComponentFromRoute(currentRoute);
    if (this.getInfoForComponent(leavingComponent)) {
      this.cache[leavingComponent.data["name"]] = currentRoute.queryParams;
    }
    return true;
  }

  private activatedRouteToUrlSegmentGroup(route: ActivatedRouteSnapshot): UrlSegmentGroup {
    const children = {};
    route.children.forEach(child => children[child.outlet] = this.activatedRouteToUrlSegmentGroup(child));
    return new UrlSegmentGroup(route.url, children);
  }

  private getInfoForComponent(component: ActivatedRouteSnapshot): BackNavigationComponentInfo {
    return this.componentInformation.find(info => info.componentName === component.data["name"]);
  }

  /**
   * Checks for all defined components if the navigation to the target is valid.
   * Otherwise the cached query parameters are removed.
   * @param target The target component reached.
   */
  private clearOutdatedCache(target: ActivatedRouteSnapshot): void {
    this.componentInformation.filter(info => this.cache[info.componentName]).forEach(info => {
      const isValidTarget = info.componentName === target.data["name"] || info.validTargets.some(validTarget => validTarget === target.data["name"]);

      if (!isValidTarget) {
        this.cache[info.componentName] = undefined;
      }
    });
  }

  private getComponentFromRoute(route: ActivatedRouteSnapshot): ActivatedRouteSnapshot {
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route;
  }
}
