import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {FormControl} from "@angular/forms";
import {forkJoin, Observable, of} from "rxjs";
import {debounceTime, map, skip, startWith, switchMap, takeUntil, tap} from "rxjs/operators";
import {GlobalSearchType, OptionAutocomplete} from "./option.autocomplete";
import {GlobalSearchService} from "../../../services/services/global-search.service";

@Component({
  selector: 'app-global-search-box',
  templateUrl: './global-search-box.component.html',
  styleUrls: ['./global-search-box.component.scss']
})
export class GlobalSearchBoxComponent implements OnInit {

  keywordControl = new FormControl();
  proposalFilteredOptions: Observable<OptionAutocomplete[]>;
  globalLoading = false;

  constructor(private _router: Router, private globalSearchService: GlobalSearchService) {
  }

  ngOnInit() {
    this.proposalFilteredOptions = this.keywordControl.valueChanges
      .pipe(
        startWith(''),
        debounceTime(500),
        switchMap(term =>
          this._proposalFilter(term).pipe(
            takeUntil(
              //skip 1 value
              this.proposalFilteredOptions.pipe(skip(1))
            )
          )
        )
      );
  }

  private _proposalFilter(value: string): Observable<OptionAutocomplete[]> {
    value = value.trim();
    if (value !== "") {
      this.globalLoading = true;
      return forkJoin([
        this.loadEmployeeProposals(value),
        this.loadProjectProposals(value)]
      ).pipe(map(([array1, array2]) => array1.concat(array2)),
        tap(array => this.globalLoading = false));
    } else {
      return of([]);
    }
  }

  private loadProjectProposals(value: string): Observable<OptionAutocomplete[]> {
    return this.globalSearchService.getProjectProposalsByKeyword(value).pipe(
      map(results =>
        results.map(result => ({
            identificator: result.client + " - " + result.projectName,
            icon: "folder",
            searchType: GlobalSearchType.PROJECT,
            id: result.sharePointId
          })
        )));
  }

  private loadEmployeeProposals(value: string): Observable<OptionAutocomplete[]> {
    return this.globalSearchService.getEmployeeProposalsByKeyword(value).pipe(
      map(results =>
        results.map(result => ({
            identificator: result.firstName + " " + result.lastName,
            icon: "person",
            searchType: GlobalSearchType.EMPLOYEE,
            id: result.id.toString()
          })
        )));
  }

  public search(): void {
    //check to prevent navigation of some mat-option is selected by keyboard
    if (typeof this.keywordControl.value === "string") {
      this._router.navigate(["/global-search"],
        {queryParams: {keyword: this.keywordControl.value}}).then();
    }
  }

  optionRouterLink(option: OptionAutocomplete): string {
    if (option.searchType === GlobalSearchType.EMPLOYEE) {
      return "/employee-edit/" + option.id;
    } else if (option.searchType === GlobalSearchType.PROJECT) {
      return "/project-edit/" + option.id;
    }
  }

  navigateToOptionRouterLink(option: OptionAutocomplete): void {
    this._router.navigate([this.optionRouterLink(option)]).then();
  }

  navigateToOptionInNewTab(option: OptionAutocomplete, e: number): void {
    if (e === 1) {
      const url = "/mep" + this.optionRouterLink(option);
      window.open(url, '_blank');
    }
  }
}
