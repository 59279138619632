import {Component, Inject} from '@angular/core';
import {TranslatableComponent} from "../../../core/models/TranslatableComponent";
import {TranslateService} from "@ngx-translate/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent extends TranslatableComponent {
  constructor(public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
              public _translate: TranslateService, @Inject(MAT_DIALOG_DATA) public confirmMessage: any) {
    super(_translate, 'ConfirmationDialogComponent');
  }
}
