import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SanitizeInputPipe} from "./pipes/sanitize-input.pipe";
import {FormatMonthForCapacityPipe} from "./pipes/format-month-capacity.pipe";
import {PspListTransformPipe} from "./pipes/psp-list-transform.pipe";
import {CapaCommentTransformPipe} from "./pipes/capaComment-transform.pipe";
import {InputWithDecimalsPipe} from "./pipes/InputWithDecimals.pipe";
import {TruncateTextPipe} from "./pipes/truncate-text.pipe";


@NgModule({
  declarations: [
    SanitizeInputPipe,
    FormatMonthForCapacityPipe,
    PspListTransformPipe,
    TruncateTextPipe,
    CapaCommentTransformPipe,
    InputWithDecimalsPipe,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    SanitizeInputPipe,
    FormatMonthForCapacityPipe,
    PspListTransformPipe,
    TruncateTextPipe,
    CapaCommentTransformPipe,
    InputWithDecimalsPipe
  ]
})
export class PipesModule {
}
