import {TranslateService} from "@ngx-translate/core";

export abstract class TranslatableComponent {
  private readonly _langPrefix: string;

  protected constructor(protected translate: TranslateService, private name: string) {
    let dashed = name.replace(/[A-Z]/g, m => "-" + m.toLowerCase());
    this._langPrefix = "mep.components." + dashed.substr(1).replace("-component", "") + ".";
  }

  public _lang(identifier: string, params: any = {}, withoutPrefix: boolean = false): string {
    if (withoutPrefix) {
      return this.translate.instant(identifier, params);
    }
    return this.translate.instant(this._langPrefix + identifier, params);
  }
}
