import {Directive, ElementRef, Input, TemplateRef, ViewContainerRef} from "@angular/core";
import {AuthenticationService} from "../../../core/services/services/authentication.service";
import {AppEntity, AppEntityAccessType, Permission} from "../../../core/models/AppPermissions";

// Passing enums into directives is not possible, so instead they are passed as strings.
// These types were defined to support IntelliJ's HTML highlighting.
declare type MinePermissionString = 'CREATE_MY' | 'READ_MY' | 'UPDATE_MY' | 'DELETE_MY';
declare type PermissionString = 'CREATE' | 'READ' | 'UPDATE' | 'DELETE';
declare type EntityString = 'EMPLOYEE' | 'EMPLOYEE_WITHOUT_NAME' | 'EMPLOYEE_FILE' | 'EMPLOYEE_LEAVING_DATE' | 'EMPLOYEE_NOT_RELEVANT_FOR_CAPA' | 'EMPLOYEE_TRAVEL_RESTRICTIONS'
  | 'EMPLOYEE_ANNOTATION' | 'ABSENCE' | 'EMPLOYEE_SKILL' | 'CAPACITY' | 'CAPACITY_ANNOTATION' | 'ASSIGNMENT' | 'ACTION_PLAN' | 'PROJECT' | 'IMPORT'
  | 'MAINTENANCE_GUIDELINE' | 'CUSTOMIZING' | 'GLOBAL_SKILLGROUP' | 'INSTANCE_WIDE_CAPACITY' | 'LOCAL_SKILLGROUP' | 'REPORTS' | 'RESOURCE_REQUEST' | 'P1_BOOKINGS'
  | 'WEBDATA_ROCKS' | 'CAPACITY_REVENUE';

@Directive({
  selector: '[appRequiresPermission]'
})
export class RequiresPermissionDirective {
  private permission: Permission;
  private accessType: AppEntityAccessType;
  private entity: AppEntity;
  private orEntity: AppEntity;
  private exactMatch = false;
  private idNotRelevant = false;
  private elseTemplate: TemplateRef<any>;
  private employeeId: number;

  private visibleTemplateRef: TemplateRef<any>;

  constructor(
    private element: ElementRef,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authService: AuthenticationService
  ) {

  }

  @Input()
  set appRequiresPermission(permission: PermissionString | MinePermissionString) {
    if (permission.endsWith('_MY')) {
      this.permission = Permission.ONLY_RELATED_TO_ME;
      this.accessType = permission.replace('_MY', '') as AppEntityAccessType;
    } else {
      this.permission = Permission.TRUE;
      this.accessType = permission as AppEntityAccessType;
    }
    if (!AppEntityAccessType[this.accessType]) {
      throw new Error(`Unknown access type ${this.accessType}`);
    }
    this.afterValuesChange();
  }

  @Input()
  set appRequiresPermissionEntity(entity: EntityString) {
    this.entity = entity as AppEntity;
    if (!AppEntity[entity]) {
      throw new Error(`Unknown app entity ${entity}`);
    }
    this.afterValuesChange();
  }

  @Input()
  set appRequiresPermissionOrEntity(orEntity: EntityString) {
    this.orEntity = orEntity as AppEntity;
    if (!AppEntity[orEntity]) {
      throw new Error(`Unknown app entity ${orEntity}`);
    }
    this.afterValuesChange();
  }

  @Input()
  set appRequiresPermissionExactMatch(exactMatch: boolean) {
    this.exactMatch = exactMatch;
    this.afterValuesChange();
  }

  @Input()
  set appRequiresPermissionElse(elseTemplate: TemplateRef<any>) {
    this.elseTemplate = elseTemplate;
    this.afterValuesChange();
  }

  @Input()
  set appRequiresPermissionEmployeeId(employeeId: number) {
    this.employeeId = employeeId;
    this.afterValuesChange();
  }

  @Input()
  set appRequiresPermissionIdNotRelevant(idNotRelevant: boolean) {
    this.idNotRelevant = idNotRelevant;
    this.afterValuesChange();
  }

  private afterValuesChange() {
    this.hasPermission().then(result => this.updateVisibility(result));
  }

  private async hasPermission(): Promise<boolean> {
    if (this.permission && this.entity) {
      const hasPermission = await this.authService.hasAtLeastPermission(this.permission, this.accessType, this.entity, this.idNotRelevant, this.employeeId, this.exactMatch);

      if (hasPermission) {
        return true;
      } else if (this.orEntity) {
        return this.authService.hasAtLeastPermission(this.permission, this.accessType, this.orEntity, this.idNotRelevant, this.employeeId, this.exactMatch);
      }
    }

    return false;
  }

  private updateVisibility(exp: boolean) {
    let templateRefToDraw;

    if (exp) {
      templateRefToDraw = this.templateRef;
    } else if (this.elseTemplate) {
      templateRefToDraw = this.elseTemplate;
    }

    if (templateRefToDraw !== this.visibleTemplateRef) {
      this.visibleTemplateRef = templateRefToDraw;
      this.viewContainer.clear();

      if (templateRefToDraw) {
        this.viewContainer.createEmbeddedView(templateRefToDraw);
      }
    }
  }
}
