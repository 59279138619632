import {SkillDbEntry} from "../../../core/models/EmployeeSkill";

export interface FilterViewData {
  loadData(): Promise<void>;
}

export interface SkillDbFilterData {
  skillDbRows: SkillDbEntry[];
  logicalOperator: 'AND' | 'OR';
}

export class DefaultSkillDbFilterData implements SkillDbFilterData {
  skillDbRows = [];
  logicalOperator: 'AND' | 'OR' = 'AND';
}
