import {FilterAttribute} from './filter-parser.component';
import {ParserStack} from './parser-stack';
import {LanguageSelection} from "../../../core/models/LanguageSelection";
import {TranslateService} from "@ngx-translate/core";

export class Filter {
  private _filterTemplate = '';
  private _validFilter = true;
  private _filterValueArray: Array<string>;

  constructor(
    dataAccessor,
    private lowerCaseFilterAttributes: FilterAttribute[],
    private freeTextSearchAttributes: string[],
    private _translate: TranslateService) {
    this._filterValueArray = new Array<string>();
    ParserStack.initStack();

    if (dataAccessor) {
      this.dataAccessor = dataAccessor;
    }
  }

  get validFilter(): boolean {
    return this._validFilter;
  }

  private mapDisplayValueToFilterAttributeInternalRep(filterAttributeDisplayValue: string) {
    const value = this.lowerCaseFilterAttributes.find((filterAttribute: FilterAttribute) => filterAttribute.displayValue === filterAttributeDisplayValue);
    if (value) {
      this._validFilter = true;
      return value.internalRepresentation;
    }
    this._validFilter = false;
    return filterAttributeDisplayValue;
  }

  private skipSpaces(currPos: number, filterString: string): number {
    while ([32, 160].includes(filterString.charCodeAt(currPos))) {
      currPos++;
    }
    return currPos;
  }

  private formFilterString(filterString: string): string {
    filterString = filterString.substr(filterString.search(/\S/));
    let currPos = 0;
    let formedFilterString = '';
    while (currPos < filterString.length) {
      switch (filterString[currPos].toLowerCase()) {
        case '\'':
          currPos++;
          let closedQuoteFound = false;
          do {
            if (filterString[currPos] === '&') {
              formedFilterString += '/';
            }
            formedFilterString += filterString[currPos++];
            if (filterString[currPos] === '\'') {
              closedQuoteFound = true;
              currPos++;
              break;
            }
          } while (currPos < filterString.length);
          this._validFilter = closedQuoteFound;
          break;
        case ':':
          formedFilterString += filterString[currPos++];
          currPos = this.skipSpaces(currPos, filterString);
          break;
        case ' ':
          if (currPos + 3 < filterString.length && filterString.substr(currPos + 1, 3).toLowerCase() === 'or ') {
            currPos += 3;
            formedFilterString += '|';
          } else {
            formedFilterString += '&';
          }
          currPos = this.skipSpaces(currPos, filterString);
          break;
        default:
          formedFilterString += filterString[currPos++];
      }
    }
    return formedFilterString;
  }

  private getExp(formedFilterString: string, currPos: number): { exp: string; currPos: number } {
    let exp = '';
    while (!['(', ')', '|'].includes(formedFilterString[currPos]) && currPos < formedFilterString.length) {
      if (formedFilterString[currPos].includes('/') && formedFilterString[currPos + 1].includes('&')) {
        exp += formedFilterString[currPos + 1];
        currPos += 2;
      } else if (!formedFilterString[currPos].includes('&')) {
        exp += formedFilterString[currPos++];
      } else {
        break;
      }
    }
    return {exp, currPos};
  }

  private generatePostfix(formedFilterString: string): Array<string> {
    let currPos = 0;
    let postfixList: Array<string> = new Array<string>();

    while (currPos < formedFilterString.length) {
      switch (formedFilterString[currPos]) {
        case '&':
        case '|':
        case '(':
          ParserStack.pushValue(formedFilterString[currPos++]);
          break;
        case ')':
          while (!ParserStack.isStackEmpty()) {
            const fetchedValue = ParserStack.popValue();
            if (fetchedValue === '(') {
              break;
            }
            postfixList.push(fetchedValue);
          }
          currPos++;
          break;
        default:
          const data = this.getExp(formedFilterString, currPos);
          currPos = data.currPos;
          postfixList.push(data.exp.trim());
      }
    }

    while (!ParserStack.isStackEmpty()) {
      if (!['&', '|'].includes(ParserStack.getTopValue())) {
        this._validFilter = false;
      }
      postfixList.push(ParserStack.popValue());
    }
    return postfixList;
  }

  private getKeyValue(keyValue: string): { key: string; value: string } {
    if (!keyValue) {
      this._validFilter = false;
      return {key: '', value: ''};
    }
    if (keyValue.indexOf(':') === -1) {
      keyValue += ':';
    }
    const key = keyValue.substr(0, keyValue.indexOf(':'));
    const value = keyValue.substr(keyValue.indexOf(':') + 1);
    return {key, value};
  }

  private generateFilterFunction(pair: { key: string; value: string }, isFreeText: boolean): string {
    this._filterValueArray.push(pair.value);
    let methodSignature: string;
    if (isFreeText) {
      methodSignature = "methods.match(element, '" + pair.key + "', '" + pair.value + "')";
    } else {
      const filterAttributeInternalRep = this.mapDisplayValueToFilterAttributeInternalRep(pair.key);
      methodSignature = "methods.match(element, '" + filterAttributeInternalRep + "', '" + pair.value + "')";
    }
    return methodSignature;
  }

  private generateFreeTextSearch(freeSearchText: string): string {
    let freeTextSearchExp = '';
    this.freeTextSearchAttributes.forEach(attr => {
      freeTextSearchExp += this.generateFilterFunction({key: attr, value: freeSearchText}, true) + ' || ';
    });
    freeTextSearchExp = '(' + freeTextSearchExp.substring(0, freeTextSearchExp.length - 4) + ')';
    return freeTextSearchExp;
  }

  private generateFilterExperision(postfixList: Array<string>): string {
    for (let item of postfixList) {
      let exp;
      switch (item) {
        case '&':
        case '|':
          const funcB = ParserStack.popValue();
          const funcA = ParserStack.popValue();
          exp = '(' + funcA + ' ' + item + item + ' ' + funcB + ')';
          ParserStack.pushValue(exp);
          break;
        default:
          const pair = this.getKeyValue(item);
          if (pair.key.length > 0 && pair.value === '') {
            exp = this.generateFreeTextSearch(pair.key);
          } else {
            exp = this.generateFilterFunction(pair, false);
          }
          ParserStack.pushValue(exp);
      }
    }
    if (ParserStack.getSize() > 1) {
      this._validFilter = false;
    }
    return ParserStack.popValue();
  }

  doFiltering(filterString: string): string[] {
    const formedFilterString = this.formFilterString(filterString);
    const postfixList = this.generatePostfix(formedFilterString);
    this._filterTemplate = this.generateFilterExperision(postfixList);

    return this._filterValueArray;
  }

  createFilter(): (element) => boolean {
    const filter = new Function("methods", "element", "return " + this._filterTemplate);
    return (element) => filter(this, element);
  }

  lowerCaseDataAccessor(element, filterAttribute): any {
    const data = this.dataAccessor(element, filterAttribute);

    if (typeof data === 'string') {
      return data.toLocaleLowerCase();
    } else if (Array.isArray(data)) {
      return data.map(arrayElement => typeof arrayElement === 'string' ? arrayElement.toLocaleLowerCase() : arrayElement);
    } else {
      return data;
    }
  }

  match(element, filterAttribute, filterValue) {
    const expected = this.lowerCaseDataAccessor(element, filterAttribute);
    let checkSingleValue: ((t: any) => boolean) = (value => typeof value === 'string' ? value.includes(filterValue) : value === filterValue);
    if (filterAttribute === 'lang' && expected !== null) {
      return LanguageSelection.testLanguageSelection(expected, filterValue);
    } else if (expected !== null && Array.isArray(expected)) {
      return expected.some(value => checkSingleValue(value) || checkSingleValue(this.safeTranslate(element, filterAttribute)));
    } else {
      return checkSingleValue(expected) || checkSingleValue(this.safeTranslate(element, filterAttribute));
    }
  }

  dataAccessor(element, filterAttribute) {
    return element[filterAttribute];
  }

  private safeTranslate(element, filterAttribute): string {
    const data = this.dataAccessor(element, filterAttribute);

    if (data && typeof data === 'string') {
      return this._translate.instant(data).toLowerCase();
    } else {
      return null;
    }
  }
}
