import {Component, Inject, OnInit} from '@angular/core';
import {TranslatableComponent} from "../../../core/models/TranslatableComponent";
import {Employee} from "../../../core/models/Employee";
import {TranslateService} from "@ngx-translate/core";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {MessageService} from "../../../core/services/services/message.service";
import {EmployeeService} from "../../../core/services/services/employee.service";
import {FormBuilder} from "@angular/forms";

@Component({
  selector: 'app-add-employee-to-project',
  templateUrl: './add-employee-to-project.component.html',
  styleUrls: ['./add-employee-to-project.component.scss'],
})

export class AddEmployeeToProjectComponent extends TranslatableComponent implements OnInit {

  employeeArray: Employee[];

  constructor(
    public dialogRef: MatDialogRef<AddEmployeeToProjectComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private _fb: FormBuilder,
    private _employeeService: EmployeeService,
    private _messageService: MessageService,
    public translate: TranslateService,
    public dialog: MatDialog,) {
    super(translate, 'AddEmployeeToProjectComponent');
  }

  async ngOnInit() {
    this.employeeArray = this.data?.addToProjectEmployees || [];

  }

  close() {
    this.dialogRef.close();
  }

  disableButton() {
    if (this.employeeArray?.length == 0) {
      return true;
    }
  }

  getResult() {
    return {
      employees: this.employeeArray,
      addEmployeeForm: this.employeeArray.length > 0
    };
  }
}
