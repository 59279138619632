import {Component, Inject, OnInit, Optional, ViewChild} from '@angular/core';
import {Skillgroup} from "../../../../../core/models/Skillgroup";
import {Skill, SkillWithMinimumLevel} from "../../../../../core/models/EmployeeSkill";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {TranslateService} from "@ngx-translate/core";
import {TranslatableComponent} from "../../../../../core/models/TranslatableComponent";
import {SkillDragDropListComponent} from "../../../../../shared/skillgroup/components/skill-drag-drop-list/skill-drag-drop-list.component";
import {MessageService} from "../../../../../core/services/services/message.service";


@Component({
  selector: 'app-skill-select',
  templateUrl: './skill-select.component.html',
  styleUrls: ['./skill-select.component.css']
})
export class SkillSelectComponent extends TranslatableComponent implements OnInit {

  @ViewChild(SkillDragDropListComponent) dragDrop: SkillDragDropListComponent;

  selectedSkillgroup = new Skillgroup();
  loading = false;
  alreadySetSkills: Skill[] = [];
  choosenSkills: SkillWithMinimumLevel[];
  showInvisibleSkills: boolean;

  constructor(public dialogRef: MatDialogRef<Skill>,
              private messageService: MessageService,
              private _dialog: MatDialog,
              private _translate: TranslateService,
              @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    super(_translate, "SkillSelectComponent");
  }

  async ngOnInit() {
    Promise.resolve().then(() => {
      this.dragDrop.displayedColumnsRight = ['category', 'skill', 'level', 'action'];
      this.dragDrop.displayedColumnsLeft = ['category', 'skill', 'action'];
      this.dragDrop.rightDataSource.data = this.data.skills;
      this.dragDrop.operator = this.data.operator;
      this.showInvisibleSkills = this.data.showInvisibleSkills;
    });
  }

  skillsChanged(event) {
    this.choosenSkills = event.skills;
    this.selectedSkillgroup.andOperator = event.andOperator.toString();
  }


  async closeDialog() {
    this.dialogRef.close({skills: this.dragDrop.rightDataSource.data,
                                    operator: this.dragDrop.operator});
  }
}
