import { AlertEnum } from './Enums/AlertEnum';

export class Message {
    message: string;
    alert: AlertEnum;
    isPermanent: boolean;

    constructor(message: string, alert: AlertEnum, isPermanent = false) {
        this.message = message;
        this.alert = alert;
        this.isPermanent = isPermanent;
    }
}
