import {Injectable} from '@angular/core';
import {HttpBaseService} from "./http-base.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {MessageService} from "./message.service";
import {TranslateService} from "@ngx-translate/core";
import {FilterElement} from "../../models/viewModels/FilterElement";
import {Observable} from "rxjs";
import Config from "../../config/Config";
import {ListService} from "../../models/list/ListService";
import {ListResponse} from "../../models/list/ListResponse";

@Injectable({
  providedIn: 'root'
})
export class FilterStoreService extends HttpBaseService implements ListService<FilterElement> {

  url: string = Config.services.filterStore.baseUrl;

  constructor(public http: HttpClient, public messageService: MessageService, private translate: TranslateService) {
    super(http, messageService);
  }

  public getFilters(filterLocation: string): Observable<FilterElement[]> {
    let params = new HttpParams();
    params = params.append('filterLocation', filterLocation);
    return this.http.get<FilterElement[]>(this.url + Config.services.filterStore.getFilters, {params});
  }

  public search(params): Observable<ListResponse<FilterElement>> {
    return this.http.get<ListResponse<FilterElement>>(this.url + Config.services.filterStore.searchFilters, {params});
  }

  public parseFilterValue(filterValue: string) {
    return JSON.parse(filterValue);
  }

  public stringifyFilterValue(filterValue): string {
    return JSON.stringify(filterValue);
  }

  public deleteFilter(filterId: number) {
    return this.http.delete<any>(this.url + '/' + filterId);
  }

  public addFilter(filterElement: FilterElement): Observable<FilterElement> {
    return this.http.post<FilterElement>(this.url + '/', filterElement);
  }

  public getStandardFilter(filterLocation: string): Observable<FilterElement> {
    let params = new HttpParams();
    params = params.append('filterLocation', filterLocation);
    return this.http.get<FilterElement>(this.url + Config.services.filterStore.getStandardFilter, {params});
  }

}
