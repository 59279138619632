import {Component, OnInit} from '@angular/core';
import {AssignEmployeeDialogComponent} from '../../shared/dialogs/assign-employee-dialog/assign-employee-dialog.component';
import {ProjectService} from '../../core/services/services/project.service';
import {EmployeeService} from '../../core/services/services/employee.service';
import {EmployeeCapacityService} from '../../core/services/services/employeeCapacity.service';
import {ProjectTotalCapacityService} from '../../core/services/services/project-total-capacity.service';
import {CustomizingService} from '../../core/services/services/customizing.service';
import {TranslatableComponent} from '../../core/models/TranslatableComponent';
import {TranslateService} from '@ngx-translate/core';
import Config from '../../core/config/Config';
import {ExtendOrMoveProjectDialogComponent} from '../../shared/dialogs/extend-or-move-project-dialog/extend-or-move-project-dialog.component';
import {CopyAssignmentsDialogComponent} from '../../shared/dialogs/copy-assignments-dialog/copy-assignments-dialog.component';
import {AuthenticationService} from '../../core/services/services/authentication.service';
import {MatDialog} from '@angular/material/dialog';
import {ApplicationUser} from '../../core/models/ApplicationUser';
import {EmployeeSkill} from '../../core/models/EmployeeSkill';
import {Employee} from "../../core/models/Employee";
import {CustomizingAbsence} from "../../core/models/Enums/CustomizingAbsence";
import {SkillDbDialogComponent} from "../../shared/dialogs/skill-db-dialog/skill-db-dialog.component";
import {FilesDialogComponent} from "../../shared/dialogs/files-dialog/files-dialog.component";
import {AppEntity, AppEntityAccessType, Permission} from "../../core/models/AppPermissions";

@Component({
  selector: 'app-homescreen',
  templateUrl: './homescreen.component.html',
  styleUrls: ['./homescreen.component.scss']
})
export class HomeScreenComponent extends TranslatableComponent implements OnInit {

  version: string;
  lastModifiedDate = new Date(document.lastModified);
  isResourceRequestEnabled: boolean;
  releaseNotesLink = Config.releaseNotesLink;
  actionPlanFunctionalityEnabled = false;
  instanceWideSearchEnabled = false;
  hideResourceList = true;
  customizingEnabledEmployeeProposal = false;
  applicationUser: ApplicationUser;
  skillgroupsAcitvated;
  showAbsenceLink = true;
  showEditProjectPeriod = true;
  displayProfileFileEdit: boolean;
  showProjectLink = false;

  private isSkillDialogOpening = false;
  private customizingEnableProposalLoaded = false;

  constructor(private matDialog: MatDialog,
              private translateService: TranslateService,
              private _projectService: ProjectService,
              private _employeeService: EmployeeService,
              private _employeeCapacityService: EmployeeCapacityService,
              private _projectTotalCapacityService: ProjectTotalCapacityService,
              private customizingService: CustomizingService,
              public loginService: AuthenticationService) {
    super(translateService, 'HomeScreenComponent');
    this.customizingService.enabledProjectEmployeeProposal().then(enabled => {
      this.customizingEnabledEmployeeProposal = enabled;
      this.customizingEnableProposalLoaded = true;
    });
  }

  async ngOnInit() {
    this.getNameOfWarFile();
    this.customizingService.isMAReadonlyUploadCenterDisplayed().then(enabled => this.displayProfileFileEdit = enabled);
    this.customizingService.isResourceRequestEnabled().then(enabled => this.isResourceRequestEnabled = enabled);
    this.customizingService.isActionPlanFunctionalityEnabled().then(enabled => this.actionPlanFunctionalityEnabled = enabled);
    this.customizingService.isInstanceWideSearchEnabled().then(enabled => this.instanceWideSearchEnabled = enabled);

    this.loginService.getApplicationUser().then(applicationUser => this.applicationUser = applicationUser);
    this.customizingService.areSkillgroupsEnabled().then(data => this.skillgroupsAcitvated = data);

    this.customizingService.getAbsenceSettings().then(absenceSetting => {
      this.showAbsenceLink = absenceSetting !== CustomizingAbsence.DISABLE_ABSENCES;
    });

    this.customizingService.requireProjectAdmin().then(require => {
      if (require && !this.loginService.isProjectAdmin()) {
        this.showEditProjectPeriod = false;
      }
    });

    this.customizingService.getMaReadonlyReslist().then(async enabled => {
      // Readonly roles have restricted permission
      const hasConfidentialFieldPermission = await this.loginService.hasPermission(Permission.TRUE, AppEntityAccessType.READ, AppEntity.RESOURCE_REQUEST_CONFIDENTIAL_FIELDS);

      // Readonly roles are allowed to see the tile if customizing allows it
      if ((!hasConfidentialFieldPermission && enabled) || hasConfidentialFieldPermission) {
        this.hideResourceList = false;
      }
    });

    this.customizingService.getMaReadonlyProjlist().then(async enabled => {
      const hasProjectUpdatePermission = await this.loginService.hasPermission(Permission.TRUE, AppEntityAccessType.UPDATE, AppEntity.PROJECT);

      // Readonly roles are allowed to see the tile if customizing allows it
      if (hasProjectUpdatePermission || enabled) {
        this.showProjectLink = true;
      }
    });
  }

  /**
   * Opens the assignment dialog for all projects
   */
  async openAssignEmployeeDialog(employee: Employee = undefined): Promise<void> {
    if (!this.customizingEnableProposalLoaded) {
      this.customizingEnabledEmployeeProposal = await this.customizingService.enabledProjectEmployeeProposal();
    }

    this.matDialog.open(AssignEmployeeDialogComponent, {
      disableClose: true,
      width: '1000px',
      data: {
        employees: employee ? [employee] : undefined,
        disableProjects: false,
        enabledProposal: this.customizingEnabledEmployeeProposal
      }
    });
  }

  openChangeProjectEndDialog(): void {
    this.matDialog.open(ExtendOrMoveProjectDialogComponent, {
      disableClose: true,
      width: '1000px'
    });
  }

  openCopyAssignmentsDialog() {
    this.matDialog.open(CopyAssignmentsDialogComponent, {
      disableClose: true,
      width: '1000px'
    });
  }

  openEditMySkills() {
    if (!this.isSkillDialogOpening) {
      this.isSkillDialogOpening = true;
      this.loginService.getApplicationUser().then((user: ApplicationUser) => {
        let currentUser = user.employee;
        this._employeeService.getEmployeeSkillsById(currentUser.id).subscribe((currentUserSkills: EmployeeSkill[]) =>
          SkillDbDialogComponent.openEditSkillsDialog(this.matDialog, false, () => {
            this.isSkillDialogOpening = false;
          }, currentUserSkills, currentUser, true));
      });
    }
  }

  openMyProfileFiles() {
    this.loginService.getApplicationUser().then((user: ApplicationUser) => {
      let currentUser = user.employee;
      this.matDialog.open(FilesDialogComponent, {data: currentUser});
    });
  }

  private getNameOfWarFile() {
    this.customizingService.getWarFileName().then((version: string) => {
      this.version = version;
    });
  }

  isNewVersion() {
    var diff = Math.abs(new Date().getTime() - new Date(document.lastModified).getTime());
    return Math.ceil(diff / (1000 * 3600 * 24)) < 3;
  }
}
