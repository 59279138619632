import {fromEvent, merge, Observable} from "rxjs";
import {AfterViewInit, Directive, EventEmitter, Input, OnInit, Output, ViewChild} from "@angular/core";
import {MatAutocomplete} from "@angular/material/autocomplete";
import {debounceTime, filter, map, mergeMap, tap} from "rxjs/operators";
import {AbstractControl} from "@angular/forms";

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class AbstractBackendAutocomplete<T> implements OnInit, AfterViewInit {

  filteredElements: Observable<T[]>;

  loading = false;

  @Output() elementSelected: EventEmitter<T> = new EventEmitter();

  @Input() showSelectedOption = true;
  @Input() smallFont = false;

  @Input()
  inputFormControl: AbstractControl;
  @Input() inputElement: HTMLInputElement;


  @ViewChild(MatAutocomplete, {static: true}) public matAutocomplete;

  ngOnInit() {

  }

  ngAfterViewInit(): void {
    if (this.inputFormControl) {
      this.registerSubscriptionForObservable(this.inputFormControl.valueChanges);
    } else if (this.inputElement) {
      this.registerSubscriptionForObservable(this.getObservableFromInputElement());
    } else {
      throw new Error("Neither a formControl nor an inputElement is supplied for this autocomplete.");
    }
  }

  private getObservableFromInputElement(): Observable<string> {
    return merge(fromEvent(this.inputElement, 'keyup'), fromEvent(this.inputElement, 'focus'))
      .pipe(map(() => this.inputElement.value));
  }

  private registerSubscriptionForObservable(observable: Observable<any>): void {
    this.filteredElements = observable.pipe(
      filter(val => typeof val === 'string'), // Ignore value changes that result of selecting a possible drop down
      debounceTime(300), // Time in milliseconds between key events
      mergeMap((searchTerm: string) => this.getElementsForSearchTerm(searchTerm)),
      tap(() => this.loading = false));
  }

  private getElementsForSearchTerm(searchTerm: string): Observable<T[]> {
    this.loading = true;
    if (searchTerm === ' ' || null || undefined) {
      return this.getElementsFromBackendForSearchTerm(" ")
    } else {
      return this.getElementsFromBackendForSearchTerm(searchTerm);
    }
  }

  /**
   * Creates a string (or html) representation of an element
   * Used for creating a drop down option
   * @param element the element to be represented as a string (or html)
   */
  displayAsDropDownOption(element: T): string {
    return this.displayAsSelectedOption(element);
  }

  /**
   * Creates a string representation of an element
   * Will be returned to the attached input field
   * @param element the element to be represented as a string
   */
  abstract displayAsSelectedOption(element: T): string;

  displayAsSelectedOptionEmpty(): string {
    return '';
  }


  abstract getElementsFromBackendForSearchTerm(searchTerm: string): Observable<T[]>;

  abstract sort(element: T, otherElement: T): number;


}
