import {Injectable} from "@angular/core";

/**
 * This service lazily loads the XLSX library. This ensures that the library is only loaded when needed.
 */
@Injectable({
  providedIn: 'root'
})
export class LazyXlsxService {
  public async getXlsx() {
    const importedModuleFile = await import('./lazy-xlsx.module');
    return importedModuleFile.WebDataRocksModule.getXlsx();
  }
}
