import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {SelectionModel} from "@angular/cdk/collections";
import {AssignmentProjectEmployeeDTO} from "../extend-or-move-project-dialog.component";
import {AssignmentProjectEmployee} from "../../../../core/models/AssignmentProjectEmployee";
import {FormControl, Validators} from "@angular/forms";
import {TranslatableComponent} from "../../../../core/models/TranslatableComponent";
import {TranslateService} from "@ngx-translate/core";
import {ProjectTimeSlice} from "../../../../core/models/ProjectTimeSlice";
import {ProjectService} from "../../../../core/services/services/project.service";
import {ProjectName} from "../../../../core/models/Project";
import {MatSort} from "@angular/material/sort";
import {MatCheckboxChange} from "@angular/material/checkbox";
import {MatTableDataSource} from "@angular/material/table";
import moment from "moment";

@Component({
  selector: 'app-assignment-table',
  templateUrl: './assignment-table.component.html',
  styleUrls: ['./assignment-table.component.scss']
})
export class AssignmentTableComponent extends TranslatableComponent implements OnInit {
  @Output() updateValidation = new EventEmitter<AssignmentProjectEmployeeDTO>();
  private _displayedAssignments: AssignmentProjectEmployee[] = [];
  private _hiddenAssignments: AssignmentProjectEmployee[] = [];
  assignmentDataSource = new MatTableDataSource<AssignmentProjectEmployeeDTO>();

  assignedEmployeesColumns: string[] = [
    'select',
    'firstName',
    'lastName',
    'effectiveFrom',
    'effectiveUntil',
    'capacity'
  ];

  @ViewChild('employeeSort', { static: true }) assignedEmployeesSort: MatSort;
  selection = new SelectionModel<AssignmentProjectEmployeeDTO>(true, []);

  /**
   * Only used for validation
   */
  private projectTimeSlices: ProjectTimeSlice[];

  private overwrittenProjectEnd: moment.Moment;

  private overwrittenProjectStart: moment.Moment;

  @Input()
  set project(project: ProjectName) {
    if (project) {
      this.projectService.getProjectProbabilities(project.id, false).then((timeSlices: ProjectTimeSlice[]) => {
        this.projectTimeSlices = timeSlices;
        this.revalidateAllAssignmentTimeIntervals();
      });
    }
  }

  constructor(protected translate: TranslateService, private projectService: ProjectService) {
    super(translate, "AssignmentTableComponent");
  }

  ngOnInit() {
    this.assignmentDataSource.sort = this.assignedEmployeesSort;
    this.assignmentDataSource.sortingDataAccessor = ((data: AssignmentProjectEmployeeDTO, sortHeaderId: string) => {
      switch (sortHeaderId) {
        case 'firstName':
          return data.employee['firstName'].toLocaleLowerCase();
        case 'lastName':
          return data.employee['lastName'].toLocaleLowerCase();
        case 'effectiveFrom':
          return data.effectiveFrom.value;
        case 'effectiveUntil':
          return data.effectiveUntil.value;
        case 'capacity':
          return data.capacity.value;
        default:
          return typeof data[sortHeaderId] === 'string' ? data[sortHeaderId].toLocaleLowerCase() : data[sortHeaderId];
      }
    });
  }

  set assignments(assignments: AssignmentProjectEmployee[]) {
    if (assignments) {
      this.selection = new SelectionModel<AssignmentProjectEmployeeDTO>(true, []);
      this._displayedAssignments = assignments;

      this.createNewAssignmentDTOs();
    }
  }

  /**
   * Additional assignments only used for validation
   */
  set hiddenAssignments(assignments: AssignmentProjectEmployee[]) {
    if (assignments) {
      this._hiddenAssignments = assignments;

      this.revalidateAllAssignmentTimeIntervals();
    }
  }

  setEffectiveUntilForAllAssignments(effectiveUntil) {
    if (effectiveUntil) {
      const until = moment(effectiveUntil).format('YYYY-MM-DD');

      this.assignmentDataSource.data.forEach(assignment => {
        assignment.checked = true;
        assignment.effectiveUntil.setValue(until);
        assignment.effectiveUntil.markAsTouched();
      });
    }
  }

  setEffectiveFromForAllAssignments(effectiveFrom) {
    if (effectiveFrom) {
      const from = moment(effectiveFrom).format('YYYY-MM-DD');

      this.assignmentDataSource.data.forEach(assignment => {
        assignment.effectiveFrom.setValue(from);
        assignment.effectiveFrom.markAsTouched();
      });
    }
  }

  /**
   * Overwrites the project end used for validation
   */
  overwriteProjectEndForValidation(projectEnd) {
    this.overwrittenProjectEnd = moment(projectEnd);

    this.revalidateAllAssignmentTimeIntervals();
  }

  overwriteProjectStartForValidation(projectStart) {
    this.overwrittenProjectStart = moment(projectStart);

    this.revalidateAllAssignmentTimeIntervals();
  }

  getSelectedAssignmentsDto() {
    return this.assignmentDataSource.data
      .filter(row => this.selection.selected.includes(row))
      .map((assignment: AssignmentProjectEmployeeDTO) => {
        return {
          effectiveUntil: assignment.effectiveUntil.value,
          effectiveFrom: assignment.effectiveFrom.value,
          capacity: assignment.capacity.value,
          projectID: assignment.project.id,
          employeeID: assignment.employee.id,
          existingAssignmentID: assignment.existingAssignmentID
        };
      });
  }

  isRowSelected() {
    return this.getSelectedAssignmentsDto().length > 0;
  }

  getSelectedAssignments(): AssignmentProjectEmployee[] {
    return this._displayedAssignments.filter(row => this.selection.selected.some(sel => sel.existingAssignmentID === row.id));
  }

  private createNewAssignmentDTOs() {
    this.assignmentDataSource.data = this._displayedAssignments.map(this.createAssignmentDTO.bind(this));

    this.selectAllEmployeesToggle();
  }

  private createAssignmentDTO(assignment: AssignmentProjectEmployee): AssignmentProjectEmployeeDTO {
    const dateNotWithinOtherAssignmentValidator = (dateControl) => this.validateDateNotWithinExistingAssignment(dateControl, assignment);

    const effectiveFrom = this.createEffectiveFromControl(assignment.effectiveFrom, dateNotWithinOtherAssignmentValidator.bind(this));
    if (assignment.effectiveFrom) {
      effectiveFrom.markAsTouched();
    }

    const effectiveUntil = this.createEffectiveUntilControl(assignment.effectiveUntil, effectiveFrom, dateNotWithinOtherAssignmentValidator.bind(this));
    if (assignment.effectiveUntil) {
      effectiveUntil.markAsTouched();
    }

    return {
      project: assignment.project,
      employee: assignment.employee,
      capacity: new FormControl(assignment.capacity),
      effectiveFrom: effectiveFrom,
      effectiveUntil: effectiveUntil,
      existingAssignmentID: assignment.id
    };
  }

  private createEffectiveUntilControl(initialValue, effectiveFrom: FormControl, dateNotWithinOtherAssignmentValidator) {
    const untilAfterFromValidator = (untilControl) => this.validateAssignmentUntilAfterFrom(untilControl, effectiveFrom);

    const validators =
      [
        Validators.required,
        this.validateAssignmentWithinProjectBounds.bind(this),
        untilAfterFromValidator.bind(this),
        dateNotWithinOtherAssignmentValidator.bind(this)
      ];

    return new FormControl(initialValue, {validators: validators});
  }

  private createEffectiveFromControl(initialValue, dateNotWithinOtherAssignmentValidator) {
    const validators =
      [
        Validators.required,
        this.validateAssignmentWithinProjectBounds.bind(this),
        dateNotWithinOtherAssignmentValidator.bind(this)
      ];
    return new FormControl(initialValue, {validators: validators});
  }

  selectAllEmployeesToggle() {
    if (this.selection.selected.length === this.assignmentDataSource.data.length) {
      this.selection.clear();
      this.assignmentDataSource.data.forEach((row: AssignmentProjectEmployeeDTO) => {
        row.effectiveUntil.markAsUntouched();
        row.effectiveFrom.markAsUntouched();
      });
    } else {
      this.assignmentDataSource.data.forEach((row: AssignmentProjectEmployeeDTO) => {
        this.selection.select(row);
        row.effectiveUntil.markAsTouched();
        row.effectiveFrom.markAsTouched();
      });
    }
  }


  selectedEmployeeAssignmentsAreValid(): boolean {
    let valid = true;

    this.assignmentDataSource.data.forEach((assignment: AssignmentProjectEmployeeDTO) => {
      if (valid && this.selection.selected.includes(assignment)) {
        valid = valid && assignment.effectiveFrom.valid && assignment.capacity.valid && assignment.effectiveUntil.valid;
      }
    });

    return valid;
  }

  validateAssignmentWithinProjectBounds(control: FormControl) {
    if (this.projectTimeSlices) {
      const startOrEnd = moment(control.value);
      let error = null;

      const withinProjectBounds = this.projectTimeSlices.some(timeSlice => {
        const from = moment(timeSlice.effectiveFrom);

        if (timeSlice.effectiveUntil) {
          const until = moment(timeSlice.effectiveUntil);

          return startOrEnd.isBetween(from, until, null, '[]');
        } else {
          return from.isSameOrBefore(startOrEnd);
        }
      });

      const withinOverwrittenProjectBounds = this.overwrittenProjectStart && this.overwrittenProjectStart.isSameOrBefore(startOrEnd)
        && (!this.overwrittenProjectEnd || this.overwrittenProjectEnd.isSameOrAfter(startOrEnd));

      if (!withinProjectBounds && !withinOverwrittenProjectBounds) {
        error = {assignmentOutsideProjectBounds: true};
      }

      return error;
    } else {
      return null;
    }
  }

  validateAssignmentUntilAfterFrom(untilControl: FormControl, fromControl: FormControl) {
    const from = Date.parse(fromControl.value);
    const until = Date.parse(untilControl.value);
    let error = null;

    if (until < from) {
      error = {untilBeforeFrom: true};
    }

    return error;
  }

  validateDateNotWithinExistingAssignment(control: FormControl, newAssignment: AssignmentProjectEmployee) {
    const date = Date.parse(control.value);
    let error = null;

    let overlappingAssignment = this.getAssignmentsForEmployee(newAssignment.employee.id).find(assignment => {
      const assignmentFrom = Date.parse(assignment.effectiveFrom);
      const assignmentUntil = Date.parse(assignment.effectiveUntil);

      return newAssignment !== assignment && assignmentFrom <= date && date <= assignmentUntil;
    });

    if (overlappingAssignment) {
      error = {dateOverlapsWith: overlappingAssignment};
    }

    return error;
  }

  getAssignmentsForEmployee(employeeID): AssignmentProjectEmployee[] {
    return this._displayedAssignments.concat(this._hiddenAssignments).filter(assignment => assignment.employee.id === employeeID);
  }

  private revalidateAllAssignmentTimeIntervals() {
    this.assignmentDataSource.data.forEach((assignment: AssignmentProjectEmployeeDTO) => {
      assignment.effectiveFrom.updateValueAndValidity();
      assignment.effectiveUntil.updateValueAndValidity();
    });
  }

  onChange(event: MatCheckboxChange, row: AssignmentProjectEmployeeDTO) {
    this.selection.toggle(row);
    row.checked = event.checked;
    if (row.checked) {
      row.effectiveUntil.markAsTouched();
      row.effectiveFrom.markAsTouched();
    } else {
      row.effectiveUntil.markAsUntouched();
      row.effectiveFrom.markAsUntouched();
    }
    this.updateValidation.emit(row);
  }
}
