export class ParserStack {
  private static stack: Array<string>;

  public static initStack() {
    ParserStack.stack = new Array<string>();
  }
  public static pushValue (value: string) {
    ParserStack.stack.push(value);
  }

  public static popValue (): string {
    return ParserStack.stack.pop();
  }

  public static getTopIndex(): number {
    return ParserStack.stack.length - 1;
  }

  public static getTopValue(): string {
    return ParserStack.stack[this.getTopIndex()];
  }

  public static isStackEmpty(): boolean {
    return ParserStack.stack.length === 0;
  }

  public static getSize(): number {
    return ParserStack.stack.length;
  }

  public static countOperators(): number {
    return ParserStack.stack.filter(x => x === '&' || x === '|').length;
  }
}
