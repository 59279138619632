import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PaginatorSmartComponent} from "./components/paginator-smart.component";
import {TranslateModule} from "@ngx-translate/core";
import {MatPaginatorModule} from "@angular/material/paginator";

@NgModule({
  declarations: [PaginatorSmartComponent],
  imports: [
    CommonModule,
    TranslateModule,
    MatPaginatorModule
  ],
  exports: [PaginatorSmartComponent]
})
export class PaginatorSmartModule {
}
