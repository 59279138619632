import {Injectable} from '@angular/core';
import {HttpResponse} from '@angular/common/http';

/**
 * Request Cache for caching server responses
 */
@Injectable({
  providedIn: 'root'
})
export class RequestCache {
  private cache: Map<string, [Date, HttpResponse<any>]> = new Map<string, [Date, HttpResponse<any>]>();

 /**
  * Get a HttpResonse from the cache
  *
  * @param key the URI of the server resource
  * @returns {HttpResponse<any>} cached response
  */
  get<T = any>(key): HttpResponse<T> {
    const timeStampedResponse: [Date, HttpResponse<T>] = this.cache.get(key);
    const now = new Date();
    if (!timeStampedResponse) {
      return null;
    } else {
      const expires = timeStampedResponse[0];
      const httpResponse = timeStampedResponse[1];
      // If cached response is not expired then return it
      return (!expires || expires.getTime() >= now.getTime()) ? this.cloneResponse(httpResponse) : null;
    }
  }

 /**
  * Set a HttpResonse in the cache
  *
  * @param key the URI of the server resource
  * @param value the response that should be cached
  * @param ttl Time-to-live: time span in seconds for which  the response should be cached
  * */
  set(key, value: HttpResponse<any>, ttl = null): void {
    const clonedValue = this.cloneResponse(value);
    if (ttl) {
      const expires = new Date();
      expires.setSeconds(expires.getSeconds() + ttl);
      this.cache.set(key, [expires, clonedValue]);
    } else {
      this.cache.set(key, [null, clonedValue]);
    }
  }

  /**
   * Removes all elements from cache
   */
  clear(): void {
    this.cache.clear();
  }

  private cloneResponse<T>(response: HttpResponse<T>): HttpResponse<T> {
    return new HttpResponse<T>({
      body: response.body ? JSON.parse(JSON.stringify(response.body)) : null,
      headers: response.headers,
      status: response.status,
      statusText: response.statusText,
      url: response.url
    });
  }
}

