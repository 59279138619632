import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import moment from "moment";
import {Client} from "../../../core/config/Client";
import {DateValidator} from "../../../core/validation/DateValidator";

class ResourceRequestFormControlConfig {
  currentClient: Client;
  id = new FormControl('');
  origId = new FormControl('');
  requestTitle = new FormControl('', {validators: Validators.required});
  commitmentStart = new FormControl('', {validators: [Validators.required]});
  commitmentEnd = new FormControl('');
  probability = new FormControl('');
  optionOnFollowUpBusiness = new FormControl('');
  client = new FormControl('', {validators: Validators.required});
  currentProject = new FormControl(null);
  skillDescriptionOfCustomer = new FormControl('');
  internalSkillset = new FormControl([]);
  skillOperator = new FormControl('OR');
  desiredCandidate = new FormControl(null);
  requiredLanguage = new FormControl(null);
  staffingLocation = new FormControl(null);
  requiredEmployees = new FormControl(null, {validators: [Validators.required, Validators.min(1)]});
  notes = new FormControl('');
  assets = new FormControl([]);
  requestingUnit = new FormControl('', {validators: Validators.required});
  requestedBy = new FormControl('', {validators: Validators.required});
  requestedFrom = new FormControl(null, {validators: [employeeValidator]});
  compensation = new FormControl('');
  currency = new FormControl('');
  ratesDetails = new FormControl('');
  travelExpensePolicy = new FormControl('');
  scope = new FormControl('', {validators: [Validators.required, Validators.min(1), Validators.max(100)]});
  scopeComment = new FormControl('');
  location = new FormControl('');
  travelMode = new FormControl('');
  travelModeDetails = new FormControl('');
  businessUnits = new FormControl([]);
  divisions = new FormControl([]);
  responsibleDE = new FormControl(null, {validators: [Validators.required, employeeValidator]});
  responsibleRO = new FormControl(null, {validators: [employeeValidator]});
  requestReceipt = new FormControl(new Date(), {validators: [Validators.required, dateBeforeValidator]});
  answerUntil = new FormControl('', {validators: [Validators.required]});
  offered = new FormControl([]);
  requestStatus = new FormControl('AT_WORK');
  resubmissionDate = new FormControl('');
  statusComment = new FormControl('');
  staffingComments = new FormControl([]);
  finishingDate = new FormControl('');
  totalScope = new FormControl('');
  staffingTag = new FormControl('');

}

export class ResourceRequestForm {
  constructor(private _fb: FormBuilder) {
  }

  public resourceRequestForm(): FormGroup {

    return this._fb.group(new ResourceRequestFormControlConfig(), {validators: [DateValidator.fromToDate("commitmentStart", "commitmentEnd")]});
  }

  public updateResourceRequestForm(formGroup: FormGroup, currentClient: Client): void {
    if (currentClient === Client.MSG_SERVICES) {
      formGroup.controls['answerUntil'].setValidators([]);
      formGroup.controls['requestedFrom'].setValidators([employeeValidator]);
      formGroup.controls['requestingUnit'].setValidators([]);
      formGroup.controls['responsibleDE'].setValidators([employeeValidator]);
      formGroup.controls['responsibleRO'].setValidators([employeeValidator]);
      formGroup.controls['answerUntil'].updateValueAndValidity();
      formGroup.controls['requestedFrom'].updateValueAndValidity();
      formGroup.controls['requestingUnit'].updateValueAndValidity();
      formGroup.controls['responsibleDE'].updateValueAndValidity();
      formGroup.controls['responsibleRO'].updateValueAndValidity();
    }
  }
}

const dateBeforeValidator = (control: FormControl): { [s: string]: boolean } => {
  if (control.value) {
    const date = moment(control.value);
    const today = moment();
    if (date.isAfter(today)) {
      return {invalidDate: true};
    }
  }
  return null;
};

const employeeValidator = (control: FormControl): { [s: string]: boolean } => {
  // Object with Id expected (not just the typed name)
  if (typeof control.value === 'string') {
    return {invalidEmployee: true};
  }
  return null;
};
