import {FormControl} from "@angular/forms";

export function hasId(control: FormControl) {
  let error = null;

  if (!control.value || !control.value.id) {
    error = {hasNoId: true};
  }

  return error;
}
