import {Component, ElementRef, HostBinding, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router, RouterOutlet} from '@angular/router';
import {projectListAnimation} from './core/models/ProjectListAnimation';
import {TranslateService} from '@ngx-translate/core';
import {DateAdapter} from "@angular/material/core";
import {CustomizingService} from "./core/services/services/customizing.service";
import {ServerErrorHandler} from "./core/services/services/server-error-handler.service";
import Config from "./core/config/Config";
import {OverlayContainer} from "@angular/cdk/overlay";
import {ThemesService} from "./core/services/services/themes.service";
import {Title} from "@angular/platform-browser";
import {filter, map} from "rxjs/operators";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    projectListAnimation
  ]
})
export class AppComponent implements OnInit {

  @HostBinding('class') componentCssClass;

  constructor(private translate: TranslateService, serverErrorHandler: ServerErrorHandler,
              private adapter: DateAdapter<any>, private customizingService: CustomizingService,
              private overlayContainer: OverlayContainer, private themeService: ThemesService,
              private elementRef: ElementRef, private titleService: Title,
              private router: Router, private activatedRoute: ActivatedRoute) {

    adapter.setLocale(Config.defaultLanguage);
  }

  ngOnInit() {
    (this.elementRef.nativeElement as HTMLElement)?.removeAttribute("ng-version");
    this.themeService.getTheme().then(data => {
      this.onSetTheme(data + "-theme");
    });

    // Set document title
    const appTitle = this.titleService.getTitle();
    this.router
      .events.pipe(
      filter(routeEvent => routeEvent instanceof NavigationEnd),
      map(() => this.router.routerState.root),
      map(route => {
        let child = route.firstChild;
        let lastTitle = child.snapshot.data['title'];
        while (child.firstChild) {
          child = child.firstChild;
          if (child.snapshot.data['title']) {
            lastTitle = child.snapshot.data['title'];
          }
        }
        return lastTitle;
      })
    ).subscribe((titleKey: string) => {
      // Set default title if no title is provided
      if (!titleKey) {
        this.titleService.setTitle(appTitle);
      } else {

        this.translate.get(titleKey).subscribe(title => {
          if (title === titleKey) {
            this.titleService.setTitle(appTitle + " - " + this.translate.instant(titleKey));
          } else {
            this.titleService.setTitle(appTitle + " - " + title);
          }
        });
      }
    });
  }

  onSetTheme(theme): void {
    this.overlayContainer.getContainerElement().classList.add(theme);
    this.componentCssClass = theme;
  }

  /**
   * Animation
   */
  getState(outlet: RouterOutlet) {
    return outlet.activatedRouteData.state;
  }


}
