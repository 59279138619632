import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {TranslatableComponent} from '../../../core/models/TranslatableComponent';
import {TranslateService} from '@ngx-translate/core';
import {MissplannedCapacityEnum} from '../../../core/models/Enums/MissplannedCapacityEnum';
import {InfoDialogComponent} from '../../info-dialog/components/info-dialog.component';
import moment, {Moment} from 'moment';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from "@angular/router";
import {merge} from "rxjs";
import {CustomizingService} from "../../../core/services/services/customizing.service";
import {Preference, PreferencesService} from "../../../core/services/services/preferences.service";
import {CapacityBorders} from "./capacity-borders";
import {CapacityOverviewService} from "../../../core/services/services/capacityOverviewService";
import {MonthPickerComponent} from "../../month-picker/component/month-picker.component";

export interface CapacityParameters {
  startDate: Moment;
  viewType: MissplannedCapacityEnum;
  months: number;
  planned: boolean;
}

@Component({
  selector: 'app-employee-capacity-legend',
  templateUrl: './employee-capacity-legend.component.html',
  styleUrls: ['./employee-capacity-legend.component.scss']
})
export class EmployeeCapacityLegendComponent extends TranslatableComponent implements OnChanges, OnInit {
  @ViewChild('picker') datePicker: MonthPickerComponent;
  @Input()
  defaultCapacityParameters: CapacityParameters;
  capacityParameters: CapacityParameters;
  @Output()
  capacityParametersChanged = new EventEmitter<CapacityParameters>();

  @Input()
  hideSelectedEmployeeView: boolean;
  @Input()
  displayShowAlreadyPlanned: boolean;
  @Input()
  parent: string;

  @Input()
  defaultCapacityBorders: CapacityBorders;
  capacityBorders: CapacityBorders = new CapacityBorders();
  @Output()
  capacityBordersChanged = new EventEmitter<CapacityBorders>();

  missplannedCapacityEnum = MissplannedCapacityEnum;
  expected = MissplannedCapacityEnum.expected;

  constructor(private _translate: TranslateService, private dialog: MatDialog, private router: Router,
              private activatedRoute: ActivatedRoute, private customizingService: CustomizingService, private preferencesService: PreferencesService,
              private capacityOverviewService: CapacityOverviewService) {
    super(_translate, 'EmployeeCapacityLegendComponent');
    merge(this.capacityParametersChanged, this.capacityBordersChanged).subscribe(() => {
      this.putParametersInUrl();
    });
  }

  ngOnInit(): void {
    this.loadParametersFromUrl();
    if (!this.capacityParameters) {
      this.capacityParameters = this.defaultCapacityParameters;
      this.putParametersInUrl();
    }
    this.capacityBorders.safeApply(this.defaultCapacityBorders);
    this.customizingService.getDefaultCapacityBorders().then((cb) => {
      this.capacityBorders.safeApply(cb);
      this.putParametersInUrl();
    });
    this.preferencesService.get(Preference.CAPACITY_NUMBER_MONTHS, this.parent).then(value => {
      if (value) {
        this.capacityParameters.months = Number(value);
      }
    });
    this.capacityParameters.planned = false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.putParametersInUrl();
  }

  putParametersInUrl() {
    if (this.capacityBorders && this.capacityParameters) {
      this.router.navigate(
        [],
        {
          relativeTo: this.activatedRoute,
          queryParams: {
            capacityParameters: JSON.stringify(this.capacityParameters),
            capacityBorders: JSON.stringify(this.capacityBorders),
          },
          queryParamsHandling: 'merge'
        }).then();
    }
  }

  loadParametersFromUrl() {
    const params = this.activatedRoute.snapshot.queryParams;
    if (params.capacityBorders) {
      this.capacityBorders.apply(JSON.parse(params.capacityBorders));
      if (JSON.stringify(this.defaultCapacityBorders) !== JSON.stringify(this.capacityBorders)) {
        this.capacityBordersChanged.emit(this.capacityBorders);
      }
    }
    if (params.capacityParameters) {
      this.capacityParameters = JSON.parse(params.capacityParameters);
      this.capacityParameters.startDate = moment(this.capacityParameters.startDate);

      if (JSON.stringify(this.defaultCapacityParameters) !== JSON.stringify(this.capacityParameters)) {
        this.capacityParametersChanged.emit(this.capacityParameters);
      }
    }
    this.capacityOverviewService.getCapacityOverviewDefault().then(data => {
      this.capacityParameters.planned = data.plannedCapacity;
    });
  }

  openCalcVariantHelpDialog() {
    let instance: InfoDialogComponent = this.dialog.open(InfoDialogComponent, {
      maxHeight: '600px', maxWidth: '800px',
      data: [{first: this._lang('expected'), second: this._lang('calcVariantTooltips.expected')},
        {first: this._lang('all-projects'), second: this._lang('calcVariantTooltips.all-projects')},
        {first: this._lang('only-save'), second: this._lang('calcVariantTooltips.only-save')}]
    }).componentInstance;
    instance.dialogTitle = this._lang('calcVariants');
    instance.showCopyButton = false;
  }

  onChangeNumMonths(months: number): void {
    months = Number(months);
    if (months > 24) {
      months = 24;
    } else if (months < 1) {
      months = 1;
    }
    this.preferencesService.put(Preference.CAPACITY_NUMBER_MONTHS, months.toString(), this.parent).then();
    this.capacityParameters.months = months;
    this.capacityParametersChanged.emit(this.capacityParameters);
  }

  onStartDateChanged(startDate: Moment) {
    this.capacityParameters.startDate = startDate;
    this.capacityParametersChanged.emit(this.capacityParameters);
  }

  emitOptimalBorder() {
    if (this.capacityBorders.inPercentage) {
      this.capacityBorders.underloadBorderPercentage = Math.max(this.capacityBorders.underloadBorderPercentage, this.capacityBorders.optimalWorkloadBorderPercentage);
    } else {
      this.capacityBorders.underloadBorderPT = Math.max(this.capacityBorders.underloadBorderPT, this.capacityBorders.optimalWorkloadBorderPT);
    }
    this.emitCapacityBordersChange();
  }

  emitUnderloadBorder() {
    if (this.capacityBorders.inPercentage) {
      this.capacityBorders.optimalWorkloadBorderPercentage = Math.min(this.capacityBorders.underloadBorderPercentage, this.capacityBorders.optimalWorkloadBorderPercentage);
    } else {
      this.capacityBorders.optimalWorkloadBorderPT = Math.min(this.capacityBorders.underloadBorderPT, this.capacityBorders.optimalWorkloadBorderPT);
    }
    this.emitCapacityBordersChange();
  }

  emitCapacityBordersChange() {
    this.capacityBordersChanged.emit(this.capacityBorders);
  }

  setCapacityCalculation(showAlreadyPlanned: boolean) {
    this.capacityParameters.planned = showAlreadyPlanned;
    this.capacityParametersChanged.emit(this.capacityParameters);
  }
}
