import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, ValidatorFn, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {SourceCodeStorage} from "../../../core/models/Project";
import {SourceCodeStorageLocation} from "../../../core/models/Enums/SourceCodeStorageLocation";
import {TranslatableComponent} from "../../../core/models/TranslatableComponent";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-add-source-code-dialog',
  templateUrl: './add-source-code-dialog.component.html',
  styleUrls: ['./add-source-code-dialog.component.scss']
})
export class AddSourceCodeDialogComponent extends TranslatableComponent implements OnInit {
  sourceCodeStorage: SourceCodeStorage;
  isChecked: any;
  isNewProject: boolean;
  sourceCodeStorageLocation = SourceCodeStorageLocation;

  sourceCodeForm = new FormGroup({
      sourceCodeStorageLocation: new FormControl("", {
        validators: Validators.required
      }),
      gbpResponsibilityFields: new FormGroup({
          stackIntegratedStorage: new FormControl("",),
          msgHub: new FormControl(""),
          cloudSourceStorage: new FormControl(""),
          reasonForOtherCloudSource: new FormControl("",)
        },
      )
    },
  );


  constructor(private dialogRef: MatDialogRef<AddSourceCodeDialogComponent>,
              private _fb: FormBuilder, @Inject(MAT_DIALOG_DATA)
              public data: any, private t: TranslateService,
              private cd: ChangeDetectorRef,
  ) {
    super(t, 'ProjectDataComponent');
  }

  ngOnInit(): void {


    this.sourceCodeStorage = this.data.sourceCodeStorage;

    if (!this.data.project) {
      this.isNewProject = true;
    }
    this.cd.detectChanges();
    this.sourceCodeForm?.get('sourceCodeStorageLocation').setValue(this.data?.project.sourceCodeStorageLocation);
    this.sourceCodeForm?.get('gbpResponsibilityFields.stackIntegratedStorage').setValue(this.data?.project.stackIntegratedStorage);
    this.sourceCodeForm?.get('gbpResponsibilityFields.msgHub').setValue(this.data?.project.msgHub);
    this.sourceCodeForm?.get('gbpResponsibilityFields.cloudSourceStorage').setValue(this.data?.project.cloudSourceStorage);
    this.sourceCodeForm?.get('gbpResponsibilityFields.reasonForOtherCloudSource').setValue(this.data?.project.reasonForOtherCloudSource);
    this.onRadioButtonChanged();

  }

  atLeastOneOptionSelected(minRequired): ValidatorFn {
    return function validate(formGroup: FormGroup) {
      let checked = 0;

      Object.keys(formGroup.controls).forEach(key => {
        const control = formGroup.controls[key];

        if ((control.value)) {
          checked++;
        }

      });
      if (checked < minRequired) {
        return {noOptionsSelected: true};
      }

      return null;
    };
  }


  /**
   * Closes the dialog
   */
  selected: any;


  closeDialog() {
    this.dialogRef.close();
  }

  getResult() {
    return {

      sourceCodeStorageLocation: this.sourceCodeForm?.get('sourceCodeStorageLocation').value,
      stackIntegratedStorage: this.sourceCodeForm?.get('gbpResponsibilityFields').value.stackIntegratedStorage,
      msgHub: this.sourceCodeForm?.get('gbpResponsibilityFields').value.msgHub,
      cloudSourceStorage: this.sourceCodeForm?.get('gbpResponsibilityFields').value.cloudSourceStorage,
      reasonForOtherCloudSource: this.sourceCodeForm?.get('gbpResponsibilityFields').value.reasonForOtherCloudSource,
      sourceCodeForm: this.sourceCodeForm.dirty,

    }

  }

  onRadioButtonChanged() {
    let sourcecodeLocation = this.sourceCodeForm?.get('sourceCodeStorageLocation').value;
    if (sourcecodeLocation === "NO_SOURCE_CODE" || sourcecodeLocation === "CLIENT_RESPONSIBILITY") {
      this.sourceCodeForm.get("gbpResponsibilityFields").disable();
      this.sourceCodeForm.get("gbpResponsibilityFields").reset();
    }
    if (sourcecodeLocation == "GBP_RESPONSIBILITY") {
      this.sourceCodeForm?.get('gbpResponsibilityFields').setValidators(this.atLeastOneOptionSelected(1));
      this.sourceCodeForm.get("gbpResponsibilityFields").enable()
    } else {
      this.sourceCodeForm.get("gbpResponsibilityFields").disable();
    }
  }


}
