import {Injectable} from '@angular/core';
import {RequestCache} from '../services/services/request-cache.service';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable, of} from "rxjs";
import {tap} from "rxjs/operators";

/**
 * Time-to-live
 * @type {number} ttl in seconds
 */
const TTL = 600;

@Injectable()
export class CachingInterceptor implements HttpInterceptor {

  constructor(private cache: RequestCache) {
  }

  /**
   * Intercept any HTTP Request
   * @param {HttpRequest<any>} req a HTTP Request
   * @param {HttpHandler} next next HTTP handler in pipeline
   * @returns {Observable<HttpEvent<any>>} the httpEvent observable
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    switch (req.method) {
      case 'GET':
        const cachedResponse = this.cache.get(req.urlWithParams);
        return cachedResponse ? of(cachedResponse) : this.sendRequest(req, next);
      case 'POST':
      case 'PUT':
      case 'PATCH':
      case 'DELETE':
        this.cache.clear();
        return next.handle(req);
      default:
        return next.handle(req);
    }
  }

  /**
   * Forward HTTP Request to server if cache miss, then response write to cache
   * @param {HttpRequest<any>} req a HTTP request
   * @param {HttpHandler} next next HTTP handler in pipeline
   * @returns {Observable<HttpEvent<any>>} the httpEvent observable
   */
  sendRequest(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req)
      .pipe(
        tap(event => {
          if (event instanceof HttpResponse) {
            this.cache.set(req.urlWithParams, event, TTL);
          }
        }));
  }
}
