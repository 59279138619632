import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {MessageService} from "./message.service";
import {HttpBaseService} from "./http-base.service";
import {Employee} from "../../models/Employee";
import {CustomizingService} from "./customizing.service";

@Injectable({
  providedIn: 'root'
})
export class MailToService extends HttpBaseService {

  constructor(public http: HttpClient, public messageService: MessageService, public customizingService: CustomizingService) {
    super(http, messageService);
  }

  public openMailTo(to: string, subject: string, body: string, cc?: string, bcc?: string) {
    let mail = 'mailto:' + to;
    mail += "?subject=" + subject;
    if (cc) {
      mail += "&cc=" + cc;
    }
    if (bcc) {
      mail += "&bcc=" + bcc;
    }
    mail += "&body=" + body;
    mail.replace(" ", "%20");

    let a = document.createElement('a');
    a.href = mail;
    a.click();
  }

  public async requestPspForEmployees(employees: Employee[], from: Date, until: Date, psp: string) {

    let emailTo = await this.customizingService.getPspRequestMailbox();
    let subject = "P1-Elemente berechtigen";
    let greeting = "Hallo zusammen,";
    let body1 = `bitte folgende P1-Elemente für ${employees.length >= 2 ? "die" : "den"} Mitarbeiter `;
    let body2 = " berechtigen.";
    let fromText = "Von: ";
    let untilText = "Bis: ";
    let salutation = "Vielen Dank!";
    let lineBreak = "%0D%0A";


    let emailBody = greeting + lineBreak + body1;

    if (employees.length === 1) {
      emailBody += employees[0].firstName;
      emailBody += " ";
      emailBody += employees[0].lastName;
      emailBody += body2 + lineBreak + lineBreak;
      emailBody += psp + lineBreak + lineBreak;
      emailBody += fromText + new Date(from).toLocaleDateString() + lineBreak;
      emailBody += untilText + new Date(until).toLocaleDateString() + lineBreak + lineBreak;
    } else {
      emailBody += body2 + lineBreak + lineBreak;
      emailBody += psp;
      emailBody += lineBreak + lineBreak;
      for (let emp of employees) {
        emailBody += emp.firstName;
        emailBody += " ";
        emailBody += emp.lastName;
        emailBody += lineBreak;
        emailBody += fromText + new Date(from).toLocaleDateString() + lineBreak;
        emailBody += untilText + new Date(until).toLocaleDateString() + lineBreak + lineBreak;
      }
    }
    emailBody += salutation;
    this.openMailTo(emailTo, subject, emailBody);
  }

}
