import {Chips} from '../../../core/models/chips';
import {MessageService} from '../../../core/services/services/message.service';
import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {TranslatableComponent} from '../../../core/models/TranslatableComponent';
import {EmployeeCapacityService} from '../../../core/services/services/employeeCapacity.service';
import {
  EmployeeCapacityFilterData,
  EmployeeCapacityFilterViewModel,
  EmployeeCapacityList,
  EmployeeCapacityListElement,
  EmployeeCapacityViewData
} from './employee-capacity-list.interface';
import {AlertEnum} from '../../../core/models/Enums/AlertEnum';
import {forkJoin, Observable, Subject} from 'rxjs';
import {ColorService} from '../../../core/services/services/color.service';
import {Employee} from '../../../core/models/Employee';
import {EmployeeService} from '../../../core/services/services/employee.service';
import {MissplannedCapacityEnum} from '../../../core/models/Enums/MissplannedCapacityEnum';
import {EnumMapper} from '../../../core/services/services/enumMapper.service';
import {AuthenticationService} from '../../../core/services/services/authentication.service';
import Config from '../../../core/config/Config';
import {
  FilterDialogComponent,
  FilterDialogTemplate,
  FilterDialogType
} from '../../dialogs/filter-dialog/filter-dialog.component';
import {FilterParserComponent} from '../../filter-parser/components/filter-parser.component';
import {SelectionModel} from '@angular/cdk/collections';
import {AssignEmployeeDialogComponent} from '../../dialogs/assign-employee-dialog/assign-employee-dialog.component';
import {
  AssignMultipleEmployeesDialogComponent
} from '../../dialogs/assign-multiple-employees-dialog/assign-multiple-employees-dialog.component';
import {ComponentType} from '@angular/cdk/portal';
import {Revenue} from '../../../feature/employee-capacity-container/revenue/revenue';
import {ActivatedRoute, Router} from '@angular/router';
import moment, {Moment} from 'moment';
import {CustomizingService} from '../../../core/services/services/customizing.service';
import {SkillFilterService} from '../../../core/services/services/skill-filter.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {DataService} from '../../../core/services/services/data.service';
import {SkillDbChipGroupTemplate} from "../../filter-chip-list/components/filter-chip-list.interface";
import {AppEntity, AppEntityAccessType, Permission} from "../../../core/models/AppPermissions";
import {
  CapacityParameters,
  EmployeeCapacityLegendComponent
} from "../employee-capacity-legend/employee-capacity-legend.component";
import {
  EmployeeCapacityListAvailableEmployeesExport,
  EmployeeCapacityListCapacityExport,
  EmployeeCapacityListExport
} from "./employee-capacity-list.exports";
import {ProjectFileService} from "../../../core/services/services/projectFile.service";
import {distinctUntilChanged, switchMap} from "rxjs/operators";
import {AssignmentEmployeeProjectTimeSlice} from "../../../core/models/viewModels/AssignmentEmployeeProjectTimeSlice";
import {
  CapacityColorFilter,
  EmployeeCapacityColorSelectComponent
} from "../employee-capacity-color-select/employee-capacity-color-select.component";
import {CapacityBorders} from "../employee-capacity-legend/capacity-borders";
import {CapacityOverviewService} from "../../../core/services/services/capacityOverviewService";
import {ExportDialogComponent} from "../../export-dialog/export-dialog/export-dialog.component";
import {Client} from "../../../core/config/Client";
import {NotifyHrmDialogComponent} from "../../dialogs/notify-hrm-dialog/notify-hrm-dialog.component";
import {NotifyHrmOrigin} from "../../../core/models/mail/NotifyHrmOrigin";
import {ComponentCanDeactivate} from "../../../core/guards/pending-changes.guard";
import {ResourceRequestOverviewDTO} from "../../../feature/resource-request-list/models/resource-request";
import {
  ResourceRequestListService
} from "../../../feature/resource-request-list/services/resource-request-list.service";
import {FilterStoreService} from "../../../core/services/services/filter-store.service";
import MarketUnit from "../../../core/config/MarketUnit";
import {PaginatorSmartComponent} from "../../paginator-smart/components/paginator-smart.component";

export interface EmployeeProjectCapacityParameters {
  startDate: Moment;
  months: number;
}

@Component({
  selector: 'app-employee-capacity-list',
  templateUrl: './employee-capacity-list.component.html',
  styleUrls: ['./employee-capacity-list.component.scss']
})
export class EmployeeCapacityListComponent extends TranslatableComponent implements OnInit, ComponentCanDeactivate {

  @ViewChild(EmployeeCapacityLegendComponent) set capaLegend(capacityLegend: EmployeeCapacityLegendComponent) {
    this.capacityLegend = capacityLegend;
  }

  @ViewChild('colorSelectComponent') colorSelectComponent: EmployeeCapacityColorSelectComponent;
  @ViewChild(FilterParserComponent) filterParser: FilterParserComponent;
  @ViewChild(PaginatorSmartComponent, {static: true}) paginator: PaginatorSmartComponent;
  @Input() revenueExportData: Revenue[];
  @Output() dataLoaded = new EventEmitter<EmployeeCapacityListElement[]>();
  @Output() tableUpdated = new EventEmitter<EmployeeCapacityListElement[]>();
  @Output() capacityParametersChanged = new EventEmitter<CapacityParameters>();
  @Input() isFirstMonthStronglyUnderplanned = false;
  @Input() getCachedValues = true;
  @Input() fromResourceRequest = false;
  @Input() parentForPref;

  capacityLegend: EmployeeCapacityLegendComponent;
  capacityParameters: CapacityParameters = {
    startDate: moment(),
    months: 5,
    viewType: MissplannedCapacityEnum.expected,
    planned: false
  };
  resourceRequestCapacityParameters: CapacityParameters;
  employeeProjectCapacyParameters: EmployeeProjectCapacityParameters = {
    startDate: moment(),
    months: 6
  };
  capacityBorders: CapacityBorders;
  months = [];
  selectedColors: { [month: string]: CapacityColorFilter } = {};
  dataSource: MatTableDataSource<EmployeeCapacityListElement> = new MatTableDataSource();
  displayedColumns: string[];
  tableLoaded = false;

  additionalMonth = [];

  capacityOverviewSearchRelevantEnabled = false;

  selection = new SelectionModel<EmployeeCapacityListElement>(true, []);
  showLegend = true;

  filterViewModel: EmployeeCapacityFilterViewModel;
  filterColor = [
    'color-overplanned',
    'color-optimal-planned',
    'color-underplanned',
    'color-strongly-underplanned'
  ];
  additionalData: MatTableDataSource<AssignmentEmployeeProjectTimeSlice> = new MatTableDataSource();
  resourceRequestDataSource: MatTableDataSource<ResourceRequestOverviewDTO> = new MatTableDataSource();
  columnsToDisplay: string[] = ['project', 'probability', 'capacity', 'effectiveFrom', 'effectiveUntil'];
  columnsToDisplayWithMonths: string[] = this.columnsToDisplay;
  resourceRequestColumnsToDisplay: string[] = ['origId', 'requestTitle', 'client', 'commitmentStart', 'commitmentEnd', 'requestStatus', 'scope'];
  filterPredicate;
  searchTextArray: string[];
  private hoverSource = new Subject<Employee>();
  private hoverSourceResourceRequests = new Subject<Employee>();
  isClientnameDisplayed: boolean;
  private sort: MatSort;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.dataSource.sort = this.sort;
  }

  skillDbHoveredEmployeeId: number;
  chips: Chips;

  private currentClient: Client;
  defaultPageSize: number;

  plainSearchText: string;
  dispoFlag = "dispoFlag";


  constructor(private _messageService: MessageService,
              private _translate: TranslateService,
              private _colorService: ColorService,
              private activateRoute: ActivatedRoute,
              private _router: Router,
              private employeeCapacityService: EmployeeCapacityService,
              private _employeeService: EmployeeService,
              public dialog: MatDialog,
              private authService: AuthenticationService,
              private projectFileService: ProjectFileService,
              private _skillFilterService: SkillFilterService,
              public loginService: AuthenticationService,
              private customizingService: CustomizingService,
              public _dataService: DataService,
              private _cdr: ChangeDetectorRef,
              private capacityOverviewService: CapacityOverviewService,
              private _resourceRequestService: ResourceRequestListService,
              private filterStoreService: FilterStoreService) {
    super(_translate, 'EmployeeCapacityListComponent');

    const filterData = new EmployeeCapacityFilterData();
    const viewData = new EmployeeCapacityViewData(this._employeeService, this.customizingService, this._translate);

    this.filterViewModel = {filterData, viewData};
  }

  async ngOnInit() {
    if (this.authService.isCustomerManager()) {
      this.isFirstMonthStronglyUnderplanned = true;
    }
    this.currentClient = await this.customizingService.getCurrentClient();
    this.filterViewModel.filterData.hideExternEmployees = await this.customizingService.getCapacityOverViewCheckBoxHideExternalDefault();

    const filterElement = await this.filterStoreService.getStandardFilter(FilterDialogType.EMPLOYEE_CAPACITY).toPromise();
    this.capacityOverviewSearchRelevantEnabled = await this.customizingService.isCapacityOverviewSearchRelevantEnabled();
    const knownQueryParams = Object.keys(this.filterViewModel.filterData);
    if (this.getCachedValues && Object.keys(this.activateRoute.snapshot.queryParams).filter(param => knownQueryParams.includes(param)).length > 0) {
      EnumMapper.requestParametersToCapacityFilterModel(this.filterViewModel.filterData, this.activateRoute.snapshot.queryParams, this.currentClient);
      await this.applyFilter();
    } else if (filterElement === null || filterElement === undefined) {
      const val = await this.authService.getApplicationUser();
      if (val.employee != null && val.employee.businessUnit != null) {
        this.filterViewModel.filterData.businessUnit = [{
          value: val.employee.businessUnit,
          viewValue: this.translate.instant(MarketUnit['MarketUnit'][val.employee.businessUnit])
        }];
      }
      this.filterViewModel.filterData.capaRelevance.push({
        value: "ONLY_RELEVANT",
        viewValue: Config.services.employees.capaRelevance.ONLY_RELEVANT
      });
      this.filterViewModel.filterData.showPastAndCurrentEmployee = false;
    } else if (!this.fromResourceRequest) {
      const standardFilterData = this.filterStoreService.parseFilterValue(filterElement.filterValue);
      this.filterViewModel.filterData = standardFilterData;
      if (standardFilterData.capaRelevance) {
        const clonedCapaRelevance = JSON.parse(JSON.stringify(standardFilterData.capaRelevance));
        this.filterViewModel.filterData.capaRelevance = [clonedCapaRelevance];
      }
      await this.applyFilter();
    }
    this.capacityBorders = await this.customizingService.getDefaultCapacityBorders();

    const standardOptions = ['humanResourcesManager', 'projectLeaderNames', 'divisionManagerNames'];
    const dropDownOptions = ['businessUnit', 'jobVariant', 'jobLevel', 'location', 'country', 'division', 'travelWillingnessCombined', 'tag', 'capaRelevance'];
    this.chips = new Chips(standardOptions, dropDownOptions, this.filterViewModel, this._translate,
      [], [new SkillDbChipGroupTemplate(this.filterViewModel, ['skillDbFilterData'], this.translate)]);

    const hasConfidentialFieldPermission = await this.authService.hasPermission(Permission.TRUE, AppEntityAccessType.READ, AppEntity.RESOURCE_REQUEST_CONFIDENTIAL_FIELDS);
    if (hasConfidentialFieldPermission) {
      this.resourceRequestColumnsToDisplay.splice(5, 0, "probability");
    }

    this.customizeFilter();
    this.dataSource.paginator = this.paginator.basePaginator;
    this.dataSource.sortingDataAccessor = (data, sortHeaderId) => {
      switch (sortHeaderId) {
        case 'annotation':
          return (data.misplannedAnnotationControl.value || '').toLocaleLowerCase();
        case 'skills':
          return (data.skillsControl.value || '').toLocaleLowerCase();
        case 'sum':
          return this.capacityBorders.inPercentage ? data['capacitySumInPercent'] : data['capacitySumInPT'];
        default:
          return typeof data[sortHeaderId] === 'string' ? data[sortHeaderId].toLocaleLowerCase() : data[sortHeaderId];
      }
    }
    this.chips.generateChips();
    this.onResize();

    this.isClientnameDisplayed = await this.customizingService.isClientnameDisplayed();
    this.registerHover();

    this.defaultPageSize = (await this.capacityOverviewService.getCapacityOverviewDefault()).employeesPerPage;
  }

  updateDispoFlag(e: Employee) {
    this.dataSource.data.forEach(row => {
      if (row.employee.id == e.id) {
        row.dispoFlag = !row.dispoFlag;
        row.employee.dispoFlag = !row.employee.dispoFlag;
      }
    });
    this._employeeService.updateEmployeeDispoFlag(e.id).subscribe();
  }

  async setSkillDbHoveredEmployee(employee: Employee) {
    this.skillDbHoveredEmployeeId = employee.id;
  }

  customizeFilter() {
    this.dataSource.filterPredicate =
      (data: EmployeeCapacityListElement, filters: string) => this.colorFilterFun(data) && (!this.filterPredicate || this.filterPredicate(data));
  }

  colorFilterFun(data) {
    return data.isVisibleByColor;
  }

  async applyFilter() {
    this.dataSource.filter = ' ';
    if (this.filterParser) {
      await this.filterParser.parseFilterData(this.filterViewModel.filterData);
      this.dataLoaded.emit(this.dataSource.filteredData);
      this.tableUpdated.emit(this.dataSource.filteredData);
    }
  }

  /**
   * Calculating underloadBorder and optimalWorkload into pt if not inPercentage.
   *
   * @param capacity is in percent if {inPercentage} otherwise in pt.
   */
  applyColorRules(capacity: number): string {
    return this._colorService.applyColorRules(capacity, this.capacityBorders);
  }

  filterByColor() {
    this.dataSource.data.forEach(row => {
      row.isVisibleByColor = this.visibleByColor(row);
    });
    this.applyFilter().then();
  }

  visibleByColor(row: EmployeeCapacityListElement): boolean {
    row.freeCapacityInPersonDays = {};
    if (this.capacityParameters.planned) {
      for (let numbersKey in row.employeeCapacityInPersonDays) {
        row.freeCapacityInPersonDays[numbersKey] = row.availableCapacity[numbersKey] - row.employeeCapacityInPersonDays[numbersKey];
      }
    } else {
      for (let numbersKey in row.employeeCapacityInPersonDays) {
        row.freeCapacityInPersonDays[numbersKey] = row.employeeCapacityInPersonDays[numbersKey];
      }
    }
    return this._colorService.allMonthsVisibleByColor(this.months, this.selectedColors,
      row.employeeCapacityInPercent, row.freeCapacityInPersonDays, this.capacityBorders);
  }

  onColorSelectionChanged(colorFilter: CapacityColorFilter, month: string) {
    this.selectedColors[month] = colorFilter;
    setTimeout(() => {
      this.filterByColor();
    });
  }

  updateUrlParameters() {
    let paramMap = EnumMapper.filterModelToRequestParameters(this.filterViewModel.filterData);
    this._router.navigate([this.getUrlWithoutParams()], {queryParams: paramMap}).then();
  }

  getUrlWithoutParams() {
    const urlTree = this._router.parseUrl(this._router.url);
    urlTree.queryParams = {};
    return urlTree.toString();
  }

  /**
   * Remove the comments.
   */
  undo() {
    this.dataSource.data.forEach(row => {
      if (row.skillsControl.dirty) {
        row.skillsControl.reset(row.skills);
        row.skillsControl.markAsPristine();
      }
      if (row.misplannedAnnotationControl.dirty) {
        row.misplannedAnnotationControl.reset(row.employee.misplannedAnnotation);
        row.misplannedAnnotationControl.markAsPristine();
      }
    });
    this.applyFilter();
  }

  sendPatchRequests(requests: Observable<any>[], newEmployeeList: EmployeeCapacityListElement[]) {
    forkJoin(requests).subscribe(() => {
      this._messageService.add(this._translate.instant('mep.components.toasts.is-saved'), AlertEnum.success);

      newEmployeeList.forEach((row: EmployeeCapacityListElement) => {
        row.employee.misplannedAnnotation = row.misplannedAnnotationControl.value;
        row.skills = row.skillsControl.value;
      });
      this.markAllAsPristine();
    }, (error) => {
      if (error.status === 412) {
        this._employeeService.getEmployee(error.url, this.currentClient).then((e: Employee) => {
          const oldEmployeeData: EmployeeCapacityListElement = this.dataSource.data.find(row => row.employee.id === e.id);

          if (oldEmployeeData) {
            oldEmployeeData.employee = e;
            oldEmployeeData.skills = e.skills;

            oldEmployeeData.skillsControl.setValue(e.skills);
            oldEmployeeData.misplannedAnnotationControl.setValue(e.misplannedAnnotation);
          }
        });
        this._messageService.add(this.translate.instant('mep.components.employee-list.errors.edited-by-other'), AlertEnum.danger, 0);
      } else {
        this._messageService.add(this.translate.instant('mep.components.employee-capacity-list.errors.save_failed'), AlertEnum.danger, 0);
      }
    });
  }

  saveAttributes() {
    const requests = [];
    const newEmployeeList = [];

    this.dataSource.data.forEach((row: EmployeeCapacityListElement) => {
      if (row.misplannedAnnotationControl.dirty || row.skillsControl.dirty) {
        let patchObject: any = {eVersion: row.employee.eVersion};

        if (row.misplannedAnnotationControl.dirty) {
          patchObject.misplannedAnnotation = row.misplannedAnnotationControl.value;
        }

        if (row.skillsControl.dirty) {
          patchObject.skills = row.skillsControl.value;
        }
        newEmployeeList.push(row);
        requests.push(this.employeeCapacityService.patch<any>(row.employee._links.self.href, patchObject));
      }
    });
    this.sendPatchRequests(requests, newEmployeeList);
  }

  markAllAsPristine() {
    this.dataSource.data.forEach(row => {
      row.skillsControl.markAsPristine();
      row.misplannedAnnotationControl.markAsPristine();
    });
  }

  areAllControlsPristine(): boolean {
    return this.dataSource.data.every(row => row.skillsControl.pristine && row.misplannedAnnotationControl.pristine);
  }

  async openFileExportDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = [
      new EmployeeCapacityListExport(this.dataSource.filteredData, this.projectFileService, this.capacityParameters.startDate, this.capacityParameters.planned),
      new EmployeeCapacityListAvailableEmployeesExport(this.projectFileService, this.capacityBorders.underloadBorderPercentage, this._messageService, this.translate),
      new EmployeeCapacityListCapacityExport(this._messageService, this.translate, this.revenueExportData),
    ];
    this.dialog.open(ExportDialogComponent, dialogConfig);
  }

  openFilterDialog() {
    const singleEnumFilterAttributes = this.capacityOverviewSearchRelevantEnabled ? ['capaRelevance'] : [];
    let enumFilterAttribute = ['businessUnit', 'jobVariant', 'jobLevel', 'location', 'country', 'division', 'travelWillingnessCombined', 'tag'];
    const filterDialogTemplate: FilterDialogTemplate = {
      initialFilterData: this.filterViewModel.filterData,
      filterType: FilterDialogType.EMPLOYEE_CAPACITY,
      normalFilterAttributes: [],
      enumFilterAttributes: enumFilterAttribute,
      singleEnumFilterAttributes,
      employeeFilterAttributes: ['humanResourcesManager', 'projectLeaderNames', 'divisionManagerNames'],
      viewData: this.filterViewModel.viewData,
      skillFilterAttribute: 'skillDbFilterData',
      excludeCheckbox: ['hideExternEmployees', 'showPastAndCurrentEmployee', 'dispoFlag'],
      excludeCheckboxName: [
        this.translate.instant('mep.components.general.filter.hide'),
        this.translate.instant('mep.components.employee-list.show-past-employee'),
        this.translate.instant('mep.components.employee-list.dispoFlag')
      ],
      showInvisibleSkills: false
    };

    const dialogRef = this.dialog.open(FilterDialogComponent, {
      data: filterDialogTemplate,
      panelClass: 'skill-tooltip-container'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        this.filterViewModel.filterData = result;
        if (!Array.isArray(result.capaRelevance)) {
          if (!result.capaRelevance) {
            result.capaRelevance = [{value: 'ONLY_RELEVANT', viewValue: 'mep.enum.capaRelevance.ONLY_RELEVANT'}];
          } else {
            result.capaRelevance = [result.capaRelevance];
          }
        }
        if (!result.tag) {
          result.tag = [];
        }
        this.onFilterViewModelChanged();
      }
    });
  }

  onFilterViewModelChanged() {
    this.updateUrlParameters();
    this.applyFilter();
    this.changeView(this.capacityParameters);
    this.chips.generateChips();
  }

  getMissplannedCapacitySum(month: string) {
    return this.dataSource.filteredData.map(r => r.employeeCapacityInPersonDays['' + month])
      .reduce((acc, value) => acc + value, 0).toFixed(1);
  }

  getTotalMissplannedCapacitySum() {
    return this.dataSource.filteredData.map(r => r['capacitySumInPT'])
      .reduce((acc, value) => acc + value, 0).toFixed(1);
  }

  /**
   * Change the displayed data in the table
   */
  changeView(capacityParameters: CapacityParameters): void {
    this.capacityParametersChanged.emit(capacityParameters);
    this.capacityParameters = capacityParameters;
    this.employeeProjectCapacyParameters.startDate = capacityParameters.startDate;
    this.tableLoaded = false;
    let businessUnits: string[] = [];

    if (this.filterViewModel.filterData.businessUnit != null) {
      this.filterViewModel.filterData.businessUnit.forEach(value => businessUnits.push(value.value));
    }

    this.employeeCapacityService.getEmployeeCapacities(capacityParameters, businessUnits, this.filterViewModel.filterData.showPastAndCurrentEmployee)
      .then((result: EmployeeCapacityList) => {
        this.dataSource.data = result.employeeCapacityListElement;
        if (this.dataSource.data != [] && this.dataSource.data[0]) {
          this.months = Object.keys(this.dataSource.data[0].employeeCapacityInPersonDays);
        }

        this.displayedColumns = [];
        this.buildDisplayedColumns().then(columns => this.displayedColumns = columns);

        this.customizeFilter();
        this.filterByColor();

        this.tableUpdated.emit(this.dataSource.filteredData);

        // Load additional data (required for filtering) in separate request
        const requests = [this._skillFilterService.setAllSkillDbEntries(this.dataSource.data, false)];

        Promise.all(requests).then(() => {
          this.applyFilter().then();
          this.tableLoaded = true;
          this._cdr.detectChanges(); // Fixes an ExpressionChangedAfterItHasBeenCheckedError
        });
      }, () => {
        this.tableLoaded = true;
      });
  }

  async buildDisplayedColumns(): Promise<string[]> {
    const canReadEmployee: boolean = await this.authService.hasPermission(Permission.TRUE, AppEntityAccessType.READ, AppEntity.EMPLOYEE);
    // SW_MEP-2973: Commented out because Stefan was not sure if we need that in the future.
    // const canReadActionPlan: boolean = await this.customizing.isActionPlanFunctionalityEnabled()
    //   && await this.authService.hasAtLeastPermission(Permission.TRUE, AppEntityAccessType.READ, AppEntity.ACTION_PLAN);
    const canReadResourceRequests: boolean = await this.customizingService.isResourceRequestEnabled()
      && await this.authService.hasPermission(Permission.TRUE, AppEntityAccessType.READ, AppEntity.RESOURCE_REQUEST);
    const canUpdateAnnotation: boolean = await this.authService.hasPermission(Permission.TRUE, AppEntityAccessType.UPDATE, AppEntity.CAPACITY_ANNOTATION);

    return [].concat(canReadEmployee ? ['select', 'employeeName'] : [])
      .concat(['location', 'skills', 'division'])
      .concat(!canReadEmployee ? ['humanResourcesManager', 'notifyHR'] : [])
      .concat(['travelWillingnessCombined'])
      .concat(this.months)
      // .concat(canReadActionPlan ? ['actionPlan'] : [])
      .concat(canReadResourceRequests ? ['resourceRequestCount'] : [])
      .concat(['dispoFlag'])
      .concat(canUpdateAnnotation ? ['annotation'] : [])
      .concat(['sum']);
  }

  sendMailToHRM(employeeId: number): void {
    NotifyHrmDialogComponent.open(this.dialog,
      message => this._employeeService.sendMailToEmployeesHRM(employeeId, message, NotifyHrmOrigin.RESOURCE_SEARCH));
  }

  trackByFunction(index, item) {
    return item ? index : null;
  }

  nextHover(e: Employee) {
    this.hoverSource.next(e);
  }

  nextHoverResourceRequests(e: Employee) {
    this.hoverSourceResourceRequests.next(e);
  }

  registerHover() {
    this.hoverSource.pipe(
      distinctUntilChanged(),
      switchMap((employee: Employee) => {
        this.additionalData.data = null;
        return this._employeeService.getRecentAssignmentEmployeeProjectCapacityTimeSlices(employee.id, this.employeeProjectCapacyParameters);
      })).subscribe((assignments: AssignmentEmployeeProjectTimeSlice[]) => {
      setTimeout(() => {
        this.additionalData.data = assignments;
        if (this.additionalData.data.length !== 0) {
          this.additionalMonth = Object.keys(this.additionalData.data[0].capacityInPersonDays);
          if (this.columnsToDisplay.length === this.columnsToDisplayWithMonths.length) {
            this.columnsToDisplayWithMonths = this.columnsToDisplay.concat(this.additionalMonth).concat('priority');
          }
        }
      });
    }, () => {
      this._messageService.add(this.translate.instant('mep.components.employee-list.errors.init-projects'), AlertEnum.danger);
    });

    this.hoverSourceResourceRequests.pipe(
      distinctUntilChanged(),
      switchMap((employee: Employee) => {
        this.resourceRequestDataSource.data = null;
        return this._resourceRequestService.findAllByEmployeeId(employee.id);
      })).subscribe((resourceRequests: ResourceRequestOverviewDTO[]) => {
      setTimeout(() => {
        this.resourceRequestDataSource.data = resourceRequests;
      });
    });
  }

  filterDataAccessor(element: EmployeeCapacityListElement, filterAttribute) {
    switch (filterAttribute) {
      case 'skills':
        return element.skills;
      case 'skillDbSkillNames':
        return element.employee.skillDbSkillNames;
      case 'misplannedAnnotation':
        return element.misplannedAnnotationControl.value;
      case 'humanResourcesManager':
        return element.employee.humanResourcesManager;
      case 'businessUnit':
        return Config.services.employees.businessUnit[element.employee.businessUnit];
      case 'tag':
        return element.employee.tag;
      case 'division':
        return element.division ? element.division.replace('& ', '') : '';
      case 'lang':
        if (element.employee === undefined || element.employee === null) {
          return '';
        }
        return element.employee.languages ? element.employee.languages.replace('N:', 'Native:')
          .replace('E:', 'English:')
          .replace('G:', 'German:')
          .replace('R:', 'Romanian:')
          .replace('O:', 'Others:') : '';
      case 'jobVariant':
        return Config.services.employees.jobVariant[element.employee.jobVariant];
      case 'capaRelevance':
        return EnumMapper.mapBoolToRelevance(element.employee.notRelevantForCapa);
      case 'jobLevel':
        return Config.services.employees.jobLevel[element.employee.jobLevel];
      case 'dispoFlag':
        return element.dispoFlag.toString();
      default:
        return element[filterAttribute];
    }
  }

  selectAllEmployeesToggle() {
    this.selection.selected.length === this.dataSource.filteredData.length ? this.selection.clear() :
      this.dataSource.filteredData.forEach((row: EmployeeCapacityListElement) => this.selection.select(row));
  }

  /**
   * Opens dialog to assign selected employees to a project
   */
  assignProject() {
    let employeeArray = this.selection.selected.map(e => e.employee);
    let comp: ComponentType<any> = employeeArray.length === 1 ? AssignEmployeeDialogComponent : AssignMultipleEmployeesDialogComponent;

    this.dialog.open(comp, {
      disableClose: true,
      width: '1000px',
      data: {
        disableProjects: false,
        employees: employeeArray
      },
    });
  }

  noEmployeesChecked() {
    return this.selection.selected.length === 0;
  }

  updatefilterPredicate(filterPredicate) {
    this.dataSource.filterPredicate = (data: EmployeeCapacityListElement) => this.colorFilterFun(data) && filterPredicate(data)
      && (!this.filterViewModel.filterData.hideExternEmployees || this._employeeService.isNotExternal(data.employee))
      && (this.filterViewModel.filterData.showPastAndCurrentEmployee || this._employeeService.isNotPastEmployee(data.employee))
      && (!this.filterViewModel.filterData.dispoFlag || this._employeeService.isNotDispoFlagFalse(data.dispoFlag))
      && this._skillFilterService.hasSkills(data.employee.skillDbRows, this.filterViewModel.filterData.skillDbFilterData);
    this.dataSource.filter = 'filter';
    this.tableUpdated.emit(this.dataSource.filteredData);
  }

  onResize() {
    this.showLegend = window.innerHeight >= 570;
  }

  capacityBorderChanged(cb: CapacityBorders) {
    this.capacityBorders.apply(cb);
    this.filterByColor();
  }

  getVacationOfEmployee(month: any) {
    return this.additionalData.data[0].vacation[month];
  }

  displayPriority(priority: number) {
    return priority === null ? '-' : priority;
  }

  transform(month: string): string {
    return month.toLowerCase().charAt(0).toUpperCase() + month.slice(1).replace(" ", "").replace('20', '');
  }

  disableMonthColorSelect(month: string): boolean {
    return this.authService.isCustomerManager() && this.months && month === this.months[0];
  }

  canDeactivate(): boolean {
    for (let row of this.dataSource.data) {
      if (row.skillsControl.dirty || row.misplannedAnnotationControl.dirty) {
        return false;
      }
    }
    return true;
  }
}
