import {Component, ElementRef, Input} from "@angular/core";
import {BdcWalkService} from 'bdc-walkthrough';
import {TranslatableComponent} from "../../core/models/TranslatableComponent";
import {TranslateService} from "@ngx-translate/core";
import moment from "moment";
import {isVisible} from "../../core/util/observers";
import {Observable} from "rxjs";


@Component({
  selector: 'app-news-notification',
  templateUrl: './news-notification.component.html'
})

export class NewsNotificationComponent extends TranslatableComponent {
  @Input() messageKey: string;
  @Input() insertDate: string;
  @Input() horizontal = true;
  readonly visible: Observable<boolean>;

  constructor(private bdcWalkService: BdcWalkService,
              private element: ElementRef<HTMLElement>,
              public translate: TranslateService) {
    super(translate, 'NewsNotificationComponent');

    this.visible = isVisible(element.nativeElement);
  }

  hasExpired(): boolean {
    const parsedInsertDate = moment(this.insertDate, "DD-MM-YYYY");
    return (moment() > parsedInsertDate.add(30, 'days'));
  }

  reset() {
    this.bdcWalkService.reset(this.messageKey);
  }
}
