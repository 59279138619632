import {Component} from "@angular/core";
import {ResourceRequestListService} from "../../../feature/resource-request-list/services/resource-request-list.service";
import {AbstractStringAutocomplete} from "./abstract-string-autocomplete";

@Component({
  selector: 'app-client-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss']
})
export class ClientAutocompleteComponent extends AbstractStringAutocomplete {

  constructor(private _resourceRequestListService: ResourceRequestListService) {
    super();
  }

  async fetchElements(): Promise<string[]> {
    return await this._resourceRequestListService.getResourceRequestClients().toPromise();
  }
}
