import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {TranslatableComponent} from "../../../core/models/TranslatableComponent";
import {TranslateService} from "@ngx-translate/core";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {EmployeeFile} from "../../../core/models/EmployeeFile";
import {EmployeeFileType} from "../../../core/models/Enums/EmployeeFileType";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {EmployeeFileForm} from "./employee-file-form";
import {AlertEnum} from "../../../core/models/Enums/AlertEnum";
import {HttpEvent, HttpEventType} from "@angular/common/http";
import {DisclaimerDialogComponent} from "../../../feature/employee-operations/disclaimer-dialog/disclaimer-dialog.component";
import {Employee} from "../../../core/models/Employee";
import {EmployeeFileService} from "../../../core/services/services/employeeFile.service";
import {MessageService} from "../../../core/services/services/message.service";
import {AuthenticationService} from "../../../core/services/services/authentication.service";
import {EmployeeService} from "../../../core/services/services/employee.service";
import {CustomizingService} from "../../../core/services/services/customizing.service";
import {FileDragDropComponent} from "../../file-input/components/file-drag-drop/file-drag-drop.component";

@Component({
  selector: 'app-files-dialog',
  templateUrl: './files-dialog.component.html',
  styleUrls: ['./files-dialog.component.scss']
})
export class FilesDialogComponent extends TranslatableComponent implements OnInit {

  @ViewChild('pdfViewerOnDemand') pdfViewerOnDemand;
  today = new Date();
  username: string;

  employeeFiles: EmployeeFile[];

  readonly employeeFileTypes = Object.keys(EmployeeFileType);

  employeeFileFormGroup: FormGroup;
  selectedFile: File;
  link: FormControl;

  loading = false;

  uploadProgress = 0;
  declarationOfConsentCheckboxDisabled: boolean;
  declarationOfConsentCheckboxEnabled: boolean;
  employeeFileUploadEnabled: boolean;

  displayedColumns = ['name', 'type', 'comment', 'createdBy', 'createdAt', 'delete', 'preview'];

  constructor(protected translate: TranslateService, private dialogRef: MatDialogRef<FilesDialogComponent>,
              private employeeFileService: EmployeeFileService, fb: FormBuilder,
              private messageService: MessageService, public dialog: MatDialog,
              private authService: AuthenticationService, @Inject(MAT_DIALOG_DATA) private employee: Employee,
              private employeeService: EmployeeService, private customizingService: CustomizingService) {
    super(translate, 'FilesDialogComponent');
    this.employeeFileFormGroup = fb.group(new EmployeeFileForm());
    this.link = new FormControl('');
  }

  async ngOnInit(): Promise<void> {
    this.employeeFileUploadEnabled = await this.customizingService.isEmployeeFileUploadEnabled();
    this.authService.getApplicationUser().then(user => {
      this.username = user.username;
      this.declarationOfConsentCheckboxDisabled = !this.authService.isMepAdmin() && this.employee.humanResourcesManager !== user.employee.firstName + " " + user.employee.lastName;
    });

    this.customizingService.isUploadcenterAgreementEnabled().then(result => this.declarationOfConsentCheckboxEnabled = result);

    this.loadEmployeeFiles(this.employeeFileUploadEnabled);
  }

  close() {
    this.dialogRef.close();
  }

  onFileChosen(file: File) {
    if (this.fileSizeOk(file)) {
      this.selectedFile = file;
    } else {
      this.selectedFile = null;
      const message = this._lang('failed-file-requirements-size');
      this.messageService.add(message, AlertEnum.warning);
    }
  }

  saveLink() {
    this.loading = true;
    this.employeeFileService.addEmployeeFile(this.employee.id, this.employeeFileFormGroup.getRawValue(), null, this.link.value).subscribe((event: HttpEvent<any>) => {
      if (event.type === HttpEventType.UploadProgress) {
        this.uploadProgress = Math.round(event.loaded / event.total * 100);
      } else if (event.type === HttpEventType.Response) {
        const message = this._lang('link-saved');
        this.messageService.add(message, AlertEnum.success);
        this.employeeFileFormGroup.reset();
        this.link.reset();
        this.loadEmployeeFiles(this.employeeFileUploadEnabled);
      }
    }).add(() => this.loading = false);
  }

  private fileSizeOk(file: File) {
    const sizeRestriction = 20;
    return (file.size / 1024 / 1024) < sizeRestriction;
  }

  private loadEmployeeFiles(isLinkUpload: boolean = false) {
    this.loading = true;
    this.employeeFileService.getEmployeeFiles(this.employee.id).subscribe(employeeFiles => {
      if (isLinkUpload) {
        this.employeeFiles = employeeFiles.filter(file => file.link !== null);
      } else {
        this.employeeFiles = employeeFiles.filter(file => file.link === null);
      }
    }).add(() => {
      this.loading = false;
      this.employeeFileFormGroup.get('type').markAsTouched();
    });
  }

  uploadNewFile() {
    this.loading = true;
    this.uploadProgress = 0;
    this.employeeFileService.addEmployeeFile(this.employee.id, this.employeeFileFormGroup.getRawValue(), this.selectedFile).subscribe((event: HttpEvent<any>) => {
      if (event.type === HttpEventType.UploadProgress) {
        this.uploadProgress = Math.round(event.loaded / event.total * 100);
      } else if (event.type === HttpEventType.Response) {
        const message = this._lang('file-uploaded');
        this.messageService.add(message, AlertEnum.success);
        this.selectedFile = undefined;
        this.employeeFileFormGroup.reset();
        this.loadEmployeeFiles(this.employeeFileUploadEnabled);
      }
    }).add(() => this.loading = false);
  }

  deleteEmployeeFile(employeeFile: EmployeeFile) {
    this.loading = true;
    this.employeeFileService.deleteEmployeeFile(employeeFile.id).subscribe(() => {
      const message = this.translate.instant('mep.components.toasts.was-deleted', {entity: employeeFile.name});
      this.messageService.add(message, AlertEnum.success);
      this.loadEmployeeFiles(this.employeeFileUploadEnabled);
    }).add(() => this.loading = false);
  }

  showConfirmationDialog(callbackFileDownload) {
    DisclaimerDialogComponent.openDialog(this.dialog, (result: boolean) => {
      if (result) {
        callbackFileDownload();
      }
    });
  }

  changeDeclarationOfConsentCheckbox() {
    this.employeeService.setDeclarationOfConsent(this.employee.id, this.employee.declarationOfConsent);
  }

  public async openPdf(event: any) {
    this.pdfViewerOnDemand.pdfSrc = event; // pdfSrc can be Blob or Uint8Array
    this.pdfViewerOnDemand.refresh(); // Ask pdf viewer to load/reresh pdf
  }

}
