import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'appPspListTransform', pure: true})
export class PspListTransformPipe implements PipeTransform {

    transform(sPSPs: string): string {
        if (typeof sPSPs !== 'string') {
          return sPSPs;
        } else if (sPSPs.indexOf(';') > -1) {
            let aPSPs = sPSPs.split(';');
            return `${aPSPs[0].trim()}, ...`;
        } else if (sPSPs.indexOf(',') > -1) {
            let aPSPs = sPSPs.split(',');
            return `${aPSPs[0].trim()}, ...`;
        } else {
            let maxLength = 18;
            if (sPSPs.trim().length <= maxLength) {
                return sPSPs.trim();
            } else {
                return sPSPs.substring(0, maxLength).trim() + ' ... ';
            }
        }
    }
}
