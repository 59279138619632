import {DataSource} from '@angular/cdk/table';
import {CollectionViewer} from '@angular/cdk/collections';
import {BehaviorSubject, Observable, of} from "rxjs";
import {catchError, finalize} from "rxjs/operators";
import {ListService} from "./ListService";
import {ListResponse} from "./ListResponse";

export class ListDataSource<T, S extends ListService<T>> implements DataSource<T> {

  private subject = new BehaviorSubject<T[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private countSubject = new BehaviorSubject<number>(0);

  // Bind your paginator like this:
  // this.dataSource.counter$
  //       .pipe(
  //         tap((count) => {
  //           this.paginator.length = count;
  //         })
  //       ).subscribe();
  public counter$ = this.countSubject.asObservable();

  // Subscribe in ngOnInit like this:
  // this.dataSource.loading$.subscribe(loading => this.loading = loading);
  // Then bind your loading animation onto your loading attribute.
  public loading$ = this.loadingSubject.asObservable();

  constructor(private service: S) {
  }

  connect(collectionViewer: CollectionViewer): Observable<T[]> {
    return this.subject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.subject.complete();
    this.loadingSubject.complete();
    this.countSubject.complete();
  }

  /**
   * Load the necessary data from the service.
   *
   * @param params The params for the request. Default is {page: 0, size: 10}.
   * @param callback Callbackfunction after the request was processed.
   */
  load(params, callback = null) {
    if (!params) {
      params = {page: 0, size: 10};
    }
    this.loadingSubject.next(true);
    this.service.search(params)
      .pipe(
        catchError(() => of([])),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe((result: ListResponse<T>) => {
          this.subject.next(result.content);
          this.countSubject.next(result.totalElements);
          if (callback) {
            callback(result);
          }
        }
      );
  }
}
