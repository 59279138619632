import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren
} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {MatOption} from "@angular/material/core";
import {Preference, PreferencesService} from "../../../core/services/services/preferences.service";

export class CapacityColorFilter {
  colors: CapacityColors[] = [];
  customPD = 10;
}

export enum CapacityColors {
  OVER_PLANNED = "color-overplanned",
  OPTIMAL_PLANNED = "color-optimal-planned",
  UNDER_PLANNED = "color-underplanned",
  STRONGLY_UNDER_PLANNED = "color-strongly-underplanned",
  CUSTOM_PD = "color-custom-pd"
}

@Component({
  selector: 'app-employee-capacity-color-select',
  templateUrl: './employee-capacity-color-select.component.html',
  styleUrls: ['./employee-capacity-color-select.component.scss']
})
export class EmployeeCapacityColorSelectComponent implements OnInit, AfterViewInit {

  @Input()
  parent: string;
  @ViewChildren('matOption')
  matOptions: QueryList<MatOption>;
  @Input()
  isFirstMonthStronglyUnderplanned = false;
  @Input()
  getCache = true;
  @Input()
  startMonth: string;
  @Input()
  disableSelect: boolean;

  capacityColorFilter = new CapacityColorFilter();

  @Output()
  capacityColorFilterChanged = new EventEmitter<CapacityColorFilter>();

  colors: string[] = [CapacityColors.OVER_PLANNED, CapacityColors.OPTIMAL_PLANNED, CapacityColors.UNDER_PLANNED, CapacityColors.STRONGLY_UNDER_PLANNED];

  CapacityColors = CapacityColors;

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private prefService: PreferencesService,
              private cdr: ChangeDetectorRef) {
  }

  async ngOnInit() {
    if (this.getCache) {
      const cacheCapacityColorFilter: string = this.activatedRoute.snapshot.queryParams[Preference.CAPACITY_COLORS + this.parent]
        || await this.prefService.get(Preference.CAPACITY_COLORS, this.parent);

      if (cacheCapacityColorFilter) {
        Object.assign(this.capacityColorFilter, JSON.parse(cacheCapacityColorFilter));
        this.capacityColorFilterChanged.emit(this.capacityColorFilter);
      }
    }
  }

  ngAfterViewInit() {
    if (this.isFirstMonthStronglyUnderplanned && this.parent === this.startMonth) {
      this.matOptions.last.select();
      this.cdr.detectChanges();
    }
  }

  onSelectedColorsChanged() {
    this.capacityColorFilterChanged.emit(this.capacityColorFilter);
    const colorFilterAsString: string = JSON.stringify(this.capacityColorFilter);

    if (this.capacityColorFilter.colors.length > 0) {
      this.prefService.put(Preference.CAPACITY_COLORS, colorFilterAsString, this.parent).then();
    } else {
      this.prefService.remove(Preference.CAPACITY_COLORS, this.parent).then();
    }

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {[Preference.CAPACITY_COLORS + this.parent]: colorFilterAsString},
      queryParamsHandling: "merge"
    }).then();
  }
}
