import {Component, Inject, OnInit} from '@angular/core';
import {TranslatableComponent} from "../../../core/models/TranslatableComponent";
import {TranslateService} from "@ngx-translate/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

export interface DialogData {
  first: string;
  second: string;
}

@Component({
  selector: 'app-info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['../../import-dialog/components/import-dialog.component.scss']
})
export class InfoDialogComponent extends TranslatableComponent implements OnInit {

  public dialogTitle: string;
  public dialogDescription: string;

  showCopyButton = true;

  constructor(public dialogRef: MatDialogRef<InfoDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData[], protected translate: TranslateService) {
    super(translate, 'InfoDialogComponent');
  }

  ngOnInit() {
    if (typeof this.data === "string") {
      this.data = [this.data];
    }
  }

  onCloseClick(): void {
    this.dialogRef.close();
  }

  copyErrors() {
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (this.data.map(item => {
        return item.second || item;
      }).join("\n")));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');
  }
}
