import {ErrorHandler} from "@angular/core";
import {environment} from "../../../../environments/environment";
import {MessageService} from "./message.service";
import {AlertEnum} from "../../models/Enums/AlertEnum";

export class ClientErrorHandlerService implements ErrorHandler {

  constructor(private messageService: MessageService) {
  }

  handleError(error) {
    if (error) {
      console.error(error);
      if (!environment.production) {
        this.messageService.add(this.createErrorMessage(error), AlertEnum.danger, null);
      }
    }
  }

  createErrorMessage(error): string {
    let errorString: string = '' + error;

    const endOfFirstLine = errorString.indexOf('\n');
    if (endOfFirstLine > 0) {
      errorString = errorString.substr(0, endOfFirstLine);
    }
    return `
<h2>Runtime error was caught and printed to console. Please fix the issue before merging to master!</h2>
<p>${errorString} ...</p>
<small>This message is only displayed during development! More info in console (F12 in chrome)</small>
`;
  }
}
