import {BaseEntity} from "./BaseEntity";
import MarketUnit from "../config/MarketUnit";

export class EmployeeSkill {
  id: Id;
  skill: Skill;
  experienceLevel: string;
  lastModifiedAt?: string;
  expirationDate?: Date;
}

export class Id {
  employeeId: number;
  skillId: number;
}

export class Skill {
  id: number;
  version: number;
  createdBy?: string;
  createdAt?: string;
  lastModifiedBy?: string;
  lastModifiedAt?: string;
  skillName: string;
  _links?: any;
  category: Category;
  new: boolean;
  eVersion: number;
  sorting: number;
  visibility: number;
  description: string;
  client: typeof MarketUnit;
  referenceCluster: Cluster;
  isCertificate: boolean;
}

export interface SkillWithAverageLevel {
  skill: Skill;
  averageLevel: number;
  employeesWithSkill: number;
}

export interface SkillWithMinimumLevel {
  skill: Skill;
  minimumLevel: number;
}

export class Cluster extends BaseEntity {
  clusterName: string;
}

export class Category {
  id: number;
  version: number;
  createdBy?: string;
  createdAt?: string;
  lastModifiedBy?: string;
  lastModifiedAt?: string;
  _links?: any;
  categoryName: string;
  cluster?: Cluster;
  new: boolean;
  eVersion: number;
}

export interface SkillDbEntry {
  skillName: string;
  level: any;
}

export interface EmployeeSkillClusterDTO {
  id: number;
  clusterName: string;
  employeeCategories: EmployeeSkillCategoryDTO[];
}

export interface EmployeeSkillCategoryDTO {
  id: number;
  categoryName: string;
  sorting: number;
  employeeSkills: EmployeeSkillDTO[];
}

export class EmployeeSkillDTO {
  id: Id;
  skillName: string;
  experienceLevel: string;
}
