import {HotkeyDirective} from "./hotkey.directive";
import {Directive, Input, OnInit} from "@angular/core";

@Directive({
  selector: '[appSaveHotkey]'
})
export class SaveHotkeyDirective extends HotkeyDirective implements OnInit {

  ngOnInit() {
    this.appHotkey = "control.s";
  }

  @Input()
  set appSaveHotkeyEnabled(enabled: boolean) {
    this.appHotkeyEnabled = enabled;
  }
}
