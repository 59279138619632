import {Component, Input, OnInit} from '@angular/core';
import {FormControl} from "@angular/forms";
import {CustomizingService} from "../../../../core/services/services/customizing.service";
import {Skill} from "../../../../core/models/EmployeeSkill";

@Component({
  selector: 'app-skill-rating',
  templateUrl: './skill-rating.component.html',
  styleUrls: ['./skill-rating.component.scss']
})
export class SkillRatingComponent implements OnInit {

  @Input()
  skillFormControl: FormControl;
  @Input() skill: Skill;

  skillLevels: string[];

  constructor(private customizing: CustomizingService) { }

  ngOnInit(): void {
    if (this.skill.isCertificate) {
      this.skillLevels = ['X']
    } else {
      this.customizing.getSkillLevels().then(skillLevels => this.skillLevels = skillLevels);
    }
  }

  levelSelected(level: string) {
    if (this.skillFormControl.value === level) {
      level = '';
    }
    this.skillFormControl.setValue(level);
    this.skillFormControl.markAsDirty();
    this.skillFormControl.markAsTouched();
  }
}
