import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {Preference, PreferencesService} from "../../../core/services/services/preferences.service";
import {TranslatablePaginator} from "../../../core/models/TranslatablePaginator";
import {TranslateParser, TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-paginator-smart',
  templateUrl: './paginator-smart.component.html',
  styleUrls: ['./paginator-smart.component.scss']
})
export class PaginatorSmartComponent implements OnInit, AfterViewInit, OnChanges {

  @Input() preferenceKey: string;
  @Input() defaultPageSize: number;
  @Input() totalElements: number;
  @Input() disabled: boolean;
  @Output() page = new EventEmitter<PageEvent>();

  @ViewChild(MatPaginator) basePaginator: MatPaginator;
  myPageSize: number;
  private pageChangedFromDefault: boolean;

  constructor(private preferencesService: PreferencesService, private translate: TranslateService, private _parser: TranslateParser) {
    this.pageChangedFromDefault = false;
  }

  ngOnInit() {
    this.myPageSize = this.defaultPageSize;
    this.preferencesService.get(Preference.PAGINATION_LIST_ELEMENTS, this.preferenceKey).then(numberOfElements => {
      if (numberOfElements) {
        this.myPageSize = Number(numberOfElements);
        this.pageChangedFromDefault = true;
      }
    });
  }

  ngAfterViewInit() {
    this.basePaginator._intl = new TranslatablePaginator(this.translate, this._parser);
  }

  ngOnChanges(changes: SimpleChanges): void {
    // only change pagesize if the user never changed it
    if (changes.defaultPageSize && !this.pageChangedFromDefault) {
      this.myPageSize = this.defaultPageSize;
    }
  }

  onPageChange($event: PageEvent) {
    // Only update page size if it really was changed
    if (this.myPageSize && this.myPageSize !== $event.pageSize) {
      this.myPageSize = $event.pageSize;
      this.pageChangedFromDefault = true;
      this.preferencesService.put(Preference.PAGINATION_LIST_ELEMENTS, String(this.myPageSize), this.preferenceKey).then();
    }

    this.page.emit($event);
  }
}
