import {Component, Inject, OnInit} from '@angular/core';
import {TranslatableComponent} from "../../../core/models/TranslatableComponent";
import {TranslateService} from "@ngx-translate/core";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {EmployeeService} from "../../../core/services/services/employee.service";
import {Skill} from "../../../core/models/EmployeeSkill";
import {AlertEnum} from "../../../core/models/Enums/AlertEnum";
import {MessageService} from "../../../core/services/services/message.service";
import {EditSkillDialogInformation} from "./edit-skill-dialog-information";
import {CustomizingService} from "../../../core/services/services/customizing.service";

@Component({
  selector: 'app-edit-skill-dialog',
  templateUrl: './edit-skill-dialog.component.html',
  styleUrls: ['./edit-skill-dialog.component.css']
})
export class EditSkillDialogComponent extends TranslatableComponent implements OnInit {

  skillForm = new FormGroup({
    selectedCluster: new FormControl('', Validators.required),
    selectedCategory: new FormControl('', Validators.required),
    skill: new FormControl('', {validators: Validators.required})
  });

  loading: boolean;

  // For detection if category has changed
  loadedCategoryId: number;

  constructor(@Inject(MAT_DIALOG_DATA) public data: EditSkillDialogInformation,
              public dialogRef: MatDialogRef<EditSkillDialogComponent>,
              private translateService: TranslateService,
              private _fb: FormBuilder,
              private employeeService: EmployeeService,
              private messageService: MessageService,
              private customizingService: CustomizingService) {
    super(translateService, 'EditSkillDialogComponent');
  }

  public static open(dialog: MatDialog, skillInfo: EditSkillDialogInformation): MatDialogRef<EditSkillDialogComponent> {
    return dialog.open(EditSkillDialogComponent, {
      width: '600px',
      data: skillInfo
    });
  }

  ngOnInit(): void {
    this.loading = false;
    this.skillForm.get("selectedCluster").setValue(this.data.skill.category.cluster.id);
    this.skillForm.get("selectedCategory").setValue(this.data.skill.category.id);
    this.loadedCategoryId = this.data.skill.category.id;
    if (!this.data.isAddMode) {
      this.skillForm.get("skill").setValue(this.data.skill.skillName);
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  async saveSkill() {
    if (!this.skillForm.invalid) {
      this.loading = true;
      let skill: Skill;
      if (this.data.isAddMode) {
        skill = new Skill();
      } else {
        skill = this.data.skill;
      }
      this.applyFormValuesToModel(skill);
      await this.employeeService.saveSkill(skill).toPromise().then(() => {
        this.messageService.add(this.translateService.instant(this.data.isAddMode ? 'mep.services.edit-skill-dialog.added-skill' : 'mep.services.edit-skill-dialog.edited-skill'), AlertEnum.success);
      }).catch(error => {
        this.messageService.add(this.translateService.instant(
          this.data.isAddMode
          ? 'mep.services.edit-skill-dialog.adding-skill-failed'
          : 'mep.services.edit-skill-dialog.editing-skill-failed') + error, AlertEnum.danger);
      });
      this.loading = false;
      this.closeDialog();
    } else {
      this.messageService.add(this.translateService.instant('mep.services.edit-skill-dialog.invalid-skill'), AlertEnum.warning);
    }
  }

  applyFormValuesToModel(skill: Skill) {
    let categoryId = this.skillForm.get("selectedCategory").value;
    skill.skillName = this.skillForm.get("skill").value;
    skill.category = this.data.allCategories.find(cat => cat.id === categoryId);
    if (this.loadedCategoryId === categoryId) {
      skill.sorting = this.data.skill.sorting;
    } else {
      // If the category was changed, then the skill should be added at the end of the skills of this category
      skill.sorting = -1;
    }
  }

  getCategoriesBySelectedCluster() {
    return this.data.allCategories.filter(cat => cat.cluster.id === this.skillForm.get("selectedCluster").value);
  }

  clusterChanged() {
    this.skillForm.patchValue({selectedCategory: null});
  }
}
