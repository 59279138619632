import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'monthForCapacity', pure: true})
export class FormatMonthForCapacityPipe implements PipeTransform {

  transform(month: string): string {
    month = month.toLowerCase();
    month = month.charAt(0).toUpperCase() + month.slice(1);
    month = month.replace(" ", "");
    return month.replace('20', '');
  }
}
