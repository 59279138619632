import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DataService} from './data.service';
import {ApplicationUser} from "../../models/ApplicationUser";
import {TranslateService} from "@ngx-translate/core";

import {KeycloakService} from "keycloak-angular";
import {Employee} from "../../models/Employee";
import {AlertEnum} from "../../models/Enums/AlertEnum";
import {MessageService} from "./message.service";
import {InfoDialogComponent} from "../../../shared/info-dialog/components/info-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {
  AppEntity,
  AppEntityAccessType,
  AppPermissions,
  DefinedEntityAccessTypes,
  Permission,
  permissionToWeight
} from "../../models/AppPermissions";
import {defer, forkJoin, from, Observable} from "rxjs";
import {map, shareReplay} from "rxjs/operators";
import {Role} from "../../models/Role";

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private applicationUser: Observable<ApplicationUser> = this.getApplicationUserObs().pipe(shareReplay());
  private appPermissions: Observable<AppPermissions> = this.http.get<AppPermissions>("/mep/permissions").pipe(shareReplay());
  private entityToDefinedAccess: Observable<DefinedEntityAccessTypes> = this.http.get<DefinedEntityAccessTypes>("/mep/appEntitiesWithDefinedAccessTypes").pipe(shareReplay());
  private userRoles: Role[];

  constructor(private http: HttpClient,
              private dataService: DataService,
              private keycloakAngular: KeycloakService,
              private messageService: MessageService,
              private translateService: TranslateService,
              private matDialog: MatDialog) {
  }

  /**
   *
   *
   * Show Alert that the USER-Role is missing
   *
   *
   *
   * @returns
   * @memberof AuthenticationService
   */
  showAlertMissingAuthentication(): boolean {
    // If the logged user does not have the role USER do an auto logout.
    // Such users are not allowed to use the app.
    const textPromise = this.translateService.get("mep.components.general.login-user-unauthorized").toPromise();
    const titlePromise = this.translateService.get("mep.components.general.login-user-missingrole").toPromise();
    Promise.all([titlePromise, textPromise]).then((texts: string[]) => {
      const dialogRef = this.matDialog.open(InfoDialogComponent, {
        maxHeight: '600px', maxWidth: '800px', data: texts[1]
      });
      dialogRef.componentInstance.dialogTitle = texts[0];
      dialogRef.afterClosed().subscribe(() => this.logout());
    });
    return false;
  }

  /**
   * Sets the authenticated flag
   * If the server responds with the user details
   * then the user is authenticated
   *
   * @memberof AppService
   */
  setAuthenticatedFlag(response: boolean) {
    this.dataService.changeAuthenticatedStatus(response);
  }

  public hasUserRole(): boolean {
    return this.keycloakAngular.isUserInRole("USER");
  }

  /**
   * Logs in and checks user authentication
   *
   * @memberof AppService
   */
  async setup(): Promise<boolean> {
    if (this.hasUserRole()) {
      this.userRoles = await this.getUserRoles().toPromise();

      return true;
    } else {
      return this.showAlertMissingAuthentication();
    }
  }

  /**
   *
   *
   * Logout user
   *
   *
   *
   * @returns
   * @memberof AuthenticationService
   */
  logout(): boolean {
    this.applicationUser = null;
    this.userRoles = [];
    this.dataService.changeAuthenticatedStatus(false);

    this.keycloakAngular.logout(document.baseURI).then();
    return true;
  }

  isCustomerManager(): boolean {
    return this.userRoles.includes(Role.CUSTOMER_MANAGER);
  }

  isProjectAdmin() {
    return this.userRoles.includes(Role.PROJECT_ADMIN);
  }

  isMepAdmin() {
    return this.userRoles.includes(Role.MEP_ADMIN);
  }

  async getApplicationUser(): Promise<ApplicationUser> {
    return this.applicationUser.toPromise();
  }

  async loadPrincipal(): Promise<Employee> {
    try {
      return (await this.http.get<any>("/mep/principal", {observe: 'response'}).toPromise()).body;
    } catch (e) {
      this.messageService.add('Abfragen des angmeldeten Benutzers fehlgeschlagen', AlertEnum.danger);
    }
  }

  getPermissions(): Observable<AppPermissions> {
    return this.appPermissions;
  }

  async hasPermission(expectedPermission: Permission, accessType: AppEntityAccessType, entity: AppEntity): Promise<boolean> {
    await this.checkForImpossibleAccess(entity, accessType);
    const permissions = await this.getPermissions().toPromise();
    const entityPermissions = permissions[entity];

    if (!entityPermissions || !this.hasUserRole()) {
      return false;
    } else {
      const permission = entityPermissions[accessType];
      const permissionWeight = permissionToWeight(permission);
      const expectedPermissionWeight = permissionToWeight(expectedPermission);

      return permissionWeight === expectedPermissionWeight;
    }
  }

  async hasAtLeastPermission(minPermission: Permission, accessType: AppEntityAccessType, entity: AppEntity, idNotRelevant = false, employeeId = null, exactMatch = false): Promise<boolean> {
    await this.checkForImpossibleAccess(entity, accessType);
    const permissions = await this.getPermissions().toPromise();
    const entityPermissions = permissions[entity];
    if (!entityPermissions || !this.hasUserRole()) {
      return false;
    } else {
      const permission = entityPermissions[accessType];
      const permissionWeight = permissionToWeight(permission);
      const minPermissionWeight = permissionToWeight(minPermission);

      if (permission === Permission.ONLY_RELATED_TO_ME) {
        const user = await this.getApplicationUser();
        if (!idNotRelevant && Number(employeeId) !== Number(user.employee.id)) {
          return false;
        }
      }

      if (exactMatch) {
        return permissionWeight === minPermissionWeight;
      } else {
        return permissionWeight >= minPermissionWeight;
      }
    }
  }

  private async checkForImpossibleAccess(appEntity: AppEntity, access: AppEntityAccessType) {
    const definedEntityAccessTypes = await this.entityToDefinedAccess.toPromise();

    const possibleAccess = definedEntityAccessTypes[appEntity].includes(access);

    if (!possibleAccess) {
      throw new Error(`${access} is not defined on ${appEntity}! Please check AppEntity.java for possible combinations!`);
    }
  }

  private getApplicationUserObs(): Observable<ApplicationUser> {
    return forkJoin([from(this.loadPrincipal()), defer(() => from(this.keycloakAngular.loadUserProfile()))]).pipe(
      map(([employee, profile]) => {
        const user = new ApplicationUser();
        user.username = profile.username;
        user.employee = employee;

        return user;
      })
    );
  }

  private getUserRoles(): Observable<Role[]> {
    return this.http.get<Role[]>("/mep/api/userRoles");
  }
}
