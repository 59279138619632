export enum Role {
  MEP_ADMIN = "MEP_ADMIN",
  PROJECT_ADMIN = "PROJECT_ADMIN",
  DIVISION_MANAGER = "DIVISION_MANAGER",
  DEPARTMENT_MANAGER = "DEPARTMENT_MANAGER",
  BUSINESS_UNIT_MANAGER = "BUSINESS_UNIT_MANAGER",
  BUSINESS_UNIT_LEADER = "BUSINESS_UNIT_LEADER",
  CUSTOMER_MANAGER = "CUSTOMER_MANAGER",
  MA_SKILL_EDIT = "MA_SKILL_EDIT",
  MA_SKILL_AND_ASSIGNMENT_EDIT = "MA_SKILL_AND_ASSIGNMENT_EDIT",
  USER = "USER"
}
