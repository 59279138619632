import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {EntityTransaction} from "../../models/util/EntityTransaction";
import {EmployeeSkill, Id} from "../../models/EmployeeSkill";
import {HttpClient} from "@angular/common/http";
import Config from "../../config/Config";
import {EmployeeCertificateData} from "../../models/EmployeeCertificateData";

@Injectable({
  providedIn: 'root'
})
export class EmployeeSkillsService {
  constructor(private http: HttpClient) {

  }

  public executeTransaction(skillTransaction: EntityTransaction<EmployeeSkill, Id>): Observable<void> {
    return this.http.post<void>(Config.services.employeeSkills.baseUrl + Config.services.employeeSkills.transaction, skillTransaction);
  }

  public downloadSkillTeamMatrixAsExcel(selectedSkillClusterIds: number[]): Observable<any> {
    return this.http.post(
      Config.services.employeeSkills.baseUrl + Config.services.employeeSkills.skillTeamMatrixDownload + '/' + selectedSkillClusterIds, {}, {responseType: 'blob'});
  }

  public getEmployeeCertificateData(): Observable<EmployeeCertificateData[]> {
    return this.http.get<EmployeeCertificateData[]>(
      Config.services.employeeSkills.baseUrl + Config.services.employeeSkills.certificateData);
  }

  public getEmployeeExpiredCertificateData(): Observable<EmployeeCertificateData[]> {
    return this.http.get<EmployeeCertificateData[]>(
      Config.services.employeeSkills.baseUrl + Config.services.employeeSkills.expiredCertificateData);
  }

  public downloadEmployeeCertificateData(): Observable<any> {
    return this.http.post(
      Config.services.employeeSkills.baseUrl + Config.services.employees.exportEmployeesAndCertificatesExcel,
      {},
      {responseType: "blob"});
  }

  public downloadEmployeeExpiredCertificateData(): Observable<any> {
    return this.http.post(
      Config.services.employeeSkills.baseUrl + Config.services.employees.exportEmployeesAndExpiredCertificatesExcel,
      {},
      {responseType: "blob"});
  }


}
