import {Component, Inject} from '@angular/core';
import {TranslatableComponent} from "../../../core/models/TranslatableComponent";
import {TranslateService} from "@ngx-translate/core";
import {FileReference} from "../../../core/models/viewModels/FileReference";
import {NestedTreeControl} from "@angular/cdk/tree";
import {MatTreeNestedDataSource} from "@angular/material/tree";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-file-selection-dialog',
  templateUrl: './file-selection-dialog.component.html',
  styleUrls: ['./file-selection-dialog.component.scss']
})
export class FileSelectionDialogComponent extends TranslatableComponent {

  treeControl = new NestedTreeControl<FileReference>(node => node.fileReferenceList);
  dataSource = new MatTreeNestedDataSource<FileReference>();

  constructor(public dialogRef: MatDialogRef<FileSelectionDialogComponent>,
              public translate: TranslateService, @Inject(MAT_DIALOG_DATA) public fileReferences: FileReference[]) {
    super(translate, "FileSelectionDialogComponent");
    this.dataSource.data = fileReferences;
  }

  hasChild(index: number, node: FileReference) {
    return !!node.fileReferenceList && node.fileReferenceList.length > 0;
  }

  fileSelected(file: FileReference) {
    if (!file.directory) {
      this.dialogRef.close(file);
    }
  }
}
