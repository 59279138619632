import Config from "../config/Config";
import {TranslateService} from "@ngx-translate/core";
import {Injector} from "@angular/core";
import {LOCATION_INITIALIZED} from "@angular/common";
import {CustomizingService} from "../services/services/customizing.service";
import {Client} from "../config/Client";

export function appInitFactory(translate: TranslateService, injector: Injector, customizingService: CustomizingService) {
  return async () => {
    await injector.get(LOCATION_INITIALIZED, Promise.resolve(null));

    let userLang = translate.getBrowserLang();
    userLang = userLang.match(Config.supportedLanguagesRegex) ? userLang : Config.defaultLanguage;
    translate.setDefaultLang(userLang);
    const client = await customizingService.getCurrentClient();
    if (client === Client.MISSING_VALUE) {
      await translate.use(userLang).toPromise();
    } else {
      await translate.use(client + "-" + userLang).toPromise();
    }
  };
}
