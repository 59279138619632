import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {TranslateModule} from "@ngx-translate/core";
import {FileInputComponent} from './components/file-input.component';
import {MatButtonModule} from "@angular/material/button";
import {FileDragDropComponent} from "./components/file-drag-drop/file-drag-drop.component";
import {FileDragDropDirective} from "./file-drag-drop.directive";
import {FormsModule} from "@angular/forms";
import {NewsNotificationModule} from "../news-notification/news-notification.module";
import {MatIconModule} from "@angular/material/icon";

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    MatButtonModule,
    FormsModule,
    NewsNotificationModule,
    MatIconModule
  ],
  declarations: [
    FileInputComponent,
    FileDragDropComponent,
    FileDragDropDirective
  ],
  exports: [
    FileInputComponent,
    FileDragDropComponent
  ]
})
export class FileInputModule {}
