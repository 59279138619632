import {Href} from './href';
import {BaseEntity} from "./BaseEntity";
import {SkillDbEntry} from "./EmployeeSkill";
import {EmployeeHierarchy} from "./EmployeeHierarchy";

/**
 * Employee model
 TODO: Always check again backend models changes and update me.
 */
export class Employee extends BaseEntity {
  personnelNumber: string;
  lastName: string;
  firstName: string;
  title: string;
  location: string;
  dispoFlag: boolean;
  country: string;
  jobFamily: string;
  jobVariant: string;
  jobLevel: string;
  status: string;
  entryDate: string;
  leavingDate: string;
  businessUnit: string;
  division: string;
  travelWillingness: string;
  travelWillingnessInternational: boolean;
  travelWillingnessCombined?: string;
  travelRestrictions: string;
  annotation: string;
  misplannedAnnotation: string;
  skills: string;
  skillDbRows?: SkillDbEntry[];
  skillDbSkillNames?: string;
  proportionOfWorkingTime: any;
  username: string;
  assignmentProjectEmployeeSet = [];
  department: string;
  humanResourcesManager: string;
  new: boolean;
  _links: Link;
  links?: any;
  id?: number;
  costCenter: string;
  languages: string;
  hierarchy?: EmployeeHierarchy;
  notRelevantForCapa: boolean;
  tag?: string;
  email: string;
  declarationOfConsent?: boolean;
  businessUnitChange?: string;
  resourceRequestNewsfeedStatus?: string;

  public getFullName(): string {
    return this.lastName + ', ' + this.firstName;
  }
}

/**
 * Link model
 */
export class Link {
  self?: Href;
  employee?: Href;
  assignmentProjectEmployeeSet?: Href;
  roleList?: Href;
}
