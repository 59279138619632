import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root',
})
export class LanguagesService {

  getLanguageExtendedText(languages) {
    return languages ? languages.toString().replace('N:', 'Native:')
      .replace('E:', 'English:')
      .replace('G:', 'German:')
      .replace('R:', 'Romanian:')
      .replace('O:', 'Others:')
      .replace(/,/g, ',\n') : '';
  }
}
