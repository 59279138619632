import {BdcWalkModule} from 'bdc-walkthrough';
import {NewsNotificationComponent} from "./news-notification.component";
import {NgModule} from "@angular/core";
import {MatButtonModule} from "@angular/material/button";
import {CommonModule} from "@angular/common";


@NgModule({
  declarations: [
    NewsNotificationComponent
  ],
  imports: [
    BdcWalkModule,
    MatButtonModule,
    CommonModule,
  ],
  exports: [
    NewsNotificationComponent
  ]
})
export class NewsNotificationModule {
}
