import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {Project} from "../../../core/models/Project";
import {EmployeeFullName} from "../../../core/models/EmployeeFullName";
import {EmployeeService} from "../../../core/services/services/employee.service";
import {Client} from "../../../core/config/Client";
import {CustomizingService} from "../../../core/services/services/customizing.service";
import {TranslatableComponent} from "../../../core/models/TranslatableComponent";
import {TranslateService} from "@ngx-translate/core";
import Config from "../../../core/config/Config";
import {ProjectStatus} from "../../../core/models/Enums/ProjectStatus";
import {DropDownChoice} from "../../../feature/employee-operations/abstract-employee-details.component";
import {AuthenticationService} from "../../../core/services/services/authentication.service";
import {NgModel} from "@angular/forms";
import {DeliveryType} from "../../../core/models/Enums/DeliveryType";
import {ProjectCritically} from "../../../core/models/Enums/ProjectCritically";
import {MatSelect} from "@angular/material/select";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";


@Component({
  selector: 'app-project-data-input',
  templateUrl: './project-data-input.component.html',
  styleUrls: ['./project-data-input.component.scss']
})
export class ProjectDataInputComponent extends TranslatableComponent implements OnInit, OnChanges {

  @Input()
  project: Project;
  @Input()
  showMappingString: boolean;
  @Input()
  isEditMode: boolean;
  @Input()
  isAddMode: boolean;
  @ViewChildren(NgModel) fields: QueryList<NgModel>;
  @ViewChild('projectCategory') category: MatSelect;

  projectEditable: boolean;
  currentClient: Client;

  strategicObjective: DropDownChoice<string>[] = null;
  businessTopics: DropDownChoice<string>[] = this.getEnums("businessTopics");
  branches: DropDownChoice<string>[] = this.getEnums("branches");
  marketUnit: DropDownChoice<string>[] = this.getEnums("businessUnit");
  businessUnit: DropDownChoice<string>[] = this.getEnums("businessUnit");
  deliveryType = Object.keys(DeliveryType);
  projectCritically = Object.keys(ProjectCritically);
  projectStatus: string[] = Object.keys(ProjectStatus);
  divisions: DropDownChoice<string>[] = null;
  projectCategories: DropDownChoice<string>[] = this.getEnums("projectCategory");
  billingType;
  requireProjectAdmin: boolean;
  onlyDivLeaderOrManager: boolean = true;

  @Output() isDataEdited = new EventEmitter<string>();
  @Output() statusChanged = new EventEmitter<string>();

  constructor(private employeeService: EmployeeService, private loginService: AuthenticationService,
              private customizing: CustomizingService,public dialog: MatDialog, private authenticationService: AuthenticationService, translate: TranslateService) {

    super(translate, 'ProjectDataComponent');
  }

  async ngOnInit() {
    this.customizing.getCurrentClient().then(client => {
      this.currentClient = client;
      if (this.currentClient === Client.ZM) {
        this.onlyDivLeaderOrManager = false;
      }
      this.divisions = this.employeeService.fetchAttributeOption('division', true, this.currentClient);
      this.billingType = this.employeeService.fetchAttributeOption('billingType', false, this.currentClient);
      this.strategicObjective = this.employeeService.fetchAttributeOption('strategicObjective', false, this.currentClient);
    });

    this.customizing.requireProjectAdmin().then(require => {
      this.requireProjectAdmin = require;
    });
    this.customizing.isProjectEditable().then(editable => {
      this.projectEditable = editable;
    });
    const val = await this.authenticationService.getApplicationUser();
    if (val.employee != null && val.employee.businessUnit != null) {
      this.project.businessUnit = val.employee.businessUnit;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.project) {
      this.fields?.forEach(model => model.control.markAsUntouched());
    }
  }

  async updateResortManager(employeeFullName: EmployeeFullName) {
    this.project.resortManager = await this.employeeService.getEmployeeById(employeeFullName.id).toPromise();
  }

  async updateProjectLeader(employeeFullName: EmployeeFullName) {
    this.project.projectLeader = await this.employeeService.getEmployeeById(employeeFullName.id).toPromise();
  }

  async updateDivisionManager(employeeFullName: EmployeeFullName) {
    this.project.divisionManager = await this.employeeService.getEmployeeById(employeeFullName.id).toPromise();
  }

  async updateResponsibleAL(employeeFullName: EmployeeFullName) {
    this.project.responsibleAL = await this.employeeService.getEmployeeById(employeeFullName.id).toPromise();
  }

  isCurrentClientGBP(): boolean {
    return this.currentClient === Client.GBP;
  }

  currentClientIsMSAOrZM() {
    return this.currentClient === Client.MSG_SERVICES || this.currentClient === Client.ZM;
  }

  currentClientIsMSAOrZMOrGBPOrCONPLANOrGBG() {
    return this.currentClient === Client.MSG_SERVICES || this.currentClient === Client.ZM || this.currentClient === Client.GBP || this.currentClient === Client.CONPLAN || this.currentClient === Client.GBG;
  }

  isCurrentClientZT() {
    return this.currentClient === Client.ZT;
  }

  currentClientIsGBI() {
    return this.currentClient === Client.GBI
  }

  isUrl(url: string): boolean {
    try {
      return !!new URL(url);
    } catch {
      return false;
    }
  }

  blockNonAdminEdit(): boolean {
    return this.requireProjectAdmin && !this.loginService.isProjectAdmin();
  }

  defaultDisabledCondition(): boolean {
    return !this.isEditMode || !this.projectEditable || this.blockNonAdminEdit();
  }

  // Getting the Enum array from the config file
  private getEnums(keyWord: string): DropDownChoice<string>[] {
    let options = Object.keys(Config.services.employees[keyWord]).sort()
      .map(i => ({value: i, viewValue: Config.services.employees[keyWord][i]}));
    if (keyWord === "businessUnit") {
      options.unshift({
        value: Config.services.missingValueBackend,
        viewValue: Config.services.missingValue
      });
    }
    return options;
  }

  inputModelChanged(newVal: any) {
    if (newVal) {
      this.isDataEdited.emit(newVal);

    }
  }

  projectStatusChanged(newVal: any) {
    if (newVal) {
      this.statusChanged.emit(newVal);
    }
  }

  openInfoDialog() {
    this.dialog.open(InformationSecurityPopup);
  }
}

@Component({
  selector: 'information-security-popup',
  templateUrl: 'information-security-popup.html'
})
export class InformationSecurityPopup {

  constructor(public dialogRef: MatDialogRef<InformationSecurityPopup>) {
  }

  close() {
    this.dialogRef.close();
  }
}
