import {Component, Input} from '@angular/core';
import {EmployeeService} from "../../../core/services/services/employee.service";
import {AbstractBackendAutocomplete} from "./abstract-backend-autocomplete";
import {map} from "rxjs/operators";
import {Observable} from "rxjs";

@Component({
  selector: 'app-skill-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss']
})
export class SkillAutocompleteComponent extends AbstractBackendAutocomplete<string> {

  @Input() hideElement: (value: string) => boolean;
  @Input() showInvisibleSkills: boolean

  constructor(private _employeeService: EmployeeService) {
    super();
  }

  getElementsFromBackendForSearchTerm(searchTerm: string): Observable<string[]> {
    let obs = this._employeeService.getSkillNamesByNameSearch(searchTerm, true, this.showInvisibleSkills);
    if (this.hideElement) {
      return obs.pipe(map(elements => elements.filter(elem => !this.hideElement(elem))));
    } else {
      return obs;
    }
  }

  displayAsSelectedOption(skillName: string): string {
    return skillName;
  }

  filter(element: string, value: string): boolean {
    return element.toLowerCase().includes(value.toLowerCase());
  }

  sort(element: string, otherElement: string): number {
    return element.localeCompare(otherElement);
  }

}
