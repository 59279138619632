import {Injectable} from '@angular/core';
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {ResourceRequest, ResourceRequestOverviewDTO} from "../models/resource-request";
import {ResourceRequestFile} from "../models/resource-request-file";
import {HttpBaseService} from "../../../core/services/services/http-base.service";
import {MessageService} from "../../../core/services/services/message.service";
import {ResourceRequestUpdateDTO} from "../../../core/models/ResourceRequestUpdateDTO";

@Injectable({
  providedIn: 'root'
})
export class ResourceRequestListService extends HttpBaseService {

  url: string = environment.baseUrl + 'api/resource-request/overview';
  baseUrl = '/mep/api/resource-request';

  constructor(public http: HttpClient, public messageService: MessageService) {
    super(http, messageService);
  }

  getResourceRequestList(): Observable<ResourceRequestOverviewDTO[]> {
    return this.http.get<ResourceRequestOverviewDTO[]>(this.url);
  }

  createResourceRequest(r: ResourceRequest): Observable<ResourceRequestUpdateDTO> {
    const formData = new FormData();
    r.files.forEach(value => formData.append('file', value.file));
    formData.append('dto', JSON.stringify(r));
    return this.http.post<ResourceRequestUpdateDTO>(this.baseUrl + '/add', formData);
  }

  updateResourceRequest(r: ResourceRequest): Observable<ResourceRequestUpdateDTO> {
    const formData = new FormData();
    r.files?.forEach(value => formData.append('file', value.file));
    formData.append('dto', JSON.stringify(r));
    return this.http.put<ResourceRequestUpdateDTO>(this.baseUrl + '/' + r.id, formData);
  }

  updateResourceRequestDispoFlag(resourceId: number): Observable<any> {
    return this.http.put('/mep/api/resource-request/dispoFlag/' + resourceId, {});
  }

  getById(id: number): Observable<ResourceRequest> {
    return this.http.get<ResourceRequest>(this.baseUrl + '/' + id, {observe: 'body'});
  }

  async findAllByEmployeeId(id: number): Promise<ResourceRequestOverviewDTO[]> {
    return (await this.http.get<ResourceRequestOverviewDTO[]>(this.baseUrl + '/find/' + id, {observe: 'response'}).toPromise()).body;
  }

  deleteResourceRequestFile(id: number) {
    return this.http.delete(this.baseUrl + '/deleteFile/' + id);
  }

  getNumberOfPersondaysForResourceRequest(from: string, to: string, scope: number, requiredEmployees: number): Observable<number> {
    const params = {
      from, to, requiredEmployees: String(requiredEmployees), scope: String(scope)
    };
    return this.http.get<number>(`${this.baseUrl}/requestPersonDays`, {observe: 'body', params});
  }

  getResourceRequestFiles(resourceRequestId: number): Observable<ResourceRequestFile[]> {
    return this.http.get<ResourceRequestFile[]>(this.baseUrl + '/getResourceRequestFiles/' + resourceRequestId);
  }

  getResourceRequestClients(): Observable<string[]> {
    return this.http.get<string[]>(this.baseUrl + '/clients');
  }

  getResourceRequestRequestingUnits(): Observable<string[]> {
    return this.http.get<string[]>(this.baseUrl + '/units');
  }

  downloadResourceRequestsAsExcel() {
    return this.http.post(this.baseUrl + '/export', {}, {responseType: 'blob'});
  }

  deleteResourceRequests(ids: number[]) {
    return this.http.post(this.baseUrl + "/delete", ids);
  }

  getResourceRequestWebhookBusinessUnits(): Observable<string[]> {
    return this.http.get<string[]>(this.baseUrl + "/businessUnits");
  }

  getResourceRequestWebhookDivisions(): Observable<string[]> {
    return this.http.get<string[]>(this.baseUrl + "/divisions");
  }

  changeNewsfeedStatus(value: any) {
    return this.http.post(this.baseUrl + '/changeNewsfeedStatus/' + value, {})
  }

  findResourceRequestBySearchTerm(searchTerm: string) {
    return this.http.get<ResourceRequestOverviewDTO[]>(this.baseUrl + '/getBySearchTerm' + '?searchTerm=' + encodeURIComponent(searchTerm));
  }

  getStaffingTags(): Observable<string[]> {
    return this.http.get<string[]>(this.baseUrl + '/tags');
  }

  isNotDispoFlagFalse(dispoFlag: boolean): boolean {
    return dispoFlag === true;
  }

}
