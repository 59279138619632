import {Injectable} from '@angular/core';
import {SkillDbEntry} from "../../models/EmployeeSkill";
import {EmployeeService} from "./employee.service";
import {SkillDbFilterData} from "../../../shared/dialogs/filter-dialog/filter-dialog.interface";
import {lastValueFrom} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SkillFilterService {

  constructor(private employeeService: EmployeeService) {
  }

  public hasAllSelectedSkills(employeeSkills: SkillDbEntry[], skills: SkillDbEntry[]): boolean {
    return skills.every(skill => this.hasSelectedSkill(employeeSkills, skill));
  }

  public hasAnySelectedSkill(employeeSkills: SkillDbEntry[], skills: SkillDbEntry[]): boolean {
    if (skills.length === 0) {
      return true;
    } else {
      return skills.some(skill => this.hasSelectedSkill(employeeSkills, skill));
    }
  }

  public hasSkills(employeeSkills: SkillDbEntry[], skillFilterData: SkillDbFilterData): boolean {
    if (skillFilterData.logicalOperator === 'AND') {
      return this.hasAllSelectedSkills(employeeSkills, skillFilterData.skillDbRows);
    } else {
      return this.hasAnySelectedSkill(employeeSkills, skillFilterData.skillDbRows);
    }
  }

  public hasSelectedSkill(employeeSkills: SkillDbEntry[], skill: SkillDbEntry): boolean {
    if (!skill || !skill.skillName) {
      return true;
    } else if (!employeeSkills) {
      return !skill.skillName;
    } else {
      const employeeSkill = employeeSkills.find(eSkill => eSkill.skillName === skill.skillName);

      return employeeSkill && (employeeSkill.level > skill.level || employeeSkill.level === 'X');
    }
  }

  public async setAllSkillDbEntries(employees: any[], withExpired: boolean): Promise<any> {
    let result;
    if (withExpired) {
      result = await lastValueFrom(this.employeeService.getAllEmployeeSkills());
    } else if (!withExpired) {
      result = await lastValueFrom(this.employeeService.getAllEmployeeSkillsWithoutExpiredCertificates());
    }
    employees.forEach(employee => {
      if (employee.employee) {
        employee = employee.employee;
      }
      const employeeSkills = result[employee.id] || [];
      this.employeeService.setSkillDbRows(employee, employeeSkills, true);
    });
    return true;
  }
}
