import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatTable, MatTableDataSource} from "@angular/material/table";
import {FormControl} from "@angular/forms";
import {MatPaginator} from "@angular/material/paginator";
import {from, of} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {CdkDragDrop, moveItemInArray, transferArrayItem} from "@angular/cdk/drag-drop";
import {Skill, SkillWithMinimumLevel} from "../../../../core/models/EmployeeSkill";
import {Skillgroup} from "../../../../core/models/Skillgroup";
import {EmployeeService} from "../../../../core/services/services/employee.service";
import {CustomizingService} from "../../../../core/services/services/customizing.service";
import {debounceTime, mergeMap} from "rxjs/operators";
import {PaginatorSmartComponent} from "../../../paginator-smart/components/paginator-smart.component";


@Component({
  selector: 'app-skill-drag-drop-list',
  templateUrl: './skill-drag-drop-list.component.html',
  styleUrls: ['./skill-drag-drop-list.component.scss']
})
export class SkillDragDropListComponent implements OnInit, AfterViewInit {


  displayedColumnsLeft: string[] = ['cluster', 'category', 'skill', 'action'];
  displayedColumnsRight: string[] = ['cluster', 'category', 'skill', 'level', 'action'];
  rightDataSource: MatTableDataSource<SkillWithMinimumLevel> = new MatTableDataSource<SkillWithMinimumLevel>();
  leftDataSource: MatTableDataSource<SkillWithMinimumLevel> = new MatTableDataSource<SkillWithMinimumLevel>();
  skills: SkillWithMinimumLevel[] = [];
  searchFormControl = new FormControl();

  operator = "AND";
  skillsBySkillId = new Map<number, { formControl: FormControl }>();
  experienceLevels: string[];

  loadingLocal = false;

  _selectedSkillgroup: Skillgroup;
  @Input()
  set selectedSkillgroup(sk: Skillgroup) {
    this._selectedSkillgroup = sk;
    this.skills = [];
    this.searchFormControl.setValue("");
    this.operator = (sk.andOperator) ? "AND" : "OR";
  }

  get selectedSkillgroup(): Skillgroup {
    return this._selectedSkillgroup;
  }

  @Input()
  showInvisibleSkills: boolean;

  @Input()
  showLocal;

  @Input() set skillsRight(skills: SkillWithMinimumLevel[]) {
    skills.sort((a, b) => a.skill.skillName > b.skill.skillName ? 1 : (a.skill.skillName < b.skill.skillName ? -1 : 0));
    this.rightDataSource.data = skills;
    if (skills) {
      skills.forEach(item => {
        this.skills.push(item);
        this.skillsBySkillId.set(item.skill.id, {formControl: new FormControl(item.minimumLevel)});
      });

    }

  }

  @Output() setSkills = new EventEmitter<any>();
  @Output() setOperator = new EventEmitter<any>();
  @Output() loading = new EventEmitter<boolean>();

  @ViewChild('rightTable') rightTable: MatTable<SkillWithMinimumLevel>;
  @ViewChild('rightPaginator') rightPaginator: PaginatorSmartComponent;

  @ViewChild('leftTable') leftTable: MatTable<SkillWithMinimumLevel>;
  @ViewChild('leftPaginator') leftPaginator: PaginatorSmartComponent;


  constructor(private _translate: TranslateService,
              private employeeService: EmployeeService,
              private customizingService: CustomizingService) {
  }

  async ngOnInit() {

    this.experienceLevels = await this.customizingService.getSkillLevels();

    this.searchFormControl.valueChanges.pipe(
      debounceTime(400), // Time in milliseconds between key events
      mergeMap((text: string) => {
        if (text === '') {
          return of([]);
        } else {
          this.loading.emit(true);
          return from(this.employeeService.getAllSkillsBySearchTerm(text, this.showInvisibleSkills));
        }
      })
      // subscription for response
    ).subscribe((data: Skill[]) => {
      let skillLevels: SkillWithMinimumLevel[] = [];
      data.forEach(skill => {
        skillLevels.push({skill: skill, minimumLevel: 1});
      });
      this.leftDataSource.data = skillLevels.filter(s => !this.rightDataSource.data.find(r => r.skill.id === s.skill.id));
      this.loading.emit(false);
    });
  }

  ngAfterViewInit(): void {
    this.leftDataSource.paginator = this.leftPaginator.basePaginator;
    this.rightDataSource.paginator = this.rightPaginator.basePaginator;
    this.updateTable();
  }

  drop(event: CdkDragDrop<SkillWithMinimumLevel[]>, currentIndexOffset: number, previousIndexOffset: number) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex + previousIndexOffset,
        event.currentIndex + currentIndexOffset);

      this.skills = [];
      this.rightDataSource.data.forEach(item => this.skills.push(item));
      let skillsAndOperator = {
        skills: this.skills,
        andOperator: (this.operator === "AND")
      };
      this.setSkills.emit(skillsAndOperator);
    }

    this.updateTable();
  }


  updateTable() {
    this.rightDataSource.data = this.rightDataSource.data;
    this.leftDataSource.data = this.leftDataSource.data;
  }

  getPaginatorOffset(paginator: MatPaginator): number {
    return paginator.pageSize * paginator.pageIndex;
  }

  isInRightTable(row: Skill) {
    return !this.tableDoesntIncludeEmployee(this.rightDataSource.data, row);
  }

  leftToRight(row: SkillWithMinimumLevel) {
    transferArrayItem(this.leftDataSource.data,
      this.rightDataSource.data,
      this.leftDataSource.data.indexOf(row),
      0);
    this.skills.push(row);
    let skillsAndOperator = {
      skills: this.skills,
      andOperator: (this.operator === "AND")
    };

    this.setSkills.emit(skillsAndOperator);

    this.updateTable();
  }

  rightToLeft(row: SkillWithMinimumLevel) {
    transferArrayItem(this.rightDataSource.data,
      this.leftDataSource.data,
      this.rightDataSource.data.indexOf(row),
      0);
    this.skills = this.skills.filter(item => item.skill.id !== row.skill.id);

    let skillsAndOperator = {
      skills: this.skills,
      andOperator: (this.operator === "AND")
    };
    this.setSkills.emit(skillsAndOperator);

    this.updateTable();
  }

  private tableDoesntIncludeEmployee(table: SkillWithMinimumLevel[], skill: Skill) {
    return table.find(row => row.skill.id === skill.id) === undefined; // >>> What is accountName ???
  }

  toggleOperator() {
    this.loadingLocal = true;
    let skillsAndOperator = {
      skills: this.skills,
      andOperator: (this.operator === "AND")
    };
    this.loadingLocal = false;
    this.setOperator.emit(skillsAndOperator);
  }

  isSkillFreeText(skill: Skill): boolean {
    return skill && skill.skillName && skill.skillName.startsWith("other");
  }

  public isCategoryOther(skill: Skill) {
    return skill.skillName.startsWith('Other');
  }
}
