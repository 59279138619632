import {Directive, HostListener} from '@angular/core';

@Directive({
  selector: '[appOnlyNumbers]'
})
export class OnlyNumbersDirective {

  private allowedNumbers: string[] = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', ','];

  constructor() {}

  @HostListener('keypress', ['$event'])
  onKeyPress(event: KeyboardEvent) {
    if (!this.allowedNumbers.includes(event.key)) {
      event.preventDefault();
    }
  }
}
