import {Injectable} from "@angular/core";
import {CustomizingService} from "./customizing.service";
import {AuthenticationService} from "./authentication.service";

export enum Preference {
  CURRENCY = "currency",
  START_DATE = "startDate",
  ABSENCE_NUMBER_DAYS = "absenceNumberDays",
  CAPACITY_COLORS = "capacityColors",
  CAPACITY_NUMBER_MONTHS = "capacityNumberMonths",
  CAPACITY_LIST_ELEMENTS = "capacityListElements",
  MY_PROJECTS_SHOW_ALL_PROJECTS = "myProjectsShowAllProjects",
  MY_PROJECTS_WITHOUT_EMPLOYEES = "myProjectsWithoutEmployees",
  TREND_ANALYSIS_MONTH_FROM = "trendAnalysisMonthFrom",
  TREND_ANALYSIS_MONTH_TO = "trendAnalysisMonthTo",
  PAGINATION_LIST_ELEMENTS = "paginationListElements"
}

interface Storage {
  getItem(key: string): string;

  setItem(key: string, value: string): void;

  removeItem(key: string): void;
}

class SessionStorage implements Storage {
  storage: { [key: string]: string } = {};

  getItem(key: string): string {
    return this.storage[key];
  }

  setItem(key: string, value: string): void {
    this.storage[key] = value;
  }

  removeItem(key: string) {
    this.storage[key] = undefined;
  }
}

@Injectable({
  providedIn: 'root'
})
export class PreferencesService {

  readonly prefToStorage: { [type in Preference]: Storage };

  constructor(private customizingService: CustomizingService, private authenticationService: AuthenticationService) {
    const sessionStorage: Storage = new SessionStorage();
    const persistentStorage: Storage = localStorage;

    // This dictionary decides whether a preference should be stored in a "session" storage or the localstorage
    this.prefToStorage = {
      currency: persistentStorage,
      startDate: persistentStorage,
      absenceNumberDays: persistentStorage,
      capacityColors: sessionStorage,
      capacityNumberMonths: persistentStorage,
      capacityListElements: persistentStorage,
      myProjectsShowAllProjects: persistentStorage,
      myProjectsWithoutEmployees: persistentStorage,
      trendAnalysisMonthFrom: persistentStorage,
      trendAnalysisMonthTo: persistentStorage,
      paginationListElements: persistentStorage,
    };
  }

  async get(preference: Preference, component?: string) {
    const isPreferencesLocalStorage = await this.customizingService.isPreferencesLocalStorage();

    if (isPreferencesLocalStorage) {
      const key = await this.keyForPref(preference, component);

      return this.prefToStorage[preference].getItem(key) || undefined;
    }
    return undefined;
  }

  async put(preference: Preference, value: string, component?: string) {
    const isPreferencesLocalStorage = await this.customizingService.isPreferencesLocalStorage();

    if (isPreferencesLocalStorage) {
      const key = await this.keyForPref(preference, component);

      this.prefToStorage[preference].setItem(key, value);
    }
  }

  async remove(preference: Preference, component?: string) {
    const key = await this.keyForPref(preference, component);
    this.prefToStorage[preference].removeItem(key);
  }

  private async keyForPref(preference: Preference, component?: string): Promise<string> {
    const user = await this.authenticationService.getApplicationUser();
    const componentSuffix = component ? `/${component}` : '';
    return `${user.username}/${preference}${componentSuffix}`;
  }
}
