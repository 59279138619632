import {BaseEntity} from "../../../core/models/BaseEntity";
import {FilterViewData} from "../../../shared/dialogs/filter-dialog/filter-dialog.interface";
import Config from "../../../core/config/Config";
import {SkillWithMinimumLevel} from "../../../core/models/EmployeeSkill";
import {StaffingComment} from "./staffing-comment";
import {EnumMapper} from "../../../core/services/services/enumMapper.service";
import {Client} from "../../../core/config/Client";
import {FormControl} from "@angular/forms";
import {ResourceRequestFile} from "./resource-request-file";
import {ResourceRequestListService} from "../services/resource-request-list.service";

export class ResourceRequest extends BaseEntity {
  origId: number;
  requestTitle: string;
  commitmentStart: string;
  commitmentEnd: string;
  probability: number;
  optionOnFollowUpBusiness: boolean;
  client: Client;
  currentProject?: any;
  skillDescriptionOfCustomer?: string;
  internalSkillset?: SkillWithMinimumLevel[];
  skillOperator: string;
  desiredCandidate?: string;
  requiredEmployees: number;
  notes: string;
  dispoFlag: boolean;
  requestingUnit: string;
  requestedBy: any;
  requestedFrom: any;
  compensation: number;
  currency: any;
  ratesDetails: string;
  travelExpensePolicy: string;
  scope: number;
  scopeComment: string;
  location: string;
  travelMode: string;
  requestStatus: string;
  statusComment: string;
  travelModeDetails?: string;
  staffingTag: string[];
  businessUnits: string[];
  divisions: string[];
  responsibleDE: any;
  responsibleRO: any;
  requestReceipt: string;
  answerUntil: string;
  offered: any;
  staffingComments?: StaffingComment[];
  finishingDate?: string;
  totalScope: number;
  files: ResourceRequestFile[];
}

export class ResourceRequestOverviewDTO extends BaseEntity {
  id: number;
  origId: number;
  createdAt: string;
  requestTitle: string;
  commitmentStart: string;
  commitmentEnd?: string;
  probability: string;
  client: Client;
  currentProject?: string;
  requiredEmployees: number;
  scope: number;
  location: string;
  travelMode: string;
  staffingTag: string[];
  businessUnits: string[];
  divisions: string[];
  responsibleDE: string;
  responsibleRO: string;
  requestReceipt: string;
  answerUntil: string;
  requestStatus: string;
  resubmissionDate?: string;
  requestStatusControl: FormControl;
  totalScope: number;
  offered: string[];
  dispoFlag: boolean;

}

export interface ResourceRequestFilterViewModel {
  filterData: ResourceRequestFilterData;
  viewData: ResourceRequestViewData;
}

export class ResourceRequestFilterData {
  requestTitle = '';
  staffingTag: [];
  businessUnits = [];
  divisions = [];
  client = '';
  requestStatus = [];
  location = '';
  travelMode = [];
  responsibleDE = '';
  responsibleRO = '';
  onlyMyBusinessUnit: boolean;
  dispoFlag: boolean;
}

export class ResourceRequestViewData implements FilterViewData {
  staffingTag = [];
  businessUnits = [];
  dispoFlag = [];
  divisions = [];
  requestStatus = [];
  travelMode = [];
  currentClient: Client;

  constructor(private resourceRequestService: ResourceRequestListService, currentClient: Client) {
    this.currentClient = currentClient;
  }

  async loadData(): Promise<void> {
    this.travelMode = await this.setTravelMode();
    this.requestStatus = await this.setRequestStatus();
    this.staffingTag = await this.setStaffingTag();
    this.businessUnits = await this.setBusinessUnits();
    this.divisions = await this.setDivisions();
  }

  private async setBusinessUnits() {
    return await Promise.all(Object.keys(Config.services.employees[EnumMapper.getConfigObjectForClient('businessUnit', this.currentClient)]).sort().map(k => ({
      value: k,
      viewValue: Config.services.employees[EnumMapper.getConfigObjectForClient('businessUnit', this.currentClient)][k]
    })));
  }

  private async setDivisions() {
    return await Promise.all(Object.keys(Config.services.employees[EnumMapper.getConfigObjectForClient('division', this.currentClient)]).sort().map(k => ({
      value: k,
      viewValue: Config.services.employees[EnumMapper.getConfigObjectForClient('division', this.currentClient)][k]
    })));
  }

  private async setStaffingTag() {
    let staffingTag = await this.resourceRequestService.getStaffingTags().toPromise();
    return staffingTag.sort().map(k => {
      return {value: k, viewValue: k};
    });
  }

  private async setRequestStatus() {
    return await Promise.all(Object.keys(Config.services.resource_request.status).sort().map(k => ({
      value: k,
      viewValue: Config.services.resource_request.status[k]
    })));
  }

  private async setTravelMode() {
    return await Promise.all(Object.keys(Config.services.resource_request.travelMode).sort().map(k => ({
      value: k,
      viewValue: Config.services.resource_request.travelMode[k]
    })));
  }
}
