import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AutofocusOnInitDirective} from "./directive/autofocus-on-init.directive";

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    AutofocusOnInitDirective
  ],
  exports: [
    AutofocusOnInitDirective
  ]
})
export class AutofocusModule {}
