import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'truncateText', pure: true})
export class TruncateTextPipe implements PipeTransform {

  transform(link: string, maxLength: number): string {
    if (typeof link !== 'string') {
      return link;
    } else {
      if (link.trim().length <= maxLength) {
        return link.trim();
      } else {
        return link.substring(0, maxLength).trim() + ' ... ';
      }
    }
  }
}
