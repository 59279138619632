import {Category, Cluster, Skill} from "../../../core/models/EmployeeSkill";

export class EditSkillDialogInformation {
  skill: Skill;
  isAddMode: boolean;
  allClusters: Cluster[];
  allCategories: Category[];

  constructor(skill: Skill, isAddMode: boolean, allClusters: Cluster[], allCategories: Category[]) {
    this.skill = skill;
    this.isAddMode = isAddMode;
    this.allClusters = allClusters;
    this.allCategories = allCategories;
  }
}
