import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {TranslatableComponent} from '../../../core/models/TranslatableComponent';
import {MatTableDataSource} from '@angular/material/table';

@Component({
  selector: 'app-language-tooltip',
  templateUrl: './language-tooltip.component.html',
  styleUrls: ['./language-tooltip.component.scss']
})

export class LanguageTooltipComponent extends TranslatableComponent implements OnInit {

  tooltipColumns: string[] = [
    'level',
    'shortText',
    'definition'
  ];

  tooltip = new MatTableDataSource();

  constructor(private _translateService: TranslateService) {
    super(_translateService, 'EditLanguagesDialog');
  }

  ngOnInit() {
    this.buildTooltip();
  }

  private buildTooltip() {
    let levels = ['0', 'a1', 'a2', 'b1', 'b2', 'c1', 'c2'];
    let tooltipRow;
    for (let i of levels) {
      tooltipRow = {
        'level' : '',
        'shortText': '',
        'definition': ''
      };
      tooltipRow.level = i.toUpperCase();
      tooltipRow.shortText = this._lang('tooltip_short_term.level_'.concat(i));
      tooltipRow.definition = this._lang('tooltip_definition.level_'.concat(i));
      this.tooltip.data.push(tooltipRow);
    }
  }
}
