import {fromEvent, merge, Observable, of} from "rxjs";
import {AfterViewInit, Directive, EventEmitter, Input, OnInit, Output, ViewChild} from "@angular/core";
import {MatAutocomplete} from "@angular/material/autocomplete";
import {filter, map, mergeMap} from "rxjs/operators";
import {AbstractControl} from "@angular/forms";

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class AbstractAutocomplete<T> implements OnInit, AfterViewInit {
  private elements: T[];
  filteredElements: Observable<T[]>;

  loading = true;

  @Input() initialFilter: (value: T, index: number, array: T[]) => boolean;
  @Input() hideElement: (value: T) => boolean;
  @Output() elementSelected: EventEmitter<T> = new EventEmitter();
  @Input()
  inputFormControl: AbstractControl;
  @Input() inputElement: HTMLInputElement;
  @Input() showSelectedOption = true;
  @Input() smallFont = false;

  @ViewChild(MatAutocomplete, {static: true}) public matAutocomplete;

  ngOnInit() {
    this.initialize();
  }

  ngAfterViewInit() {
    if (this.inputFormControl) {
      this.registerSubscriptionForObservable(this.inputFormControl.valueChanges);
    } else if (this.inputElement) {
      this.registerSubscriptionForObservable(this.getObservableFromInputElement());
    } else {
      throw new Error("Neither a formControl nor an inputElement is supplied for this autocomplete.");
    }
  }

  private getObservableFromInputElement(): Observable<string> {
    return merge(fromEvent(this.inputElement, 'keyup'), fromEvent(this.inputElement, 'focus'))
      .pipe(map(() => this.inputElement.value));
  }

  private registerSubscriptionForObservable(observable: Observable<any>): void {
    this.filteredElements = observable.pipe(
      filter(val => typeof val === 'string'), // Ignore value changes that result of selecting a possible drop down
      mergeMap((searchTerm: string) => this.filterElements(searchTerm)));
  }

  filterElements(value: string): Observable<T[]> {
    if (value !== 'unassigned' && this.elements) {
      return of(this.elements.filter(elem => this.filter(elem, value) && (!this.hideElement || !this.hideElement(elem))));
    } else {
      return of([]);
    }
  }

  initialize() {
    this.fetchElements().then((elements: T[]) => {
      this.loading = true;

      elements.sort(this.sort);

      if (this.initialFilter) {
        elements = elements.filter(this.initialFilter);
      }

      this.elements = elements;

      this.loading = false;
    }, () => null);
  }

  /**
   * Creates a string (or html) representation of an element
   * Used for creating a drop down option
   *
   * @param element the element to be represented as a string (or html)
   */
  displayAsDropDownOption(element: T): string {
    return this.displayAsSelectedOption(element);
  }

  /**
   * Creates a string representation of an element
   * Will be returned to the attached input field
   *
   * @param element the element to be represented as a string
   */
  abstract displayAsSelectedOption(element: T): string;

  displayAsSelectedOptionEmpty(): string {
    return '';
  }

  abstract fetchElements(): Promise<T[]>;

  abstract filter(element: T, value: string): boolean;

  abstract sort(element: T, otherElement: T): number;
}
