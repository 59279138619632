export default {
  MarketUnit: {
    INNOVAS: 'Innovas',
    MINNOSPHERE: 'Minnosphere',
    MSG_SERVICES: 'msg services',
    MSG_GILLARDON: 'msg gillardon',
    MSG_GLOBAL: 'msg global',
    MSG_INDUSTRY_ADVISORS: 'msg industry advisors',
    MSG_TREORBIS: 'msg treorbis',
    MSG_AT: 'msg.AT',
    MSG_CH: 'msg.CH',
    MSG_LIFE: 'msg.life',
    PLAUT_AT: 'Plaut AT',
    PLAUT_DE: 'Plaut DE',
    SDA_SE: 'SDA SE',
    XBI: 'XBI',
    GBA: 'GBA',
    GBF: 'GBF',
    GBG: 'GBG',
    GBI: 'GBI',
    GBM: 'GBM',
    GBP: 'GBP',
    GBS: 'GBS',
    GBO: 'GBO',
    GBL: 'GBL',
    GBY: 'GBY',
    GB_ITC_GFG: 'GB ITC GFG',
    XQT: 'XQT',
    MSG_NEXINSURE: 'msg nexinsure',
    M3: 'm3',
    DX: 'DX',
    GBD: 'GBD',
    // hofmanma, 2019/05/17, Remove the following Divisions
    // NEXINSURE: 'nexinsure',
    // MSG_INDURSTRY_ADVISORS: 'msg industry advisors'
    // hofmanma, 2019/05/17, Added the following Divisions:
    MSG_DAVID: 'msg.DAVID',
    ZA: 'ZA',
    ZT: 'ZT',
    ZM: 'ZM - Zentralbereich Marketing',
    ZG: 'ZG - Geschäftsanwendungen',
    BELTIOS: 'BELTIOS',
    CONPLAN: 'conplan',
    GB_MARZIPAN: 'GB MARZIPAN',
    SOFTPROVIDING: 'Softproviding',
    NEXONTIS: 'nexontis',
    // 21.10.2022 added the following Divisions:
    GBV: 'GBV',
    HSC: 'HSC',
    HDS: 'HDS',
    XAI: 'XAI',
    GBR: 'GBR'
  }
};
