import {TranslateService} from "@ngx-translate/core";
import {
  ChipGroupTemplate,
  ChipTemplate,
  DropDownChipGroupTemplate,
  FilterChip,
  FilterChipGroup,
  StandardChipTemplate
} from "../../shared/filter-chip-list/components/filter-chip-list.interface";


export class Chips {
  private static _chipsWrapTreasure = 2;
  private _filterChips: FilterChip[];
  private _filterChipGroups: FilterChipGroup[];

  private readonly _filterChipTemplates: ChipTemplate[];
  private readonly _filterChipGroupTemplates: ChipGroupTemplate[];

  public static get chipsWrapTreasure() {
    return Chips._chipsWrapTreasure;
  }

  public get filterChips() {
    return this._filterChips;
  }

  public get filterChipGroups() {
    return this._filterChipGroups;
  }

  constructor(private standardOptions: string[], private dropDownOptions: string[], private filterViewModel, translate: TranslateService,
              customChipTemplates: ChipTemplate[] = [], customChipGroupTemplates: ChipGroupTemplate[] = []) {
    this._filterChipTemplates = customChipTemplates.concat([new StandardChipTemplate(filterViewModel, standardOptions, translate)]);
    this._filterChipGroupTemplates = customChipGroupTemplates.concat([new DropDownChipGroupTemplate(filterViewModel, dropDownOptions)]);
    this.generateChips();
  }

  generateChips() {
    this._filterChips = this.flattenArrays(this._filterChipTemplates.map(template => template.getAllFilterChips()));
    this._filterChipGroups = [];

    const groups = this.flattenArrays(this._filterChipGroupTemplates.map(template => template.getAllFilterChipGroups()));

    // Only add groups with a name and more then one chips to the group array
    groups.forEach(group => {
      if (group.name === null || group.filterChips.length === 1) {
        group.filterChips.forEach(chip => {
          this._filterChips.push(chip);
        });
      } else {
        this._filterChipGroups.push(group);
      }
    });
  }

  private flattenArrays<T>(array: T[][]): T[] {
    return [].concat.apply([], array);
  }
}
