import {Href} from './href';
import {BaseEntity} from "./BaseEntity";
import {ProjectStatus} from "./Enums/ProjectStatus";
import {SourceCodeStorageLocation} from "./Enums/SourceCodeStorageLocation";

export interface ProjectName {
  projectName: string;
  client: string;
  probabilitySP: number;
  psp: string;
  status: ProjectStatus;
  sharePointId: string;
  businessUnit?: string;
  startDate: string;
  endDate: string;
  id: number;
  _links: Link;
}

export interface SourceCodeStorage {
  sourceCodeStorageLocation?: SourceCodeStorageLocation;
  stackIntegratedStorage?: string;
  msgHub?: string;
  cloudSourceStorage?: string;
  reasonForOtherCloudSource?: string;
  sourceCodeForm?: boolean;

}

/**
 * Project model
 * TODO: Always check again backend models changes and update me.
 */
export class Project extends BaseEntity implements ProjectName {
  id: number;
  projectName: string;
  client: string;
  status: any;
  divisionManager?: any;
  projectLeader?: any;
  marketUnitContact?: string;
  marketUnit?: any;
  businessUnit?: string;
  startDate: string;
  endDate: string;
  probabilitySP: number;
  technologyStack?: string;
  plCluster?: string;
  psp: string;
  furtherInfo?: string;
  description?: string;
  priority?: number;
  projectCategory?: string;
  division?: string;
  strategicObjective?: string;
  branch?: string;
  businessTopic?: string;
  mainCluster?: string;
  annotation?: string;
  new?: boolean;
  _links: Link;
  sharePointId: string;
  mappingString?: string;
  billingType?: string;
  checkProjectEnd?: boolean;
  deliveryType?: any;
  monthlyReporting?: boolean;
  effortControlling?: boolean;
  informationSecurityRelevant?: any;
  critically?: any;
  linkIS?: string;
  responsibleAL?: any;
  responsibleHeadOfDepartment?: any;
  stackIntegratedStorage?: string;
  msgHub?: string;
  cloudSourceStorage?: string;
  reasonForOtherCloudSource?: string;
  sourceCodeStorageLocation?: SourceCodeStorageLocation;
  resortManager?: any;
  totalScope?: number;
  lastModifiedAt?: any;
  lastModifiedBy?: string;
  protectionNeedsAssessment?: any;
  projectScopePd?: number;
}

export class ProjectWithDivisionsAndDepartments extends Project {
  divisionsOfEmployees: string[];
  departmentsOfEmployees?: string[];
}


export class SourceCodeStorage extends Project {
  sourceCodeStorageLocation?: SourceCodeStorageLocation;
  stackIntegratedStorage?: string;
  msgHub?: string;
  cloudSourceStorage?: string;
  reasonForOtherCloudSource?: string;

}

/**
 * Project link model
 */
export class Link {
  self: Href;
  project: Href;
  assignments: Href;
  divisionManager: Href;
  leader: Href;
  projectTimeSlices: Href;
}
