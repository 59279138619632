import {Component, ElementRef, Inject, OnInit, Optional, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {TranslateService} from "@ngx-translate/core";
import {TranslatableComponent} from "../../../../../core/models/TranslatableComponent";
import {Employee} from "../../../../../core/models/Employee";

@Component({
  selector: 'app-offerd-employees',
  templateUrl: './offerd-employees.component.html',
  styleUrls: ['./offerd-employees.component.css']
})
export class OfferdEmployeesComponent extends TranslatableComponent implements OnInit {
  @ViewChild('appEmployeeList') appEmployeeList: ElementRef;
  @ViewChild('appProjectAssignedEmployeesList') appProjectAssignedEmployeesList: ElementRef;
  rightDataSource: Employee[] = [];

  constructor(public dialogRef: MatDialogRef<OfferdEmployeesComponent>,
              private _translateService: TranslateService,
              @Optional() @Inject(MAT_DIALOG_DATA) public data: Employee[]) {
    super(_translateService, 'OfferdEmployeesComponent');
  }

  ngOnInit(): void {
    this.rightDataSource = this.data;
  }

  /**
   * Closes the dialog
   */
  closeDialog() {
    this.dialogRef.close(this.rightDataSource);
  }

}
