import {Observable, of} from "rxjs";
import {Revenue} from "../../../feature/employee-capacity-container/revenue/revenue";
import {AlertEnum} from "../../../core/models/Enums/AlertEnum";
import * as XLSX from "xlsx";
import {TranslateService} from "@ngx-translate/core";
import moment, {Moment} from "moment";
import {EmployeeCapacityListElement} from "./employee-capacity-list.interface";
import {Export, ExportParameter, NoParameterExport} from "../../export-dialog/export";
import {MessageService} from "../../../core/services/services/message.service";
import {ProjectFileService} from "../../../core/services/services/projectFile.service";

export class EmployeeCapacityListCapacityExport extends NoParameterExport {
  nameKey = 'mep.components.export-dialog.exportType.CAPA';

  constructor(private messageService: MessageService, private translate: TranslateService, private revenueExportData: Revenue[]) {
    super();
  }

  getFileNamePrefix(): string {
    return "Revenue_Capacities_export";
  }

  export(): Observable<BlobPart> {
    if (this.revenueExportData !== undefined) {
      return new Observable<BlobPart>(observer => {
        const result = this.exportAsExcelFile(this.revenueExportData);
        observer.next(result);
        observer.complete();
      });
    } else {
      this.messageService.add(this._lang('notification.revenue-error'), AlertEnum.danger);
      return of(undefined);
    }
  }

  private exportAsExcelFile(json: Revenue[]): any {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json, {skipHeader: true});
    const workbook: XLSX.WorkBook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
    return XLSX.write(workbook, {bookType: 'xlsx', type: 'array'});
  }

  private _lang(key: string): string {
    return this.translate.instant('mep.components.export-dialog.' + key);
  }
}


export class EmployeeCapacityListExport extends NoParameterExport {
  nameKey = 'mep.components.export-dialog.exportType.EMPLOYEE_CAPACITIES';
  private employeeIds: number[];

  constructor(private filterData: EmployeeCapacityListElement[], private projectFileService: ProjectFileService, private readonly startDate: Moment, private plannedCapa: boolean) {
    super();
    if (!this.startDate) {
      this.startDate = moment();
    }
    this.employeeIds = filterData.map(data => data.employee.id);
  }

  export(): Observable<BlobPart> {
    let startDateAsString = this.startDate.format('MM_YYYY');

    return this.projectFileService.downloadEmployeeCapacitiesAsExcel(this.employeeIds, startDateAsString, this.plannedCapa);
  }

  getFileNamePrefix(): string {
    return "Capacities";
  }
}

export class EmployeeCapacityListAvailableEmployeesExport implements Export {
  exportParameters: ExportParameter[] = [
    {
      nameKey: 'mep.components.export-dialog.from',
      type: 'monthYear',
      value: moment(new Date()).format("YYYY-MM").toString()
    },
    {
      nameKey: 'mep.components.export-dialog.until',
      type: 'monthYear',
      value: moment(new Date()).add(3, "months").format("YYYY-MM").toString()
    },
    {
      nameKey: 'mep.components.export-dialog.skill-amount',
      type: 'number',
      value: 3,
      tooltipKey: "mep.components.export-dialog.skill-amount-tooltip"
    },
    {
      nameKey: 'mep.components.export-dialog.skill-level',
      type: 'skillLevel',
      value: "1",
      tooltipKey: "mep.components.export-dialog.skill-level-tooltip"
    },
    {
      nameKey: 'mep.components.export-dialog.checkbox',
      type: 'boolean',
      value: true
    }
  ];
  nameKey = 'mep.components.export-dialog.exportType.AVAILABLE_EMPLOYEES';
  nameTooltipKey = 'mep.components.export-dialog.capacity.availableEmployeesTooltip';

  constructor(private projectFileService: ProjectFileService, private underloadBorder: number, private messageService: MessageService, private translate: TranslateService) {

  }

  private static tryAddjustSimple(date: string) {
    date = date.trim();
    let adjustPattern = /^[1-9][0-9][0-9][0-9]-[1-9]$/;
    if (adjustPattern.test(date)) {
      date = date.replace("-", "-0");
    }
    return date;
  }

  export(): Observable<BlobPart> {
    let start = EmployeeCapacityListAvailableEmployeesExport.tryAddjustSimple(this.getStartYearMonth());
    let end = EmployeeCapacityListAvailableEmployeesExport.tryAddjustSimple(this.getEndYearMonth());

    if (((!this.isValidDate(start) || (!this.isValidDate(end))))) {
      (this.messageService.add(this.translate.instant('mep.components.export-dialog.error.invalid-date'), AlertEnum.danger))
      return of(null);
    } else if (Date.parse(<string>this.exportParameters[0].value) > Date.parse(<string>this.exportParameters[1].value)) {
      this.messageService.add(this.translate.instant('mep.components.export-dialog.error.date-error'), AlertEnum.danger);
      return of(null);
    }

    return this.projectFileService.downloadAvailableEmployeeCapacitiesExcel(start, end, this.underloadBorder, this.areExternalEmployeesExcluded(),
      this.skillAmount(), this.skillLevel());
  }

  getFileNamePrefix(): string {
    return "free_employees";
  }

  isValidDate(date): boolean {
    if (moment(date, "YYYY-MM", true).isValid())
      return true;
  }

  parametersValid(): boolean {
    return this.exportParameters[0].value != null && this.exportParameters[1].value != null && this.exportParameters[2].value != null && this.exportParameters[3].value != null

  }

  private getStartYearMonth(): string {
    return this.exportParameters[0].value as string;
  }

  private getEndYearMonth(): string {
    return this.exportParameters[1].value as string;
  }

  private skillAmount(): number {
    return this.exportParameters[2].value as number;
  }

  private skillLevel(): number {
    return this.exportParameters[3].value as number;
  }


  private areExternalEmployeesExcluded(): boolean {
    return this.exportParameters[4].value as boolean;
  }
}
