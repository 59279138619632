import {Component, OnInit} from '@angular/core';
import {Observable} from "rxjs";
import {TranslatableComponent} from "../../models/TranslatableComponent";
import {TranslateService} from "@ngx-translate/core";
import {ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router} from "@angular/router";
import {Breadcrumb, BreadcrumbsService} from "@exalif/ngx-breadcrumbs";
import {filter} from "rxjs/operators";

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent extends TranslatableComponent implements OnInit {

  o: Observable<Breadcrumb[]>;
  iconName: string;

  constructor(
    public breadcrumbService: BreadcrumbsService,
    private t: TranslateService,
    private route: ActivatedRoute,
    private router: Router) {
    super(t, 'BreadcrumbComponent');
  }

  private static lastChild(route: ActivatedRouteSnapshot): ActivatedRouteSnapshot {
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route;
  }

  ngOnInit() {
    this.o = this.breadcrumbService.crumbs$;
    this.o.subscribe((items: Breadcrumb[]) => {
      const filteredItems: Breadcrumb[] = items.filter((item: Breadcrumb) => item.text.startsWith("/"));
      filteredItems.forEach(item => item.path = item.text);
    });
    this.router.events.pipe(
      filter(e => e instanceof NavigationEnd)
    ).forEach(_ => {
      this.iconName = BreadcrumbComponent.lastChild(this.route.root.firstChild.snapshot).data['breadCrumbIcon'];
    });
  }
}
