import {ChangeDetectorRef, Component, ElementRef, Input, ViewChild} from "@angular/core";
import {COMMA, ENTER, SEMICOLON} from "@angular/cdk/keycodes";
import {MatChipInputEvent} from "@angular/material/chips";
import {AbstractControl, FormControl, FormGroup} from "@angular/forms";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {map, startWith} from "rxjs/operators";
import {Observable} from "rxjs";
import {
  ResourceRequestListService
} from "../../../feature/resource-request-list/services/resource-request-list.service";

@Component({
  selector: 'app-staffing-tag-autocomplete-chip',
  templateUrl: './staffing-tag-autocomplete-chip.component.html',
  styleUrls: ['./autocomplete.component.scss']
})
export class StaffingTagAutocompleteChipComponent {

  @Input()
  disabled: boolean;

  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;

  staffingForm: FormGroup;
  separatorKeysCodes: number[] = [ENTER, COMMA, SEMICOLON];
  allTags: string[];
  tagCtrl = new FormControl();
  filteredTags: Observable<string[]>;
  selectedTags: string[];
  tagFormControl: AbstractControl;
  staffingTag: string;

  constructor(private resourceRequestService: ResourceRequestListService, private cdref: ChangeDetectorRef) {
    this.getFilteredTags()
  }


  public init(staffingForm: FormGroup): void {
    this.staffingForm = staffingForm;
    this.tagFormControl = this.staffingForm.get("staffingTag");
    this.staffingTag = this.tagFormControl.value;
    this.fetchElements().then(tags => {
      this.allTags = tags;
      this.getSelectedTags();
      this.getFilteredTags();
    });
  }

  getSelectedTags() {
    this.selectedTags = this.staffingTag ? this.staffingTag.split(/,|;/) : [];
    this.selectedTags = this.selectedTags.map(tag => tag.trim());
  }

  getFilteredTags() {
    this.filteredTags = this.tagCtrl.valueChanges.pipe(startWith(null),
      map((tag: string | null) => (tag ? this._filter(tag) : this.allTags?.slice())))
  }

  remove(tag: string) {
    const index = this.selectedTags.indexOf(tag);
    if (index >= 0) {
      this.selectedTags.splice(index, 1);
      this.getFilteredTags();
      this.updateTagString();
      this.cdref.detectChanges();
    }
  }

  addOnBlur(event: FocusEvent) {
    const target: HTMLElement = event.relatedTarget as HTMLElement;
    if (!target || target.tagName !== 'MAT-OPTION') {
      const matChipEvent: MatChipInputEvent = {input: this.tagInput.nativeElement, value : this.tagInput.nativeElement.value};
      this.add(matChipEvent);
    }
  }

  add(event: MatChipInputEvent) {
    const value = (event.value || '').trim();

    // Add the selected tag
    if (value) {
      this.selectedTags.push(value);
      this.getFilteredTags();
    }

    // Clear the input value
    event.input.value = '';

    this.updateTagString();
  }

  selected(event: MatAutocompleteSelectedEvent) {
    this.selectedTags.push(event.option.viewValue);
    this.tagInput.nativeElement.value = '';
    this.getFilteredTags();
    this.updateTagString();
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.allTags.filter(tag => tag.toLowerCase().includes((filterValue)))
  }

  private updateTagString() {
    this.staffingTag = this.selectedTags.join(", ");
    this.tagFormControl.setValue(this.staffingTag);
    this.tagFormControl.markAsDirty();
  }

  private async fetchElements(): Promise<string[]> {
    return await this.resourceRequestService.getStaffingTags().toPromise();
  }
}
