import {NgModule} from "@angular/core";
import {OnlyNumbersDirective} from "./onlyNumbers.directive";
import {SanitizeInputDirective} from "./sanitize-input.directive";
import {CommonModule} from "@angular/common";

@NgModule({
  imports: [CommonModule],
  declarations: [OnlyNumbersDirective, SanitizeInputDirective],
  exports: [OnlyNumbersDirective, SanitizeInputDirective]
})
export class InputDirectivesModule {
}
