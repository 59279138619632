import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ProjectAutocompleteComponent} from './components/project-autocomplete.component';
import {SkillAutocompleteComponent} from './components/skill-autocomplete.component';
import {EmployeeAutocompleteComponent} from "./components/employee-autocomplete.component";
import {EmployeeFrontendAutocompleteComponent} from "./components/employee-frontend-autocomplete.component";
import {EmployeeTagAutocompleteChipComponent} from "./components/employee-tag-autocomplete-chip.component";
import {TranslateModule} from "@ngx-translate/core";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatChipsModule} from "@angular/material/chips";
import {MatIconModule} from "@angular/material/icon";
import {MatInputModule} from "@angular/material/input";
import {ClientAutocompleteComponent} from "./components/client-autocomplete";
import {ResourceRequestAutocompleteComponent} from "./components/resource-request-autocomplete.component";
import {StaffingTagAutocompleteChipComponent} from "./components/staffing-tag-autocomplete-chip.component";

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
    MatAutocompleteModule,
    MatProgressBarModule,
    MatFormFieldModule,
    MatChipsModule,
    MatIconModule,
    MatInputModule
  ],
  declarations: [
    ProjectAutocompleteComponent,
    SkillAutocompleteComponent,
    EmployeeAutocompleteComponent,
    EmployeeFrontendAutocompleteComponent,
    EmployeeTagAutocompleteChipComponent,
    StaffingTagAutocompleteChipComponent,
    ClientAutocompleteComponent,
    ResourceRequestAutocompleteComponent
  ],
  exports: [
    MatAutocompleteModule,
    ProjectAutocompleteComponent,
    SkillAutocompleteComponent,
    EmployeeAutocompleteComponent,
    EmployeeFrontendAutocompleteComponent,
    EmployeeTagAutocompleteChipComponent,
    StaffingTagAutocompleteChipComponent,
    ClientAutocompleteComponent,
    ResourceRequestAutocompleteComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AutoCompleteModule {
}
