export default {
  JobVariants: {
    DEPARTMENT_LEADER: 'Abteilungsleiter',
    ARCHITECTURE: 'Architecture',
    DIVISIONAL_LEADER: 'Bereichsleiter',
    BUSINESS_CONSULTING: 'Business Consulting',
    BUSINESS_ANALYST: "Business Analyst",
    CONTROLLING: 'Controlling',
    DEVELOPMENT: 'Development',
    FI_APPLICATION_DEVELOPMENT: 'F1-Anwendungsentwicklung',
    FI_SYSTEM_INTEGRATION: 'F1-Systemintegration',
    MINORS: 'Geringfügige',
    DIVISION_MANAGER: 'Geschäftsbereichsleiter',
    INFORMATION_DEVELOPMENT: 'Information Development',
    IT_SERVICE_MANAGEMENT: 'IT-Service Management',
    MANAGEMENT_ASSISTANT_FOR_OFFICE_MANAGEMENT: 'Kaufmann/-frau für Büromanagement',
    MANAGEMENT_ASSISTANCE: 'Management Assistance',
    PRODUCT_MANAGEMENT: 'Product Management',
    PROJECT_MANAGEMENT: 'Project Management',
    PROJECT_ASSISTANCE: 'Project Assistance',
    QUALITY_ASSURANCE: 'Quality Assurance',
    SAP_INSURANCE: 'SAP Insurance',
    SAP_FOOD: 'SAP Food',
    SOFTWARE_ENGINEERING: 'Software Engineering',
    SYSTEM_COORDINATION: 'System Coordination',
    TEAM_ASSISTANCE: 'Team Assistance',
    TEST_MANAGEMENT: 'Test Management',
    STUDENT: 'Werkstudent',
    APPLICATION_MANAGEMENT: 'Application Management',
    WORKS_COUNCIL: 'Betriebsrat freigestellt',
    APPLICATION_DELIVERY: 'Application Delivery',
    SALES: 'Sales',
    BOARD: 'Vorstand',
    ADMINISTRATION_SUPPORT: 'Administration Support',
    MANAGEMENT_CONSULTING: 'Management Consulting',
    MARKETING: 'Marketing',
    WINDOWS_INFRASTRUCTURE: 'Windows Infrastructure',
    IT_SECURITY: 'IT-Security',
    HR: 'Human Resources',
    INTERN: 'Praktikant',
    BUSINESS_INTELLIGENCE: 'Business Intelligence',
    CONVERSION: 'Conversion',
    TRAINER: 'Trainer',
    TRAVEL: 'Travel',
    ACTUARIAL_SERVICES: 'Actuarial Services'
  },
  JobVariantsMSG_SERVICES: {
    FREELANCER_NO_PROV: 'Freelancer (without prov)',
    FREELANCER_WITH_PROV: 'Freelancer (with prov)',
    TEMPORARY_WORKER: 'Temporary Worker'
  },
  JobVariantsM3: {
    MSG_ADVISOR: 'msg Advisors',
    DIVISION_MANAGER: 'Geschäftsführer'
  }
};
