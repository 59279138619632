import {ChangeDetectorRef, Component, HostListener, Injectable, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, ValidationErrors} from "@angular/forms";
import {ResourceRequestForm} from "../../models/resource-request.form";
import {MatDialog} from "@angular/material/dialog";
import {SkillWithMinimumLevel} from "../../../../core/models/EmployeeSkill";
import {SkillSelectComponent} from "./skill-select/skill-select.component";
import Config from "../../../../core/config/Config";
import {StaffingComment} from "../../models/staffing-comment";
import {AlertEnum} from "../../../../core/models/Enums/AlertEnum";
import {ResourceRequestListService} from "../../services/resource-request-list.service";
import {TranslateService} from "@ngx-translate/core";
import {lastValueFrom, Observable, Subscription} from "rxjs";
import {ResourceRequest} from "../../models/resource-request";
import {ProjectName} from "../../../../core/models/Project";
import {StaffingCommentDialogComponent} from "./staffing-comment-dialog/staffing-comment-dialog.component";
import {MatTable, MatTableDataSource} from "@angular/material/table";
import moment from "moment";
import {TranslatableComponent} from "../../../../core/models/TranslatableComponent";
import {ActivatedRoute, Router} from "@angular/router";
import {MatSort} from "@angular/material/sort";
import {OfferdEmployeesComponent} from "./offerd-employees/offerd-employees.component";
import {ResourceRequestFile} from "../../models/resource-request-file";
import {AssetsUploadComponent} from "./assets-upload/assets-upload.component";
import {
  ConfirmationDialogComponent
} from "../../../../shared/dialogs/confirmation-dialog/confirmation-dialog.component";
import {
  EmployeeCapacityFilterData
} from "../../../../shared/capacity-list/employee-capacity-list/employee-capacity-list.interface";
import {
  EmployeeCapacityListComponent
} from "../../../../shared/capacity-list/employee-capacity-list/employee-capacity-list.component";
import {CustomizingService} from "../../../../core/services/services/customizing.service";
import {EmployeeService} from "../../../../core/services/services/employee.service";
import {MessageService} from "../../../../core/services/services/message.service";
import {ImportRefreshService} from "../../../../core/services/services/importDialogRefresh.service";
import {ProjectService} from "../../../../core/services/services/project.service";
import {AuthenticationService} from "../../../../core/services/services/authentication.service";
import {DataService} from "../../../../core/services/services/data.service";
import {EnumMapper} from "../../../../core/services/services/enumMapper.service";
import {ApplicationUser} from "../../../../core/models/ApplicationUser";
import {Client} from "../../../../core/config/Client";
import {FileDownloadService} from "../../../../core/services/services/file-download.service";
import {Employee} from "../../../../core/models/Employee";
import {ResourceRequestUpdateDTO} from "../../../../core/models/ResourceRequestUpdateDTO";
import {UntilDestroy} from "@ngneat/until-destroy";
import {Preference, PreferencesService} from "../../../../core/services/services/preferences.service";
import {ComponentCanDeactivate} from "../../../../core/guards/pending-changes.guard";
import {AppEntity, AppEntityAccessType, Permission} from "../../../../core/models/AppPermissions";
import {MatSidenav} from "@angular/material/sidenav";
import {ProjectDataComponent} from "../../../../shared/project-data/project-data.component";
import {ProjectStatus} from "../../../../core/models/Enums/ProjectStatus";

@Injectable({providedIn: 'root'})
@UntilDestroy()
@Component({
  selector: 'app-add-resource-request',
  templateUrl: './add-resource-request.component.html',
  styleUrls: ['./add-resource-request.component.scss']
})

export class AddResourceRequestComponent extends TranslatableComponent implements OnInit, ComponentCanDeactivate {

  @ViewChild('skillTable') skillSelect: SkillSelectComponent;
  @ViewChild('skillSort') sort: MatSort;
  @ViewChild('staffingCommentTable') table: MatTable<StaffingComment>;
  @ViewChild('projectAddDrawer') projectAddDrawer: MatSidenav;
  @ViewChild(ProjectDataComponent) pdc: ProjectDataComponent;

  @ViewChild("staffingTagAutocompleteChipComponent") staffingTagAutocompleteChipComponent;

  @ViewChild('staffingCommentSort') set staffingSort(sort: MatSort) {
    if (sort) {
      this.staffingCommentDataSource.sort = sort;
      this.staffingCommentDataSource.sortingDataAccessor = (item, property) => {
        if (property === 'date') {
          return new Date(item.createdAt).getTime();
        } else if (property === 'user') {
          return item.createdBy;
        } else if (property === 'comment') {
          return item.comment.toLowerCase();
        } else {
          return item[property];
        }
      };
    }
  }

  @ViewChild('resourceRequestFileSort') set resourceRequestFileSort(sort: MatSort) {
    if (sort) {
      this.resourceRequestFileDataSource.sort = sort;
    }
  }

  @ViewChild('offeredEmployeesSort') set offeredEmployeesSort(sort: MatSort) {
    if (sort) {
      this.offeredEmployeesDataSource.sort = sort;
    }
  }

  @ViewChild('offeredEmployeesTable') offeredEmployeeTable: MatTable<Employee>;

  @ViewChild('resourceRequestFileTable') resourceRequestFileTable: MatTable<ResourceRequestFile>;

  @ViewChild('capacityList') set capaListComponent(employeeCapacityListComponent: EmployeeCapacityListComponent) {
    this.employeeCapacityListComponent = employeeCapacityListComponent;
    if (this.employeeCapacityListComponent) {
      this.navigateToCapacityOverview();
    }
  }

  public prefName = "resource-request";

  employeeCapacityListComponent: EmployeeCapacityListComponent;
  skillDataSource: MatTableDataSource<SkillWithMinimumLevel> = new MatTableDataSource();
  staffingCommentDataSource: MatTableDataSource<StaffingComment> = new MatTableDataSource();
  offeredEmployeesDataSource: MatTableDataSource<Employee> = new MatTableDataSource();
  resourceRequestFileDataSource: MatTableDataSource<ResourceRequestFile> = new MatTableDataSource();
  resourceRequestForm: FormGroup;
  skillEvalColumns = ['categorie', 'skillName', 'minSkilllevel'];
  offeredEmployeesColumns = ['firstName', 'lastName', 'delete'];
  staffingCommentColumns = ['user', 'date', 'comment', 'delete'];
  resourceRequestFileColumns = ['creator', 'date', 'name', 'comment', 'delete'];
  isAddMode: boolean;
  loading = true;
  currentClient: Client;
  currentUser: ApplicationUser;
  staffingLocationDropDown: any[];
  requiredLanguageDropDown: any[];
  travelModeDropDown: any[];
  currencyDropDown: any[];
  requestStatusDropDown: any[];
  businessUnitsDropDown: any[];
  divisionsDropDown: any[];
  probabilityDropDown: any[];
  currentResourceRequest: ResourceRequest;
  isEditingEnabled: boolean;
  showCapaOverview = false;
  selectedIndex: number;
  activeSection = 1;
  isMsgServices: boolean;
  showStatusComment: boolean;
  resubmissionSelected: boolean;
  private isClientNameDisplayed: boolean;
  private filterData: EmployeeCapacityFilterData;


  constructor(private _resourceRequestService: ResourceRequestListService,
              private customizingService: CustomizingService,
              private employeeService: EmployeeService,
              private _fb: FormBuilder,
              private matDialog: MatDialog,
              public translate: TranslateService,
              private _messageService: MessageService,
              private _importRefreshService: ImportRefreshService,
              private _projectService: ProjectService,
              private _authService: AuthenticationService,
              private _dataService: DataService,
              private _activatedRoute: ActivatedRoute,
              private _fileDownloadService: FileDownloadService,
              private _router: Router,
              private prefService: PreferencesService,
              private cdr: ChangeDetectorRef) {
    super(translate, 'AddResourceRequestComponent');
    this.resourceRequestForm = (new ResourceRequestForm(this._fb)).resourceRequestForm();
  }

  async ngOnInit(): Promise<void> {
    this.currentUser = await this._authService.getApplicationUser();
    this.isClientNameDisplayed = await this.customizingService.isClientnameDisplayed();
    this.currentClient = await this.customizingService.getCurrentClient();
    this.isMsgServices = this.currentClient === Client.MSG_SERVICES;
    (new ResourceRequestForm(this._fb)).updateResourceRequestForm(this.resourceRequestForm, this.currentClient);
    this.resourceRequestForm.get("requestStatus").valueChanges.subscribe(value => this.setShowStatusComment(value));
    this.resourceRequestForm.get("requestStatus").valueChanges.subscribe(value => this.setResubmissionSelected(value));
    this.requiredLanguageDropDown = this.getEnums(Config['services']['resource_request']['requiredLanguage']);
    this.staffingLocationDropDown = this.getEnums(Config['services']['resource_request']['staffingLocation']);
    this.travelModeDropDown = this.getEnums(Config['services']['resource_request']['travelMode']);
    this.currencyDropDown = this.getEnums(Config['services']['employees']['currency']);
    this.requestStatusDropDown = this.getEnums(Config['services']['resource_request']['status']);
    this.probabilityDropDown = this.getEnumsTranslated(Config['services']['resource_request']['probability']);
    this._resourceRequestService.getResourceRequestWebhookBusinessUnits().subscribe(div => {
      let allEnumValues = this.getEnums(Config.services.employees[EnumMapper.getConfigObjectForClient('businessUnit', this.currentClient)]);
      this.businessUnitsDropDown = allEnumValues.filter(val => div.includes(val.value))
        .sort((a, b) => a.viewValue.localeCompare(b.viewValue));
    });
    if (this.currentUser.employee != null && this.currentUser.employee.businessUnit != null) {
      this.resourceRequestForm.get("businessUnits").setValue([this.currentUser.employee.businessUnit]);
    }
    this._resourceRequestService.getResourceRequestWebhookDivisions().subscribe(div => {
      let allEnumValues = this.getEnums(Config.services.employees[EnumMapper.getConfigObjectForClient('division', this.currentClient)]);
      this.divisionsDropDown = allEnumValues.filter(val => div.includes(val.value));
      if (this.divisionsDropDown.length == 0) {
        this.divisionsDropDown = this.getEnums(Config.services.employees.division);
      }
    });
    await this.setResourceRequestData();
    await this.fetchAssets();
    this.initStaffingCommentDataSource();
    this.initSkillDataSource();
    this.offeredEmployeesDataSource.data = this.resourceRequestForm.get('offered').value;
    this.resourceRequestFileDataSource.data = this.resourceRequestForm.get('assets').value;
    this.resourceRequestFileDataSource.sortingDataAccessor = (item, property) => {
      if (property === 'date') {
        return new Date(item.createdAt);
      } else {
        return item[property];
      }
    };
    this.loading = false;
    this.resourceRequestForm.markAsPristine();
    this.staffingTagAutocompleteChipComponent.init(this.resourceRequestForm);
  }

  initSkillDataSource() {
    this.skillDataSource.data = this.resourceRequestForm.get('internalSkillset').value;
    this.skillDataSource.sort = this.sort;
    this.skillDataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'categorie':
          return item.skill.category.categoryName;
        case 'skillName':
          return item.skill.skillName;
        case 'minSkilllevel':
          return item.minimumLevel;
        default:
          return item[property];
      }
    };
  }

  initStaffingCommentDataSource() {
    this.staffingCommentDataSource.data = this.resourceRequestForm.get('staffingComments').value;
  }

  async fetchAssets() {
    if (!this.isAddMode && await this._authService.hasPermission(Permission.TRUE, AppEntityAccessType.READ, AppEntity.RESOURCE_REQUEST_CONFIDENTIAL_FIELDS)) {
      const assets = await this._resourceRequestService.getResourceRequestFiles(this.currentResourceRequest.id).toPromise();
      this.resourceRequestForm.get("assets").setValue(assets);
      this.resourceRequestFileDataSource.data = assets;
    }
  }

  async setResourceRequestData() {
    let id = this._activatedRoute.snapshot.params['resourceRequestId'];
    if (this._activatedRoute.snapshot && id !== undefined) {
      this.isAddMode = false;
      this.currentResourceRequest = await lastValueFrom(this._resourceRequestService.getById(id));
      this.resourceRequestForm.markAsPristine();
      this.resourceRequestForm.patchValue(this.currentResourceRequest);
      if (this.resourceRequestForm.get('totalScope').value != null) {
        this.resourceRequestForm.get('totalScope').markAllAsTouched();
      }
      this.resourceRequestForm.disable();
    } else {
      this.isAddMode = true;
      if (this.currentUser.employee) {
        this.resourceRequestForm.controls["requestedBy"].setValue(this.currentUser.employee.firstName + " " + this.currentUser.employee.lastName);
      }
    }
    await this.updateNumberOfPersonDays();
    this.isEditingEnabled = this.isAddMode;
  }


  openSkillEdit(obj) {
    const dialog = this.matDialog.open(SkillSelectComponent, {
      disableClose: true,
      width: '80%',
      data: {
        skills: obj,
        operator: this.resourceRequestForm.get('skillOperator').value,
        showInvisibleSkills: false
      }
    });
    dialog.afterClosed().subscribe((value) => {
      if (value) {
        this.resourceRequestForm.get('internalSkillset').setValue(value.skills);
        this.resourceRequestForm.get('skillOperator').setValue(value.operator);
        this.resourceRequestForm.get('internalSkillset').markAsDirty();
        this.resourceRequestForm.get('skillOperator').markAsDirty();
        this.initSkillDataSource();
      }
    });
  }

  submitChangesResourceRequest() {
    // Do nothing if something is currently loading (prevents multiple saving)
    if (this.loading) {
      return;
    } else if (this.resourceRequestForm.invalid) {
      this._messageService.add(this.translate.instant('mep.components.employee-list.errors.not-filled'), AlertEnum.warning);
      this.resourceRequestForm.markAllAsTouched();
    } else if (this.resourceRequestForm.pristine) {
      this._messageService.add(this.translate.instant('mep.components.employee-list.errors.no-changes'), AlertEnum.warning);
      this.resourceRequestForm.markAllAsTouched();
    } else {
      this.loading = true;
      if (this.isAddMode) {
        this.submitResourceRequest(this._resourceRequestService.createResourceRequest).add(() => {
          this._importRefreshService.refreshScreens(true);
          this._router.navigate(['/resource-request-list/' + this.currentResourceRequest.id]).then(() => {
            this.afterSubmitActions();
          });
        });
      } else {
        this.submitResourceRequest(this._resourceRequestService.updateResourceRequest).add(() => {
          this.afterSubmitActions();
        });
      }
    }
  }

  fillInMissingEnumValues(rawFormData) {
    const attributes = ['currency', 'travelMode', 'requestStatus', 'probability'];
    for (const attribute of attributes) {
      if (!rawFormData[attribute]) {
        rawFormData[attribute] = Config.services.missingValueBackend;
      }
    }
  }

  onProjectSelectionChanged(projectName: ProjectName) {
    if (projectName !== null) {
      this.resourceRequestForm.get('currentProject').setValue(projectName);
      this.resourceRequestForm.get('currentProject').markAsDirty();
    }
  }

  onEmployeeSelectionChanged(employee: Employee, item: string) {
    if (employee !== null) {
      this.resourceRequestForm.get(item).setValue(employee);
      this.resourceRequestForm.get(item).markAsDirty();
    }
  }

  openDialog(obj) {
    const dialogRef = this.matDialog.open(StaffingCommentDialogComponent, {
      width: '70%',
      data: obj
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        this.addRowData(result.data);
      }

    });
  }

  addRowData(rowObj) {
    this.resourceRequestForm.get('staffingComments').value.push({
      createdAt: moment(new Date()).format('yyyy-MM-DDThh:MM:ss.ms'),
      createdBy: this.currentUser.username,
      comment: rowObj.value
    });
    this.resourceRequestForm.get('staffingComments').markAsDirty();
    this.initStaffingCommentDataSource();
  }

  displayProject() {
    if (this.currentResourceRequest != null && this.currentResourceRequest.currentProject != null) {
      return (this.isClientNameDisplayed ? this.currentResourceRequest.currentProject.client + ' - ' : '') + this.currentResourceRequest.currentProject.projectName;
    }
  }

  displayEmployeeName(field: string) {
    if (this.currentResourceRequest != null && this.currentResourceRequest[field] != null) {
      return this.currentResourceRequest[field].firstName + ' ' + this.currentResourceRequest[field].lastName;
    }
  }

  isDisabled() {
    return !this.isEditingEnabled;
  }

  changeEditMode(setTo: boolean = !this.isEditingEnabled) {
    this.isEditingEnabled = setTo;
    if (this.isEditingEnabled) {
      this.resourceRequestForm.enable();
      this.resourceRequestForm.markAllAsTouched();
    } else {
      this.resourceRequestForm.disable();
      this.resourceRequestForm.markAsUntouched();
      this.resourceRequestForm.markAsPristine();
    }
  }

  setFormfieldNullIfInputEmpty(field: string, value: HTMLInputElement) {
    if (value.value === "") {
      this.resourceRequestForm.get(field).setValue(null);
    }
  }

  openEmployeeDialog(obj) {
    const dialog = this.matDialog.open(OfferdEmployeesComponent, {
      disableClose: true,
      width: '80%',
      data: obj
    });
    dialog.afterClosed().subscribe((employees) => {
      if (employees != null && (this.resourceRequestForm.get('offered').value.length > 0 || employees.length > 0)) {
        this.resourceRequestForm.get('offered').setValue(employees);
        this.resourceRequestForm.get('offered').markAsDirty();
        this.offeredEmployeesDataSource.data = employees;
      }
    });
  }

  openCapaOverviewDialog() {
    if (this.showCapaOverview) {
      // Tab already exists, just select it
      this.selectedIndex = 1;
    } else {
      this.filterData = new EmployeeCapacityFilterData();
      const dbskills = this.resourceRequestForm.get('internalSkillset').value.map(value => ({
        skillName: value.skill.skillName,
        level: value.minimumLevel === 'X' ? 0 : parseInt(value.minimumLevel, 10) - 1
      }));
      this.filterData.skillDbFilterData = {
        logicalOperator: this.resourceRequestForm.get('skillOperator').value,
        skillDbRows: dbskills
      };
      this.showCapaOverview = true;
    }
  }

  setStartDate() {
    if (!this.resourceRequestForm.get('commitmentStart').invalid) {
      const startDate = moment(this.resourceRequestForm.get('commitmentStart').value, "YYYY-MM-DD");
      this.prefService.put(Preference.START_DATE, startDate.toJSON(), this.prefName).then();
    }
  }

  navigateToCapacityOverview() {
    this.selectedIndex = 1;
    this.setNavigatedFilterdata();
    this.setStartDate();
    this.cdr.detectChanges();
  }

  /**
   * This method is required to create the filter chips for the capaOverview properly
   */
  setNavigatedFilterdata() {
    this.employeeCapacityListComponent.filterViewModel.filterData.skillDbFilterData = this.filterData.skillDbFilterData;
    this.employeeCapacityListComponent.filterViewModel.filterData.businessUnit = [];
    this.employeeCapacityListComponent.filterViewModel.filterData.division = [];
    this.employeeCapacityListComponent.filterViewModel.filterData.country = [];
    this.employeeCapacityListComponent.filterViewModel.filterData.divisionManagerNames = '';
    this.employeeCapacityListComponent.filterViewModel.filterData.humanResourcesManager = '';
    this.employeeCapacityListComponent.filterViewModel.filterData.jobVariant = [];
    this.employeeCapacityListComponent.filterViewModel.filterData.location = [];
    this.employeeCapacityListComponent.filterViewModel.filterData.projectLeaderNames = '';
  }


  /**
   * Goes to the selected employee from the offered employees table after the user accepted that his/changes would not be saved before pressing the save button
   */
  onEmployeeSelected(employee: Employee) {
    this._dataService.changeEmployee(employee);

    this._router.navigate(['/employee-edit/' + employee.id]);
  }

  openResourceRequestFileDialog() {
    let dialogRef = this.matDialog.open(AssetsUploadComponent, {
      disableClose: true,
      width: '80%'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        this.resourceRequestFileDataSource.data.push(result);
        this.resourceRequestFileDataSource.sort = this.resourceRequestFileSort;
        this.resourceRequestForm.get('assets').setValue(this.resourceRequestFileDataSource.data);
        this.resourceRequestForm.get('assets').markAsDirty();
      }
    });
  }

  onClick($event: MouseEvent, id, filename) {
    this._fileDownloadService.downloadFile('/mep/api/resource-request/add/downloadFile/' + id, filename).subscribe();
  }

  deleteResourceRequestFile(requestFile: ResourceRequestFile) {
    const dialogRef = this.matDialog.open(ConfirmationDialogComponent, {
      disableClose: false,
      data: 'mep.components.general.notification.delete-resource-request-file'
    });
    dialogRef.afterClosed().subscribe(val => {
        if (val) {
          this.loading = true;
          if (!requestFile.id) {
            this.removeAssetFromList(requestFile);
            this.loading = false;
          } else {
            this._resourceRequestService.deleteResourceRequestFile(requestFile.id).subscribe(() => {
              this.removeAssetFromList(requestFile);
            }).add(() => this.loading = false);
          }
        }
      }
    );
  }

  deleteStaffingComment(staffingComment: StaffingComment) {
    const dialogRef = this.matDialog.open(ConfirmationDialogComponent, {
      disableClose: false,
      data: 'mep.components.general.notification.delete-staffing-comment'
    });
    dialogRef.afterClosed().subscribe(val => {
      if (val) {
        this.removeCommentFromList(staffingComment);
      }
    });
  }

  deleteOfferedEmployee(offeredEmployee: Employee) {
    const dialogRef = this.matDialog.open(ConfirmationDialogComponent, {
      disableClose: false,
      data: 'mep.components.general.notification.delete-offered-employee'
    });
    dialogRef.afterClosed().subscribe(val => {
      if (val) {
        this.removeEmployeeFromList(offeredEmployee);
      }
    });
  }

  warnAboutPageChange(employee: Employee) {
    if (this.isEditingEnabled) {
      const dialogRef = this.matDialog.open(ConfirmationDialogComponent, {
        disableClose: false,
        data: 'mep.components.general.notification.warn-about-page-change'
      });
      dialogRef.afterClosed().subscribe(val => {
        if (val) {
          this.onEmployeeSelected(employee);
        }
      });
    } else if (!this.isEditingEnabled) {
      this.onEmployeeSelected(employee);
    }
  }

  removeCommentFromList(requestFile: StaffingComment) {
    this.resourceRequestForm.get('staffingComments').setValue(this.resourceRequestForm.get('staffingComments').value.filter(value => value !== requestFile));
    this.resourceRequestForm.get('staffingComments').markAsDirty();
    this.staffingCommentDataSource.data = this.resourceRequestForm.get('staffingComments').value;
    const message = this.translate.instant('mep.components.toasts.was-deleted', {entity: requestFile.comment});
    this._messageService.add(message, AlertEnum.success);
  }

  removeEmployeeFromList(offeredEmployee: Employee) {
    this.resourceRequestForm.get('offered').setValue(this.resourceRequestForm.get('offered').value.filter(value => value !== offeredEmployee));
    this.resourceRequestForm.get('offered').markAsDirty();
    this.offeredEmployeesDataSource.data = this.resourceRequestForm.get('offered').value;
    const message = this.translate.instant('mep.components.toasts.was-deleted', {entity: offeredEmployee.firstName});
    this._messageService.add(message, AlertEnum.success);
  }


  removeAssetFromList(requestFile: ResourceRequestFile) {
    this.resourceRequestForm.get('assets').setValue(this.resourceRequestForm.get('assets').value.filter(value => value !== requestFile));
    this.resourceRequestForm.get('assets').markAsDirty();
    this.resourceRequestFileDataSource.data = this.resourceRequestForm.get('assets').value;
    const message = this.translate.instant('mep.components.toasts.was-deleted', {entity: requestFile.name});
    this._messageService.add(message, AlertEnum.success);
  }

  removeDateError(fromDate: string, errors: ValidationErrors) {
    if (errors) {
      delete errors['dateError'];
      if (!Object.keys(errors).length) {
        this.resourceRequestForm.controls[fromDate].setErrors(null);
      } else {
        this.resourceRequestForm.controls[fromDate].setErrors(errors);
      }
    }
  }

  closeTab() {
    this.showCapaOverview = false;
    this.selectedIndex = 0;
  }

  async updateNumberOfPersonDays() {
    if (!this.resourceRequestForm.errors && !this.resourceRequestForm.get('totalScope').touched &&
      !this.resourceRequestForm.get('commitmentStart').errors && !this.resourceRequestForm.get('commitmentEnd').errors &&
      !this.resourceRequestForm.get('scope').errors && !this.resourceRequestForm.get('requiredEmployees').errors &&
      this.resourceRequestForm.get('commitmentStart').value && this.resourceRequestForm.get('commitmentEnd').value &&
      this.resourceRequestForm.get('scope').value && this.resourceRequestForm.get('requiredEmployees').value) {
      this.resourceRequestForm.get('totalScope').setValue(await this._resourceRequestService.getNumberOfPersondaysForResourceRequest(
        moment(this.resourceRequestForm.get('commitmentStart').value).format('YYYY-MM-DD'),
        moment(this.resourceRequestForm.get('commitmentEnd').value).format('YYYY-MM-DD'),
        this.resourceRequestForm.get('scope').value, this.resourceRequestForm.get('requiredEmployees').value).toPromise());
    }
  }

  fullPageScroll(e: HTMLElement) {
    e.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    this.selectedIndex = 0;
  }

  setShowStatusComment(status: string) {
    this.showStatusComment = status === "CLIENT_DISCARDED" || status === "CLIENT_OFFER_DECLINED" || status === "DECLINED";
  }

  setResubmissionSelected(status: string) {
    this.resubmissionSelected = status === "FOLLOW_UP";
  }

  isCurrentClientGBP(): boolean {
    return this.currentClient === Client.GBP;
  }

  private getEnums(enumPath: any) {
    return Object.keys(enumPath).map(i => ({value: i, viewValue: enumPath[i]})).sort();
  }

  private getEnumsTranslated(enumPath: any) {
    return Object.keys(enumPath).map(i => ({value: i, viewValue: this.translate.instant(enumPath[i])})).sort();
  }

  private submitResourceRequest(method: (r: ResourceRequest) => Observable<ResourceRequestUpdateDTO> | Observable<ResourceRequestFile>): Subscription {
    const formData = this.resourceRequestForm.getRawValue();
    this.fillInMissingEnumValues(formData);
    formData["files"] = this.resourceRequestForm.get('assets').value?.filter(value => value instanceof ResourceRequestFile);
    return method.apply(this._resourceRequestService, [formData]).subscribe((result: ResourceRequestUpdateDTO) => {
      this.currentResourceRequest = result.resourceRequestDTO;

      // Update model
      this.resourceRequestForm.patchValue(this.currentResourceRequest);

      result.businessUnitNotificationErrors.map(error => this.translate.instant(error.key, error.arguments))
        .forEach(error => this._messageService.add(error, AlertEnum.warning));

      result.divisionNotificationErrors.map(error => this.translate.instant(error.key, error.arguments))
        .forEach(error => this._messageService.add(error, AlertEnum.warning));

      // After saving we switch to edit mode
      this.isAddMode = false;
      this.changeEditMode();
    });
  }

  private afterSubmitActions() {
    this._messageService.add(this.resourceRequestForm.get('requestTitle').value + this._lang('save-success'), AlertEnum.success);
    this.loading = false;
  }

  @HostListener('window:beforeunload')
  canDeactivate(): boolean {
    const capaPristine = this.employeeCapacityListComponent ? this.employeeCapacityListComponent.canDeactivate() : true;
    return this.resourceRequestForm.pristine && capaPristine;
  }

  async openDrawer() {
    const addEmployeesToProjectData = {
      employees: this.offeredEmployeesDataSource.data,
      capacity: this.resourceRequestForm.value.scope,
      effectiveUntil: this.resourceRequestForm.value.commitmentEnd,
      effectiveFrom: this.resourceRequestForm.value.commitmentStart,
      addEmployeeForm: true,
    };
    this.pdc.project.client = this.resourceRequestForm.value.client;
    this.pdc.project.status = ProjectStatus.ACTIVE;
    this.pdc.projectProbabilitiesDataSource.data[0].get('effectiveFrom').setValue(this.resourceRequestForm.value.commitmentStart);
    this.pdc.projectProbabilitiesDataSource.data[0].get('effectiveUntil').setValue(this.resourceRequestForm.value.commitmentEnd);
    this.pdc.project.projectLeader = await this.employeeService.getEmployeeById(this.resourceRequestForm.value.responsibleDE.id).toPromise();
    this.pdc.project.description = this.resourceRequestForm.value.skillDescriptionOfCustomer;
    this.pdc.project.projectName = this.resourceRequestForm.value.requestTitle;
    this.pdc.projectDataInput.fields.toArray().forEach(value => value.control.markAsTouched());
    this.pdc.formGroup.get('effectiveFrom').setValue(this.resourceRequestForm.value.commitmentStart);
    this.pdc.formGroup.get('effectiveUntil').setValue(this.resourceRequestForm.value.commitmentEnd);
    this.pdc.employeesToProjectDataSource.data = this.resourceRequestForm.value.offered;
    this.pdc.activateEditMode();
    if (this.resourceRequestForm.value.commitmentEnd && this.resourceRequestForm.value.commitmentStart && this.resourceRequestForm.value.offered.length) {
      this.pdc.addEmployeeToProjectElement = addEmployeesToProjectData;
      this.pdc.addEmployees(addEmployeesToProjectData);
    }
    this.pdc.addEmployeeToProjectElement = addEmployeesToProjectData;
    this.pdc.addEmployees(addEmployeesToProjectData);
    this.projectAddDrawer.open().then();
  }
}
