import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {TranslateModule} from "@ngx-translate/core";
import {SkillDbListComponent} from "./components/skill-db-list.component";
import {MatProgressBarModule} from "@angular/material/progress-bar";


@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    MatProgressBarModule
  ],
  declarations: [
    SkillDbListComponent,
  ],
  exports: [
    SkillDbListComponent,
  ]
})
export class SkillDbListModule {
}
