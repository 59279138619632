import {Component, OnInit} from "@angular/core";
import {AbstractBackendAutocomplete} from "./abstract-backend-autocomplete";
import {ResourceRequestOverviewDTO} from "../../../feature/resource-request-list/models/resource-request";
import {Observable} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {CustomizingService} from "../../../core/services/services/customizing.service";
import {
  ResourceRequestListService
} from "../../../feature/resource-request-list/services/resource-request-list.service";


@Component({
  selector: 'resource-request-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss']
})
export class ResourceRequestAutocompleteComponent extends AbstractBackendAutocomplete<ResourceRequestOverviewDTO> implements OnInit {

  constructor(private _resourceRequestService: ResourceRequestListService,
              private translateService: TranslateService,
              private customizingService: CustomizingService) {
    super();
  }

  displayAsSelectedOption(element: ResourceRequestOverviewDTO): string {
    if (element.client) {
      return element.client + ' ' + element.requestTitle;
    } else {
      return element.requestTitle;
    }
  }

  getElementsFromBackendForSearchTerm(searchTerm: string): Observable<ResourceRequestOverviewDTO[]> {
    return this._resourceRequestService.findResourceRequestBySearchTerm(searchTerm);
  }

  sort(element: ResourceRequestOverviewDTO, otherElement: ResourceRequestOverviewDTO): number {
    return element.requestTitle.toLowerCase().localeCompare(otherElement.requestTitle.toLowerCase());
  }

}
