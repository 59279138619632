import {Component, Inject, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {TranslateService} from "@ngx-translate/core";
import {TranslatableComponent} from "../../../core/models/TranslatableComponent";
import {ExcelExportService} from "../../../core/services/services/excelExport.service";
import {Export} from "../export";
import {CustomizingService} from "../../../core/services/services/customizing.service";

@Component({
  selector: 'app-export-dialog',
  templateUrl: 'export-dialog.component.html',
  styleUrls: ['export-dialog.component.scss']
})
export class ExportDialogComponent extends TranslatableComponent implements OnInit {
  selectedExport: Export;
  loading: boolean;
  expLevels = [];

  constructor(@Inject(MAT_DIALOG_DATA) public exports: Export[],
              private dialogRef: MatDialogRef<ExportDialogComponent>,
              private excelExportService: ExcelExportService,
              translate: TranslateService, private customizingService: CustomizingService) {
    super(translate, 'ExportDialogComponent');
    this.selectedExport = exports[0];
  }

  async ngOnInit() {
    this.expLevels = await this.customizingService.getSkillLevels();
  }

  closeDialog(successfullyExported: boolean = false) {
    this.dialogRef.close(successfullyExported);
  }

  export() {
    this.loading = true;

    this.excelExportService.export(this.selectedExport).subscribe(success => {
      if (success) {
        this.closeDialog(true);
      }
    }).add(() => this.loading = false);
  }
}
