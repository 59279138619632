export class ExportSkill {
  categoryName: string;
  skillName: string;
  experienceLevel: string|number;
}

export class ExportSkillWithCluster {
  clusterName: string;
  categoryName: string;
  skillName: string;
  experienceLevel: string|number;
}
