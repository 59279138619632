import {Component, Input, OnInit} from '@angular/core';
import {EmployeeSkillClusterDTO, EmployeeSkillDTO} from "../../../core/models/EmployeeSkill";
import {SkillService} from "../../../core/services/services/skill.service";
import {TranslatableComponent} from "../../../core/models/TranslatableComponent";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-skill-db-list',
  templateUrl: './skill-db-list.component.html',
  styleUrls: ['./skill-db-list.component.scss'],
})
export class SkillDbListComponent extends TranslatableComponent implements OnInit {

  @Input()
  employeeId: number;

  @Input()
  instanceName: string;

  @Input()
  filterStringArray: string[];

  @Input()
  wideSearchSkill: boolean;

  allEmployeeSkillClusters: EmployeeSkillClusterDTO[];
  highlightedCluster: EmployeeSkillClusterDTO;
  nonHighlightedClusters: EmployeeSkillClusterDTO[] = [];

  loading = true;

  private highlightedClusterName = "Allgemeine, persönliche Kompetenzen";

  constructor(public translate: TranslateService, private skillService: SkillService) {
    super(translate, "SkillDbListComponent");
  }

  ngOnInit(): void {
    if (this.wideSearchSkill === true) {

      this.skillService.getAllEmployeeSkillSetCluster(this.employeeId, this.instanceName).subscribe(allEmployeeSkillClusters => {
        this.allEmployeeSkillClusters = allEmployeeSkillClusters;

        // Search for highlighted cluster
        for (let cluster of this.allEmployeeSkillClusters) {
          if (cluster.clusterName === this.highlightedClusterName) {
            this.highlightedCluster = cluster;
          } else {
            this.nonHighlightedClusters.push(cluster);
          }
        }

        this.loading = false;
      });

    } else {
      this.skillService.getAllEmployeeSkillCluster(this.employeeId).subscribe(allEmployeeSkillClusters => {
        this.allEmployeeSkillClusters = allEmployeeSkillClusters;

        // Search for highlighted cluster
        for (let cluster of this.allEmployeeSkillClusters) {
          if (cluster.clusterName === this.highlightedClusterName) {
            this.highlightedCluster = cluster;
          } else {
            this.nonHighlightedClusters.push(cluster);
          }
        }

        this.loading = false;
      });
    }
  }

  highlight(content: string, searchStringArray: string[]) {
    if (searchStringArray && searchStringArray[0] !== undefined && searchStringArray.length > 0) {
      const orCombination = searchStringArray[0].split(' ')
        .map(value => value.includes('+') ? value.split('+').join('\\+') : value)
        .reduce((acc, cur) => acc + "|" + cur);
      content = content.replace(new RegExp(orCombination, 'gi'), match => '<span class="highlightText">' + match + '</span>');
    }
    return content;
  }

  getSortedNonHighlightedSkills(): EmployeeSkillDTO[] {
    let skillList = [];
    for (let cluster of this.nonHighlightedClusters) {
      for (let category of cluster.employeeCategories) {
        skillList.push(...category.employeeSkills);
      }
    }
    return skillList.sort((s1: EmployeeSkillDTO, s2: EmployeeSkillDTO) => {
      if (s1.experienceLevel > s2.experienceLevel) {
        return -1;
      }
      if (s2.experienceLevel > s1.experienceLevel) {
        return 1;
      }
      if (s1.skillName > s2.skillName) {
        return 1;
      }
      if (s2.skillName > s1.skillName) {
        return -1;
      }
      return 0;
    });
  }

  calculateColumnCount(list: [any]) {
    const entriesPerCol = 15;
    const maxCol = 6;
    return Math.min(Math.ceil(list.length / entriesPerCol), maxCol);
  }
}

