import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {BreadcrumbItem} from 'src/app/core/models/BreadcrumbItem';
import {ProjectName} from 'src/app/core/models/Project';
import {ProjectService} from 'src/app/core/services/services/project.service';
import {DataService} from 'src/app/core/services/services/data.service';
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";

@Component({
  selector: 'app-breadcrumb-project-search',
  templateUrl: './breadcrumb-project-search.component.html',
  styleUrls: ['./breadcrumb-project-search.component.scss']
})

@UntilDestroy()
export class BreadcrumbProjectSearchComponent implements OnInit {
  @Input() breadcrumb: BreadcrumbItem;
  currentProject: ProjectName;
  readonly detailPath: string = '/project-edit/';

  @ViewChild('projectInput') projectInput: ElementRef;

  constructor(
    private _projectService: ProjectService,
    private _router: Router,
    private _dataService: DataService
  ) {
  }

  ngOnInit(): void {
    this._dataService.currentProject.pipe(untilDestroyed(this)).subscribe(currentProject => {
      const subPath = this.breadcrumb.path.substring(this.breadcrumb.path.lastIndexOf(this.detailPath) + this.detailPath.length);
      const sharePointId = subPath.lastIndexOf('/') > 0 ? subPath.substring(0, subPath.lastIndexOf('/')) : subPath;
      if (currentProject && currentProject.sharePointId === sharePointId) {
        this.currentProject = currentProject;
      } else {
        this._projectService.getProjectData(sharePointId).subscribe(project => this.currentProject = project);
      }
    });
  }

  onItemChange(project: ProjectName) {
    this._dataService.changeProject(project);
    this._router.navigate([this.detailPath + project.sharePointId]).then();
  }

  onClear() {
    this.projectInput.nativeElement.value = '';
  }

  onLeaveText() {
    this.projectInput.nativeElement.value = this.currentProject.client + ' ' + this.currentProject.projectName;
  }
}
