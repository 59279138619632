import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {HotkeyDirective} from "./directives/hotkey.directive";
import {SaveHotkeyDirective} from "./directives/save-hotkey.directive";
import {EscapeHotkeyDirective} from "./directives/escape-hotkey.directive";

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    HotkeyDirective,
    SaveHotkeyDirective,
    EscapeHotkeyDirective
  ],
  exports: [
    HotkeyDirective,
    SaveHotkeyDirective,
    EscapeHotkeyDirective
  ]
})
export class HotkeyModule {}
