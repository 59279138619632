import {Component, EventEmitter, forwardRef, HostListener, Input, OnInit, Output} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {TranslatableComponent} from "../../../../core/models/TranslatableComponent";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
  selector: 'app-file-drag-drop',
  templateUrl: './file-drag-drop.component.html',
  styleUrls: ['./file-drag-drop.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileDragDropComponent),
      multi: true
    }
  ]
})
export class FileDragDropComponent extends TranslatableComponent implements ControlValueAccessor {

  constructor(protected translate: TranslateService) {
    super(translate, 'FileInputComponent');
  }

  @Input() selectedFile: File;
  @Output() fileDropped = new EventEmitter<any>();
  @Input() accept: string;

  showFileDropZone: boolean = false;

  onChange =  (file: File) => {};
  onTouched =  () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(obj: File): void {
    this.selectedFile = obj;
  }

  public onFileDropped($event) {
    this.selectedFile = $event[0];
    this.showFileDropZone = false;
    this.onChange($event[0]);
    this.fileDropped.emit($event[0]);
  }

  public onFileSelected($event){
    this.selectedFile = $event;
    this.onChange(this.selectedFile);
    this.fileDropped.emit($event);
  }

  public onFileDragOver() {
    this.showFileDropZone = true;
  }

  public onFileDragLeave() {
    this.showFileDropZone = false;
  }

  @HostListener('window:dragover', ['$event'])
  dragListener(evt) {
    evt.preventDefault();
    evt.dataTransfer.effectAllowed = "none";
    evt.dataTransfer.dropEffect = "none";
  }

  @HostListener('window:drop', ['$event'])
  dropListener(evt) {
    evt.preventDefault();
    evt.dataTransfer.effectAllowed = "none";
    evt.dataTransfer.dropEffect = "none";
  }

}
