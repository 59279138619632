import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {TranslateService} from "@ngx-translate/core";
import {TranslatableComponent} from "../../../core/models/TranslatableComponent";
import {FormControl} from "@angular/forms";
import {EmployeeService} from "../../../core/services/services/employee.service";
import {Observable} from "rxjs";
import {AlertEnum} from "../../../core/models/Enums/AlertEnum";
import {MailDialogData} from "../../../core/models/mail/MailDialogData";
import {MessageService} from "../../../core/services/services/message.service";

@Component({
  selector: 'app-notify-hrm-dialog',
  templateUrl: './notify-hrm-dialog.component.html',
  styleUrls: ['./notify-hrm-dialog.component.css']
})
export class NotifyHrmDialogComponent extends TranslatableComponent implements OnInit {
  notifyForm = new FormControl();
  signature: string;


  constructor(public dialogRef: MatDialogRef<NotifyHrmDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: MailDialogData,
              public translate: TranslateService,
              private messageService: MessageService,
              public employeeService: EmployeeService) {
    super(translate, 'NotifyHrmDialogComponent');
  }

  public static open(dialog: MatDialog, sendMail: (message: string) => Observable<any>): MatDialogRef<NotifyHrmDialogComponent> {
    const mailDialogData: MailDialogData = {
      sendMail
    };

    return dialog.open(NotifyHrmDialogComponent, {width: '50%', data: mailDialogData});
  }

  ngOnInit(): void {
    this.notifyForm.setValue(this.translate.instant('mep.components.notify-hrm-dialog.default_message'));
    this.employeeService.getSignature().subscribe(signature => this.signature = signature);
  }

  sendMail() {
    this.data.sendMail(this.notifyForm.value).subscribe(() => {
      this.messageService.add(this.translate.instant('mep.components.general.notification.notify-hrm-success'), AlertEnum.success);
      this.dialogRef.close(true);
    }, () => {
      this.messageService.add(this.translate.instant('mep.components.general.notification.notify-hrm-failed'), AlertEnum.danger, null);
    });
  }
}
