import {AfterViewInit, Directive, ElementRef, Input, OnDestroy, Renderer2} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";


@Directive({
  selector: '[appHotkey]'
})
export class HotkeyDirective implements OnDestroy, AfterViewInit {

  private unsubscribeFromListener: () => void;
  private dialogsOpenAtStart: number;
  private enabled = true;

  constructor(private element: ElementRef,
              private renderer: Renderer2,
              private dialog: MatDialog) {
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  ngAfterViewInit() {
    this.dialogsOpenAtStart = this.dialog.openDialogs.length;
  }

  @Input()
  set appHotkey(hotkey: string) {
    this.unsubscribe();
    this.unsubscribeFromListener = this.renderer.listen(document, 'keydown.' + hotkey, event => this.onHotkeyClicked(event));
  }

  @Input()
  set appHotkeyEnabled(enabled: boolean) {
    this.enabled = !!enabled;
  }

  private unsubscribe() {
    if (this.unsubscribeFromListener) {
      this.unsubscribeFromListener();
    }
  }

  private onHotkeyClicked(event): void {
    event.preventDefault();

    const htmlElement = this.element.nativeElement as HTMLButtonElement;

    if (this.enabled && !htmlElement.disabled && this.dialogsOpenAtStart === this.getCurrentlyOpenDialogs()) {
      htmlElement.click();
    }
  }

  private getCurrentlyOpenDialogs(): number {
    return this.dialog.openDialogs.length;
  }
}
