export enum Permission {
  TRUE = "TRUE",
  FALSE = "FALSE",
  ONLY_RELATED_TO_ME = "ONLY_RELATED_TO_ME"
}

export function permissionToWeight(permission: Permission): number {
  switch (permission) {
    case Permission.TRUE:
      return 2;
    case Permission.ONLY_RELATED_TO_ME:
      return 1;
    case Permission.FALSE:
      return 0;
    default:
      return -1;
  }
}

export enum AppEntityAccessType {
  CREATE = "CREATE",
  READ = "READ",
  UPDATE = "UPDATE",
  DELETE = "DELETE"
}

export enum AppEntity {
  EMPLOYEE = "EMPLOYEE",
  EMPLOYEE_WITHOUT_NAME = "EMPLOYEE_WITHOUT_NAME",
  EMPLOYEE_FILE = "EMPLOYEE_FILE",
  EMPLOYEE_LEAVING_DATE = "EMPLOYEE_LEAVING_DATE",
  EMPLOYEE_NOT_RELEVANT_FOR_CAPA = "EMPLOYEE_NOT_RELEVANT_FOR_CAPA",
  EMPLOYEE_TRAVEL_RESTRICTIONS = "EMPLOYEE_TRAVEL_RESTRICTIONS",
  EMPLOYEE_ANNOTATION = "EMPLOYEE_ANNOTATION",
  ABSENCE = "ABSENCE",
  EMPLOYEE_SKILL = "EMPLOYEE_SKILL",
  CAPACITY = "CAPACITY",
  CAPACITY_ANNOTATION = "CAPACITY_ANNOTATION",
  INSTANCE_WIDE_CAPACITY = "INSTANCE_WIDE_CAPACITY",
  ASSIGNMENT = "ASSIGNMENT",
  ACTION_PLAN = "ACTION_PLAN",
  PROJECT = "PROJECT",
  IMPORT = "IMPORT",
  MAINTENANCE_GUIDELINE = "MAINTENANCE_GUIDELINE",
  CUSTOMIZING = "CUSTOMIZING",
  GLOBAL_SKILLGROUP = "GLOBAL_SKILLGROUP",
  LOCAL_SKILLGROUP = "LOCAL_SKILLGROUP",
  REPORTS = "REPORTS",
  P1_BOOKINGS = "P1_BOOKINGS",
  RESOURCE_REQUEST = "RESOURCE_REQUEST",
  RESOURCE_REQUEST_CONFIDENTIAL_FIELDS = "RESOURCE_REQUEST_CONFIDENTIAL_FIELDS",
  WEBDATA_ROCKS = "WEBDATA_ROCKS",
  CAPACITY_REVENUE = "CAPACITY_REVENUE"
}

export type DefinedEntityAccessTypes = {
  [type in AppEntity]: AppEntityAccessType[];
};

export type EntityPermissions = {
  [type in AppEntityAccessType]: Permission;
};

export type AppPermissions = {
  [entity in AppEntity]: EntityPermissions;
};
