import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthenticationService} from '../../services/services/authentication.service';
import {map} from 'rxjs/operators';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {HttpBaseService} from '../../services/services/http-base.service';
import {CustomizingService} from '../../services/services/customizing.service';
import {TranslateService} from '@ngx-translate/core';
import {TranslatableComponent} from '../../models/TranslatableComponent';
import {MatDialog} from '@angular/material/dialog';
import Config from '../../config/Config';
import {MaintenanceGuidelinesService} from "../../services/services/maintenanceGuidelines.service";
import {MaintenanceGuideline} from "../../models/MaintenanceGuideline";
import {AppEntity, AppEntityAccessType, Permission} from "../../models/AppPermissions";
import {FileDownloadService} from "../../services/services/file-download.service";

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent extends TranslatableComponent implements OnInit {
  currentAuthenticatedStatus = true;
  showProjectLink = false;
  systemDescription: string;
  displayGlobalSearchbar: boolean;
  userId: number;

  adName = '';
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );

  userManualLink = Config.userManualLink;

  guideline: MaintenanceGuideline;
  isEmployeeInDB = true;

  constructor(private breakpointObserver: BreakpointObserver, private _httpBaseService: HttpBaseService, public router: Router,
              private matDialog: MatDialog, private authService: AuthenticationService, private customizingService: CustomizingService,
              private _translate: TranslateService, private maintenanceGuidelineService: MaintenanceGuidelinesService,
              private fileDownloadService: FileDownloadService) {
    super(_translate, 'NavigationComponent');
  }

  async ngOnInit() {
    this.getEnvironment();
    this.authService.getApplicationUser().then(user => {
      if (user.employee) {
        this.userId = user.employee.id;
      } else {
        this.isEmployeeInDB = false;
      }
    });
    this.customizingService.isGlobalSearchbarEnabled().then(enabled => {
      if (enabled) {
        Promise.all([
          this.authService.hasPermission(Permission.TRUE, AppEntityAccessType.READ, AppEntity.EMPLOYEE),
          this.authService.hasPermission(Permission.TRUE, AppEntityAccessType.READ, AppEntity.PROJECT)
        ]).then(([canReadEmployee, canReadProject]) => {
          this.displayGlobalSearchbar = canReadEmployee && canReadProject;
        });
      }
    });
    this.customizingService.getMaReadonlyProjlist().then(async enabled => {
      const hasProjectUpdatePermission = await this.authService.hasPermission(Permission.TRUE, AppEntityAccessType.UPDATE, AppEntity.PROJECT);
      // Readonly roles are allowed to see the button if customizing allows it
      if (hasProjectUpdatePermission || enabled) {
        this.showProjectLink = true;
      }
    });
    this.authService.hasPermission(Permission.TRUE, AppEntityAccessType.READ, AppEntity.MAINTENANCE_GUIDELINE).then(data => {
      if (data) {
        // For start
        this.maintenanceGuidelineService.getMaintenanceGuideline().subscribe(value => {
          this.guideline = value;
        });

        // For changes
        this.maintenanceGuidelineService.share.subscribe(guideline => {
          this.guideline = guideline;
        });
      }
    });
  }

  logout() {
    this.authService.logout();
  }

  getEnvironment() {
    const availableEnvironments = ['dev', 'testGBP', 'testMIA', 'testGBM', 'testGBL', 'testMSA', 'testM3',
      'testZM', 'intGBP', 'prodGBP', 'prodGBI', 'prodMSA', 'prodM3', 'prodMIA', 'prodTREORBIS', 'prodZM', 'prodGILLARDON',
      'prodGBF', 'prodSCHWEIZ', 'prodCONPLAN', 'prodGBG', 'prodXAI', 'prodZT', 'prodGBY', 'ci', 'testTREORBIS', 'testBELTIOS', 'testLIFE',
      'testGILLARDON', 'testGBG', 'testCOMPLIANCE', 'testSCHWEIZ', 'testCONPLAN', 'testGLOBAL', 'testXQT', 'testGBR', 'testGBA'];
    this.customizingService.getEnvironment().then((env: string) => {
      let key: string;
      if (availableEnvironments.includes(env)) {
        key = 'mep.environment.' + env;
      } else {
        key = 'mep.environment.unknown';
      }
      this._translate.get(key).subscribe((name) => {
        this.systemDescription = name;
      });
    });
  }

  downloadGuideline(guideline: MaintenanceGuideline): void {
    this.fileDownloadService.downloadFile(guideline.downloadLink, guideline.name).subscribe();
  }
}
