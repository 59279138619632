import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ProjectName} from '../../../core/models/Project';
import {TranslatableComponent} from '../../../core/models/TranslatableComponent';
import {AssignmentTableComponent} from '../extend-or-move-project-dialog/assignment-table/assignment-table.component';
import {AlertEnum} from '../../../core/models/Enums/AlertEnum';
import {AssignmentProjectEmployee} from '../../../core/models/AssignmentProjectEmployee';
import {Router} from '@angular/router';
import moment from 'moment';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ConfirmationDialogComponent} from "../confirmation-dialog/confirmation-dialog.component";
import {ProjectService} from "../../../core/services/services/project.service";
import {MessageService} from "../../../core/services/services/message.service";

@Component({
  selector: 'app-copy-assignments-dialog',
  templateUrl: './copy-assignments-dialog.component.html',
  styleUrls: ['./copy-assignments-dialog.component.scss']
})
export class CopyAssignmentsDialogComponent extends TranslatableComponent implements OnInit {
  destinationProject: ProjectName;
  sourceProject: ProjectName;

  defaultEffectiveFrom;
  defaultEffectiveUntil;

  today = moment();

  noAssignments = false;

  loading = false;

  @ViewChild(AssignmentTableComponent)
  newAssignmentTable: AssignmentTableComponent;

  removeSourceProject = false;

  constructor(public dialogRef: MatDialogRef<CopyAssignmentsDialogComponent>,
              protected translateService: TranslateService,
              protected _projectService: ProjectService,
              protected _messageService: MessageService,
              protected _router: Router,
              @Inject(MAT_DIALOG_DATA) public data: ProjectName,
              public dialog: MatDialog) {
    super(translateService, 'CopyAssignmentsDialogComponent');
  }

  ngOnInit() {
    if (this.data) {
      this.onSourceProjectChanged(this.data);
    }
  }

  closeDialog(result: boolean = false) {
    this.dialogRef.close(result);
  }

  saveChanges() {
    this.loading = true;

    this._projectService.extendProject(this.destinationProject.id, this.getCopiedSelectedAssignments(), undefined).then(() => {
      this.afterCopyOrMove('save-changes');
    }).catch(() => {
      this.loading = false;
    });
  }

  move() {
    if (this.getRemoveSourceProject()) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        disableClose: false,
        data: {
          messageKey: "mep.components.copy-assignments-dialog.remove-source-project-confirmation",
          params: {name: this.sourceProject.client + " - " + this.sourceProject.projectName}
        }
      });

      dialogRef.afterClosed().subscribe(value => {
        if (value) {
          this.moveInternal();
        }
      });
    } else {
      this.moveInternal();
    }
  }

  private moveInternal() {
    this.loading = true;
    this._projectService.extendProject(this.destinationProject.id, this.getCopiedSelectedAssignments(), undefined).then(() => {

      // First copy all the assignments and only after successful copy we remove the source assignments
      this._projectService.deleteAssignmentsProjectEmployeesWithCallback(this.newAssignmentTable.getSelectedAssignments(), () => {
        if (this.getRemoveSourceProject()) {
          this._projectService.deleteProject(this.sourceProject).subscribe(() => this.afterCopyOrMove('move-saved-source-deleted'));
        } else {
          this.afterCopyOrMove('move-saved');
        }
      });
    }).catch(() => {
      this.loading = false;
    });
  }

  private getRemoveSourceProject() {
    // Remove source project is checked and all assignments are selected
    return this.removeSourceProject && this.newAssignmentTable.assignmentDataSource.data.length === this.newAssignmentTable.getSelectedAssignments().length;
  }

  afterCopyOrMove(resourceIdentifier: string) {
    this.loading = false;
    this._messageService.add(this._lang(resourceIdentifier), AlertEnum.success);
    this.closeDialog(true);
    this._router.navigateByUrl('/project-edit/' + this.destinationProject.sharePointId);
  }

  getCopiedSelectedAssignments() {
    const selectedAssignments = this.newAssignmentTable.getSelectedAssignmentsDto();

    selectedAssignments.forEach(assignment => assignment.projectID = this.destinationProject.id);

    return selectedAssignments;
  }

  onDestinationProjectChanged(project: ProjectName) {
    this.destinationProject = project;

    this.loading = true;
    this._projectService.getAssignmentEmployeeProjectInfo(project._links.self.href).then((assigned: AssignmentProjectEmployee[]) => {
      this.loading = false;

      this.newAssignmentTable.hiddenAssignments = assigned;
    }, () => {
      this.loading = false;
    });
  }

  onSourceProjectChanged(project: ProjectName) {
    this.sourceProject = project;

    this.loading = true;
    this._projectService.getAssignmentEmployeeProjectInfo(project._links.self.href).then((assigned: AssignmentProjectEmployee[]) => {
      this.noAssignments = !assigned || assigned.length === 0;
      this.loading = false;
      this.newAssignmentTable.assignments = assigned;
    }, () => {
      this.loading = false;
    });
  }

  get selectedProjectsValid(): boolean {
    return !!this.sourceProject && !!this.destinationProject && this.sourceProject.id !== this.destinationProject.id;
  }

  onDefaultEffectiveFromChanged(effectiveFrom) {
    if (effectiveFrom) {
      this.newAssignmentTable.setEffectiveFromForAllAssignments(effectiveFrom);
    }
  }

  onDefaultEffectiveUntilChanged(effectiveUntil) {
    if (effectiveUntil) {
      this.newAssignmentTable.setEffectiveUntilForAllAssignments(effectiveUntil);
    }
  }
}
