import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {MessageService} from './message.service';
import {Observable} from "rxjs";


@Injectable({
  providedIn: 'root'
})
export class HttpBaseService {

  private readonly headers = new HttpHeaders();

  constructor(
    public http: HttpClient,
    protected _messageService: MessageService) {
    this.headers = this.headers.set('Content-Type', 'application/json');
    this.headers = this.headers.set('Accept', 'application/json');
  }

  private static getObjectWithoutLinks<T>(object: T): T {
    if (object['_links']) {
      let clone = JSON.parse(JSON.stringify(object));

      delete clone['_links'];

      return clone;
    } else {
      return object;
    }
  }

  protected static removeProjectionSuffix(link: string): string {
    return link.replace('{?projection}', '');
  }

  getAll<T = any>(baseUrl, projection = ''): Observable<HttpResponse<T>> {
    return this.http.get<T>(baseUrl + projection, {observe: 'response'});
  }

  getSingle<T>(baseUrl, id: number, projection = '') {
    return this.http.get<T>(`${baseUrl}${id}${projection}`);
  }

  getSingle1<T>(baseUrl) {
    return this.http.get<T>(baseUrl);
  }

  add<T>(baseUrl, toAdd: T): Observable<T> {
    return this.http.post<T>(baseUrl, toAdd, {headers: this.headers});
  }

  update<T>(url: string, toUpdate: any) {
    let headers = this.headers;
    if (typeof toUpdate['eVersion'] === 'number') {
      headers = this.headers.set('If-Match', toUpdate['eVersion'].toString());
    }
    return this.http.put<T>(url,
      toUpdate,
      {headers: headers});
  }

  patch<T>(url: string, toUpdate: T): Observable<T> {
    toUpdate = HttpBaseService.getObjectWithoutLinks(toUpdate);

    let headers = this.headers;
    if (typeof toUpdate['eVersion'] === 'number') {
      headers = this.headers.set('If-Match', toUpdate['eVersion'].toString());
    }
    return this.http.patch<T>(url,
      toUpdate,
      {headers: headers});
  }

  delete(url: string) {
    return this.http.delete(url);
  }
}
