import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Project} from '../../models/Project';
import {Employee} from '../../models/Employee';
import {ActionPlan} from "../../models/ActionPlan";
import {
  ResourceRequest,
  ResourceRequestOverviewDTO
} from "../../../feature/resource-request-list/models/resource-request";

/**
 * Data service used for
 * data transfer between
 * components
 *
 * @export
 * @class DataService
 */
@Injectable({
  providedIn: 'root',
})
export class DataService {
  public selectedProject: Project;
  public selectedActionPlan: ActionPlan;
  public selectedResourceRequest: ResourceRequest;

  private defaultAuthenticated = new BehaviorSubject<boolean>(false);
  projectRowIndex: any;

  private employeeSource = new BehaviorSubject<Employee>(undefined);
  currentEmployee = this.employeeSource.asObservable();

  private projectSource = new BehaviorSubject<Project>(undefined);
  currentProject = this.projectSource.asObservable();

  private resourceRequestSource = new BehaviorSubject<ResourceRequestOverviewDTO>(undefined);
  currentResourceRequest = this.resourceRequestSource.asObservable();

  changeEmployee(employee: Employee) {
    this.employeeSource.next(employee);
  }

  clearProjectCache() {
    this.projectSource.next(undefined);
  }

  clearEmployeeCache() {
    this.employeeSource.next(undefined);
  }

  changeProject(project: Project) {
    this.projectSource.next(project);
  }

  changeResourceRequest(resourceRequest: ResourceRequestOverviewDTO) {
    this.resourceRequestSource.next(resourceRequest);
  }

  /**
   *
   *
   * Change Authentication flag
   *
   *
   *
   * @param authenticated
   * @memberof DataService
   */
  changeAuthenticatedStatus(authenticated: boolean) {
    this.defaultAuthenticated.next(authenticated);
  }

}

export function sumObjectsByKey(...objs) {
  return objs.reduce((a, b) => {
    for (let k in b) {
      if (b.hasOwnProperty(k)) {
        a[k] = Math.round(((a[k] || 0) + b[k]) * 10) / 10;
      }
    }
    return a;
  }, {});
}
