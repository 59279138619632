import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {TranslatableComponent} from "../../../core/models/TranslatableComponent";
import {TranslateService} from "@ngx-translate/core";
import {MatSort} from "@angular/material/sort";
import {FileWithType} from "../../../core/models/file";
import {FileDownloadService} from "../../../core/services/services/file-download.service";

@Component({
  selector: 'app-file-table',
  templateUrl: './file-table.component.html',
  styleUrls: ['./file-table.component.scss']
})
export class FileTableComponent<T extends FileWithType<any>> extends TranslatableComponent implements AfterViewInit, OnChanges {
  @Input()
  files: T[];

  @Input()
  fileTypePrefix: string;

  @Input()
  displayedColumns;

  @Input()
  isFileDownload: boolean;

  @Input()
  fileDownloadClicked: (callbackFileDownload) => void;

  @Output()
  deleteClicked = new EventEmitter<any>();

  @Output()
  downloadedFile = new EventEmitter<any>();

  dataSource = new MatTableDataSource<T>();

  loading: boolean;

  @ViewChild(MatSort)
  private sort: MatSort;

  private static SUPPORTED_FILE_TYPES = ['.pdf', '.ppt', '.pptx', '.pptm', '.jpg', '.png'];

  constructor(protected translate: TranslateService, private fileDownloadService: FileDownloadService) {
    super(translate, 'FileTableComponent');
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.dataSource.data = this.files;
  }

  onClick($event: MouseEvent, href, filename) {
    if (this.fileDownloadClicked) {
      this.fileDownloadClicked(() => {
        this.downloadFile(href, filename);
      });
    } else {
      this.downloadFile(href, filename);
    }
  }

  downloadFile(href, filename) {
    if (this.isFileDownload === false) {
      window.open(filename, "_blank");
    } else {
      this.loading = true;
      this.fileDownloadService.downloadFile(href, filename).subscribe(val => {
        this.downloadedFile.emit(val);
      }).add(() => this.loading = false);
    }
  }

  openFile(file, filename) {
    if (this.isFileDownload === false) {
      window.open(filename, "_blank");
    } else {
      this.loading = true;
      this.fileDownloadService.loadPreviewFile(file.id, filename).subscribe(val => {
        this.downloadedFile.emit(val);
      }).add(() => this.loading = false);
    }
  }

  isSupportedFileType(name) {
    return FileTableComponent.SUPPORTED_FILE_TYPES.filter(type => name.toLowerCase().endsWith(type)).length;
  }
}
