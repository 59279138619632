import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ScreenRefreshService {

  public _refreshSubjectAfterAssignOfEmployee: BehaviorSubject<any> = new BehaviorSubject(false);
  public _refreshSubjectAfterProjectChange: BehaviorSubject<any> = new BehaviorSubject(false);

  public employeeWasAdded() {
    this._refreshSubjectAfterAssignOfEmployee.next(true);
  }

  public employeeWasEdited() {
    this._refreshSubjectAfterAssignOfEmployee.next(true);
  }

  public employeeWasRemoved() {
    this._refreshSubjectAfterAssignOfEmployee.next(true);
  }

  public projectWasEdited() {
    this._refreshSubjectAfterProjectChange.next(true);
  }

  public projectWasRemoved() {
    this._refreshSubjectAfterProjectChange.next(true);
  }
  public projectWasAdded() {
    this._refreshSubjectAfterProjectChange.next(true);
  }
}
