import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {FileTableComponent} from "./components/file-table.component";
import {TranslateModule} from "@ngx-translate/core";
import {MatTableModule} from "@angular/material/table";
import {MatSortModule} from "@angular/material/sort";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {NewsNotificationModule} from "../news-notification/news-notification.module";

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    MatTableModule,
    MatSortModule,
    MatIconModule,
    MatButtonModule,
    MatProgressBarModule,
    NewsNotificationModule
  ],
  declarations: [
    FileTableComponent
  ],
  exports: [
    FileTableComponent
  ]
})
export class FileTableModule {
}
