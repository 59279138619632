import {Injectable} from '@angular/core';
import {HttpBaseService} from './http-base.service';
import {HttpClient} from '@angular/common/http';
import {MessageService} from './message.service';
import Config, {AWS_BASE_URL} from '../../config/Config';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {EnumMapper} from "./enumMapper.service";
import {shareReplay} from "rxjs/operators";
import {CustomizingTable} from "../../models/CustomizingTable";
import {CustomizingAbsence} from "../../models/Enums/CustomizingAbsence";
import moment, {Moment} from "moment";
import {CapacityBorders} from "../../../shared/capacity-list/employee-capacity-legend/capacity-borders";
import {Client} from "../../config/Client";
import {KeycloakConfig} from "keycloak-js";
@Injectable({
  providedIn: 'root'
})
export class CustomizingService extends HttpBaseService {

  constructor(public http: HttpClient, public messageService: MessageService, public translate: TranslateService) {
    super(http, messageService);
  }

  private customizingTableCache$: Observable<CustomizingTable>;

  evictCustomizingCache() {
    console.log("Evicting customizing cache");
    return this.http.post(Config.services.customizing.baseUrl + Config.services.customizing.evictCache, null)
      .subscribe(() => this.refreshCustomizingTable());
  }

  async getIllnessFactor(): Promise<number> {
    return (await this.getCustomizingTable()).sicknessRate;
  }

  async getTravelWillingness(): Promise<string> {
    return (await this.getCustomizingTable()).travelWillingnessForImport;
  }

  /**
   * Get the concretely calculated amount of months from the current month to the past (excluding the current month).
   *
   * Example: If the current month is september, and the number is 2 of concrete months to the past, this means
   * the august and july are being calculated concretely.
   */
  async getMonthsToPast(): Promise<number> {
    return (await this.getCustomizingTable()).monthsToPast;
  }

  /**
   * Get the concretely calculated amount of months from the current month to the future (including the current month).
   *
   * Example: If the current month is september, and the number is 2 of concrete months to the future, this means
   * the september and november are being calculated concretely.
   */
  async getMonthsToFuture(): Promise<number> {
    return (await this.getCustomizingTable()).monthsToFuture;
  }

  /**
   * Gets the bounds of concrete calculations (Start date inclusive and end date exclusive)
   */
  async getConcreteCalculationBounds(): Promise<{ start: Moment; end: Moment }> {
    const customizingTable: CustomizingTable = await this.getCustomizingTable();
    const start = moment().add(-customizingTable.monthsToPast, 'months');
    const end = moment().add(customizingTable.monthsToFuture, 'months');

    return {start, end};
  }

  /**
   * Decides whether the given month was calculated concretely
   *
   * @param month The month to check
   * @param concreteCalculationBounds The concrete calculation bounds returned by CustomizingService#getConcreteBounds
   */
  isConcreteCalculation(month: Moment, concreteCalculationBounds: { start: Moment; end: Moment }): boolean {
    return month.isBetween(concreteCalculationBounds.start, concreteCalculationBounds.end, 'M', '[)');
  }

  async getWarFileName(): Promise<string> {
    return (await this.http.get<any>(AWS_BASE_URL + '/mep/version', {
      observe: 'response'
    }).toPromise()).body.version;
  }

  async getEnvironment(): Promise<string> {
    return (await this.http.get<any>(AWS_BASE_URL + '/mep/environment', {
      observe: 'response'
    }).toPromise()).body.environment;
  }

  getKeycloakConfig(): Observable<KeycloakConfig> {
    return this.http.get<KeycloakConfig>(AWS_BASE_URL + '/mep/keycloakFrontendClientConfig');
  }

  async getMonthPickerMonthsToPast(): Promise<number> {
    return (await this.getCustomizingTable()).monthPickerMonthsToPast;
  }

  async isActionPlanFunctionalityEnabled(): Promise<boolean> {
    return (await this.getCustomizingTable()).actionPlanEnabled;
  }

  async p1BookingEnabled(): Promise<boolean> {
    return (await this.getCustomizingTable()).p1BookingEnabled;
  }

  async getPlanIsThreshold(): Promise<number> {
    return (await this.getCustomizingTable()).p1ColoringThreshold;
  }

  async isGlobalSearchbarEnabled(): Promise<boolean> {
    return (await this.getCustomizingTable()).globalSearchbarEnabled;
  }

  async getCurrentClient(): Promise<Client> {
    return (await this.getCustomizingTable()).currentClient;
  }

  async isProjectEditable(): Promise<boolean> {
    return (await this.getCustomizingTable()).projectEditable;
  }

  async isInstanceWideSearchEnabled(): Promise<boolean> {
    return (await this.getCustomizingTable()).instanceWideSearchEnabled;
  }

  async isNewProjAddEmployee(): Promise<boolean> {
    return (await this.getCustomizingTable()).newProjAddEmployee;
  }

  async isCapacityOverviewSearchRelevantEnabled(): Promise<boolean> {
    return (await this.getCustomizingTable()).capacityOverviewSearchRelevant;
  }

  async getAbsenceSettings(): Promise<CustomizingAbsence> {
    return (await this.getCustomizingTable()).absenceSetting;
  }

  async isEmployeeHierarchyActive(): Promise<boolean> {
    return (await this.getCustomizingTable()).employeeHierarchyActive;
  }

  async isRemainingVacationFunctionalityEnabled(): Promise<boolean> {
    return (await this.getCustomizingTable()).remainingVacationEnabled;
  }

  async isResourceRequestEnabled(): Promise<boolean> {
    return (await this.getCustomizingTable()).resourceRequestEnabled;
  }

  async getShowCapaRevenueChkbx(): Promise<number> {
    return (await this.getCustomizingTable()).showCapaRevenueChkbx;
  }


  async getRevenuePanelExtEmployee(): Promise<boolean> {
    return (await this.getCustomizingTable()).revenuePanelExtEmployee;
  }

  async getSkillLevels(): Promise<string[]> {
    const client = await this.getCurrentClient();
    return Config.services.skills[EnumMapper.getConfigObjectForClient("skillLevels", client)];
  }

  async isAggregationAvailable(): Promise<boolean> {
    return (await this.getCustomizingTable()).projectAggregateEnabled;
  }

  async isProjectDetailsDayViewEnabled(): Promise<boolean> {
    return (await this.getCustomizingTable()).projectDetailsDayViewEnabled;
  }

  async requireProjectAdmin(): Promise<boolean> {
    return (await this.getCustomizingTable()).requireProjectAdmin;
  }

  async isConcreteCalculationsEnabled(): Promise<boolean> {
    return (await this.getCustomizingTable()).concreteCalculationEnabled;
  }

  async enabledProjectEmployeeProposal(): Promise<boolean> {
    return (await this.getCustomizingTable()).projectEmployeeProposalEnabled;
  }

  async isVacationDaysPerMonthEnabled(): Promise<boolean> {
    return (await this.getCustomizingTable()).vacationDaysPerMonthEnabled;
  }

  async isUtilizationChartEnabled(): Promise<boolean> {
    return (await this.getCustomizingTable()).utilizationChartEnabled;
  }

  async isEmployeeDetailsPlannedCapacityEnabled(): Promise<boolean> {
    return (await this.getCustomizingTable()).employeeDetailsPlannedCapacityEnabled;
  }

  async fetchFeastCountries(): Promise<string[]> {
    return (await this.getCustomizingTable()).holidayCountries;
  }

  async getUnderloadBorderPercent(): Promise<number> {
    return (await this.getCustomizingTable()).underloadBorderPercent;
  }

  async getOptimalWorkloadPercent(): Promise<number> {
    return (await this.getCustomizingTable()).optimalWorkloadPercent;
  }

  async getDefaultCapacityBorders(): Promise<CapacityBorders> {
    let defaultCapacityBorders: CapacityBorders = new CapacityBorders();
    defaultCapacityBorders.optimalWorkloadBorderPercentage = await this.getOptimalWorkloadPercent();
    defaultCapacityBorders.underloadBorderPercentage = await this.getUnderloadBorderPercent();
    defaultCapacityBorders.underloadBorderPT = await this.getUnderloadBorderPT();
    defaultCapacityBorders.optimalWorkloadBorderPT = await this.getOptimalWorkloadPT();
    return defaultCapacityBorders;
  }

  async getInstanceWideSearchMinCapacity(): Promise<number> {
    return (await this.getCustomizingTable()).instanceWideSearchMinCapacity;
  }

  async getUnderloadBorderPT(): Promise<number> {
    return (await this.getCustomizingTable()).underloadBorderPT;
  }

  async getOptimalWorkloadPT(): Promise<number> {
    return (await this.getCustomizingTable()).optimalWorkloadPT;
  }

  async isEmployeeLeavingDateVisible(): Promise<boolean> {
    return (await this.getCustomizingTable()).employeeLeavingDateVisible;
  }

  async getCapacityOverViewCheckBoxHideExternalDefault(): Promise<boolean> {
    return (await this.getCustomizingTable()).capacityOverViewCheckBoxHideExternalDefault;
  }

  async getTotalCapacitiesInternalCost(): Promise<boolean> {
    return (await this.getCustomizingTable()).totalCapacitiesInternalCost;
  }

  async getExternalLinks(): Promise<string> {
    return (await this.getCustomizingTable()).externalLinks;
  }

  setIllnessFactor(data: number) {
    return this.http.put(Config.services.customizing.baseUrl + Config.services.customizing.illnessFactor, data).subscribe(() => this.refreshCustomizingTable());
  }

  setMonthsToFuture(data: number) {
    return this.http.put(Config.services.customizing.baseUrl + Config.services.customizing.monthsToFuture, data).subscribe(() => this.refreshCustomizingTable());
  }

  setMonthsToPast(data: number) {
    return this.http.put(Config.services.customizing.baseUrl + Config.services.customizing.monthsToPast, data).subscribe(() => this.refreshCustomizingTable());
  }

  setUnderloadBorderPercent(data: number) {
    return this.http.put(Config.services.customizing.baseUrl + Config.services.customizing.underloadBorderPercent, data).subscribe(() => this.refreshCustomizingTable());
  }

  setOptimalWorkloadPercent(data: number) {
    return this.http.put(Config.services.customizing.baseUrl + Config.services.customizing.optimalWorkloadPercent, data).subscribe(() => this.refreshCustomizingTable());
  }

  setUnderloadBorderPT(data: number) {
    return this.http.put(Config.services.customizing.baseUrl + Config.services.customizing.underloadBorderPT, data).subscribe(() => this.refreshCustomizingTable());
  }

  setOptimalWorkloadPT(data: number) {
    return this.http.put(Config.services.customizing.baseUrl + Config.services.customizing.optimalWorkloadPT, data).subscribe(() => this.refreshCustomizingTable());
  }

  setInstanceWideSearchMinCapacity(data: number) {
    return this.http.put(Config.services.customizing.baseUrl + Config.services.customizing.instanceWideSearchMinCapacity, data).subscribe();
  }

  setSkilleditSkillsPerPage(data: number) {
    return this.http.put(Config.services.customizing.baseUrl + Config.services.customizing.skilleditSkillsPerPage, data).subscribe();
  }

  setNewSkillsDate(data: Date) {
    return this.http.put(Config.services.customizing.baseUrl + Config.services.customizing.newSkillsDate, data).subscribe();
  }

  async isPreferencesLocalStorage(): Promise<boolean> {
    return (await this.getCustomizingTable()).preferencesInLocalStorageEnabled;
  }

  async isCheckboxCapaNotRelevant(): Promise<boolean> {
    return (await this.getCustomizingTable()).checkboxNotRelevantForCapaEnabled;
  }

  async areSkillgroupsEnabled(): Promise<boolean> {
    return (await this.getCustomizingTable()).skillGroupsEnabled;
  }

  async getIsExternalLink(): Promise<string> {
    return (await this.getCustomizingTable()).externalLinks;
  }

  async isClientnameDisplayed(): Promise<boolean> {
    return (await this.getCustomizingTable()).displayClientname;
  }

  async isUploadcenterAgreementEnabled(): Promise<boolean> {
    return (await this.getCustomizingTable()).uploadcenterAgreementEnabled;
  }

  async isMAReadonlyUploadCenterDisplayed(): Promise<boolean> {
    return (await this.getCustomizingTable()).displayMAReadonlyUploadCenter;
  }

  async isProjectListCheckDatesEnabled(): Promise<boolean> {
    return (await this.getCustomizingTable()).projectListCheckDatesEnabled;
  }

  async isShowLastImportEmployeeProjectEnabled(): Promise<boolean> {
    return (await this.getCustomizingTable()).showLastImportEmployeeProject;
  }

  async getSkilleditSkillsPerPage(): Promise<number> {
    return (await this.getCustomizingTable()).skilleditSkillsPerPage;
  }

  async isEmployeeFileUploadEnabled(): Promise<boolean> {
    return (await this.getCustomizingTable()).employeeFileUploadEnabled;
  }

  async isIgnoreIWSMinCapacityAllowed(): Promise<boolean> {
    return (await this.getCustomizingTable()).allowIgnoreIWSMinCapacity;
  }

  async getNewSkillsDate(): Promise<string> {
    return (await this.getCustomizingTable()).newSkillsDate;
  }

  async getMaReadonlyReslist(): Promise<boolean> {
    return (await this.getCustomizingTable()).maReadonlyReslist;
  }

  async getMaReadonlyProjlist(): Promise<boolean> {
    return (await this.getCustomizingTable()).maReadonlyProjList;
  }

  async getPspRequestMailbox(): Promise<string> {
    return (await this.getCustomizingTable()).pspRequestMailbox;
  }

  public getCustomizingTable(): Promise<CustomizingTable> {
    if (!this.customizingTableCache$) {
      this.customizingTableCache$ = this.http.get<CustomizingTable>(
        AWS_BASE_URL + '/mep/' + Config.services.customizing.customizingTable)
        .pipe(shareReplay(1));
    }
    return this.customizingTableCache$.toPromise();
  }

  public refreshCustomizingTable() {
    this.customizingTableCache$ = null;
  }
}
