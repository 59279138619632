import {SkillDbFilterData} from "../../dialogs/filter-dialog/filter-dialog.interface";
import {TranslateService} from "@ngx-translate/core";
import {SkillDbEntry} from "../../../core/models/EmployeeSkill";

declare interface FilterData {
  [key: string]: any;
}

export interface FilterChipGroup {
  name: string;
  filterChips: FilterChip[];
}

export interface FilterChip {
  viewValue: string;

  remove();
}

export abstract class ChipGroupTemplate {
  protected constructor(protected filterViewModel: { filterData: FilterData }, protected attributes: string[]) {
  }

  public getAllFilterChipGroups(): FilterChipGroup[] {

    return this.attributes.map(attribute => this.getFilterChips(this.filterViewModel.filterData, attribute))
      .filter(group => group.filterChips.length > 0);
  }

  protected abstract getFilterChips(filterData: FilterData, attribute: string): FilterChipGroup;
}

export abstract class ChipTemplate {
  protected constructor(protected filterViewModel: { filterData: FilterData }, protected attributes: string[]) {
  }

  public getAllFilterChips(): FilterChip[] {
    return this.attributes.map(attribute => this.getFilterChip(this.filterViewModel.filterData, attribute))
      .filter(chip => !!chip);
  }

  protected abstract getFilterChip(filterData: FilterData, attribute: string): FilterChip;
}

export class StandardChipTemplate extends ChipTemplate {
  constructor(protected filterViewModel: { filterData: FilterData }, protected attributes: string[], private translate: TranslateService) {
    super(filterViewModel, attributes);
  }

  protected getFilterChip(filterData: FilterData, attribute: string): FilterChip {
    const value = filterData[attribute];

    if (value === undefined || value === null || value === '') {
      return undefined;
    } else {
      const chip: FilterChip = {
        viewValue: value,
        remove: () => {
          filterData[attribute] = undefined;
        }
      };

      this.translate.get(`mep.components.general.filter.prefixes.${attribute}`).subscribe(prefix => {
        chip.viewValue = prefix + chip.viewValue;
      });

      return chip;
    }
  }
}

export class DropDownChipGroupTemplate extends ChipGroupTemplate {
  constructor(protected filterViewModel: { filterData: FilterData }, protected attributes: string[]) {
    super(filterViewModel, attributes);
  }

  protected getFilterChips(filterData: FilterData, attribute: string): FilterChipGroup {
    const dropDownOptions = filterData[attribute];
    let filterChips: FilterChip[] = [];
    if (dropDownOptions) {
      filterChips = dropDownOptions.map((option) => {
        const viewValue = this.getViewValue(option);
        return {
          viewValue: viewValue,
          remove: () => {
            const index = dropDownOptions.indexOf(option);
            dropDownOptions.splice(index, 1);
          }
        };
      });
    }

    return {
      filterChips: filterChips,
      name: null
    };
  }

  getViewValue(option): string {
    return option.viewValue;
  }
}

export class SkillDbChipGroupTemplate extends DropDownChipGroupTemplate {
  constructor(protected filterViewModel: { filterData: FilterData }, protected attributes: string[], private translate: TranslateService) {
    super(filterViewModel, attributes);
  }

  protected getFilterChips(filterData: FilterData, attribute: string): FilterChipGroup {
    const skillFilterData: SkillDbFilterData = filterData[attribute];

    const group = super.getFilterChips(skillFilterData, "skillDbRows");
    group.name = undefined;

    this.translate.get(`mep.components.general.filter.logical-operator.${skillFilterData.logicalOperator}`).subscribe(name => {
      group.name = name;
    });

    return group;
  }

  getViewValue(option: SkillDbEntry): string {
    return `${option.skillName} > ${option.level}`;
  }
}
