import {Component, OnInit} from '@angular/core';
import {ProjectName} from "../../../core/models/Project";
import {ProjectService} from "../../../core/services/services/project.service";
import {TranslateService} from "@ngx-translate/core";
import {DatePipe} from "@angular/common";
import {CustomizingService} from "../../../core/services/services/customizing.service";
import {Client} from "../../../core/config/Client";
import {AbstractBackendAutocomplete} from "./abstract-backend-autocomplete";
import {Observable} from "rxjs";

@Component({
  selector: 'app-project-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss']
})
export class ProjectAutocompleteComponent extends AbstractBackendAutocomplete<ProjectName> implements OnInit {
  private currentClient: Client;
  private isClientnameDisplayed: boolean;

  constructor(private _projectService: ProjectService, private translateService: TranslateService, private customizingService: CustomizingService) {
    super();
  }

  async ngOnInit() {
    this.isClientnameDisplayed = await this.customizingService.isClientnameDisplayed();
    this.currentClient = await this.customizingService.getCurrentClient();
    super.ngOnInit();
  }

  displayAsSelectedOption = (project: ProjectName) => project ? `${this.showClientname(project.client)}${project.projectName}` : undefined;

  displayAsDropDownOption = (project: ProjectName) => {
    if (this.currentClient === Client.GBP) {
      return project ? `${this.showClientname(project.client)}${project.projectName} <small class="color-grey">(${this.translateService.instant('mep.components.filter-parser.filter-attribute.businessUnit')}: ${project.businessUnit}, ProjID: ${project.sharePointId}, PSP: ${project.psp}, ${this.translateService.instant('mep.components.project-data.attributes.start')}: ${new DatePipe('de-DE').transform(project.startDate)}, ${this.translateService.instant('mep.components.project-data.attributes.end')}: ${new DatePipe('de-DE').transform(project.endDate)})</small>` : undefined;
    } else {
      return project ? `${this.showClientname(project.client)}${project.projectName} <small class="color-grey">(PSP: ${project.psp}, ${this.translateService.instant('mep.components.project-data.attributes.start')}: ${new DatePipe('de-DE').transform(project.startDate)}, ${this.translateService.instant('mep.components.project-data.attributes.end')}: ${new DatePipe('de-DE').transform(project.endDate)})</small>` : undefined;
    }
  };

  showClientname(clientname: string): string {
    return this.isClientnameDisplayed ? `${clientname} - ` : '';
  }

  sort(element: ProjectName, otherElement: ProjectName): number {
    return element.projectName ? element.projectName.localeCompare(otherElement.projectName) : 0;
  }

  getElementsFromBackendForSearchTerm(searchTerm: string): Observable<ProjectName[]> {
    return this._projectService.getProjectNames(searchTerm);
  }

}
