import {CanDeactivate} from '@angular/router';
import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {ConfirmationDialogComponent} from "../../shared/dialogs/confirmation-dialog/confirmation-dialog.component";
import {MatDialog} from "@angular/material/dialog";

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean;
}

@Injectable()
export class PendingChangesGuard implements CanDeactivate<ComponentCanDeactivate> {

  constructor(private matDialog: MatDialog) {
  }

  canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> {
    // if there are no pending changes, just allow deactivation; else confirm first
    return component.canDeactivate() ?
      true :
      this.showDialog();
  }

  showDialog(): Observable<boolean> {
    const dialogRef = this.matDialog.open(ConfirmationDialogComponent, {
      disableClose: false,
      data: 'mep.components.general.notification.warn-about-page-change'
    });
    return dialogRef.afterClosed();
  }
}
