import {KeycloakService} from 'keycloak-angular';
import {CustomizingService} from "../services/services/customizing.service";
import {LocationStrategy} from "@angular/common";

async function initialize(keycloak: KeycloakService, customizingService: CustomizingService, locationStrategy: LocationStrategy): Promise<void> {
  const keycloakConfig = await customizingService.getKeycloakConfig().toPromise();

  await keycloak.init({
    config: keycloakConfig,
    initOptions: {
      onLoad: 'check-sso',
      silentCheckSsoRedirectUri: `${window.location.origin}/${locationStrategy.getBaseHref()}assets/silent-check-sso.html`,
    },
    bearerExcludedUrls: []
  });

  // If user is not logged in then redirect to login
  if (!await keycloak.isLoggedIn()) {
    await keycloak.login();
  }
}

export function initializer(keycloak: KeycloakService, customizingService: CustomizingService, locationStrategy: LocationStrategy): () => Promise<any> {
  return () => initialize(keycloak, customizingService, locationStrategy);
}
