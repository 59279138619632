import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LanguageTooltipComponent} from "./components/language-tooltip.component";
import {MatTableModule} from "@angular/material/table";
import {MatDialogModule} from "@angular/material/dialog";


@NgModule({
  declarations: [LanguageTooltipComponent],
  exports: [
    LanguageTooltipComponent
  ],
  imports: [
    CommonModule,
    MatTableModule,
    MatDialogModule
  ]
})
export class LanguageTooltipModule { }
