import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Cluster, EmployeeSkillClusterDTO} from "../../models/EmployeeSkill";
import Config from "../../config/Config";

@Injectable({
  providedIn: 'root'
})
export class SkillService {

  constructor(private http: HttpClient) {
  }

  getAllEmployeeSkillCluster(employeeId: number): Observable<EmployeeSkillClusterDTO[]> {
    return this.http.get<EmployeeSkillClusterDTO[]>(Config.services.skills.baseUrl + Config.services.skills.getAllEmployeeSkillClustersUrl + "/" + employeeId);
  }

  getAllEmployeeSkillSetCluster(employeeId: number, instanceName: string): Observable<EmployeeSkillClusterDTO[]> {
    return this.http.get<EmployeeSkillClusterDTO[]>(Config.services.skills.baseGlobalUrl + Config.services.skills.getAllEmployeeSkillsetCluster + encodeURIComponent(instanceName) + '&' + "employeeId=" + employeeId);
  }

  getAllSkillCluster(): Observable<Cluster[]> {
    return this.http.get<Cluster[]>(Config.services.skills.baseUrl + Config.services.skills.allSkillCluster);
  }

}
