import {Component, HostListener, Inject, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Project, ProjectName} from '../../../core/models/Project';
import {ProjectService} from '../../../core/services/services/project.service';
import {AlertEnum} from '../../../core/models/Enums/AlertEnum';
import {EmployeeService} from '../../../core/services/services/employee.service';
import {MessageService} from '../../../core/services/services/message.service';
import {ScreenRefreshService} from '../../../core/services/services/refresh-screens.service';
import {Employee} from "../../../core/models/Employee";
import {AssignmentProjectEmployee} from "../../../core/models/AssignmentProjectEmployee";
import {TranslatableComponent} from "../../../core/models/TranslatableComponent";
import {TranslateService} from "@ngx-translate/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {MatSort} from "@angular/material/sort";
import {MatTableDataSource} from "@angular/material/table";
import Config from "../../../core/config/Config";
import {EmployeeFullName} from "../../../core/models/EmployeeFullName";
import {MatCheckboxChange} from "@angular/material/checkbox";
import {
  EmployeeFrontendAutocompleteComponent
} from "../../autocomplete/components/employee-frontend-autocomplete.component";
import {CustomizingService} from "../../../core/services/services/customizing.service";
import {AuthenticationService} from "../../../core/services/services/authentication.service";
import {AppEntity, AppEntityAccessType, Permission} from "../../../core/models/AppPermissions";
import {Preference, PreferencesService} from "../../../core/services/services/preferences.service";
import moment from "moment";
import {hasId} from "../../../core/models/validators";
import {DateValidator} from "../../../core/validation/DateValidator";
import {MailToService} from "../../../core/services/services/mail-to.service";

export class AssignData {
  projects: Project[]; // Project(s) for that the assignment is done
  disableProjects: boolean; // If selection of Projects should be disabled
  employees: Employee[]; // Employee(s) that will be assigned to a Project
  enabledProposal: boolean;
}

@Component({
  selector: 'app-assign-employee-dialog',
  templateUrl: './assign-employee-dialog.component.html',
  styleUrls: ['./assign-employee-dialog.component.scss']
})
export class AssignEmployeeDialogComponent extends TranslatableComponent implements OnInit {


  constructor(public dialogRef: MatDialogRef<AssignEmployeeDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: AssignData,
              private _fb: FormBuilder,
              private _projectService: ProjectService,
              private _messageService: MessageService,
              private _employeeService: EmployeeService,
              private _screenRefreshService: ScreenRefreshService,
              private _authenticationService: AuthenticationService,
              private translateService: TranslateService,
              private customizingService: CustomizingService,
              private authenticationService: AuthenticationService,
              private preferencesService: PreferencesService,
              private mailToService: MailToService,
              private messageService: MessageService
  ) {
    super(translateService, 'AssignEmployeeDialogComponent');
    if (typeof this.data === undefined) {
      this._messageService.add(this._lang('notification.no-projects'), AlertEnum.warning);
    } else {
      this.customizingEmployeeProposalEnabled = this.data.enabledProposal !== undefined && this.data.enabledProposal;
      this.proposeEmployees = this.customizingEmployeeProposalEnabled;
    }
  }

  @ViewChild("auto")
  autocomplete: EmployeeFrontendAutocompleteComponent;


  assignmentOverwrittenMonths: { [monthYear: string]: FormControl };
  assignmentOverwrittenMonthKeys: string[] = [];
  validAssignmentInterval: boolean;
  assignForm: FormGroup;
  assignedEmployeesDataSource: MatTableDataSource<AssignmentProjectEmployee> = new MatTableDataSource();
  projectsTeamsDataSource: MatTableDataSource<AssignmentProjectEmployee> = new MatTableDataSource();

  customizingEmployeeProposalEnabled = false;
  proposeEmployees = false;

  assignedEmployeesColumns: string[] = [
    'firstName',
    'lastName',
    'effectiveFrom',
    'effectiveUntil',
    'capacity'
  ];
  projectsTeamsColumns: string[] = [
    'project',
    'capacity',
    'effectiveFrom',
    'effectiveUntil',
    'probability'
  ];

  selectedEmployeeLink: string;
  selectedProjectLink: string;
  // Progress bar status
  loadingProjectAssignments = false;
  loadingEmployeesAssignments = false;
  loading = false;

  currency = this.getEnums("currency");
  showPspRequestButton = false;


  @ViewChild('employeeSort') set contentE(content: MatSort) {
    if (content) {
      this.employeeSorting(content);
    }
  }

  @ViewChild('projectSort') set contentP(content: MatSort) {
    if (content) {
      this.projectSorting(content);
    }
  }


  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.code === 'Escape') {
      this.closeDialog();
    }
  }

  async ngOnInit() {
    this.assignForm = this._fb.group({
      project: new FormControl({value: '', disabled: this.data.disableProjects}, {
        validators: [Validators.required, hasId]
      }),
      employee: new FormControl('', {
        validators: [Validators.required, hasId]
      }),
      effectiveFrom: new FormControl('', {
        validators: Validators.required
      }),
      effectiveUntil: new FormControl('', {
        validators: Validators.required
      }),
      capacity: new FormControl('100', [
        Validators.required, Validators.max(100), Validators.min(0)
      ]),
      role: new FormControl('', {}),
      annotation: new FormControl('', {}),
      subteam: new FormControl('', {}),
      extRate: new FormControl('', {}),
      extRateCurrency: new FormControl('', {}),
      overwrittenAssignment: this._fb.array([], {})
    }, {validators: [DateValidator.fromToDate("effectiveFrom", "effectiveUntil")]});

    if (this.data.projects) {
      const project = this.data.projects[0];
      this.assignForm.get('project').setValue(project);
      this.onProjectSelectionChanged(project);
    }

    if (this.data.employees) {
      this.onEmployeeSelectionChanged(this.data.employees[0]);
      this.assignForm.get('employee').setValue(this.data.employees[0]);
    }

    this.customizingService.getPspRequestMailbox().then(res => {
      this.showPspRequestButton = res !== "";
    });

  }

  async extRateChange() {
    if (this.assignForm.get('extRate').value && !this.assignForm.get('extRateCurrency').value) {
      const prefRes = await this.preferencesService.get(Preference.CURRENCY);
      if (prefRes && prefRes !== "undefined" && prefRes !== "null") {
        this.assignForm.get('extRateCurrency').setValue(prefRes);
      }
    }
  }

  private projectSorting(content) {
    this.projectsTeamsDataSource.sort = content;

    this.projectsTeamsDataSource.sortingDataAccessor = ((data: AssignmentProjectEmployee, sortHeaderId: string) => {
      switch (sortHeaderId) {
        case 'project':
          return data.project.projectName.toLocaleLowerCase();
        case 'probability':
          return data.project.probabilitySP;
        case 'client':
          return data.project.client;
        default:
          return typeof data[sortHeaderId] === 'string' ? data[sortHeaderId].toLocaleLowerCase() : data[sortHeaderId];
      }
    });
  }

  private employeeSorting(content) {
    this.assignedEmployeesDataSource.sort = content;

    this.assignedEmployeesDataSource.sortingDataAccessor = ((data: AssignmentProjectEmployee, sortHeaderId: string) => {
      switch (sortHeaderId) {
        case 'firstName':
          return data.employee['firstName'].toLocaleLowerCase();
        case 'lastName':
          return data.employee['lastName'].toLocaleLowerCase();
        default:
          return typeof data[sortHeaderId] === 'string' ? data[sortHeaderId].toLocaleLowerCase() : data[sortHeaderId];
      }
    });
  }

  // Getting the Enum array from the config file
  private getEnums(keyWord: string) {
    return Object.keys(Config.services.employees[keyWord]).sort()
      .map(function (i) {
        return {value: i, viewValue: Config.services.employees[keyWord][i]};
      });
  }

  /**
   * Closes the dialog
   */
  closeDialog() {
    this.dialogRef.close();
  }

  onProjectSelectionChanged(project: ProjectName) {
    this.selectedProjectLink = project ? project._links.self.href : this.assignForm.get('project').value._links.self.href;

    if (this.autocomplete && this.customizingEmployeeProposalEnabled) {
      this.autocomplete.projectId = project ? project.id : null;
      this.autocomplete.toggleProposeEmployees(this.proposeEmployees, true);
    }

    this.getAssignmentProjectEmployees().then();
    this._projectService.getMinMaxEffectiveDates(project).subscribe(data => {
      this.assignForm.get('effectiveFrom').setValue(new Date());
      this.assignForm.get('effectiveUntil').setValue(data[1]);
      this.onAssignmentIntervalChanged();
    });
  }

  private async getAssignmentProjectEmployees() {
    if (await this._authenticationService.hasAtLeastPermission(Permission.TRUE, AppEntityAccessType.READ, AppEntity.ASSIGNMENT)) {
      this.loadingProjectAssignments = true;
      this._projectService.getAssignmentEmployeeProjectInfo(this.selectedProjectLink).then((assignedEmployee: AssignmentProjectEmployee[]) => {
        const result = [];
        let currentDate = parseInt(moment().format('YYYYMMDD'), 0);
        assignedEmployee.forEach(assigned => {
          let until = parseInt(moment(assigned.effectiveUntil).format('YYYYMMDD'), 0);
          if (assigned.project.status !== 'REJECTED' && until >= currentDate) {
            result.push(assigned);
          }
        });
        this.assignedEmployeesDataSource.data = result; // .filter((a) => a.actuallyActive);
        this.loadingProjectAssignments = false;
      }, () => {
        this.loadingProjectAssignments = false;
      });
    }
  }

  onEmployeeSelectionChanged(employee: EmployeeFullName | Employee) {
    this.selectedEmployeeLink = Config.services.employees.baseUrl + employee.id;
    if (employee.firstName === 'NN') {
      this.assignForm.get('capacity').setValidators([Validators.required, Validators.min(0)]);
    } else {
      this.assignForm.get('capacity').setValidators([Validators.required, Validators.min(0), Validators.max(100)]);
    }
    this.getAssignmentsOfEmployee();
  }

  private getAssignmentsOfEmployee() {
    this.loadingEmployeesAssignments = true;
    this._employeeService.getAssignments(this.selectedEmployeeLink).subscribe((assignments: AssignmentProjectEmployee[]) => {
        const result = [];
        let currentDate = parseInt(moment().format('YYYYMMDD'), 0);
        assignments.forEach(assignment => {
          let until = parseInt(moment(assignment.effectiveUntil).format('YYYYMMDD'), 0);
          if (assignment.project.status !== 'REJECTED' && until >= currentDate) {
            result.push(assignment);
          }
        });
        this.projectsTeamsDataSource.data = result;
        this.loadingEmployeesAssignments = false;
      },
      () => {
        this._messageService.add(this._lang('notification.fetch-assigned-error'), AlertEnum.danger);
        this.loadingEmployeesAssignments = false;
      });
  }

  /**
   * Add the selected Employee to the project
   *
   * @private
   * @memberof AssignEmployeeDialogComponent
   */
  async addEmployeeToProject() {
    if (this.assignForm.value.extRateCurrency && this.assignForm.value.extRateCurrency !== "null") {
      await this.preferencesService.put(Preference.CURRENCY, this.assignForm.value.extRateCurrency);
    }


    for (const i in this.assignForm.controls) {
      if (this.assignForm.controls[i].invalid) {
        this.assignForm.controls[i].markAsTouched();
      }
    }

    if (this.assignForm.valid) {
      this.loading = true;
      this.assignForm.value.project = this.selectedProjectLink;
      this.assignForm.value.employee = this.selectedEmployeeLink;
      if (this.assignForm.value.extRate) {
        this.assignForm.value.extRate = Number(this.assignForm.value.extRate.toString().replace(",", "."));
      }
      if (this.assignForm.value.extRateCurrency === ""
        || this.assignForm.value.extRateCurrency === null
        || this.assignForm.value.extRateCurrency === "undefined") {
        this.assignForm.value.extRateCurrency = 'MISSING_VALUE';
      }

      const overwrittenAssignments = {};
      Object.keys(this.assignmentOverwrittenMonths).forEach(month => {
        const formControl = this.assignmentOverwrittenMonths[month];

        if (formControl.dirty && !isNaN(Number(formControl.value))) {
          overwrittenAssignments[month] = formControl.value;
        }
      });
      this.assignForm.value.overwrittenAssignment = overwrittenAssignments;

      this._projectService.addAssignmentProjectEmployee(this.assignForm.value).subscribe(() => {
        this._screenRefreshService.employeeWasAdded();

        this._messageService.add(this.translate.instant('mep.components.general.notification.edit-success'), AlertEnum.success);
        this.loading = false;
        this.dialogRef.close(true);

      }, () => {
        this._messageService.add(this._lang('notification.add-error'), AlertEnum.danger, 0);
        this.loading = false;
      });
    } else {
      this.loading = false;
      this._messageService.add(this._lang('notification.fields-missing'), AlertEnum.warning);
    }
  }

  onAssignmentIntervalChanged() {


    const end = this.assignForm.get('effectiveUntil').value;
    const start = this.assignForm.get('effectiveFrom').value;
    this.validAssignmentInterval = this._employeeService.validAssignmentInterval(start, end);

    this.assignmentOverwrittenMonths = {};

    if (this.validAssignmentInterval) {
      this.assignmentOverwrittenMonthKeys = this._employeeService.getYearMonthKeysBetween(start, end);
      this.assignmentOverwrittenMonthKeys.forEach(month => {
        this.assignmentOverwrittenMonths[month] = new FormControl('', Validators.min(0));
      });
    }
  }

  invalidCurrency() {
    if (this.assignForm.value.extRate !== undefined) {
      let currencyRequired = (!this.assignForm.value.extRateCurrency
        || this.assignForm.value.extRateCurrency === ''
        || this.assignForm.value.extRateCurrency === "undefined"
        || this.assignForm.value.extRateCurrency === "MISSING_VALUE");
      return !!this.assignForm.value.extRate ? currencyRequired : false;
    }
  }

  toggleEmployeeProposal(change: MatCheckboxChange) {
    this.proposeEmployees = change.checked;
    this.autocomplete.toggleProposeEmployees(this.proposeEmployees);
  }

  sendPspRequestEmail() {
    let from = new Date(this.assignForm.get("effectiveFrom").value);
    let until = new Date(this.assignForm.get("effectiveUntil").value);
    let employees: Employee[] = [this.assignForm.get("employee").value];
    let psp = this.assignForm.get("project").value ? this.assignForm.get("project").value.psp : "";

    if (!psp) {
      this.messageService.add(this.translate.instant('mep.services.mailto.no-psp-element'), AlertEnum.danger);
    } else {
      this.mailToService.requestPspForEmployees(employees, from, until, psp);
    }

  };


}
