import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'appCapaCommentTransform', pure: true})
export class CapaCommentTransformPipe implements PipeTransform {

  transform(comment: string): string {
    let maxLength = 60;
    if (typeof comment !== 'string') {
      return comment;
    } else if ((comment.match(/\n/g)?.length) >= 2 || comment.length > maxLength) {
      return comment;
    } else {
      return null;
    }
  }
}
