import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Project, ProjectName} from '../../../core/models/Project';
import {ProjectService} from '../../../core/services/services/project.service';
import {AlertEnum} from '../../../core/models/Enums/AlertEnum';
import {EmployeeService} from '../../../core/services/services/employee.service';
import {MessageService} from '../../../core/services/services/message.service';
import {ScreenRefreshService} from '../../../core/services/services/refresh-screens.service';
import {Employee} from "../../../core/models/Employee";
import {AssignmentProjectEmployee} from "../../../core/models/AssignmentProjectEmployee";
import {TranslatableComponent} from "../../../core/models/TranslatableComponent";
import {TranslateService} from "@ngx-translate/core";
import {Observable} from "rxjs";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {MatSort} from "@angular/material/sort";
import {MatTableDataSource} from "@angular/material/table";
import Config from "../../../core/config/Config";
import {CustomizingService} from "../../../core/services/services/customizing.service";
import {AuthenticationService} from "../../../core/services/services/authentication.service";
import {Preference, PreferencesService} from "../../../core/services/services/preferences.service";
import {hasId} from "../../../core/models/validators";


export class AssignData {
  projects: Project[]; // Project(s) for that the assignment is done
  disableProjects: boolean; // If selection of Projects should be disabled
  employees: Employee[]; // Employee(s) that will be assigned to a Project
}

@Component({
  selector: 'app-assign-multiple-employees-dialog',
  templateUrl: './assign-multiple-employees-dialog.component.html',
  styleUrls: ['./assign-multiple-employees-dialog.component.scss']
})
export class AssignMultipleEmployeesDialogComponent extends TranslatableComponent implements OnInit {

  employeeErrorList = [];
  currentEmployeeForm;
  assignForm: FormGroup;
  addAssignForm: FormGroup;
  assignedEmployeesDataSource: MatTableDataSource<AssignmentProjectEmployee> = new MatTableDataSource();
  projectsTeamsDataSource: MatTableDataSource<AssignmentProjectEmployee> = new MatTableDataSource();

  assignedEmployeesColumns: string[] = [
    'firstName',
    'lastName',
    'effectiveFrom',
    'effectiveUntil',
    'capacity'
  ];

  selectedProjectLink: string;
  // Progress bar status
  loading = false;

  currency = this.getEnums("currency");
  defaultCurrency = "MISSING_VALUE";

  @ViewChild('employeeSort') set contentE(content: MatSort) {
    if (content) {
      this.employeeSorting(content);
    }
  }

  constructor(public dialogRef: MatDialogRef<AssignMultipleEmployeesDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: AssignData,
              private _fb: FormBuilder,
              private _projectService: ProjectService,
              private _messageService: MessageService,
              private _employeeService: EmployeeService,
              private _screenRefreshService: ScreenRefreshService,
              private translateService: TranslateService,
              private authenticationService: AuthenticationService,
              private customizingService: CustomizingService,
              private preferencesService: PreferencesService
  ) {
    super(translateService, 'AssignMultipleEmployeesDialogComponent');
    if (typeof this.data === undefined) {
      this._messageService.add(this._lang('notification.no-projects'), AlertEnum.warning);
    }
  }

  async ngOnInit() {
    this.assignForm = this._fb.group({
      project: new FormControl({value: '', disabled: this.data.disableProjects}, {
        validators: [Validators.required, hasId]
      }),
      employees: this._fb.array([]),
      effectiveFromAll: new FormControl('', {
        validators: Validators.required
      }),
      effectiveUntilAll: new FormControl('', {
        validators: Validators.required
      }),
      capacityAll: new FormControl('', [
        Validators.required, Validators.max(100), Validators.min(0)
      ])
    });

    // Implementing FormControl for each dynamically created employee
    this.data.employees.forEach(e => {
      (<FormArray>this.assignForm.controls['employees']).controls.push(this.initEmployee(e.id));
    });


    this.currentEmployeeForm = (this.assignForm.get('employees') as FormArray).controls;

    if (this.data.projects) {
      this.assignForm.get('project').setValue(this.data.projects);
      this.onProjectSelectionChanged();
    } else {
      this.fetchProjects();
    }

    this.data.employees.forEach(e => {
      (<FormArray>this.assignForm.controls['employees']).controls.push(this.initEmployee(e.id));
    });
  }

  async extRateChange(i: number) {
    if (this.currentEmployeeForm[i].get('extRate').value && !this.currentEmployeeForm[i].get('extRateCurrency').value) {
      const prefRes = await this.preferencesService.get(Preference.CURRENCY);
      if (prefRes && prefRes !== "undefined" && prefRes !== "null") {
        this.currentEmployeeForm[i].get('extRateCurrency').setValue(prefRes);
      }
    }
  }

  private employeeSorting(content) {
    this.assignedEmployeesDataSource.sort = content;

    this.assignedEmployeesDataSource.sortingDataAccessor = ((data: AssignmentProjectEmployee, sortHeaderId: string) => {
      switch (sortHeaderId) {
        case 'firstName':
          return data.employee['firstName'].toLocaleLowerCase();
        case 'lastName':
          return data.employee['lastName'].toLocaleLowerCase();
        default:
          return typeof data[sortHeaderId] === 'string' ? data[sortHeaderId].toLocaleLowerCase() : data[sortHeaderId];
      }
    });
  }

  initEmployee(employeeid: number) {
    return this._fb.group({
      employee: new FormControl(''),
      effectiveFrom: new FormControl(''),
      effectiveUntil: new FormControl(''),
      capacity: new FormControl('', [
        Validators.max(100), Validators.min(0)
      ]),
      role: new FormControl('', {}),
      annotation: new FormControl('', {}),
      subteam: new FormControl('', {}),
      extRate: new FormControl('', {}),
      extRateCurrency: new FormControl('', {}),
      employeeId: new FormControl(employeeid, {})
    });
  }

  /**
   * Closes the dialog
   */
  closeDialog() {
    this.dialogRef.close();
  }

  // Getting the Enum array from the config file
  private getEnums(keyWord: string) {
    return Object.keys(Config.services.employees[keyWord]).sort()
      .map(function (i) {
        return {value: i, viewValue: Config.services.employees[keyWord][i]};
      });
  }

  fetchProjects() {
    this._projectService.getAllProjects().subscribe((projects: Project[]) => {
        projects.sort(((a: Project, b: Project) => a.projectName.toLowerCase().localeCompare(b.projectName.toLowerCase())));
        this.data.projects = projects;
        this.loading = false;
      },
      () => {
        this._messageService.add(this._lang('notification.fetch-failed'), AlertEnum.danger);
      });
  }

  onProjectSelectionChanged(project?: ProjectName) {
    this.selectedProjectLink = project ? project._links.self.href : this.assignForm.get('project').value._links.self.href;
    this.getAssignmentProjectEmployees();

    if (project !== undefined) {
      this._projectService.getMinMaxEffectiveDates(project).subscribe(data => {
        this.assignForm.get('effectiveFromAll').setValue(data[0]);
        this.assignForm.get('effectiveUntilAll').setValue(data[1]);
      });
    }
  }

  private getAssignmentProjectEmployees() {
    this.loading = true;
    this._projectService.getAssignmentEmployeeProjectInfo(this.selectedProjectLink).then((assigned: AssignmentProjectEmployee[]) => {
      this.assignedEmployeesDataSource.data = assigned; // .filter((a) => a.actuallyActive);
      this.loading = false;
    }, () => {
      this.loading = false;
    });
  }

  /**
   * Add the selected Employee to the project
   *
   * @privat
   * @memberof AssignMultipleEmployeesDialogComponent
   */
  addEmployeeToProject() {
    for (const i in this.assignForm.controls) {
      if (this.assignForm.controls[i].invalid) {
        this.assignForm.controls[i].markAsTouched();
      }
    }

    if (this.assignForm.valid) {
      this.loading = true;

      let observes: Array<Observable<AssignmentProjectEmployee>> = new Array<Observable<AssignmentProjectEmployee>>();
      this.data.employees.forEach(async (e, i) => {
        if (this.currentEmployeeForm[i].value.effectiveFrom === "") {
          this.currentEmployeeForm[i].value.effectiveFrom = this.assignForm.value.effectiveFromAll;
        }
        if (this.currentEmployeeForm[i].value.effectiveUntil === "") {
          this.currentEmployeeForm[i].value.effectiveUntil = this.assignForm.value.effectiveUntilAll;
        }
        if (this.currentEmployeeForm[i].value.capacity === "") {
          this.currentEmployeeForm[i].value.capacity = this.assignForm.value.capacityAll;
        }
        if (this.currentEmployeeForm[i].value.extRateCurrency === ""
          || this.currentEmployeeForm[i].value.extRateCurrency === null
          || this.currentEmployeeForm[i].value.extRateCurrency === "undefined") {
          this.currentEmployeeForm[i].value.extRateCurrency = this.defaultCurrency;
        }

        this.addAssignForm = this._fb.group({
          project: this.selectedProjectLink,
          employee: this.data.employees[i]._links.self.href,
          effectiveFrom: this.currentEmployeeForm[i].value.effectiveFrom,
          effectiveUntil: this.currentEmployeeForm[i].value.effectiveUntil,
          capacity: this.currentEmployeeForm[i].value.capacity,
          role: this.currentEmployeeForm[i].value.role,
          annotation: this.currentEmployeeForm[i].value.annotation,
          subteam: this.currentEmployeeForm[i].value.subteam,
          extRate: this.currentEmployeeForm[i].value.extRate ?
            Number(this.currentEmployeeForm[i].value.extRate.toString().replace(",", ".")) : '',
          extRateCurrency: this.currentEmployeeForm[i].value.extRateCurrency
        });
        observes.push(this._projectService.addAssignmentProjectEmployee(this.addAssignForm.value));
      });

      this.submitEmployees(observes).then(() => {
        this._messageService.add(this.translate.instant('mep.components.general.notification.edit-success'), AlertEnum.success);
        this.loading = false;
        this.closeDialog();
      }, () => {
        this._messageService.add(this._lang('notification.add-error-multiple'), AlertEnum.danger, 0);
        this._messageService.add(this._lang('error-message') + this.employeeErrorList.join(', '), AlertEnum.danger, 10000);
        this.closeDialog();
      });
    } else {
      this.loading = false;
      this._messageService.add(this._lang('notification.fields-missing'), AlertEnum.warning);
    }
  }

  submitEmployees(observes: Array<Observable<AssignmentProjectEmployee>>): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      let j = 0;
      let hasError = false;
      for (let item of observes) {
        let i = observes.indexOf(item);
        item.subscribe(() => {
          this._screenRefreshService.employeeWasAdded();
          j++;
        }, () => {
          hasError = true;
          this.employeeErrorList.push(this.data.employees[i].firstName + " " + this.data.employees[i].lastName);
          j++;
          // Only executed when last observable has an error
          if (observes.length === j) {
            reject(true);
          }
        }, () => {
          // Checking if an error occurred in any observable
          if (observes.length === j) {
            hasError ? reject(hasError) : resolve(!hasError);
          }
        });
      }
    });
  }

  invalidCurrency(employeeId: number): boolean {
    for (let i = 0; i < this.currentEmployeeForm.length; i++) {
      if (this.currentEmployeeForm[i].value.employeeId === employeeId
        && this.currentEmployeeForm[i].value.extRate !== ''
        && (!this.currentEmployeeForm[i].value.extRateCurrency
          || this.currentEmployeeForm[i].value.extRateCurrency === ''
          || this.currentEmployeeForm[i].value.extRateCurrency === "undefined")) {
        return true;
      }
    }
    return false;
  }

  invalidForm(): boolean {
    for (let i = 0; i < this.currentEmployeeForm.length; i++) {
      if (this.currentEmployeeForm[i].value.extRate !== ''
        && (!this.currentEmployeeForm[i].value.extRateCurrency
          || this.currentEmployeeForm[i].value.extRateCurrency === ''
          || this.currentEmployeeForm[i].value.extRateCurrency === "undefined")) {
        return true;
      }
    }
    return false;
  }
}
