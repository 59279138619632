export class Skillgroup {

  id: number;

  name: string;

  principalId: number;

  version: number;

  createdBy?: string;

  createdAt?: string;

  lastModifiedBy?: string;

  lastModifiedAt?: string;

  andOperator: boolean;

}
