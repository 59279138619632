import {Observable} from "rxjs";
import {filter, map, take} from "rxjs/operators";

export function isVisible(element: HTMLElement, threshold: number = 0.8): Observable<boolean> {
  return new Observable<boolean>(subscriber => {
    const intersectionObserver = new IntersectionObserver(([entry], observer) => {
      subscriber.next(entry.isIntersecting);
    }, {threshold});

    intersectionObserver.observe(element);

    return {
      unsubscribe: () => {
        intersectionObserver.disconnect();
      }
    };
  });
}

export function waitUntilVisible(element: HTMLElement, threshold: number = 0.8): Observable<void> {
  return isVisible(element, threshold).pipe(
    filter(visible => visible),
    take(1),
    map(() => void{})
  );
}
