import {NgModule} from "@angular/core";
import {HomeScreenComponent} from "./homescreen.component";
import {HomescreenRoutingModule} from "./homescreen-routing.module";
import {CommonModule} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {PermissionModule} from "../../shared/permission/permission.module";
import {DialogsModule} from "../../shared/dialogs/dialogs.module";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatCardModule} from "@angular/material/card";
import {MatIconModule} from "@angular/material/icon";

@NgModule({
  imports: [
    HomescreenRoutingModule,
    CommonModule,
    TranslateModule,
    PermissionModule,
    DialogsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatCardModule,
    MatIconModule
  ],
  declarations: [HomeScreenComponent]
})
export class HomescreenModule {
}
