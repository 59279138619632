import {MessageService} from './message.service';
import Config from '../../../core/config/Config';
import {HttpBaseService} from './http-base.service';
import {Observable} from 'rxjs';
import {Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {UploadImportFileResponse} from "../../models/import/UploadImportFileResponse";


@Injectable({
  providedIn: 'root'
})
export class ProjectFileService extends HttpBaseService {

  constructor(public http: HttpClient, messageService: MessageService) {
    super(http, messageService);
  }

  fireProjectsImportRequest(projectFile: FormData): Observable<UploadImportFileResponse> {
    return this.http.post<UploadImportFileResponse>(Config.services.projects.import, projectFile);
  }

  fireP1RateImportRequest(projectFile: FormData): Observable<UploadImportFileResponse> {
    return this.http.post<UploadImportFileResponse>(Config.services.projects.p1rateimport, projectFile);
  }

  fireP1BookingAssignmentImportRequest(projectFile: FormData): Observable<UploadImportFileResponse> {
    return this.http.post<UploadImportFileResponse>(Config.services.projects.p1bookingAssignmentsImport, projectFile);
  }

  downloadProjectEmployeesAsEuplanExcel(projectId: number, projectShortName: string): Observable<any> {
    return this.http.post(
      "/mep/api/projects/" + projectId + Config.services.projects.exportEuplanExcel + '?projectShortName=' + projectShortName, {}, {responseType: 'blob'});
  }

  downloadProjectAvailabilityAsExcel(): Observable<any> {
    return this.http.post(
      "/mep/api/projects" + Config.services.projects.exportExcel, {}, {responseType: 'blob'});
  }

  downloadShouldIsComparisonAsExcel(month: string): Observable<any> {
    return this.http.post(
      Config.services.projects.baseUrl + Config.services.projects.exportShouldIs + "/" + month, {}, {responseType: 'blob'}
    );
  }

  downloadSelectedProjectListAvailabilityAsExcel(selectedProjectIds: any): Observable<any> {
    return this.http.post(
      "/mep/api/projects/" + Config.services.projects.exportSelectedProjectListExcel + "/" + selectedProjectIds, {}, {responseType: 'blob'});
  }

  downloadEmployeeCapacitiesAsExcel(employees: number[], startDateAsString: string, isPlannedCapa: boolean): Observable<any> {
    return this.http.post(
      "/mep/api/employees" + Config.services.employees.exportCapacitiesAsExcel + "/" + startDateAsString + "/" + isPlannedCapa, employees,
      {responseType: 'blob'});
  }

  downloadAvailableEmployeeCapacitiesExcel(from: any, until: any, underloadBorder, excludeExternalEmployees, amount: number, level: number): Observable<any> {
    const params = new HttpParams().set('from', from).set("until", until).set("threshold", underloadBorder).set("noExternalDivision", excludeExternalEmployees)
      .set('amount', amount.toString()).set('level', level.toString());

    return this.http.post(
      "/mep/api/employees" + Config.services.employees.availableEmployees, {}, {responseType: 'blob', params: params});
  }

  downloadSelectedProjectEmployeesAsEuplanExcel(selectedProjectIds: number[]) {
    const params = {projectIds: `${selectedProjectIds}`};
    return this.http.post(
      "/mep/api/projects" + Config.services.projects.exportMultiEuplanExcel, {},
      {responseType: 'blob', params});
  }

  downloadAllProjectsAsExcel() {
    return this.http.post(
      Config.services.projects.baseUrl + Config.services.projects.exportProjects, {}, {responseType: 'blob'}
    );
  }

  downloadSelectedProjectsAsExcel(selectedProjectIds: number[]) {
    const params = {projectIds: `${selectedProjectIds}`};
    return this.http.post(
      Config.services.projects.baseUrl + Config.services.projects.exportSelectedProjects, {},
      {responseType: 'blob', params});
  }
}
