import {TranslateParser, TranslateService} from '@ngx-translate/core';
import {MatPaginatorIntl} from "@angular/material/paginator";

export class TranslatablePaginator extends MatPaginatorIntl {
  private readonly _langPrefix: string;
  private rangeLabelIntl: string;

  constructor(private translateService: TranslateService, private translateParser: TranslateParser) {
    super();
    this._langPrefix = "mep.components.paginator.";

    this.getTranslations();
  }

  getTranslations() {
    this.translateService.get([
      this._langPrefix + 'items-per-page',
      this._langPrefix + 'next-page',
      this._langPrefix + 'previous-page',
      this._langPrefix + 'last-page',
      this._langPrefix + 'first-page',
      this._langPrefix + 'range'
    ])
      .subscribe(() => {
        this.itemsPerPageLabel = this.translateService.instant(this._langPrefix + 'items-per-page');
        this.nextPageLabel = this.translateService.instant(this._langPrefix + 'next-page');
        this.previousPageLabel = this.translateService.instant(this._langPrefix + 'previous-page');
        this.rangeLabelIntl = this.translateService.instant(this._langPrefix + 'range');
        this.lastPageLabel = this.translateService.instant(this._langPrefix + 'last-page');
        this.firstPageLabel = this.translateService.instant(this._langPrefix + 'first-page');
        this.changes.next();
      });
  }

  getRangeLabel = (page, pageSize, length) => {
    length = Math.max(length, 0);
    const startIndex = (page * pageSize) + 1;
    const endIndex = startIndex < length ?
      Math.min(startIndex - 1 + pageSize, length) :
      startIndex - 1 + pageSize;
    return this.translateParser.interpolate(this.rangeLabelIntl, { startIndex, endIndex, length });
  }
}
