export class CapacityBorders {
  underloadBorderPT: number;
  optimalWorkloadBorderPT: number;
  underloadBorderPercentage: number;
  optimalWorkloadBorderPercentage: number;
  inPercentage: boolean;

  // Default parameters
  constructor() {
    this.inPercentage = true;
  }

  getUnderload(): number {
    if (this.inPercentage) {
      return this.underloadBorderPercentage;
    }
    return this.underloadBorderPT;
  }

  getOptimal(): number {
    if (this.inPercentage) {
      return this.optimalWorkloadBorderPercentage;
    }
    return this.optimalWorkloadBorderPT;
  }

  // Apply a WorkloadParameters instance to this instance.
  // This is necessary if somewhere the type is lost during event emitting.
  apply(structure: CapacityBorders): void {
    this.inPercentage = structure.inPercentage;
    this.optimalWorkloadBorderPercentage = structure.optimalWorkloadBorderPercentage;
    this.optimalWorkloadBorderPT = structure.optimalWorkloadBorderPT;
    this.underloadBorderPT = structure.underloadBorderPT;
    this.underloadBorderPercentage = structure.underloadBorderPercentage;
  }

  // Copies the values of cb without overriding existing ones in this instance.
  safeApply(cb: CapacityBorders) {
    if (!this.optimalWorkloadBorderPercentage) {
      this.optimalWorkloadBorderPercentage = cb.optimalWorkloadBorderPercentage;
    }
    if (!this.underloadBorderPercentage) {
      this.underloadBorderPercentage = cb.underloadBorderPercentage;
    }
    if (!this.underloadBorderPT) {
      this.underloadBorderPT = cb.underloadBorderPT;
    }
    if (!this.optimalWorkloadBorderPT) {
      this.optimalWorkloadBorderPT = cb.optimalWorkloadBorderPT;
    }
  }
}
