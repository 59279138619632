import {AbstractAutocomplete} from "./abstract-autocomplete";


export abstract class AbstractStringAutocomplete extends AbstractAutocomplete<string> {

  constructor() {
    super();
  }

  displayAsSelectedOption(client: string): string {
    return client;
  }

  filter(element: string, value: string): boolean {
    return element.toLowerCase().includes(value.toLowerCase());
  }

  sort(element: string, otherElement: string): number {
    return element.localeCompare(otherElement);
  }

  abstract fetchElements(): Promise<string[]> ;
}


