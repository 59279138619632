import {Href} from "./href";

export class BaseEntity {
  id?: number;
  createdBy?: string;
  createdAt?: string;
  lastModifiedBy?: string;
  lastModifiedAt?: string;
  eVersion?: string;
  _links?: Link;
}

export class Link {
  self?: Href;
}
