export default {
  JobFamilies: {
    LINE_MANAGEMENT: 'Line Management',
    ADMINISTRATION_SUPPORT: 'Administration Support',
    PROJECT_MANAGEMENT: 'Project Management',
    BUSINESS_CONSULTING: 'Business Consulting',
    INFORMATION_TECHNOLOGY: 'Information Technology',
    TRAINEES: 'Auszubildende',
    STUDENTS: 'Werkstudenten',
    OTHERS: 'Sonstige',
    SALES: 'Sales'
  },
  JobFamiliesMSG_SERVICES: {
    EXTERNAL: 'External'
  },
  JobFamiliesM3: {
    MSG_ADVISORS: 'msg Advisors'
  }
};
