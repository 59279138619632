import {Skillgroup} from "./Skillgroup";
import {Skill, SkillWithMinimumLevel} from "./EmployeeSkill";
import {Employee} from "./Employee";
import {MatTableDataSource} from "@angular/material/table";

export class SkillEvaluation {
  skillgroup: Skillgroup;
  skills: SkillWithMinimumLevel[];
  employees: MatTableDataSource<Employee>[];
}

export class SkillEvaluationExportData {
  skillgroup: Skillgroup;
  employees: Employee[][];
}
