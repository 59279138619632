import {Component, EventEmitter, forwardRef, Input, Output} from '@angular/core';
import {TranslatableComponent} from "../../../core/models/TranslatableComponent";
import {TranslateService} from "@ngx-translate/core";
import {AlertEnum} from "../../../core/models/Enums/AlertEnum";
import {MessageService} from "../../../core/services/services/message.service";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";

/**
 * A custom input control for selecting files
 *
 * @see https://angular.io/api/forms/ControlValueAccessor
 */
@Component({
  selector: 'app-file-input',
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileInputComponent),
      multi: true
    }
  ]
})
export class FileInputComponent extends TranslatableComponent implements ControlValueAccessor {

  @Input()
  accept: string;
  selectedFile: File;
  disabled: boolean;

  onChange: (file: File) => void;
  onTouched: () => void;

  constructor(protected translate: TranslateService,
              private messageService: MessageService) {
    super(translate, 'FileInputComponent');
  }

  onFileChosen(event: Event) {
    this.onTouched();
    if (event.target['files']) {
      const file = event.target['files'][0];

      if (file) {
        this.selectedFile = file;
        this.onChange(file);
      }
    } else {
      const message = this._lang('failed-getting-file-from-input');
      this.messageService.add(message, AlertEnum.danger);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = true;
  }

  writeValue(obj: File): void {
    this.selectedFile = obj;
  }
}
