import {MatPaginatorIntl} from '@angular/material/paginator';
import {Injectable} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";

@Injectable()
export class MatPaginatorTranslator extends MatPaginatorIntl {
  constructor(private translate: TranslateService) {
    super();
    this.getAndInitTranslations();
  }

  getAndInitTranslations() {
    this.translate.get("mep.paginator.itemsPerPageLabel").subscribe((translation: string) => {
      this.itemsPerPageLabel = translation;
      this.changes.next();
    });
    this.translate.get("mep.paginator.nextPageLabel").subscribe((translation: string) => {
      this.nextPageLabel = translation;
      this.changes.next();
    });
    this.translate.get("mep.paginator.previousPageLabel").subscribe((translation: string) => {
      this.previousPageLabel = translation;
      this.changes.next();
    });
  }

  getRangeLabel = (page: number, pageSize: number, length: number) => {
    let sep = this.translate.instant("mep.paginator.rangeSeparator");
    if (length === 0 || pageSize === 0) {
      return `0 ${sep} ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
    return `${startIndex + 1} - ${endIndex} ${sep} ${length}`;
  }
}
