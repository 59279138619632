import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ImportRefreshService {

  public _refreshSubject: BehaviorSubject<any> = new BehaviorSubject(false);

  public refreshScreens(value) {
      this._refreshSubject.next(value);
  }
}
