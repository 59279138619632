import {ChangeDetectorRef, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {TranslatableComponent} from "../../../../core/models/TranslatableComponent";
import {TranslateService} from "@ngx-translate/core";
import {FormBuilder, FormGroup} from "@angular/forms";
import {FilterForm} from "./filter-form";
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef} from "@angular/material/dialog";
import {AlertEnum} from "../../../../core/models/Enums/AlertEnum";
import {FilterStoreService} from "../../../../core/services/services/filter-store.service";
import {FilterElement} from "../../../../core/models/viewModels/FilterElement";
import {MessageService} from "../../../../core/services/services/message.service";
import {ListDataSource} from "../../../../core/models/list/ListDataSource";
import {tap} from "rxjs/operators";
import {MatSort} from "@angular/material/sort";
import {merge} from "rxjs";
import {ListResponse} from "../../../../core/models/list/ListResponse";
import {PaginatorSmartComponent} from "../../../paginator-smart/components/paginator-smart.component";
import {Preference, PreferencesService} from "../../../../core/services/services/preferences.service";

@Component({
  selector: 'app-filter-list',
  templateUrl: './filter-list.component.html',
  styleUrls: ['./filter-list.component.scss']
})
export class FilterListComponent extends TranslatableComponent implements OnInit {

  loading = false;
  dataSourceFilters: ListDataSource<FilterElement, FilterStoreService>;
  private paginator: PaginatorSmartComponent;
  defaultPaginatorPageSize: number;
  private sort: MatSort;

  // Can't use direct declaration because of *ngIf by pagination element in view
  @ViewChild(PaginatorSmartComponent) set matPaginator(mp: PaginatorSmartComponent) {
    this.paginator = mp;

    // Paginator and sort must be available before calling this method
    // paginator can be null if the list is empty
    if (this.sort && this.paginator) {
      this.afterViewLoad();
    }
  }

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;

    // Paginator and sort must be available before calling this method
    if (this.paginator) {
      this.afterViewLoad();
    }
  }

  public readonly filterListFormGroup: FormGroup;
  displayedColumns = ['filterName', 'comment', 'createdAt', 'isStandardFilter', 'delete'];

  constructor(protected translate: TranslateService,
              protected readonly formBuilder: FormBuilder,
              @Inject(MAT_DIALOG_DATA) public data: { filterValue, filterType, isStandardFilter },
              private dialogRef: MatDialogRef<FilterListComponent>,
              private storeFilterService: FilterStoreService,
              private messageService: MessageService,
              private cdr: ChangeDetectorRef,
              private preferencesService: PreferencesService) {
    super(translate, 'FilterListComponent');
    this.filterListFormGroup = this.formBuilder.group(new FilterForm());
  }

  public static openDialog(dialogContainer: MatDialog, filterValue, filterType, afterClose: () => void) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = 600;
    dialogConfig.data = {
      filterValue: filterValue,
      filterType: filterType
    };

    dialogContainer.open(FilterListComponent, dialogConfig).afterClosed().subscribe(result => {
      if (afterClose) {
        afterClose();
      }
    });
  }

  async ngOnInit() {
    this.dataSourceFilters = new ListDataSource<FilterElement, FilterStoreService>(this.storeFilterService);
    this.dataSourceFilters.loading$.subscribe(loading => this.loading = loading);

    // break cyclic dependency: we can't load the list before we know how many to load from the paginator, but the paginator isn't loaded before the list has elements...
    const preferencePageSize = await this.preferencesService.get(Preference.PAGINATION_LIST_ELEMENTS, "filterDialogList");
    this.defaultPaginatorPageSize = preferencePageSize ? Number(preferencePageSize) : 10;

    this.fetchFilters();
  }

  // Paginator and sort must be available before calling this method
  afterViewLoad() {
    this.dataSourceFilters.counter$
      .pipe(
        tap((count) => {
          this.paginator.basePaginator.length = count;
        })
      ).subscribe();
    this.cdr.detectChanges();

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.fetchFilters())
      )
      .subscribe();
  }

  public saveFilter(): void {
    this.loading = true;
    let filterElement = new FilterElement();
    filterElement.filterName = this.filterListFormGroup.controls.filterName.value;
    filterElement.filterLocation = this.data.filterType;
    filterElement.comment = this.filterListFormGroup.controls.comment.value;
    filterElement.filterValue = this.storeFilterService.stringifyFilterValue(this.data.filterValue);
    filterElement.isStandardFilter = this.filterListFormGroup.controls.isStandardFilter.value;
    this.storeFilterService.addFilter(filterElement).subscribe(filter => {
      this.messageService.add(this.translate.instant('mep.components.general.filter.save-success'), AlertEnum.success);
      this.close();
    }, () => {
      this.messageService.add(this.translate.instant('mep.components.general.filter.save-failed'), AlertEnum.danger);
    });
  }

  public close() {
    this.dialogRef.close();
  }

  public deleteFilter(event, filter: FilterElement) {
    this.storeFilterService.deleteFilter(filter.id).subscribe((result: boolean) => {
      if (result) {
        this.fetchFilters((response: ListResponse<FilterElement>) => {

          // If no element is on the page, then move on back if possible
          if (response.content.length === 0) {
            this.paginator.basePaginator.previousPage();
          }
        });
      }
    });
  }

  private fetchFilters(callback = null) {

    // Load the data from the service with default params on initial load
    this.dataSourceFilters.load({
      filterLocation: this.data.filterType,
      page: this.paginator ? this.paginator.basePaginator.pageIndex : 0,
      size: this.paginator ? this.paginator.basePaginator.pageSize : this.defaultPaginatorPageSize,
      sortDirection: this.sort ? this.sort.direction : 'asc',
      sortColumn: this.sort ? this.sort.active : 'filterName'
    }, callback);
  }
}
