import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {TranslatableComponent} from '../../../core/models/TranslatableComponent';
import {MatTableDataSource} from '@angular/material/table';
import {CustomizingService} from "../../../core/services/services/customizing.service";

@Component({
  selector: 'app-skill-db-tooltip',
  templateUrl: './skill-db-tooltip.component.html',
  styleUrls: ['./skill-db-tooltip.component.scss']
})

export class SkillDbTooltipComponent extends TranslatableComponent implements OnInit {

  tooltipColumns: string[] = [
    'level',
    'shortText',
    'definition'
  ];

  tooltip = new MatTableDataSource();

  showSkillDbTooltip;

  constructor(private _translateService: TranslateService, private customizing: CustomizingService) {
    super(_translateService, 'SkillDbDialogComponent');
  }

  ngOnInit() {
    this.customizing.getSkillLevels().then(skillLevels => this.buildTooltip(skillLevels));
  }

  private buildTooltip(skillLevels: string[]) {
    this.tooltip.data = skillLevels.map(i => {
      return {
        level: i,
        shortText: this._lang('tooltip_short_term.level_'.concat(i)),
        definition: this._lang('tooltip_definition.level_'.concat(i))
      };
    });
  }
}
