import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { KeycloakService, KeycloakAuthGuard } from 'keycloak-angular';
import {AuthenticationService} from "../services/services/authentication.service";

@Injectable()
export class AppAuthGuard extends KeycloakAuthGuard {
  constructor(protected router: Router,
              protected keycloakAngular: KeycloakService,
              protected loginService: AuthenticationService) {
    super(router, keycloakAngular);
  }

  isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (!this.authenticated) {
        return this.keycloakAngular.login();
      } else {
        this.loginService.setup().then( (done) => {
          return resolve(done);
        }).catch( (error) => {
          return reject(error);
        });
      }
    });
  }
}
