import {Href} from './href';
import {BaseEntity} from "./BaseEntity";
import {AbstractControl, FormControl, FormGroup, Validators} from "@angular/forms";
import {DateValidator} from "../validation/DateValidator";
import moment from "moment";

export class ProjectTimeSliceForm extends FormGroup {
  readonly effectiveFrom: AbstractControl;
  readonly effectiveUntil: AbstractControl;
  readonly probability: AbstractControl;

  constructor(projectTimeSlice: ProjectTimeSlice) {
    super({
      effectiveFrom: new FormControl(projectTimeSlice.effectiveFrom ? moment(projectTimeSlice.effectiveFrom) : null, {validators: [Validators.required], updateOn: 'blur'}),
      effectiveUntil: new FormControl(projectTimeSlice.effectiveUntil ? moment(projectTimeSlice.effectiveUntil) : null,{updateOn: 'blur'}),
      probability: new FormControl(projectTimeSlice.probability, {validators: [Validators.required]})
    }, {validators: [DateValidator.fromToDate('effectiveFrom', 'effectiveUntil')]});

    this.effectiveFrom = this.controls['effectiveFrom'];
    this.effectiveUntil = this.controls['effectiveUntil'];
    this.probability = this.controls['probability'];
  }
}

export class ProjectTimeSlice extends BaseEntity {
  effectiveFrom: string;
  effectiveUntil: string;
  probability: string;
  id?: number;
  _links: Link;
}

/**
 * ProjectTimeSlice link model
 */
export class Link {
  self: Href;
  projectTimeSlice: Href;
}
