import {AbstractControl, ValidatorFn} from "@angular/forms";
import {isMoment} from "moment";

export class DateValidator {
  static fromToDate(fromDateField: string, toDateField: string, errorName: string = 'fromToDate'): ValidatorFn {
    return (formGroup: AbstractControl): { [key: string]: boolean } | null => {
      const fromDate = formGroup.get(fromDateField);
      const toDate = formGroup.get(toDateField);
      const fromValue = isMoment(fromDate.value) ? fromDate.value.format("yyyy-MM-DD") : fromDate.value;
      const toValue = isMoment(toDate.value) ? toDate.value.format("yyyy-MM-DD") : toDate.value;

      if ((fromValue !== null && toValue !== null) && (fromValue !== "" && toValue !== "") && fromValue > toValue) {
        toDate.setErrors({[errorName]: true});
        toDate.markAsTouched();
      } else if (toDate.hasError(errorName)) {

        // Removes the error and keeps other validation errors on the same field
        delete toDate.errors[errorName];
        if (Object.keys(toDate.errors).length === 0) {
          toDate.setErrors(null);
        }
        toDate.markAsTouched();
      }
      return null;
    };
  }
}

