import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {TranslatableComponent} from '../../../core/models/TranslatableComponent';
import {TranslateService} from '@ngx-translate/core';
import {FormControl, Validators} from '@angular/forms';
import {AssignmentProjectEmployee} from '../../../core/models/AssignmentProjectEmployee';
import {ProjectService} from '../../../core/services/services/project.service';
import {MessageService} from '../../../core/services/services/message.service';
import {Project, ProjectName} from '../../../core/models/Project';
import {AlertEnum} from '../../../core/models/Enums/AlertEnum';
import {Employee} from '../../../core/models/Employee';
import {Router} from '@angular/router';
import {AssignmentTableComponent} from './assignment-table/assignment-table.component';
import {ProjectTimeSlice} from '../../../core/models/ProjectTimeSlice';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import moment from 'moment';
import {CustomizingService} from "../../../core/services/services/customizing.service";
import {Client} from "../../../core/config/Client";

@Component({
  selector: 'app-extend-project-dialog',
  templateUrl: './extend-or-move-project-dialog.component.html',
  styleUrls: ['./extend-or-move-project-dialog.component.scss']
})
export class ExtendOrMoveProjectDialogComponent extends TranslatableComponent implements OnInit {
  private readonly dateFormat = 'YYYY-MM-DD';

  allProjectAssignments: AssignmentProjectEmployee[] = [];

  projectStart: string;
  oldProjectEnd: string;
  oldProjectProbability: string;
  newProjectEnd = new FormControl('', {
    validators: [Validators.required,
      this.validateNewProjectEnd.bind(this), this.validateNotWithinOtherProjectTimeSlice.bind(this)]
  });
  newProjectStart = new FormControl('', {
    validators: [Validators.required,
      this.validateNewProjectStart.bind(this), this.validateNotWithinOtherProjectTimeSlice.bind(this)]
  });
  newProjectProbability = new FormControl('', {validators: [Validators.required, Validators.max(100), Validators.min(1)]});
  loading = false;
  noAssignments = false;

  project: Project;
  projectTimeSlices: ProjectTimeSlice[];
  selectedProjectTimeSlice: ProjectTimeSlice;

  moveProject = false;

  currentClient: Client;
  @ViewChild(AssignmentTableComponent)
  newAssignmentTable: AssignmentTableComponent;

  constructor(public dialogRef: MatDialogRef<ExtendOrMoveProjectDialogComponent>,
              protected customizingService: CustomizingService,
              protected translateService: TranslateService,
              protected _projectService: ProjectService,
              protected _messageService: MessageService,
              private _router: Router,
              @Inject(MAT_DIALOG_DATA) public data: Project,
  ) {
    super(translateService, 'ExtendOrMoveProjectDialogComponent');
  }

  async ngOnInit() {
    if (this.data) {
      this.onProjectSelectionChanged(this.data);
    }
    this.currentClient = await this.customizingService.getCurrentClient();
  }

  closeDialog(result?) {
    this.dialogRef.close(result);
  }

  saveButtonDisable(): boolean {
    let disabled = this.newProjectEnd.invalid || this.loading || !this.newAssignmentTable?.selectedEmployeeAssignmentsAreValid();
    return this.moveProject ? disabled || this.newProjectStart.invalid : disabled;
  }

  saveChanges() {
    this.loading = true;

    if (this.moveProject) {
      this._projectService.moveProject(this.selectedProjectTimeSlice.id, this.newProjectStart.value,
        this.newProjectEnd.value, this.newProjectProbability.value,
        this.newAssignmentTable.getSelectedAssignmentsDto()).then(() => {
        this.afterExtendingOrMovingProject();
      }).catch(() => {
        this.loading = false;
      });
    } else {
      this._projectService.extendProject(this.project.id, this.newAssignmentTable.getSelectedAssignmentsDto(),
        this.newProjectEnd.value, this.newProjectProbability.value).then(() => {
        this.afterExtendingOrMovingProject();
      }).catch(() => {
        this.loading = false;
      });
    }
  }

  afterExtendingOrMovingProject() {
    this._messageService.add(this._lang('save-changes'), AlertEnum.success);
    this.closeDialog(true);
    this._router.navigateByUrl('/project-edit/' + this.project.sharePointId);
  }

  onProjectSelectionChanged(projectName: ProjectName) {
    this.noAssignments = false;
    this.allProjectAssignments = [];

    this._projectService.getProjectData(projectName.sharePointId).subscribe(project => {
      this.project = project;

      this.projectStart = this.project.startDate;
      this.getProjectProbabilities();
      this.getAssignmentProjectEmployees();

      this.newProjectEnd.reset();
    });
  }

  onSelectedProjectTimeSliceChanged() {
    this.newProjectProbability.setValue(this.selectedProjectTimeSlice.probability);
    this.newProjectProbability.updateValueAndValidity();

    this.updateAssignmentTableRows();
  }

  updateAssignmentTableRows() {
    if (this.moveProject) {
      this.updateAssignmentTableRowsMoveMode();
    } else {
      this.updateAssignmentTableRowsExtendMode();
    }
  }

  private getAssignmentProjectEmployees() {
    this.allProjectAssignments = undefined;
    this.loading = true;
    this._projectService.getAssignmentEmployeeProjectInfo(this.project._links.self.href).then((assigned: AssignmentProjectEmployee[]) => {
      this.allProjectAssignments = assigned;
      this.noAssignments = !assigned || assigned.length === 0;
      this.loading = false;

      this.updateAssignmentTableRows();
      this.allProjectAssignments.forEach(element => element.checked = true);
    }, () => {
      this.loading = false;
    });
  }

  private getProjectProbabilities() {
    this._projectService.getProjectProbabilities(this.project, false).then((timeSlices: ProjectTimeSlice[]) => {
      if (timeSlices && timeSlices.length > 0) {
        timeSlices.sort(this._projectService.sortByEffectiveFrom);
        this.projectTimeSlices = timeSlices;
        this.selectedProjectTimeSlice = this.projectTimeSlices[timeSlices.length - 1];

        this.oldProjectEnd = this.selectedProjectTimeSlice.effectiveUntil;
        this.oldProjectProbability = this.selectedProjectTimeSlice.probability;
        this.newProjectProbability.setValue(this.oldProjectProbability);
        this.newProjectProbability.updateValueAndValidity();
      } else {
        this.projectTimeSlices = [];
      }
    });
  }

  updateAssignmentTableRowsExtendMode() {
    const newAssignments: any[] = [];

    const from = moment(this.oldProjectEnd);
    from.add(1, 'day');

    let fromString = from.format(this.dateFormat);

    let mostRecentAssignments = {};

    this.allProjectAssignments.forEach(assignment => {
      if (assignment.employee.id in mostRecentAssignments) {

        let previousDate = Date.parse(mostRecentAssignments[assignment.employee.id].effectiveFrom);
        let newDate = Date.parse(assignment.effectiveFrom);

        if (previousDate < newDate) {
          mostRecentAssignments[assignment.employee.id] = assignment;
        }

      } else {
        mostRecentAssignments[assignment.employee.id] = assignment;
      }
    });

    for (let key in mostRecentAssignments) {
      if (mostRecentAssignments.hasOwnProperty(key)) {
        let assignment = mostRecentAssignments[key];

        if (!this.oldProjectEnd) {
          fromString = this.getEffectiveFrom(assignment.employee.id);
        }

        const newAssignment = {
          effectiveFrom: fromString,
          effectiveUntil: '',
          capacity: assignment.capacity,
          project: assignment.project,
          employee: assignment.employee,
          id: assignment.id
        };

        newAssignments.push(newAssignment);
      }
    }

    this.newAssignmentTable.overwriteProjectStartForValidation(fromString);
    this.newAssignmentTable.hiddenAssignments = this.allProjectAssignments;
    this.newAssignmentTable.assignments = newAssignments;
  }

  private getEffectiveFrom(employeeID: number): string {
    let until: string;

    this.allProjectAssignments.forEach(assignment => {
      if (employeeID === assignment.employee.id) {
        let temp = moment(assignment.effectiveUntil);

        if (!until || (moment(until) < temp)) {
          until = assignment.effectiveUntil;
        }
      }
    });

    const from = moment(until);
    from.add(1, 'day');

    return from.format(this.dateFormat);
  }

  updateAssignmentTableRowsMoveMode() {
    const selectedProjectTimeSliceFrom = Date.parse(this.selectedProjectTimeSlice.effectiveFrom);
    const selectedProjectTimeSliceUntil = this.selectedProjectTimeSlice.effectiveUntil ? Date.parse(this.selectedProjectTimeSlice.effectiveUntil) : null;

    // all assignments within selected project time slice
    const assignmentsWithinSelectedTimeSlice = this.allProjectAssignments.filter(assignment => Date.parse(assignment.effectiveFrom) >= selectedProjectTimeSliceFrom
        && (!selectedProjectTimeSliceUntil || Date.parse(assignment.effectiveUntil) <= selectedProjectTimeSliceUntil));

    // all assignments outside selected project time slice
    this.newAssignmentTable.hiddenAssignments = this.allProjectAssignments.filter(assignment => !assignmentsWithinSelectedTimeSlice.includes(assignment));
    this.newAssignmentTable.assignments = assignmentsWithinSelectedTimeSlice;
  }

  updateAssignmentProjectEnd() {
    this.newAssignmentTable.setEffectiveUntilForAllAssignments(this.newProjectEnd.value);
    this.newAssignmentTable.overwriteProjectEndForValidation(this.newProjectEnd.value);
  }

  updateAssignmentProjectStart() {
    this.newAssignmentTable.setEffectiveFromForAllAssignments(this.newProjectStart.value);
    this.newAssignmentTable.overwriteProjectStartForValidation(this.newProjectStart.value);
  }

  updateValidation(data: AssignmentProjectEmployeeDTO) {
    this.allProjectAssignments.forEach(element => {
      if (element.employee.id === data.employee.id) {
        element.checked = data.checked;
      }
    });
    this.newProjectEnd.updateValueAndValidity();
  }

  validateNewProjectEnd(control: FormControl) {
    if (this.moveProject) {
      return null;
    }

    const inputEnd = Date.parse(control.value);
    let error = null;

    if (this.oldProjectEnd) {
      const oldProjectEnd = Date.parse(this.oldProjectEnd);

      if (oldProjectEnd >= inputEnd) {
        error = {oldProjectEndAfterNew: {value: control.value}};
      }
    }

    if (this.allProjectAssignments) {
      const assignmentCutOffByNewEnd = this.allProjectAssignments.filter(
        assignment => assignment.checked && Date.parse(assignment.effectiveUntil) > inputEnd);

      if (assignmentCutOffByNewEnd.length > 0) {
        error = {newProjectEndCutsOffAssignment: assignmentCutOffByNewEnd};
      }
    }

    return error;
  }

  validateNewProjectStart(control: FormControl) {
    const inputStart = Date.parse(control.value);
    let error = null;

    if (this.newProjectEnd.value) {
      const inputEnd = Date.parse(this.newProjectEnd.value);

      if (inputStart > inputEnd) {
        error = {startAfterEnd: true};
      }
    }

    return error;
  }

  validateNotWithinOtherProjectTimeSlice(control: FormControl) {
    if (!this.moveProject) {
      return null;
    }

    const input = Date.parse(control.value);
    let error = null;

    const timeSliceResult = this.projectTimeSlices.find(timeSlice => timeSlice !== this.selectedProjectTimeSlice
      && Date.parse(timeSlice.effectiveFrom) <= input && Date.parse(timeSlice.effectiveUntil) >= input);

    if (timeSliceResult) {
      error = {withinExistingTimeSlice: timeSliceResult};
    }

    return error;
  }

  onNewProjectEndChanged(value) {
    this.newProjectEnd.setValue(value ? value.format(this.dateFormat) : '');
    this.updateAssignmentProjectEnd();
    this.newProjectStart.updateValueAndValidity();
  }

  onNewProjectStartChanged(value) {
    this.newProjectStart.setValue(value ? value.format(this.dateFormat) : '');
    this.updateAssignmentProjectStart();
  }
}

export interface AssignmentProjectEmployeeDTO {
  employee: Employee;
  project: Project;
  effectiveFrom: FormControl;
  effectiveUntil: FormControl;
  capacity: FormControl;
  existingAssignmentID: number;
  checked?: boolean;
}
