import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {BreadcrumbItem} from 'src/app/core/models/BreadcrumbItem';
import {Employee} from 'src/app/core/models/Employee';
import {EmployeeService} from 'src/app/core/services/services/employee.service';
import {DataService} from "../../../services/services/data.service";
import {EmployeeFullName} from "../../../models/EmployeeFullName";
import {FormControl} from "@angular/forms";
import {filter, tap} from "rxjs/operators";
import {Observable} from "rxjs";
import Config from "../../../config/Config";
import {CustomizingService} from "../../../services/services/customizing.service";


@Component({
  selector: 'app-breadcrumb-emp-search',
  templateUrl: './breadcrumb-emp-search.component.html',
  styleUrls: ['./breadcrumb-emp-search.component.scss']
})
export class BreadcrumbEmpSearchComponent implements OnInit {
  @Input() breadcrumb: BreadcrumbItem;
  currentEmployeeObservable: Observable<Employee>;
  lastEmployee: Employee;
  employeeForm: FormControl = new FormControl();
  readonly detailPath: string = '/employee-edit/';

  @ViewChild('employeeInput') employeeInput: ElementRef;

  constructor(
    private _employeeService: EmployeeService,
    private _router: Router,
    private _dataService: DataService,
    private _customizingService: CustomizingService
  ) {
  }


  ngOnInit() {
    this.fetchCurrentEmployeeFromUrlParam();

    this.currentEmployeeObservable = this._dataService.currentEmployee
      .pipe(filter(e => e !== undefined), tap(currentEmployee => {
        this.updateCurrentEmployee(currentEmployee);
      }));
  }

  private updateCurrentEmployee(currentEmployee: Employee) {
    this.lastEmployee = currentEmployee;
    this.updateCurrentEmployeeForm(currentEmployee);
  }

  private fetchCurrentEmployeeFromUrlParam() {
    const subPath = this.breadcrumb.path.substring(this.breadcrumb.path.lastIndexOf(this.detailPath) + this.detailPath.length);
    const id = subPath.lastIndexOf('/') > 0 ? Number.parseInt(subPath.substring(0, subPath.lastIndexOf('/')), 10) : Number.parseInt(subPath, 10);
    this._customizingService.getCurrentClient().then(client => {
      this._employeeService.getEmployee(Config.services.employees.baseUrl + id, client)
        .then(employee => this._dataService.changeEmployee(employee));
    });
  }

  updateCurrentEmployeeForm(employee: Employee): void {
    this.employeeForm.setValue(employee);
  }

  onItemChange(employee: EmployeeFullName) {
    this._employeeService.getEmployeeById(employee.id).subscribe(result => {
      let selectedEmployee = Object.assign(result);
      this._dataService.changeEmployee(selectedEmployee);
    });

    this._router.navigate([this.detailPath + employee.id]);
  }

  onClear() {
    this.employeeForm.reset();
  }

  onLeaveText() {
    if (this.lastEmployee) {
      this.updateCurrentEmployeeForm(this.lastEmployee);
    }
  }
}
