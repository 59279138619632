import {Injectable} from '@angular/core';
import {MessageService} from './message.service';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {HttpBaseService} from './http-base.service';
import {forkJoin, Observable, Subject} from 'rxjs';
import {ScreenRefreshService} from './refresh-screens.service';
import {TranslateService} from '@ngx-translate/core';
import {EnumMapper} from './enumMapper.service';
import moment, {Moment} from "moment";
import {AuthenticationService} from "./authentication.service";
import {sumObjectsByKey} from "./data.service";
import {MatTableDataSource} from "@angular/material/table";
import {map, tap} from "rxjs/operators";
import {MatDialog} from "@angular/material/dialog";
import {environment} from "../../../../environments/environment";
import {Link, Project, ProjectName, ProjectWithDivisionsAndDepartments} from "../../models/Project";
import {MyProject} from "../../models/MyProject";
import Config from "../../config/Config";
import {MyEmployeeTableRow} from "../../../feature/my-projects/my-employee-table/my-employee-table.interface";
import {AssignmentProjectEmployee} from "../../models/AssignmentProjectEmployee";
import {ProjectTimeSlice, ProjectTimeSliceForm} from "../../models/ProjectTimeSlice";
import {Href} from "../../models/href";
import {AlertEnum} from "../../models/Enums/AlertEnum";
import {AssignmentTimeSliceRow} from "../../models/viewModels/AssignmentTimeSliceRow";
import {MyProjectsEnum} from "../../models/Enums/MyProjectsEnum";
import {MyProjectCustomers} from "../../../feature/my-projects/customer-table/customer-table.interface";
import {DropDownChoice} from "../../../feature/employee-operations/abstract-employee-details.component";
import {ProjectOfficeReportRow} from "../../models/ProjectOfficeReport";
import {AddProjectDialogComponent} from "../../../shared/dialogs/add-project-dialog/add-project-dialog.component";
import {Client} from "../../config/Client";
import {DeletedProjectDTO} from "../../models/util/DeletedProjectDTO";
import {CustomizingService} from "./customizing.service";

@Injectable({
  providedIn: 'root'
})
export class ProjectService extends HttpBaseService {

  projectUrl: string = environment.baseUrl + 'api/projects/';
  projectOfficeReportUrl: string = environment.baseUrl + 'api/projects/wrongPlannedProjects';
  myProjectsUrl: string = environment.baseUrl + 'api/projects/myProjects/';


  private concreteFlag = new Subject<any>();
  dateArray: number[] = [];
  eventProjectsSource: EventSource = null;
  private readonly yearBegin = '&beginYear=';
  private readonly endMonth = '&endMonth=';
  currentClient: Client;

  constructor(public http: HttpClient, public messageService: MessageService,
              private _screenRefreshService: ScreenRefreshService, private translate: TranslateService,
              private customizing: CustomizingService,
              private _authenticationService: AuthenticationService, private dialog: MatDialog) {
    super(http, messageService);
    customizing.getCurrentClient().then(client => {
      this.currentClient = client;
    });
    this.fillDateArray();
  }

  static checkAssignmentsAndProjectTimeSlicesForOverlapping(timeSlice: ProjectTimeSlice, assignments: MatTableDataSource<AssignmentProjectEmployee>): string[] {
    let filteredData = assignments.filteredData;
    let effectiveFrom = moment(timeSlice.effectiveFrom);
    let employeesWithAssignmentInDeletedTimeSlice: string[] = [];
    filteredData.forEach(data => {
      let assignmentsForEmployee = data.assignmentsToProject;
      assignmentsForEmployee.forEach(assignment => {
        let effectiveUntilForAssignment = moment(assignment.effectiveUntil);
        if (effectiveFrom.isBefore(effectiveUntilForAssignment)) {
          employeesWithAssignmentInDeletedTimeSlice.push(data.employee.firstName + ' ' + data.employee.lastName);
        }
      });
    });
    return employeesWithAssignmentInDeletedTimeSlice;
  }

  static checkAssignmentsAndProjectTimeSliceFormsForOverlapping(timeSlice: ProjectTimeSliceForm, assignments: MatTableDataSource<AssignmentProjectEmployee>): string[] {
    return this.checkAssignmentsAndProjectTimeSlicesForOverlapping(timeSlice.getRawValue(), assignments);
  }

  static monthMinusOne(month: number, year: number): { monthMinusOne: number; year: number } {
    if (month === 1) {
      return {monthMinusOne: 12, year: year - 1};
    } else {
      return {monthMinusOne: month - 1, year};
    }
  }

  /**
   * Concats all project names and sets the corresponding attribute
   *
   * @param rows
   */
  private static setProjectNamesConcat(rows: MyEmployeeTableRow[]) {
    rows.forEach(row => {
      row.projectNamesConcat = row.projectList.map(p => p.projectName).reduce((prevN, currN) => prevN + ' ' + currN, '');
    });
  }

  static getTotalCapacity(data: AssignmentProjectEmployee[], month: string) {
    return data.map(r => r.capacityMonths['' + month])
      .reduce((acc, value) => acc + value, 0).toFixed(1);
  }

  static getTotalCapacitySum(data: AssignmentProjectEmployee[]) {
    return data.map((r: AssignmentProjectEmployee) => r['capacitySum'])
      .reduce((acc, value) => acc + value, 0).toFixed(1);
  }

  getProjectNames(searchTerm: string): Observable<ProjectName[]> {
    return this.http.get<ProjectName[]>(Config.services.projects.baseUrl + Config.services.projects.nameProjection + '?searchTerm=' + encodeURIComponent(searchTerm)).pipe(
      tap(projects => {
        (projects || []).forEach(project => {
          project._links = new Link();
          project._links.self = new Href();
          project._links.self.href = Config.services.projects.baseUrl + project.id;
        });
      })
    );
  }

  fillDateArray() {
    const date = new Date();
    this.dateArray[0] = date.getMonth() + 1;
    this.dateArray[1] = date.getFullYear() - 1;
    this.dateArray[2] = 1;
    this.dateArray[3] = date.getFullYear() + 2;
  }

  fillDateArrayWithStart(startDate: Moment) {
    if (startDate) {
      this.dateArray[0] = startDate.month() + 1;
      this.dateArray[1] = startDate.year();
      this.dateArray[2] = 1;
      this.dateArray[3] = startDate.year() + 3;
    }
  }

  async registerProjectNotification(): Promise<EventSource> {
    await this._authenticationService.getApplicationUser().then((user) => {
      if (this.eventProjectsSource === null ||
        this.eventProjectsSource.readyState === this.eventProjectsSource.CLOSED) {
        this.eventProjectsSource = new EventSource(Config.services.projects.notification + '/' + user.username);
      }
    });
    return this.eventProjectsSource;
  }

  unregisterProjectNotification(hasError: boolean) {
    if (this.eventProjectsSource) {
      this.eventProjectsSource.onmessage = null;
      this.eventProjectsSource.onerror = null;
      if (hasError === true && this.eventProjectsSource.readyState !== this.eventProjectsSource.CLOSED) {
        this.eventProjectsSource.close();
      }
    }
  }

  getAllProjects(): Observable<Project[]> {
    return this.getAllProjectsByProjection(Config.services.projects.inlineLeader);
  }

  getAggregatedProjects(id: number): Observable<Project[]> {
    let url = this.projectUrl + id + "/getAggregatedProjects";
    return this.http.get<Project[]>(url);
  }

  getAggregateProject(id: number): Observable<Project> {
    let url = this.projectUrl + id + "/getAggregateProject";
    return this.http.get<Project>(url);
  }

  setAggregate(aggregateId: number, projectId: number) {
    let url = this.projectUrl + "setAggregate";
    let data = [aggregateId, projectId];
    this.http.put(url, data).subscribe();
  }

  clearAggregateReferences(projectId: number): Observable<any> {
    return this.http.put(this.projectUrl + "clearAggregateReferences", projectId);
  }

  getAllProjectsByProjection(projection: string) {
    return this.getAll(Config.services.projects.baseUrl + projection)
      .pipe(
        map(r => r.body._embedded.projects)
      );
  }

  getAllProjectWithDivisions(): Observable<ProjectWithDivisionsAndDepartments[]> {
    return this.getAllProjectsByProjection(Config.services.projects.projectList);
  }

  getAllProjectWithDivisionsAndDepartments(): Observable<ProjectWithDivisionsAndDepartments[]> {
    return this.getAllProjectsByProjection(Config.services.projects.projectListWithEmployeeHierarchy);
  }

  getAllProjectsByProjectionAndStatus(projection: string) {
    return this.getAll(Config.services.projects.baseUrl + projection).pipe(
      map(r => r.body)
    );
  }

  getAllActiveProjectWithDivisions(): Observable<HttpResponse<any>> {
    return this.getAllProjectsByProjectionAndStatus(Config.services.projects.activeProjectList);
  }

  getAllActiveProjectWithDivisionsAndDepartments() {
    return this.getAllProjectsByProjectionAndStatus(Config.services.projects.activeProjectListWithEmployeeHierarchy);
  }

  getAllProjectsWithoutProjection() {
    return this.getAllProjectsByProjection(Config.services.projects.baseUrl);
  }

  getAllActiveProjectsWithoutProjection(): Observable<ProjectWithDivisionsAndDepartments[]> {
    return this.http.get<ProjectWithDivisionsAndDepartments[]>(Config.services.projects.baseUrl + "all-active-projects");
  }


  updateProject(project: Project): Observable<Project> {
    project = JSON.parse(JSON.stringify(project));
    // because of ZT has not divisionManager as required field
    if (this.currentClient != Client.ZT) {
      project.divisionManager = project.divisionManager._links.self.href;
    }
    // It can happen after import that some project have no project leader.
    // Despite that, it should be possible to save projects.
    if (project.projectLeader) {
      project.projectLeader = project.projectLeader._links.self.href;
    }
    if (project.responsibleAL) {
      project.responsibleAL = project.responsibleAL._links.self.href;
    }
    if (project.resortManager) {
      project.resortManager = project.resortManager._links.self.href;
    }
    return this.patch<any>(project._links.self.href, project);
  }

  createProject(project: Project): Observable<Project> {
    project = JSON.parse(JSON.stringify(project));
    // because ZT has not divisionManager as required field
    if (this.currentClient != Client.ZT) {
      project.divisionManager = project.divisionManager._links.self.href;
      delete project._links;
    }
    project.projectLeader = project.projectLeader._links.self.href;
    if (project.responsibleAL) {
      project.responsibleAL = project.responsibleAL._links.self.href;
    }
    if (project.resortManager) {
      project.resortManager = project.resortManager._links.self.href;
    }
    delete project._links;

    return this.add<Project>(Config.services.projects.baseUrl, project);
  }

  updateProjectEnd(project: Project): Observable<Project> {
    return this.patch<any>(project._links.self.href, {endDate: project.endDate});
  }

  getProjectData(sharePointId: string): Observable<Project> {
    const url = Config.services.projects.baseUrl + "search/bySharePointId?sharePointId=" + sharePointId + Config.filter.inlineLeader;
    return this.http.get<Project>(url, {observe: 'response'}).pipe(
      map(p => p.body)
    );
  }

  async getProject(projectResourceURL: string): Promise<Project> {
    const url = projectResourceURL.replace("{?projection}", "?projection=inlineLeader");
    try {
      return (await this.http.get<any>(url, {
        observe: 'response'
      }).toPromise()).body;
    } catch (e) {
      this.messageService.add(this.translate.instant('mep.services.project-service.fetch-project-failed'), AlertEnum.danger);
    }
  }

  public unfollowProject(project: MyProject): Observable<any> {
    const urlArray = project._links.self.href.split('/');
    const projectID = urlArray[urlArray.length - 1];
    return super.delete(Config.services.projects.baseUrl + 'unfollowProject?projectId='
      + projectID);
  }

  public getMinMaxEffectiveDates(project: Project): Observable<[string, string]> {
    return this.http.get<[string, string]>(project._links.self.href + '/getMinMaxEffectiveDates');
  }

  /**
   *
   *
   * Get project probablities
   *
   *
   *
   * @returns
   * @memberof ProjectService
   * @param project
   * @param isAddMode
   */
  public async getProjectProbabilities(project: Project | number, isAddMode: boolean): Promise<ProjectTimeSlice[]> {
    let url;
    if (typeof project === 'number') {
      const id = project as number;
      url = Config.services.projects.baseUrl + id.toString() + Config.services.projects.projectTimeSlices;
    } else {
      url = project._links.projectTimeSlices.href;
    }
    try {
      const response = (await
        this.http.get<any>(url, {observe: 'response'}).toPromise()).body;

      let slices: ProjectTimeSlice[] = response._embedded.projectTimeSlices;
      let newSlices: ProjectTimeSlice[] = [];
      slices.forEach((sl) => {
        newSlices.push(Object.assign({}, sl));
      });

      return newSlices.sort(function (a: ProjectTimeSlice, b: ProjectTimeSlice) {
        return (Date.parse(a.effectiveFrom) > Date.parse(b.effectiveFrom)) ? 1 : ((Date.parse(b.effectiveFrom) >
          Date.parse(a.effectiveFrom)) ? -1 : 0);
      });
    } catch (e) {
      // Use translation key instead of hardcoded message
      if (!isAddMode) {
        this.messageService.add(this.translate.instant('mep.components.project-data.timeslice_import_error'),
          AlertEnum.danger);
      }
    }
  }

  public putProjectTimeSlices(projectId, timeSlices: ProjectTimeSlice[]): Observable<ProjectTimeSlice[]> {
    const cleanTimeSlices = timeSlices.map(timeSlice => ({
      effectiveFrom: timeSlice.effectiveFrom,
      effectiveUntil: timeSlice.effectiveUntil,
      probability: timeSlice.probability
    }));
    return this.http.put<ProjectTimeSlice[]>(this.projectUrl + projectId + "/putProjectTimeSlices", cleanTimeSlices);
  }


  public sortByEffectiveFrom(a: { effectiveFrom }, b: { effectiveFrom }) {
    return a.effectiveFrom.localeCompare(b.effectiveFrom);
  }

  public addProjectTimeSlice(timeSlice: ProjectTimeSlice, id: number) {
    let url = this.projectUrl + id + '/projectTimeSlices';

    return super.add<ProjectTimeSlice>(url, timeSlice);
  }


  addAssignmentProjectEmployee(assignment: AssignmentProjectEmployee) {
    const requestBody = JSON.parse(JSON.stringify(assignment));
    if (requestBody.employee && requestBody.employee._links) {
      requestBody.employee = requestBody.employee._links.self.href;
    }

    if (requestBody.project && requestBody.project._links) {
      requestBody.project = requestBody.project._links.self.href;
    }
    return super.add<AssignmentProjectEmployee>(Config.services.assignmentProjectEmployees.baseUrl, requestBody);
  }

  deleteProjectTimeSlice(timeSlice: ProjectTimeSlice): Observable<any> {
    return this.delete(timeSlice._links.self.href);
  }

  deleteProject(project: Project): Observable<any> {
    return this.delete(project._links.self.href + '/manually');
  }

  deleteProjects(ids: number[]) {
    return this.http.post<DeletedProjectDTO>(this.projectUrl + "delete", ids)
  }

  /**
   *
   *
   *
   * Get assigned projects
   *
   *
   *
   * @returns
   * @memberof ProjectService
   * @param projectLink
   */
  public async getAssignmentEmployeeProjectInfo(projectLink: any): Promise<any> {
    try {
      const response = (await
        this.http.get<any>(projectLink +
          Config.services.assignment.baseUrl
          + Config.services.assignment.inlineEmployeeProjectInfo,
          {observe: 'response'}).toPromise()).body;
      return response._embedded.assignmentProjectEmployees;
    } catch (e) {
      // Use translation key instead of hardcoded message
      this.messageService.add(this.translate.instant('mep.components.project-data.timeslice_import_error'),
        AlertEnum.danger);
    }
  }

  followProject(project: Project): Observable<any> {
    return super.add<number>(Config.services.projects.baseUrl + 'followProject?projectId=' + project.id.toString(), null);
  }

  /**
   *
   * Get assignment project employees
   *
   * @memberof ProjectService
   * @param project
   * @param currentClient
   * @param employeeId If an employee id is given, only the assignment information for the given employee is calcluated.
   * If the employee is not assigned to the project an empty array is returned!
   */
  public async getAssignmentProjectEmployees(project: Project, currentClient: Client, concreteCalc: boolean, employeeId?: number): Promise<AssignmentProjectEmployee[]> {
    let url = project._links.self.href + (employeeId ? "/" + employeeId : "") + '/personDays?beginMonth=' + this.dateArray[0] + this.yearBegin +
      this.dateArray[1] + this.endMonth + this.dateArray[2] + '&endYear=' + this.dateArray[3] + '&concrete=' + concreteCalc;
    let assignments: AssignmentProjectEmployee[] = (await this.getAll<any>(url).toPromise()).body;

    assignments.forEach((assignment) => {
      assignment.capacityMonths = assignment['valueMap'];
      assignment.employee = EnumMapper.mapEmployeeData(assignment.employee, currentClient, false, this.translate);
      assignment.project = project;
    });
    assignments.forEach((assignment) => {
      assignment.assignmentsToProject.sort(this.sortByEffectiveFrom);
      assignment['capacitySum'] = Math.round(Object.values(assignment.capacityMonths).reduce((m1, m2) => m1 + m2, 0) * 10) / 10;
    });

    return assignments;
  }

  public createAssignmentTimeSliceRows(assignments: AssignmentProjectEmployee[]): AssignmentTimeSliceRow[] {
    assignments.sort((a1, a2) => `${a1.employee.lastName} ${a1.employee.firstName}`.localeCompare(`${a2.employee.lastName} ${a2.employee.firstName}`));
    return assignments.map(assignmentWrapper => ({
      id: assignmentWrapper.id,
      name: `${assignmentWrapper.employee.lastName} ${assignmentWrapper.employee.firstName}`,
      currentCapacity: assignmentWrapper.capacity,
      subteam: assignmentWrapper.subteam,
      capacitySum: assignmentWrapper.capacitySum,
      assignmentWrapper,
      timeSlices: assignmentWrapper.assignmentsToProject.map(assignment => ({
        id: assignment.id,
        label: `${assignment.capacity} %`,
        title: assignment.subteam,
        assignment,
        effectiveFrom: new Date(assignment.effectiveFrom),
        effectiveUntil: new Date(assignment.effectiveUntil)
      }))
    }));
  }

  public async getProjectEmployeeKapa(projectID: number, employeeID: number, startDate: string, concreteCalc: boolean): Promise<any> {
    const url = this.projectUrl + projectID + '/capacityInfoProjectDetail/' + employeeID + '/' + startDate + '?concrete=' + concreteCalc;
    const data = (await
      this.getAll<any>(url).toPromise()).body;
    const months = Object.keys(data.freeCapacityInPd);

    const headerCol = ['header'];
    const projectEmployeeKapaColumns = headerCol.concat(months);

    const projectEmployeeKapaDataSource = [
      {
        headerCol: 'manual-in-PD',
        value: data.overwrittenAssignment,
        concrete: false,
        contenteditable: true
      },
      {
        headerCol: 'calc-in-PD',
        value: data.availableCapacityInPd,
        concrete: true,
        contenteditable: false
      },
      {
        headerCol: 'free-kapa-in-PD',
        value: data.freeCapacityInPd,
        concrete: false,
        contenteditable: false
      },
      {
        headerCol: 'free-kapa-in-percent',
        value: data.freeCapacityInPercent,
        concrete: false,
        contenteditable: false
      }];
    return {
      projectEmployeeKapaDataSource,
      projectEmployeeKapaColumns,
      months
    };
  }

  /**
   * Get myprojects
   *
   * @memberof ProjectService
   */
  public async getMyProjects(showInPercent: boolean, calculationType: MyProjectsEnum, withoutEmployees: boolean): Promise<MyProject[]> {
    try {
      const url = this.myProjectsUrl + calculationType + '?beginMonth=' + this.dateArray[0] + this.yearBegin +
        this.dateArray[1] + this.endMonth + this.dateArray[2] + '&endYear=' + this.dateArray[3] + '&showInPercent=' + showInPercent + '&withoutEmployees=' + withoutEmployees;
      const response = (await
        this.http.get<any[]>(url, {observe: 'response'}).toPromise()).body;
      response.forEach(result => {
        if (result.projectInformation) {
          result.projectInformation.map((project) => {
            project['capacitySumEmp'] = Math.round(Object.values(<number>project.valueMap).reduce((a, b) => a + b, 0) * 10) / 10;
            project.capacityMonths = project.valueMap;
          });

          result.projectInformation = result.projectInformation.filter(assignment => Object.values(assignment.valueMap).some(value => value !== 0));
        } else {
          result.projectInformation = [];
        }
      });

      return response;
    } catch (e) {
      const errorMsg: string = await (this.translate.get('mep.services.project-list.error-my-projects').toPromise());
      this.messageService.add(errorMsg, AlertEnum.danger);
    }
  }

  public getEmployeesPerClient(): Observable<MyProjectCustomers[]> {
    const params = {
      beginMonth: '' + this.dateArray[0],
      beginYear: '' + this.dateArray[1],
      endMonth: '' + this.dateArray[2],
      endYear: '' + this.dateArray[3],
      showInPercent: 'false'
    };
    return this.http.get<MyProjectCustomers[]>(this.myProjectsUrl + MyProjectsEnum.allCustomerProjects, {params})
      .pipe();
  }

  public async getMyEmployees(enumValue: MyProjectsEnum, showInPercent: boolean): Promise<MyEmployeeTableRow[]> {
    try {
      const url = this.myProjectsUrl + enumValue + '?beginMonth=' + this.dateArray[0] + this.yearBegin +
        this.dateArray[1] + this.endMonth + this.dateArray[2] + '&endYear=' + this.dateArray[3] + '&showInPercent=' + showInPercent;
      const resultObjects = (await
        this.http.get<MyEmployeeTableRow[]>(url, {observe: 'response'}).toPromise()).body;

      for (const result of resultObjects) {
        // herausfiltern leerer Einträge (Einträge, die für jeden Monat 0PT als Kapazität enthalten)
        result.projectList = result.projectList.filter(res => Object.values(res.valueMap).reduce((a, b) => a + b, 0) > 0);

        let newProjectList: any = {};
        result.projectList.forEach((p) => {
          Object.keys(p.valueMap).forEach(month => {
            p.valueMap[month] = Math.round(p.valueMap[month] * 10) / 10;
          });
          if (!newProjectList[p.sharePointId]) {
            newProjectList[p.sharePointId] = p;
            newProjectList[p.sharePointId].allAssignmentsForProject = sumObjectsByKey(newProjectList[p.sharePointId].allAssignmentsForProject, p.projectTimeSlices);
          } else {
            newProjectList[p.sharePointId].allAssignmentsForProject = sumObjectsByKey(newProjectList[p.sharePointId].allAssignmentsForProject, p.projectTimeSlices);
            newProjectList[p.sharePointId].valueMap = sumObjectsByKey(newProjectList[p.sharePointId].valueMap, p.valueMap);
          }
        });
        result.projectList = Object.values(newProjectList);
      }

      resultObjects.forEach((row: MyEmployeeTableRow) => {
        row.projectList.sort((a, b) => a.projectName.localeCompare(b.projectName));
      });

      ProjectService.setProjectNamesConcat(resultObjects);
      return resultObjects;
    } catch (e) {
      const errorMsg: string = await (this.translate.get('mep.services.project-list.error-my-employees').toPromise());
      this.messageService.add(errorMsg, AlertEnum.danger);
    }
  }

  public async getMonthsForMyEmployees(): Promise<string[]> {
    const url = this.myProjectsUrl + 'months' + '?beginMonth=' + this.dateArray[0] + this.yearBegin +
      this.dateArray[1] + this.endMonth + this.dateArray[2] + '&endYear=' + this.dateArray[3];

    return (await this.http.get<string[]>(url, {observe: 'response'}).toPromise()).body;
  }

  deleteAssignmentProjectEmployees(assignment: AssignmentProjectEmployee): Observable<any> {
    return this.delete(assignment._links.self.href);
  }

  deleteAssignmentsProjectEmployeesWithCallback(assignments: AssignmentProjectEmployee[], callback = null) {
    const responses = [];
    assignments.forEach((assignment: AssignmentProjectEmployee) => {
      responses.push(this.delete(assignment._links.self.href));
    });
    forkJoin(responses).subscribe(() => {
      if (callback) {
        callback();
      } else {
        this.messageService.add(this.translate.instant('mep.services.project-service.processed'), AlertEnum.success);
        this._screenRefreshService.employeeWasRemoved();
      }
    }, () => {
      this.messageService.add(this.translate.instant('mep.services.project-service.delete-assignment-failed'), AlertEnum.danger);
    });
  }

  deleteAssignmentsProjectEmployees(assignments: number[]) {
    return this.http.delete(Config.services.assignmentProjectEmployees.baseUrl + 'deleteAssignments/' + assignments);
  }

  fetchAttributeOptions(filterViewModel?): Array<DropDownChoice<string>> {
    const dropDowns = ['status', 'marketUnit'];
    const allOptions = [];
    for (let attribute of dropDowns) {
      allOptions[attribute] = Object.keys(Config.services.projects[attribute]).sort().map(function (i) {
        return {value: i, viewValue: Config.services.projects[attribute][i]};
      });
      allOptions[attribute].unshift({
        value: Config.services.missingValue,
        viewValue: Config.services.missingValue
      });
      if (filterViewModel && filterViewModel.viewData && filterViewModel.viewData[attribute]) {
        filterViewModel.viewData[attribute] = Object.keys(Config.services.projects[attribute]).sort().map(function (i) {
          return {value: i, viewValue: Config.services.projects[attribute][i]};
        });
        filterViewModel.viewData[attribute].unshift({
          value: Config.services.missingValueBackend,
          viewValue: Config.services.missingValue
        });
      }
    }
    return allOptions;
  }

  public async getProjectOfficeReport(referenceDate: Moment): Promise<ProjectOfficeReportRow[]> {
    try {
      return (await this.http.get<any>(this.projectOfficeReportUrl + "/" + referenceDate.toISOString().split('T')[0], {
        observe: 'response'
      }).toPromise()).body;
    } catch (e) {
      const errorMsg: string = await (this.translate.get('mep.services.project-list.error-project-office-report').toPromise());
      this.messageService.add(errorMsg, AlertEnum.danger);
    }
  }

  public async extendProject(projectID, assignments, newEnd, probability?): Promise<ProjectTimeSlice> {
    let params = newEnd ? {newEnd} : {};
    params = probability ? Object.assign(params, {probability}) : params;
    return (await this.http.post(this.projectUrl + projectID + '/extend', assignments, {
      observe: 'response',
      params
    }).toPromise()).body as ProjectTimeSlice;
  }

  public async moveProject(projectTimeSliceID, newStart, newEnd, probability, assignments): Promise<ProjectTimeSlice> {
    const params = {
      projectTimeSliceID,
      newStart,
      newEnd,
      probability
    };
    return (await this.http.post(this.projectUrl + 'move', assignments, {
      observe: 'response',
      params
    }).toPromise()).body as ProjectTimeSlice;
  }

  /**
   * Assign the subteam, role, and annotation with the current version.
   * If for the moment there is no current assignments, then the first next version will be assigned.
   *
   * @param assignment
   */
  public async assignCurrentSubteams(assignment: AssignmentProjectEmployee) {
    if (!assignment) {
      return;
    }

    let maxEnd = this.getCurrentAssignmentOrClosest(assignment.assignmentsToProject);

    if (maxEnd != null) {
      assignment.subteam = assignment.assignmentsToProject[maxEnd].subteam;
      assignment.role = assignment.assignmentsToProject[maxEnd].role;
      assignment.extRateCurrency = assignment.assignmentsToProject[maxEnd].extRateCurrency;
      assignment.extRate = assignment.assignmentsToProject[maxEnd].extRate;

    }
  }

  public getCurrentAssignmentOrClosest(assignments: AssignmentProjectEmployee[]) {
    let current = moment();
    let maxBefore = null;
    let maxBeforeDate = moment("1900-01-01");
    let minAfter = null;
    let minAfterDate = null;
    for (let i = 0; i < assignments.length; i++) {
      let endDate = moment(assignments[i].effectiveUntil);
      let startDate = moment(assignments[i].effectiveFrom);
      if (endDate.isSameOrAfter(current) && startDate.isSameOrBefore(current)) {
        return i;
      } else {
        if (endDate.isBefore(current) && endDate.isAfter(maxBeforeDate)) {
          maxBeforeDate = endDate;
          maxBefore = i;
        } else if (startDate.isAfter(current) && (minAfterDate == null || startDate.isBefore(minAfterDate))) {
          minAfter = i;
          minAfterDate = startDate;
        }
      }
    }

    return minAfter == null ? maxBefore : minAfter;
  }

  public sortMyProjects(myProjects: MyProject[]) {
    return myProjects.sort((a: MyProject, b: MyProject) => {
      if (a.projectInformation.length === 0 && b.projectInformation.length !== 0) {
        return 1;
      } else if (a.projectInformation.length !== 0 && b.projectInformation.length === 0) {
        return -1;
      } else {
        return a.projectName.localeCompare(b.projectName);
      }
    });
  }

  getConcreteCalc(): Observable<any> {
    return this.concreteFlag.asObservable();
  }

  checkOverlapFree(slices: AssignmentProjectEmployee[]): boolean {
    moment.locale('en');
    let startAndEndDate = {};
    slices.forEach((slice: AssignmentProjectEmployee) => {
      startAndEndDate[slice.id] = [moment(slice.effectiveFrom), moment(slice.effectiveUntil)];
    });

    for (let key in startAndEndDate) {
      if (startAndEndDate.hasOwnProperty(key)) {
        let current = startAndEndDate[key];
        for (let other in startAndEndDate) {
          if (other !== key) {
            let otherSlice = startAndEndDate[other];
            if (this.overlapExists(current[0], current[1], otherSlice[0], otherSlice[1])) {
              return false;
            }
          }
        }
      }
    }
    return true;
  }

  checkOverlapFreeWithNewSlice(slices: AssignmentProjectEmployee[], newSlice: AssignmentProjectEmployee): boolean {
    moment.locale('en');
    let startAndEndDate = {};
    let capacityProject = {};
    let capacityOverlapTotal = newSlice.capacity;
    slices.forEach((slice: AssignmentProjectEmployee) => {
      startAndEndDate[slice.id] = [moment(slice.effectiveFrom), moment(slice.effectiveUntil)];
      capacityProject[slice.id] = slice.capacity;
    });

    for (let key in startAndEndDate) {
      if (startAndEndDate.hasOwnProperty(key)) {
        let otherSlice = startAndEndDate[key];
        if (this.overlapExists(moment(newSlice.effectiveFrom).toDate(), moment(newSlice.effectiveUntil).toDate(), otherSlice[0], otherSlice[1])) {
          capacityOverlapTotal += capacityProject[key];
          if (capacityOverlapTotal > 100) {
            return false;
          }
        }
      }
    }
    return true;
  }

  overlapExists(toTestStart: Date, toTestEnd: Date, otherStart: Date, otherEnd: Date): boolean {
    return (toTestStart <= otherStart && otherStart <= toTestEnd)
      || (toTestStart <= otherEnd && otherEnd <= toTestEnd)
      || (otherStart <= toTestStart && toTestStart <= otherEnd)
      || (otherStart <= toTestEnd && toTestEnd <= otherEnd);
  }

  public openAddFollowProjectDialog(afterFollowed: () => void) {
    this.dialog.open(AddProjectDialogComponent, {
      width: '400px',
      disableClose: true,
    }).afterClosed().subscribe(project => {
      if (project) {
        this.followProject(project).subscribe(afterFollowed);
      }
    });
  }

  getCharismaLink(): Observable<string> {
    return this.http.get(this.projectUrl + "charismaLink", {responseType: 'text'});
  }

  getOOBLink(): Observable<string> {
    return this.http.get(this.projectUrl + "OOBLink", {responseType: 'text'});
  }

  public getLastImportDate(): Observable<string> {
    return this.http.get(this.projectUrl + "lastImportDate", {responseType: 'text'});
  }
}
