import {Component, Inject, Optional} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {StaffingComment} from "../../../models/staffing-comment";
import {TranslatableComponent} from "../../../../../core/models/TranslatableComponent";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-staffing-comment-dialog',
  templateUrl: './staffing-comment-dialog.component.html',
  styleUrls: ['./staffing-comment-dialog.component.css']
})
export class StaffingCommentDialogComponent extends TranslatableComponent {

  comment: any;
  displayErrorMessage: boolean;

  constructor(
    public dialogRef: MatDialogRef<StaffingCommentDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: StaffingComment,
    public translate: TranslateService) {
    super(translate, 'StaffingCommentDialogComponent');
    this.comment = {...data};
  }

  save(value: HTMLTextAreaElement) {
    if (value.value.trim() === "") {
      this.displayErrorMessage = true;
    } else {
      this.dialogRef.close({data: this.comment});
    }
  }

  closeDialog() {
    this.dialogRef.close(null);
  }
}
