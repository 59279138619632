import {FormControl} from "@angular/forms";
import {Employee} from "../../../core/models/Employee";
import Config from '../../../core/config/Config';
import {EmployeeService} from "../../../core/services/services/employee.service";
import {CustomizingService} from "../../../core/services/services/customizing.service";
import {EnumMapper} from "../../../core/services/services/enumMapper.service";
import {
  DefaultSkillDbFilterData,
  FilterViewData,
  SkillDbFilterData
} from "../../dialogs/filter-dialog/filter-dialog.interface";
import {TranslateService} from "@ngx-translate/core";

export class EmployeeCapacityListElement {
  employee: Employee | any;
  employeeName: string;
  location: string;
  dispoFlag: boolean;
  country: string;
  skills: string;
  skillsControl: FormControl;
  division: string;
  travelWillingness: string;
  travelWillingnessInternational: boolean;
  travelWillingnessCombined: string;
  misplannedAnnotationControl: FormControl;
  employeeCapacityInPersonDays: { [index: string]: number };
  freeCapacityInPersonDays: { [index: string]: number };
  employeeCapacityInPercent: { [index: string]: number };
  vacation: { [index: string]: number };
  availableCapacity: { [month: string]: number };
  projectLeaderNames: string;
  divisionManagerNames: string;
  isVisibleByColor: boolean;
  isVisibleByMask: boolean;
  capacitySumInPT: number;
  capacitySumInPercent: number;
  hasValidActionPlan: boolean;
  resourceRequestCount: number;
}

export class EmployeeRevenueListElement {
  employee: Employee;
  employeeCapacityInPersonDaysWithoutFixprice: { [index: string]: number };
  employeeCapacityInPercentWithoutFixprice: { [index: string]: number };
}

export interface EmployeeRevenueList {
  employeeRevenueListElements: EmployeeRevenueListElement[];
  errors: string[];
}

export interface EmployeeCapacityList {
  employeeCapacityListElement: EmployeeCapacityListElement[];
  errors: string[];
}

export interface EmployeeCapacityFilterViewModel {
  filterData: EmployeeCapacityFilterData;
  viewData: EmployeeCapacityViewData;
}

export class EmployeeCapacityFilterData {
  humanResourcesManager = null;
  businessUnit = [];
  jobVariant = [];
  jobLevel = [];
  division = [];
  travelWillingnessCombined = [];
  location = [];
  country = [];
  skillDbFilterData: SkillDbFilterData = new DefaultSkillDbFilterData();
  projectLeaderNames = '';
  divisionManagerNames = '';
  tag = [];
  capaRelevance = [];
  hideExternEmployees: boolean;
  showPastAndCurrentEmployee: boolean;
  dispoFlag: boolean;
}

export class EmployeeCapacityViewData implements FilterViewData {
  businessUnit = [];
  location = [];
  dispoFlag = [];
  country = [];
  jobVariant = [];
  jobLevel = [];
  division = [];
  travelWillingnessCombined = [];
  tag = [];
  capaRelevance = [];

  constructor(private _employeeService: EmployeeService, private customizingService: CustomizingService, private translateService: TranslateService) {
  }

  private setLocation = async () => {
    let currentClient = await this.customizingService.getCurrentClient();
    let customizedLocationObject = Config.services
      .employees[EnumMapper.getConfigObjectForClient('location', currentClient)];
    return await Promise.all(Object.keys(customizedLocationObject).sort().map(k => ({
      value: k,
      viewValue: this.translateService.instant(customizedLocationObject[k])
    })));
  };

  private setCountry = async () => {
    let currentClient = await this.customizingService.getCurrentClient();
    let customizedCountryObject = Config.services
      .employees[EnumMapper.getConfigObjectForClient('country', currentClient)];
    return await Promise.all(Object.keys(customizedCountryObject).sort().map(k => ({
      value: k,
      viewValue: customizedCountryObject[k]
    })));
  };

  private setBusinessUnit = async () => {
    let currentClient = await this.customizingService.getCurrentClient();
    let customizedBusinessUnitObject = Config.services.employees[EnumMapper.getConfigObjectForClient('businessUnit', currentClient)];
    return await Promise.all(Object.keys(customizedBusinessUnitObject).sort().map(k => ({
      value: k,
      viewValue: customizedBusinessUnitObject[k]
    })));
  };

  private setJobVariant = async () => {
    let currentClient = await this.customizingService.getCurrentClient();
    let customizedJobVariantObject = Config.services
      .employees[EnumMapper.getConfigObjectForClient('jobVariant', currentClient)];
    return await Promise.all(Object.keys(customizedJobVariantObject).sort().map(k => ({
      value: k,
      viewValue: customizedJobVariantObject[k]
    })));
  };

  private setJobLevel = async () => {
    let currentClient = await this.customizingService.getCurrentClient();
    let customizedJobLevelObject = Config.services
      .employees[EnumMapper.getConfigObjectForClient('jobLevel', currentClient)];
    return await Promise.all(Object.keys(customizedJobLevelObject).sort().map(k => ({
      value: k,
      viewValue: customizedJobLevelObject[k]
    })));
  };

  private setDivision = async () => {
    let currentClient = await this.customizingService.getCurrentClient();
    let customizedDivisionObject = Config.services
      .employees[EnumMapper.getConfigObjectForClient('division', currentClient)];
    Object.assign(customizedDivisionObject, Config.services.default_values)
    return await Promise.all(Object.keys(customizedDivisionObject).sort().map(k => ({
      value: k,
      viewValue: customizedDivisionObject[k]
    })));
  };

  private setTravelWillingnessCombined = async () => {
    let currentClient = await this.customizingService.getCurrentClient();
    let travelwillingnessCombinedObject = Config.services
      .employees[EnumMapper.getConfigObjectForClient('travelWillingnessCombined', currentClient)];
    return await Promise.all(Object.keys(travelwillingnessCombinedObject).sort().map(k => ({
      value: k,
      viewValue: this.translateService.instant(travelwillingnessCombinedObject[k])
    })));
  };

  private setTag = async () => {
    let tags = await this._employeeService.getEmployeeTags().toPromise();
    return tags.sort().map(k => ({value: k, viewValue: k}));
  };

  private setCapaRelevance = async () => {
    let currentClient = await this.customizingService.getCurrentClient();
    let customizedLocationObject = Config.services
      .employees[EnumMapper.getConfigObjectForClient('capaRelevance', currentClient)];
    return await Promise.all(Object.keys(customizedLocationObject).sort().map(k => ({
      value: k,
      viewValue: customizedLocationObject[k]
    })));
  };

  async loadData(): Promise<void> {
    this.jobVariant = await this.setJobVariant();
    this.jobLevel = await this.setJobLevel();
    this.businessUnit = await this.setBusinessUnit();
    this.country = await this.setCountry();
    this.location = await this.setLocation();
    this.travelWillingnessCombined = await this.setTravelWillingnessCombined();
    this.division = await this.setDivision();
    this.tag = await this.setTag();
    this.capaRelevance = await this.setCapaRelevance();
  }
}
