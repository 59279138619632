import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {EmployeeService} from '../../../core/services/services/employee.service';
import {TranslatableComponent} from '../../../core/models/TranslatableComponent';
import {TranslateService} from '@ngx-translate/core';
import {MatTableDataSource} from '@angular/material/table';
import {PageEvent} from '@angular/material/paginator';
import {debounceTime, startWith} from 'rxjs/operators';
import {FormControl} from '@angular/forms';
import {merge} from 'rxjs';
import {Category, Cluster, EmployeeSkill, Id, Skill, SkillWithAverageLevel} from "../../../core/models/EmployeeSkill";
import {EditSkillDialogComponent} from "../edit-skill-dialog/edit-skill-dialog.component";
import {ConfirmationDialogComponent} from "../confirmation-dialog/confirmation-dialog.component";
import {MessageService} from "../../../core/services/services/message.service";
import {AlertEnum} from "../../../core/models/Enums/AlertEnum";
import {CustomizingService} from "../../../core/services/services/customizing.service";
import {EmployeeSkillsService} from "../../../core/services/services/employee-skills.service";
import {LanguagesService} from "../../../core/services/services/languages.service";
import {ExcelExportService} from "../../../core/services/services/excelExport.service";
import {SkillDbDialogExports} from "./skill-db-dialog.exports";
import {ExportSkillWithCluster} from "./exportSkill";
import {AuthenticationService} from "../../../core/services/services/authentication.service";
import {AppEntity, AppEntityAccessType, Permission} from "../../../core/models/AppPermissions";
import {EditSkillDialogInformation} from "../edit-skill-dialog/edit-skill-dialog-information";
import {CdkDragDrop} from "@angular/cdk/drag-drop";
import {Employee} from "../../../core/models/Employee";
import {EditLanguagesDialogComponent} from "../edit-languages-dialog/edit-languages-dialog.component";
import {MatSort} from "@angular/material/sort";
import {LazyXlsxService} from "../../xlsx/lazy-xlsx.service";
import {EmployeeFullName} from "../../../core/models/EmployeeFullName";
import {PaginatorSmartComponent} from "../../paginator-smart/components/paginator-smart.component";
import {Preference, PreferencesService} from "../../../core/services/services/preferences.service";

interface SkillDb2Data {
  maintenance: boolean;
  employeeId: number;
  readonly: boolean;
}

@Component({
  selector: 'app-skill-db-dialog',
  templateUrl: './skill-db-dialog.component.html',
  styleUrls: ['./skill-db-dialog.component.scss']
})
export class SkillDbDialogComponent extends TranslatableComponent implements OnInit, AfterViewInit, SkillDb2Data {
  displayedColumns = ['cluster', 'category', 'skill'];
  displayedColumnsFullTable = this.displayedColumns.concat(['employeesWithSkill', 'averageLevel']);

  employeeSkills: EmployeeSkill[];
  allSkillsDataSource = new MatTableDataSource<SkillWithAverageLevel>();
  allClusters: Cluster[];
  allCategories: Category[];


  skilleditSkillsPerPage: number;

  employeeSkillsBySkillId = new Map<number, { formControl: FormControl; new: boolean; lastModifiedAt: string; expirationDate: FormControl }>();

  resultsLength = 0;
  @ViewChild(PaginatorSmartComponent) paginator: PaginatorSmartComponent;
  @ViewChild(MatSort) sort: MatSort;

  loading = true;

  search = new FormControl('');
  selectedCluster = new FormControl('');
  selectedCategories = new FormControl('');

  showSkillsWithEmployeeSkill = false;

  showNewSkillOnly = false;

  showFullTable = false;

  languages = "";

  maintenance;
  employeeId;
  employeeFullName: EmployeeFullName;
  readonly;
  showSkillDbTooltip = false;
  newSkillsDate;


  constructor(private _dialogRef: MatDialogRef<SkillDbDialogComponent>,
              private matDialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) public data: SkillDb2Data,
              private employeeService: EmployeeService,
              private employeeSkillService: EmployeeSkillsService,
              protected _translate: TranslateService,
              private messageService: MessageService,
              private lazyXlsxService: LazyXlsxService,
              private customizingService: CustomizingService,
              private languagesService: LanguagesService,
              private excelExportService: ExcelExportService,
              private authService: AuthenticationService,
              private preferencesService: PreferencesService) {
    super(_translate, 'SkillDbDialogComponent');
  }

  static openEditSkillsDialog(dialogContainer: MatDialog, maintenance: boolean, afterClose: () => void, employeeSkills?: EmployeeSkill[],
                              employee?: Employee, languageEditEnabled: boolean = true) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = 600;
    dialogConfig.disableClose = true;

    let skillDBDialog;

    if (maintenance) {
      dialogConfig.data = {
        maintenance: true
      };
    } else {
      dialogConfig.data = {
        maintenance: false,
        employeeId: employee.id,
        readonly: languageEditEnabled
      };
    }
    dialogConfig.width = "80%";
    dialogConfig.maxWidth = 1500;
    skillDBDialog = dialogContainer.open(SkillDbDialogComponent, dialogConfig);
    skillDBDialog.afterClosed().subscribe(() => {
      afterClose();
    });
  }

  ngOnInit(): void {

    this.maintenance = this.data.maintenance;
    this.employeeId = this.data.employeeId;
    this.readonly = this.data.readonly;

    if (!this.maintenance) {
      this.displayedColumns = this.displayedColumns.concat(['level', 'lastModifiedAt']);
      this.loadEmployeeSkillsById();
      this.employeeService.getEmployeeFullNameByEmployeeId(this.employeeId).subscribe(fullName => {
        this.employeeFullName = fullName;
      });
    } else {
      this.displayedColumns = this.displayedColumns.concat(['edit', 'add', 'delete', 'visibility']);
    }

    if (this.employeeId) {
      this.displayedColumns.push('expirationDate');
    }

    if (this.readonly) {
      this.employeeService.getEmployeeLanguages(this.employeeId).subscribe(languages => {
        this.languages = languages;
      });
    }

    this.employeeService.getAllSkillCluster(this.maintenance, this.employeeId).subscribe(skillClusters => {
      this.allClusters = skillClusters;
    });

    this.employeeService.getAllSkillCategories(this.maintenance).subscribe(skillCategorie => {
      this.allCategories = skillCategorie;
    });

    this.customizingService.getNewSkillsDate().then(value => {
      this.newSkillsDate = value;
    });

    this.allSkillsDataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'lastModifiedAt': {
          return this.employeeSkillsBySkillId.get(item.skill.id).lastModifiedAt;
        }
        case 'expirationDate': {
          return this.employeeSkillsBySkillId.get(item.skill.id).expirationDate.value;
        }
        default: {
          return item[property];
        }
      }
    };
  }

  async ngAfterViewInit() {
    // this "hack" is needed, because during the first fetch, the paginator hasn't yet loaded the preference, but it is required in fetchData()
    const preferencePageSize = await this.preferencesService.get(Preference.PAGINATION_LIST_ELEMENTS, "skillDbDialogList");
    this.skilleditSkillsPerPage = preferencePageSize ? Number(preferencePageSize) : await this.customizingService.getSkilleditSkillsPerPage();

    this.paginator.page
      .pipe(startWith({}))
      .subscribe(() => this.fetchData());

    merge(this.search.valueChanges.pipe(debounceTime(500)), this.selectedCluster.valueChanges, this.selectedCategories.valueChanges).subscribe(() => {
      if (this.paginator.basePaginator.pageIndex !== 0) {
        // Changing the page will refetch the data
        this.paginator.basePaginator.firstPage();
      } else {
        this.fetchData();
      }
    });
    this.allSkillsDataSource.sort = this.sort;
  }

  onShowSkillsWithEmployeeSkillChangedAndOnlyNewSkill() {
    if (this.showSkillsWithEmployeeSkill || this.showNewSkillOnly) {
      this.paginator.basePaginator.firstPage();
    }
    this.fetchData();
  }

  async fetchData() {
    this.loading = true;
    let pageNumber = this.paginator.basePaginator.pageIndex;
    const pageSize = this.paginator.myPageSize ?? this.skilleditSkillsPerPage; // nullish coalescing operator :)

    const newDateParam = this.showNewSkillOnly ? this.newSkillsDate : undefined;

    const additionalSkillsToShow = [];
    const unselectedSkills = [];
    this.employeeSkillsBySkillId.forEach((value, skillId) => {
      if (value.formControl.dirty && value.formControl.value !== "") {
        additionalSkillsToShow.push(skillId);
      }
    });

    if (this.resultsLength === 0 && pageSize === 9999) {
      this.employeeService.getSkillPage(pageNumber, pageSize, this.search.value, this.maintenance, this.showSkillsWithEmployeeSkill, this.employeeId, this.showFullTable,
        this.selectedCluster.value, this.selectedCategories.value, newDateParam, additionalSkillsToShow, unselectedSkills)
        .subscribe(page => {
          this.skilleditSkillsPerPage = page.totalElements;
          this.setData(pageNumber, pageSize, this.employeeId, additionalSkillsToShow, unselectedSkills);
        });
    } else {
      this.setData(pageNumber, pageSize, this.employeeId, additionalSkillsToShow, unselectedSkills);
    }
  }

  setData(pageNumber: number, skilleditSkillsPerPage: number, employeeIdParam: number, additionalSkillsToShow: any[], unselectedSkills: any[]) {
    this.employeeService.getSkillPage(pageNumber, skilleditSkillsPerPage, this.search.value, this.maintenance, this.showSkillsWithEmployeeSkill, this.showFullTable, employeeIdParam,
      this.selectedCluster.value, this.selectedCategories.value, this.showNewSkillOnly ? this.newSkillsDate : undefined,
      additionalSkillsToShow, unselectedSkills).subscribe(page => {
      this.resultsLength = page.totalElements;
      this.paginator.basePaginator.length = this.resultsLength;
      this.allSkillsDataSource.data = page.content;

      page.content.forEach(skillWithAverageLevel => {
        const skillId = skillWithAverageLevel.skill.id;
        const formControl = this.employeeSkillsBySkillId.get(skillId);

        if (!formControl) {
          this.employeeSkillsBySkillId.set(skillId, {
            formControl: new FormControl(),
            new: true,
            lastModifiedAt: '',
            expirationDate: new FormControl()
          });
        }
      });
    }).add(() => this.loading = false);
  }

  save() {
    this.loading = true;

    const toDelete: Id[] = [];
    const toUpdate: EmployeeSkill[] = [];
    const toCreate: EmployeeSkill[] = [];

    if (this.readonly) {
      this.employeeService.saveEmployeeLanguages(this.employeeId, this.languages);
    }

    this.employeeSkillsBySkillId.forEach((value, skillId) => {
      if (value.formControl.dirty || value.expirationDate.dirty) {
        const employeeSkill = {
          id: {employeeId: this.employeeId, skillId},
          experienceLevel: value.formControl.value,
          skill: undefined,
          expirationDate: value.expirationDate.value
        };

        if (!employeeSkill.experienceLevel || employeeSkill.experienceLevel === 'N/A') {
          if (!value.new || employeeSkill.experienceLevel === 'N/A') {
            toDelete.push(employeeSkill.id);
          }
        } else if (value.new) {
          toCreate.push(employeeSkill);
        } else {
          toUpdate.push(employeeSkill);
        }
      }
    });

    this.employeeSkillService.executeTransaction({toCreate, toDelete, toUpdate}).subscribe(() => {
      this.loadEmployeeSkillsById();
      this.messageService.add(this._translate.instant('mep.services.skill-db-dialog.successfully_saved'), AlertEnum.success);
    }).add(() => this.loading = false);
  }

  loadEmployeeSkillsById() {
    this.employeeService.getEmployeeSkillsById(this.employeeId).subscribe(employeeSkills => {
      this.employeeSkills = employeeSkills;

      employeeSkills.forEach(employeeSkill => {
        this.employeeSkillsBySkillId.set(employeeSkill.id.skillId, {
          formControl: new FormControl(employeeSkill.experienceLevel),
          new: false,
          lastModifiedAt: employeeSkill.lastModifiedAt,
          expirationDate: new FormControl(employeeSkill.expirationDate)
        });
      });
    });
  }

  closeDialog(editedSkills: boolean = false): void {
    this._dialogRef.close(editedSkills);
  }

  isSkillFreeText(skill: Skill): boolean {
    return skill && skill.skillName && skill.skillName.startsWith("Other");
  }

  editSkill(skill: any) {
    this.openSkillDialog(skill, false);
  }

  addNewSkill(skill: any) {
    this.openSkillDialog(skill, true);
  }

  openSkillDialog(skill: Skill, isAddMode: boolean) {
    const skillInfo = new EditSkillDialogInformation(skill, isAddMode, this.allClusters, this.allCategories);
    EditSkillDialogComponent.open(this.matDialog, skillInfo).afterClosed().subscribe(() => {
      this.fetchData().then();
    });
  }

  deleteSkill(skill: any) {
    let confirmationDialog = this.matDialog.open(ConfirmationDialogComponent, {
      disableClose: false,
      data: "mep.components.skill-db-dialog.delete-confirmation-message"
    });

    confirmationDialog.afterClosed().subscribe(selectedValue => {
      if (selectedValue) {
        this.employeeService.deleteSkill(skill).toPromise().then(() => {
          this.messageService.add(this._translate.instant('mep.services.skill-db-dialog.deleted-skill'), AlertEnum.success);
          this.fetchData();
        }).catch(error => {
          this.messageService.add(this._translate.instant('mep.services.skill-db-dialog.deleting-failed') + error, AlertEnum.danger);
        });
      }
    });
  }

  changeVisibility(skill: any) {
    this.employeeService.changeSkillVisibility(skill);
  }

  onSkillPageChange($event: PageEvent) {
    document.getElementById("allSkillsTable").scrollIntoView();
  }

  openLanguageEditDialog() {
    const dialog = this.matDialog.open(EditLanguagesDialogComponent, {
      data: {language: this.languageExtendedText.replace(/\n/g, '')}
    });

    dialog.componentInstance.submitLanguages.subscribe(result => {
      this.languages = result;
      dialog.componentInstance.submitLanguages.unsubscribe();
    });
  }

  get languageExtendedText() {
    return this.languagesService.getLanguageExtendedText(this.languages);
  }

  async exportSkills() {
    this.loading = true;
    let exportSkills;
    exportSkills = this.initializeExportSkillsWithCluster();

    let employeeName = "";

    if (await this.authService.hasAtLeastPermission(Permission.ONLY_RELATED_TO_ME, AppEntityAccessType.READ, AppEntity.EMPLOYEE, false, this.employeeId)) {
      let employee = await this.employeeService.getEmployeeById(this.employeeId).toPromise();
      employeeName = Object.assign(employee).firstName + "_" + Object.assign(employee).lastName;
    }

    this.employeeSkills.forEach(skill => {
      let exportSkill;
      exportSkill = new ExportSkillWithCluster();
      exportSkill.clusterName = skill.skill.category.cluster ? skill.skill.category.cluster.clusterName : null;

      exportSkill.categoryName = skill.skill.category.categoryName;
      exportSkill.skillName = skill.skill.skillName;
      exportSkill.experienceLevel = Number(skill.experienceLevel) ? Number(skill.experienceLevel) : skill.experienceLevel;
      exportSkills.push(exportSkill);
    });

    this.excelExportService.export(new SkillDbDialogExports(this.messageService, this.translate, this.lazyXlsxService,
      exportSkills, employeeName))
      .subscribe()
      .add(() => this.loading = false);
  }

  onListDrop($event: CdkDragDrop<SkillWithAverageLevel, any>) {
    if ($event.previousIndex === $event.currentIndex) {
      return;
    }
    let skillToMove = this.allSkillsDataSource.filteredData[$event.previousIndex].skill;
    let skillToDrop = this.allSkillsDataSource.filteredData[$event.currentIndex].skill;

    // Moving is only allowed within the same category
    if (skillToMove.category.id === skillToDrop.category.id) {
      const dialogRef = this.matDialog.open(ConfirmationDialogComponent, {
        disableClose: false,
        data: 'mep.components.skill-db-dialog.skill-moving-confirmation'
      });
      dialogRef.afterClosed().subscribe(value => {
        if (value) {
          if ($event.currentIndex === 0) {
            skillToMove.sorting = skillToDrop.sorting / 2.0;
          } else {
            let skillToCheck = this.allSkillsDataSource.filteredData[$event.currentIndex - 1].skill;
            skillToMove.sorting = $event.currentIndex < $event.previousIndex ? skillToDrop.sorting = (skillToCheck.sorting + skillToDrop.sorting) / 2 : skillToDrop.sorting;
          }
          this.loading = true;
          this.employeeService.saveSkill(skillToMove).subscribe(() => this.fetchData().then(() => this.loading = false));
        }
      });
    } else {
      this.messageService.add(this.translate.instant('mep.components.skill-db-dialog.skill-moving-outside-category'), AlertEnum.warning);
    }
  }


  private initializeExportSkillsWithCluster() {
    return [{
      clusterName: this._translate.instant('mep.components.skill-db-dialog.cluster'),
      categoryName: this._translate.instant('mep.components.skill-db-dialog.category'),
      skillName: this._translate.instant('mep.components.skill-db-dialog.skill'),
      experienceLevel: this._translate.instant('mep.components.skill-db-dialog.level')
    }, {
      clusterName: "",
      categoryName: "",
      skillName: "",
      experienceLevel: ""
    }];
  }

  getEmployeeFullNameString() {
    if (this.employeeFullName) {
      return this.employeeFullName.firstName + ' ' + this.employeeFullName.lastName + ' - ';
    } else {
      return '';
    }
  }
}
