import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import * as FileSaver from 'file-saver';
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import Config from "../../config/Config";

@Injectable({
  providedIn: 'root'
})
export class FileDownloadService {

  constructor(private http: HttpClient) {
  }

  downloadFile(url: string, fileName: string): Observable<void> {
    return this.http.post(url, {}, {responseType: 'blob'}).pipe(
      map(file => {
        const data: Blob = new Blob([file]);
        FileSaver.saveAs(data, fileName);
      })
    );
  }

  loadPreviewFile(id: string, fileName: string): Observable<Promise<void>> {
    return this.http.post(Config.services.employees.baseUrl + Config.services.employees.loadPreviewFile + id, {}, {responseType: 'blob'}).pipe(
      map((result: any) => {
        return result;
      })
    );
  }
}
