import {HotkeyDirective} from "./hotkey.directive";
import {Directive, Input, OnInit} from "@angular/core";

@Directive({
  selector: '[appEscapeHotkey]'
})
export class EscapeHotkeyDirective extends HotkeyDirective implements OnInit {

  ngOnInit() {
    this.appHotkey = "esc";
  }

  @Input()
  set appEscapeHotkeyEnabled(enabled: boolean) {
    this.appHotkeyEnabled = enabled;
  }
}
